/*-----------

Theme Name: U-Listing
Description: U-Listing Directory - Listing Html Template
Version: 1.0.8

------------*/

@charset "UTF-8";

/*Bootstrap Css*/
@import url("bootstrap-grid.css");
@import url("bootstrap.css");

/*Icons*/
@import url("icons.css");

/*Revolutionslider Css*/
@import url("revolutionslider.css");

/*Google Fonts*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800");
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,600i,700,800&subset=latin-ext');

/*=================
Table Of Content
------------------------------------

#. Typography
#. Loader
#. Header
#. Backto Top
#. Navigation
#. Footer
#. User Menu
#. Verified Style
#. Panel Menu Style
#. Bookings Style
#. Search Container Block
#. Main Inner Search Block
#. Rangeslider Style
#. Chosen Container Style
#. Listing Filter Block
#. Search Map Section Block
#. Sort By Style
#. Main Input Search Style
#. Select Option Style
#. Panel Dropdown Style
#. More Search Style
#. Navigation Layout Style
#. Listings Container Style
#. Bootstrap Column Style
#. Titlebar Style
#. Listing Slider Style
#. Box Widget Style
#. Opening Hours Style
#. Listing Share Style
#. Listing Nav Style
#. Pricing Info Style
#. Color Switcher Style
#. Pricing List Container Style
#. Show More Style
#. Comments Style
#. Review Style
#. Pagination Style
#. Contact Style
#. Sidebar Style
#. Blog Post Style
#. Widget Style
#. Blog Single Post Style
#. Button Style
#. Box Icon Style
#. Posts Nav Style
#. Coming Soon Style
#. Plan Featured Style
#. Testimonial Style
#. Flip Banner Style
#. Tabs Nav Style
#. Login Block
#. Social Icon Style
#. Check Box Style
#. Listing Item Container Style
#. Categories Box
#. Titlebar Style
#. Breadcrumbs Style
#. Page Not Found Style
#. Bookings Wallet Style
#. Bookings Payment Style
#. Bookings Style
#. Bookings Confimation Style
#. Edit Profile Style
#. Dashboard Content Style
#. Messages Style
#. Edit Profile Style
#. Dashboard List Style
#. Map Box Style
#. Dropzone Style
#. Slick Slider Style
#. Categorie Item Style
#. Typography List
#. Responsive Style
=================*/


/*==========
  Typography
============*/

body {
	color: #707070;
	font-size: 15px;
	line-height: 28px;
	background-color: #fff;
}
body p {
	font-size: 16px;
	line-height: 30px;
	font-weight:400;
}
#main_wrapper {
	background-color: #fff;
}
body, h1, h2, h3, h4, h5, h6, input[type="text"], input[type="password"], input[type="email"], textarea, select, input[type="button"], input[type="submit"], button, #tiptip_content, .map-box p, .map-box div, .numerical-rating .utf_counter_star_rating, body .nav_responsive i.menu-trigger:after {
	font-family:'Nunito', sans-serif;
	text-transform: none;
}
h1, h2, h3, h4, h5, h6 {
	color: #323232;
}
h1.white, h2.white, h3.white, h4.white, h5.white, h6.white {
	color: #fff;
}
h2.headline_part, h3.headline_part {
	font-size: 30px;
	line-height: 40px;
	margin: 0 0 30px 0;
	font-weight: 700;
}
h2.headline_part span {
	font-size: 20px;
	line-height: 30px;
}
input, input[type="text"], input[type="password"], input[type="email"], input[type="number"], textarea, select {
	height: 50px;
	line-height: 50px;
	padding: 0 15px;
	outline: none;
	font-size: 15px;
	color: #808080;
	margin: 0 0 16px 0;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	display: block;
	background-color: #fff;
	border: 1px solid #dbdbdb;
	box-shadow:0 4px 8px 0 rgba(0,0,0,0.03);
	font-weight: 400;
	opacity: 1;
	border-radius: 4px;
}
select {
	padding: 15px 18px;
	cursor: pointer;
}
.main_input_search_part_item .utf_chosen_select{
	background:#fff;
	border:0 #fff;
}
input {
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}
input:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, input[type="number"]:focus, textarea:focus {
	color: #808080;
	transition: box-shadow 0.2s!important;
	box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.15);
	border: 1px solid #d8d8d8;
	opacity: 1;
}
input[type="submit"] {
	border: none;
	padding: 11px 18px;
	width: auto;
}
input[type="checkbox"] {
	display: inline;
}
input[type="radio"] {
	width: 15px;
	height: 15px;
	cursor: pointer;
	box-shadow: none;
}
::-webkit-input-placeholder {
color:#888;
opacity:1;
}
:-moz-placeholder {
color:#888;
opacity:1;
}
::-moz-placeholder {
color:#888;
opacity:1;
}
:-ms-input-placeholder {
color:#888;
opacity:1;
}
textarea {
	height: auto;
	line-height: 24px;
	padding: 15px;
	min-height: 130px;
	transition: none!important;
	min-width: 100%;
}
label, legend {
	display: block;
	font-weight: bold;
	font-size: 15px;
	font-weight: normal;
	margin-bottom: 8px;
	font-weight:400;
}
label span, legend span {
	font-weight: normal;
	font-size: 14px;
	color: #444;
}
fieldset {
	padding: 0;
	border: none;
}
mark.color{
	background-color: #764c29;
}
a, button {
	outline: none!important;
}
a:focus, a:hover {
	text-decoration: none;
	color: #333;
}
img {
	max-width: 100%;
}

/*==========
  Loader
============*/
#vfx_loader_block {
	background: #ffffff;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
}
.vfx-loader-item {
	margin: 0 auto;
	position: relative;
	top: 50%;
	width: 100px;
}
#logo {
	display: inline-block;
	margin-top: 0px;
}
#logo img {
	max-height: 38px;
	width: auto;
	transform: translate3d(0, 0, 0);
}
img.footer-logo {
	max-height: 38px;
	width: auto;
}

/*==========
  Header
============*/
#header {
	position: relative;
	z-index: 999;
	padding: 23px 0 13px 0;
	box-shadow: 0 0 8px 0 rgba(0,0,0,0.12);
	font-size: 16px;
}
.header_widget {
	position: relative;
	top: -1px;
	height: 54px;
}
.header_widget .button, .header_widget .button.border {
	background:#764c29;
	border:1px solid transparent;
	color:#fff;
	margin:0 5px;
	min-width: 110px;
	text-align: center;
	position: relative;
	line-height: 26px;
	font-weight: 600;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.header_widget .button, .header_widget .button.border:hover {
	background:transparent;
	border:1px solid #764c29;
	color:#764c29;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.header_widget .button.with-icon:last-child {
	margin-right:0
}
.header_widget .button.border i {
	font-size: 18px;
	padding: 0px 7px 0 0px;
	top: 1px;
	display: inline-block;
	position: relative;
}
.header_widget .sign-in {
	position: relative;
	color: #333;
	margin-right: 25px;
	top: 10px;
	display: inline-block;
	vertical-align: top;
}
.header_widget .sign-in i {
	padding-right: 4px;
	font-size: 15px;
}
.header_widget .sign-in:hover {
	color: #66676b;
}
header.fullwidth_block .container {
	width: 100%;
	margin: 0;
	padding: 0 20px;
}
.fl_left{
	float:left;
}
.fl_right{
	float:right;
}
.utf_left_side {
	float: left;
	width: 68%;
	display: inline-block;
}
.utf_right_side {
	float: right;
	width: 32%;
	display: inline-block;
}
#logo, #navigation {
	float: left;
}
#logo {
	margin-right: 40px;
}
.header_widget {
	text-align: right;
}
#header.cloned {
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 999;
}
#header.cloned {
	background-color:rgba(255, 255, 255, 0.98);
}
#header.cloned #logo img {
	transform: translate3d(0, 0, 0);
	max-width: 140px;
	margin-top: 1px;
}
#header.cloned {
	padding: 18px 0 7px 0;
}
.sticky-logo {
	display: none;
}
#header.cloned.unsticky {
	opacity: 1;
	visibility: hidden;
	pointer-events: none;
	transform: translate(0, -100%) scale(1);
	transition: 0.3s;
}
#header.cloned.sticky {
	opacity: 1;
	visibility: visible;
	transform: translate(0, 0) scale(1);
	transition: 0.3s;
}
.header-one .search_container_block:before {
	background: rgba(48,48,48,.80)
}

/*==========
  Backto Top
============*/
#bottom_backto_top {
	position: fixed;
	right: 0;
	opacity: 0;
	visibility: hidden;
	bottom: 25px;
	margin: 0 25px 0 0;
	z-index: 9999;
	transition: 0.35s;
	transform: scale(0.7);
}
#bottom_backto_top.visible {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
}
#bottom_backto_top a {
	text-decoration: none;
	border: 0 none;
	display: block;
	width: 46px;
	height: 46px;
	background-color: #764c29;
	opacity: 1;
	transition: all 0.3s;
	border-radius: 50%;
	text-align: center;
	font-size: 26px
}
body #bottom_backto_top a {
	color: #fff;
}
div.datedropper .pick-m, div.datedropper .pick-submit, div.datedropper:before, div.datedropper .pick-lg-h{
    background-color: #764c29 !important;
}
div.datedropper .pick-btn, div.datedropper .pick li span, div.datedropper.primary .pick-lg-b .pick-wke{
	color:#764c29 !important
}
#bottom_backto_top a:after {
	content: "\f106";
	font-family: "FontAwesome";
	position: relative;
	display: block;
	top: 50%;
	-webkit-transform: translateY(-55%);
	transform: translateY(-55%);
}

/*==========
  Navigation
============*/
#navigation {
	position: relative;
	display: block;
}
#navigation ul {
	list-style: none;
	position: relative;
	float: left;
	margin: 0;
	padding: 0;
}
#navigation ul a {
	display: block;
	text-decoration: none;
}
#navigation ul li {
	position: relative;
	float: left;
	margin: 0;
	padding: 0
}
#navigation ul li ul {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	top: 100%;
	left: 0;
	padding: 0;
	z-index: 99999;
}
#navigation ul ul li {
	float: none;
}
#navigation ul ul ul {
	top: -1px;
	left: 100%;
	margin: 0;
}
#navigation ul ul {
	margin: 6px 0 0 0px;
}
#navigation ul li ul:before {
    content: '';
    position: absolute;
	left: 25px;
    bottom: 100%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #eaeaea;
}
#navigation ul li ul li ul:before {
    content: '';
    position: absolute;
    left: -20px;
    bottom: 100%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #eaeaea transparent;
    transform: rotate(-90deg);
    top: 10px;
}
#navigation ul li:hover>ul {
	opacity: 1;
	visibility: visible;
}
#navigation ul li a:after, #navigation ul ul li a:after {
	font-family: "FontAwesome";
	opacity: 0.5;
}
#navigation ul li a:after {
	content: '\f101';
	padding-left: 7px;
	font-weight:400;
}
#navigation ul ul li a:after {
	content: '\f101';
	position: absolute;
	right: 15px;
	top: 7px;
}
#navigation ul li a:only-child:after {
	content: '';
	padding: 0;
}
#navigation ul ul li a:only-child:after {
	content: '';
}
#navigation ul ul {
	background-color: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.12);
	border-radius: 4px;
	font-size: 15px;
	border:1px solid rgba(0, 0, 0, 0.05);
}
#navigation ul ul li {
	border-radius: 4px;
}
#navigation ul ul li:last-child {
	border-bottom: none;
}
#navigation ul li:hover ul a, #navigation ul ul a {
	line-height: 27px!important;
	padding: 8px 15px!important;
	line-height: 24px!important;	
}
#navigation ul li:hover ul a, #navigation ul ul a:last-child{
	border-bottom:0;
}
body #navigation ul ul {
	padding:0;
	box-sizing: border-box;
}
#navigation ul ul li {
	width: 220px;
}
#navigation ul ul li:hover {
	border-radius: 0;
}
#navigation ul ul li:first-child {
	border-radius: 4px 4px 0 0;
}
#navigation ul ul li:last-child {
	border-radius: 0 0 4px 4px;
}
#navigation ul ul {
	opacity: 0;
	visibility: hidden;
	transition: all 0.25s;
	transform: translate3d(0, 15px, 0);
}
#navigation ul li:hover>ul {
	opacity: 1;
	visibility: visible;
	transform: translate3d(0, 0, 0);
}
#navigation.style_one .current {
	background-color: transparent;
	color: #764c29;
}
#navigation.style_one {
	margin-top: 0px;
}
#navigation.style_one .current:after {
	opacity: 1;
}
#navigation.style_one ul li {
	transition: all 0.25s;
}
#navigation.style_one ul li a {
	color: #444;
	cursor: pointer;
}
#navigation.style_one ul li:hover a {
	color: #764c29;
}
#navigation.style_one ul ul li a {
	color: #444 !important;
}
#navigation.style_one ul ul li a.active {
    color: #764c29 !important;
}
#navigation.style_one ul ul li {
	opacity: 1;
}
#navigation.style_one ul ul li:hover {
	opacity: 1;
}
#navigation.style_one ul a {
	line-height: 32px;
	font-weight:400;
	padding: 5px 15px 15px 15px;
}
#navigation.style_one ul li {
	margin: 0 2px;
}
#navigation.style_one ul ul li {
	margin: 0;
	border-radius: 0;
	border-bottom:1px solid rgba(0, 0, 0, 0.07);	
}
#navigation.style_one ul ul li:last-child{
	border-bottom:0;
}
#navigation.style_one ul ul li:first-child {
	border-radius: 4px 4px 0 0;
}
#navigation.style_one ul ul li:last-child {
	border-radius: 0 0 4px 4px;
}
#navigation ul ul li a:after {
	opacity: 1;
	transition: all 0.25s;
	transform: translateX(-5px);
}
#navigation ul li ul li a:hover{
	color:#764c29 !important;
}	
#navigation ul ul li:hover a:after {
	opacity: 1;
	transform: translateX(0);
}

/*==========
  Footer
============*/
#footer {
	padding:170px 0 0 0;
	background-color:#f9f9f9;
	border-top:1px solid rgba(0, 0, 0, 0.05);
	font-size: 16px;
	position:relative;
}
#footer.footer_sticky_part {
	box-shadow: none;
}
#footer.dark {
	border-top: none;
}
#footer h4 {
	position:relative;
	color: #424242;
	font-size: 20px;
	font-weight:600;
	margin-bottom: 35px;
}
#footer h4:after {
    position: absolute;
    content: '';
    left: 0px;
    bottom: -10px;
    height: 2px;
    width: 30px;
    background-color: #764c29;
}
#footer:before {
    position: absolute;
    content: '';
    bottom: 0px;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: url(../images/footer-bg.png) no-repeat;
	background-size: cover;
}
.footer-shadow {
	width: 100%;
	display: block;
	background: #fff;
	height: 200px;
	margin-top: -200px;
	position: relative;
	z-index: -1;
	box-shadow: 0 20px 40px -20px rgba(0,0,0,0.06);
}
.text-widget span {
	color: #707070;
}
.text-widget span.address {
	display: block;
	margin-bottom: 10px;
}
#footer a {
	color: #66676b;
}
#footer ul.social_footer_link {
	margin: 0 0 15px 0;
	padding: 0;
	width:100%;
	box-sizing: border-box;
	display: inline-block;
	float: left;
}
#footer .social_footer_link li {
	position: relative;
	list-style: none;
}
#footer .social_footer_link li a {
	padding: 0 0 10px 20px;
	display: inline-block;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;	
	color: #707070;
	font-weight:400;
	line-height: 22px;
}
#footer .social_footer_link li a:hover {
	padding-left: 25px;
	color: #764c29;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;	
}
#footer .social_footer_link li:before {
	font-family: "FontAwesome";
	font-size: 18px;
	content: "\f101";
	position: absolute;
	left: 0;
	top: 0px;
	padding: 0 7px 0 0;
	color: #999;
}
.footer_copyright_part {
	color: #707070;
	border-top: 1px solid rgba(0,0,0,0.09);
	margin-top: 60px;
	text-align: center;
	padding: 40px 0;
	display: inline-block;
	width: 100%;
	font-weight:400;
}
#footer.alt .footer_copyright_part {
	margin-top: 0;
	border: none;
	padding: 25px;
}
.footer_copyright_part a {
	color: #707070;
}
#footer.dark {
	background: #222;
	color: #aaa;
}
#footer.dark a, #footer.dark h4, #footer.dark a:hover {
	color: #fff;
}
#footer.dark .text-widget, #footer.dark .text-widget span, #footer.dark .social_footer_link li a {
	color: #aaa;
}
#footer.dark .social_footer_link li a:hover {
	color: #fff;
}
#footer.dark .social_footer_link li:before {
	color: #666;
}
#footer.dark .footer_copyright_part {
	color: #aaa;
	border-top: 1px solid rgba(255,255,255,0.09);
}
#footer .utf_social_icon li a {
	opacity: 0.8;
	transition: opacity 0.35s;
}
#footer .utf_social_icon li a:hover {
	opacity: 1;
}

/*==========
  User Menu
============*/
.utf_user_menu {
	position: relative;
	display: inline-block;
	cursor: pointer;
	margin-right: 5px;
	top: 9px;
	vertical-align: top;
	padding-left: 25px;
	margin-left: 25px;
}
.utf_user_menu ul:before {
    content: '';
    position: absolute;
	right: 25px;
    bottom: 100%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fff;
}
.utf_user_menu .utf_user_name {
	transition: 0.2s;
	text-align:left;
}
.utf_user_menu .utf_user_name:after {
	content: "\f0d7";
	font-family: "FontAwesome";
	transition: 0.2s;
	transform: rotate(0deg);
	display: inline-block;
	margin-left: 6px;
	color: #aaa;
}
.utf_user_menu.active .utf_user_name:after, .utf_user_menu:hover .utf_user_name:after, .utf_user_menu.active .utf_user_name, .utf_user_menu:hover .utf_user_name {
	color: #66676b;
}
.utf_user_menu.active .utf_user_name:after {
	transform: rotate(180deg);
}
.utf_user_name span img {
	width: 100%;
	display: inline-block;
	border-radius: 50%;
    border:0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);    
}
.utf_user_name span {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin-right: 10px;
	border-radius: 50%;
	position: absolute;
	left: -26px;
	top: -5px;
}
.utf_user_menu ul {
	float: left;
	text-align: left;
	position: absolute;
	top: 55px;
	right: 0;
	list-style: none;
	background-color: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.12);
    border-radius: 4px;
    border:1px solid rgba(0, 0, 0, 0.05);
	display: inline-block;
	width: 190px;
	font-size: 15px;
	transform: translate3d(0, 15px, 0);
	padding:0;
	box-sizing: border-box;
	transition: 0.25s;
	visibility: hidden;
	opacity: 0;
	z-index: 110;
}
.utf_user_menu ul li{
	border-bottom:1px solid rgba(0, 0, 0, 0.05);
}
.utf_user_menu ul li:last-child{
	border-bottom:none;
}
.utf_user_menu ul li a {
	padding:7px 10px;
	line-height: 22px;
	display: inline-block;
	color: #696969;
	width:100%;
	transition: 0.2s;
}
.utf_user_menu ul li a:hover {
	color: #764c29;
}
.utf_user_menu ul li a i {
	width: 30px;
	position: relative;
	display: inline-block;
	margin-right: 10px;
	background: #efefef;
	height: 30px;
	padding: 5px;
	line-height: 20px;
	border-radius:50%;
	text-align: center;
	color: #626262;
	font-size: 14px;
}
.utf_user_menu.active ul {
	visibility: visible;
	opacity: 1;
	transform: translate3d(0, 0, 0);
}
.nav_responsive, #utf_panel_menu {
	display: none;
}
@media only screen and (max-width: 1024px) {
.menu {
	max-height: none;
}
.utf_user_menu ul:before {
	content: '';
	position: absolute;
	left: 25px;
	bottom: 100%;
	border-width: 15px;
	border-style: solid;
	border-color: transparent transparent #fff;
	width: 26px;
}
#responsive {
	display: none;
}
#utf_panel_menu, .nav_responsive {
	display: block!important
}
.nav_responsive {
	color: #777;
	display: block;
	float: left;
	position: relative;
	width:auto;
	margin: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	border-radius: 4px;
}
#main-header {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.nav_responsive i {
	padding: 12px 14px;
	margin: 0;
	font-size:20px;
	font-weight: 500;
	cursor: pointer;
	width: 100%;
	color:#fff;
	background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, transparent);
	background-color: #764c29;
	border-radius:30px;
	margin: 0px 0 20px 0;
}
.nav_responsive i.menu-trigger {
	width: 100%;
}
.responsive-search input:focus {
	border: none;
	color: #fff;
}
#logo {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    margin-top: 5px;
    width: 40%;
    text-align: center;
    left: 0;
    right: 0;
}
#header_part.header-style-2 .utf_left_side, #header_part.header-style-2 .utf_right_side, .utf_right_side, .utf_left_side {
	width: 100%;
}
#top-bar .utf_right_side .utf_social_icon {
	float: left;
	position: relative;
	z-index: 9;
}
#header_part.header-style-2 .header_widget {
	float: left;
	margin-top: 35px;
	margin-bottom: -5px
}
.header_widget {
    text-align: center;
    padding-top: 18px;
    border-top: 1px solid #d2d2d2;
}
ul.top-bar-menu {
	z-index: 99;
	position: relative;
}
.header-style-2 .nav_responsive {
	width: 750px;
	margin-left: auto;
	margin-right: auto;
	float: none;
	padding: 0 15px;
}
#header_part.header-style-2 .header_widget {
	width: calc(100%);
	border-top: 1px solid #e0e0e0;
	padding-top: 30px;
}
}
@media (max-width: 767px) {
.header-style-2 .nav_responsive {
	width: auto;
	margin-left: auto;
	margin-right: auto;
	float: none;
	padding: 0 15px;
}
#header_part.header-style-2 #header .container {
	width: 100%;
}
#header_part.header-style-2 .header_widget li .button {
	margin-left: 0;
}
#header_part.header-style-2 .header_widget li:after {
	display: none;
}
#header_part.header-style-2 .header_widget li {
	width: 100%;
	padding: 10px 0;
	margin: 0;
}
#header_part.header-style-2 .header_widget li i {
	width: 50px;
	margin: 0 0 0 0px;
	display: inline-block;
	text-align: left;
	top: 3px;
}
}
.fa.fa-search.active:before {
	content: "\f00d";
}
body[data-menu-position="open"] .nav_responsive i.fa.fa-reorder:before {
	content: "\f00d";
}
body[data-menu-position="closed"] .nav_responsive i.fa.fa-reorder:before {
	content: "\f0c9";
}
#utf_panel_menu a.current:after, #utf_panel_menu .with-ul:after {
	display: none;
}
body #utf_panel_menu li a {
	display: block;
	padding: 8px 20px;
	background-color: #764c29;
	color: #eaeaea;
	font-weight: 600;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
	outline: none;
	text-transform: none;
	text-shadow: none;
}
body #utf_panel_menu li li a {
	background-color: #363636;
	padding-left: 30px;
	text-transform: none;
	font-weight: 400;
}
body #utf_panel_menu li li li a {
	padding-left: 48px;
	position: relative;
}

/*==========
  Panel Menu Style
============*/
#utf_panel_menu li.cart-icon, #utf_panel_menu li.search, #utf_panel_menu .cart-counter, #utf_panel_menu .hidden-on-mobile, #utf_panel_menu .mega-headline_part {
	display: none;
}
#utf_panel_menu, #utf_panel_menu ul {
	list-style: none;
	padding-left: 0;
}
#utf_panel_menu li a{
	border-bottom:1px solid rgba(255, 255, 255, 0.2)
}
#utf_panel_menu ul li a{
	border-bottom:1px solid #525252
}
#utf_panel_menu ul li a i {
	padding-right: 7px;
}
#utf_panel_menu .mega-content {
	display: inline-block;
}
#utf_panel_menu .mega-section {
	display: inline-block;
	float: none;
	width: 100%;
	border-right: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

/*==========
  Search Container Block
============*/
.search_container_block {
	display: block;
	width: 100%;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50%;
	height: 620px;
	z-index: 9;
}
.search_container_block:before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	display: block;
	background:rgba(0, 0, 0, 0.6);
}
.search_container_block h2 {
	font-size: 42px;
	color:#fff;
	margin-bottom:15px;
	font-weight:700;
	text-align:center;
}
.search_container_block h2 span.typed-words{
	color:#764c29;
}
@media (max-width: 767px) {
.typed-words {
    display: block !important;
    width: 100%;
    min-height: 46px;
}
}
.search_container_block h4 {
	font-size: 22px;
	font-weight: 400;
	color: #ccc;
	line-height: 32px;
	text-align:center;
}
.utf_video_container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -10;
	overflow: hidden;
}
.search_container_block video {
	top: 50%;
	transform: translateY(-52%);
	left: 0;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -10;
	background-size: cover;
	transition: 1s opacity;
	position: absolute;
}
.search_container_block.overlay_dark_part:before {
	background: rgba(0, 0, 0, 0.70);
}
.search_container_block.overlay_dark_part h2 {
	color: #fff;
}
.search_container_block.overlay_dark_part h4 {
	color: rgba(255,255,255,0.7);
}

/*==========
  Main Inner Search Block
============*/
.main_inner_search_block {
	position: relative;
	display: block;
	top: 50%;
	transform: translate(0, -50%);
	padding-bottom: 30px;
}
.main_inner_search_block select {
	border: none;
	padding-top: 2px;
	padding-bottom: 0;
	height: 44px;
	box-shadow: none;
}
.main_input_search_part {
	margin-top: 50px;
	border-radius: 4px;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 0 8px 0 rgba(0,0,0,0.12);
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	padding: 6px;
	border:3px solid rgba(0, 0, 0, 0.12);
	max-height: 70px;	
}
.main_input_search_part_item {
	flex: 1;
	border-right:1px solid rgba(0, 0, 0, 0.10);
	margin-top: 2px;
	position: relative;
	padding-left: 20px;
	padding-right: 20px;
}
.main_input_search_part .main_input_search_part_item:nth-last-child(2) {
	border-right: none;
	padding-left: 15px;
	padding-right: 15px;
}
.main_input_search_part_item.location a {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	color: #c0c0c0;
	padding: 10px 0;
	background:transparent;
}
.main_input_search_part_item.location a i{
	font-weight:600;
}
.main_input_search_part_item.location a:hover {
	color: #764c29;
}
.main_input_search_part input, .main_input_search_part input:focus {
	font-size: 16px;
	border: none;
	background: #fff;
	margin: 0;
	padding: 0;
	height: 44px;
	line-height: 44px;
	box-shadow: none;
}
.main_input_search_part button.button {
	font-size: 18px;
	font-weight: 400;
	padding: 0 40px;
	margin-right: 1px;
	height: 42px;
	margin-top: 5px;
	outline: none;
}
.gray-style.main_input_search_part {
	box-shadow: none;
	background-color: #f7f7f7;
	top: -10px;
	position: relative;
}
.gray-style .main_input_search_part_item.location a, .gray-style.main_input_search_part input, .gray-style.main_input_search_part input:focus {
	background: #f7f7f7;
}
.gray-style .main_input_search_part_item {
	border-color: #e0e0e0;
}
.utf_listing_section .detail-video iframe {
	max-width: 100%;
	border:3px solid rgba(0, 0, 0, 0.2);
	border-radius:4px;
}

/*==========
  Rangeslider Style
============*/
.rangeslider, .utf_rangeslider_fill {
	display: block;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
}
.rangeslider {
	background: #e6e6e6;
	position: relative;
	margin: 25px 0;
}
.utf_rangeslider_horizontal {
	height: 6px;
	width: 100%;
}
.utf_rangeslider_vertical_part {
	width: 20px;
	min-height: 150px;
	max-height: 100%;
}
.utf_rangeslider_disabled {
	filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
	opacity: 0.4;
}
.utf_rangeslider_fill {
	background: #764c29;
	position: absolute;
}
.utf_rangeslider_horizontal .utf_rangeslider_fill {
	top: 0;
	height: 100%;
}
.utf_rangeslider_vertical_part .utf_rangeslider_fill {
	bottom: 0;
	width: 100%;
}
.utf_rangeslider_handle {
	background: #764c29;
	border: 2px solid #764c29;
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.15);
	cursor: pointer;
	display: inline-block;
	position: absolute;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	top: 50%;
	transform: translateY(-50%);
}
.utf_rangeslider_vertical_part .utf_rangeslider_handle {
	left: -10px;
	touch-action: pan-x;
	-ms-touch-action: pan-x;
}
input[type="range"]:focus+.rangeslider .utf_rangeslider_handle {
	-moz-box-shadow: 0 0 8px rgba(255,0,255,0.9);
	-webkit-box-shadow: 0 0 8px rgba(255,0,255,0.9);
	box-shadow: 0 0 8px rgba(255,0,255,0.9);
}
.utf_range_output {
	font-size: 20px;
	font-weight: 600;
	color: #666;
}
.utf_range_output:after {
	content: " Miles";
}
.data-radius-title {
	font-style: normal;
	font-size: 15px;
	color: #888;
	font-weight: 400;
	margin-top: 0;
	display: block;
}

/*==========
  Chosen Container Style
============*/
.chosen-container {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	font-size: 15px;
	zoom: 1;
	*display:inline;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.chosen-container * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.chosen-container .chosen-drop {
	position: absolute;
	top: 100%;
	left: 0;
	display: block;
	z-index: 990;
	width: 100%;
	border: 1px solid #dbdbdb;
	background-color: #fff;
	border-radius:0px;
}
.chosen-container.chosen-with-drop .chosen-drop {
	left: 0;
}
.chosen-container a {
	cursor: pointer;
}
.chosen-container-single .chosen-single {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 0 0 0 15px;
	height: 51px;
	line-height: 50px;
	border: 1px solid #dbdbdb;
	box-shadow:0 4px 8px 0 rgba(0,0,0,0.03);
	background-color: #fff;
	color: #888;
	text-decoration: none;
	white-space: nowrap;
	font-weight: 500;
	border-radius: 4px;
}
.booking_widget_box .row.with-forms .chosen-container{
	margin-bottom:15px;
}
.booking_widget_box .chosen-container-active.chosen-with-drop .chosen-single {
    border: 0px solid transparent;
}
.booking_widget_box .chosen-container-single .chosen-single{
	border: 0px solid transparent;
	box-shadow:0 1px 6px 0 rgba(0,0,0,.1);	
}
.booking_widget_box .chosen-container-single .chosen-single span{
	font-weight: 400;
	color:#888888;
}
.chosen-container-active.chosen-with-drop .chosen-single {
	border-radius: 4px 4px 0 0;
}
.chosen-container-active .chosen-drop, .chosen-container-single .chosen-drop {
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;
}
.chosen-container-active.chosen-with-drop .chosen-drop {
	opacity: 1;
	visibility: visible;
	z-index:9999;
}
.chosen-container-single .chosen-default {
	color: #999;
}
.chosen-container-single .chosen-single span {
	display: block;
	overflow: hidden;
	margin-right: 26px;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size:15px;
	color: #909090;
	font-weight: 400;
}
.chosen-container-single .chosen-single-with-deselect span {
	margin-right: 38px;
}
.chosen-container-single .chosen-single div {
	position: absolute;
	top: 0;
	right: 0px;
	float:right;
	display: block;
	width: 18px;
	height: 100%;
}
.chosen-container-single .chosen-single div:after {
	content: "\f0d7";
	font-family: "FontAwesome";
	font-size: 14px;
	margin:0;
	right: 20px;
	position: relative;
	width: auto;
	height: auto;
	display: inline-block;
	color: #c0c0c0;
	float: right;
	font-weight: normal;
	transition: transform 0.3s;
	transform: translate3d(0, 0, 0) rotate(0deg);
}
.chosen-container-active.chosen-with-drop .chosen-single div:after {
	transform: translate3d(0, 0, 0) rotate(180deg);
}
.chosen-container-single .chosen-search {
	position: relative;
	z-index: 1010;
	margin: 0;
	padding: 3px 8px;
	white-space: nowrap;
}
.chosen-container-single .chosen-search input[type="text"] {
	margin: 1px 0;
	padding: 6px 20px 6px 15px;
	width: 100%;
	height: 42px;
	outline: 0;
	margin-bottom: 10px!important;
	border: 1px solid #ddd!important;
	background-color: #fbfbfb!important;
	display: block;
	position: relative;
	z-index: 9;
	border-radius:4px;
	background-color: #fafafa;
	box-shadow: 0 1px 3px 0px rgba(0,0,0,0.04);
}
.chosen-container-single .chosen-search:before {
	content: "\f002";
	font-family: "FontAwesome";
	font-size: 15px;
	margin: 0 15px 0 0;
	top: 12px;
	position: relative;
	width: 11px;
	height: 5px;
	display: inline-block;
	color: #aaa;
	float: right;
	font-weight: normal;
	z-index: 10;
	transition: transform 0.3s;
}
.chosen-container-single .chosen-drop {
	margin-top:4px;
	background-clip: padding-box;
}
.chosen-container-single.chosen-container-single-nosearch .chosen-search {
	position: absolute;
	left: -9999px;
}
.chosen-container .chosen-results {
	color: #888;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	margin: 0;
	padding: 0;
	max-height: 300px;
}
#utf_rev_slider_block .chosen-container .chosen-results {
	height:150px;
}
.chosen-container .chosen-results li:last-child{
	border-bottom:0;
}
.chosen-container .chosen-results li {
	display: none;
	margin: 0;
	padding:10px 15px;
	list-style: none;
	font-size:15px;
	font-weight:400;
	line-height: 18px;
	border-bottom:1px solid rgba(0, 0, 0, 0.05);
	word-wrap: break-word;
	-webkit-touch-callout: none;
}
.chosen-container-multi .chosen-results li {
	padding: 10px 18px;
	transition: color 0.3s;
}
.chosen-container .chosen-results li.active-result {
	display: list-item;
	cursor: pointer;
}
.chosen-container .chosen-results li:hover {
	color:#764c29 !important;
}
.chosen-container .chosen-results li.disabled-result {
	display: list-item;
	color: #ccc;
	cursor: default;
}
.chosen-container .chosen-results li.highlighted {
	background-color: transparent;
	border-radius: 0;
	color: #66676b;
	transition: color 0.2s ease-in-out;
}
.chosen-container .chosen-results li.no-results {
	color: #888;
	display: list-item;
	background-color: #fff;
}
.chosen-container-multi .chosen-results li.no-results {
	background-color: #fff;
}
.chosen-container .chosen-results li.group-result {
	display: list-item;
	font-weight: bold;
	cursor: default;
}
.chosen-container .chosen-results li.group-option {
	padding-left: 15px;
}
.chosen-container .chosen-results li em {
	font-style: normal;
	text-decoration: underline;
}
.chosen-container-multi .chosen-choices {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 0 0 0 18px;
	height: auto;
	border: 1px solid #dbdbdb;
	background-color: #fff;
	text-decoration: none;
	white-space: nowrap;
	line-height: 33px;
	cursor: pointer;
	margin: 0;
	border-radius: 4px;
	box-shadow: 0 1px 3px 0px rgba(0,0,0,0.06);
}
.chosen-container-multi.chosen-with-drop .chosen-choices {
	border-radius: 4px 4px 0 0;
}
.chosen-container-multi .chosen-choices li {
	float: left;
	list-style: none;
}
.chosen-container-multi .chosen-choices li.search-field {
	margin: 0;
	padding: 0;
	white-space: nowrap;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
	margin: 0;
	padding: 0;
	height: 49px;
	outline: 0;
	border: 0!important;
	background: transparent!important;
	color: #888;
	line-height: normal;
	font-weight: 500;
	box-shadow: none;
	transition: none;
}
.chosen-container-multi .chosen-choices li.search-choice {
	position: relative;
	margin: 10px 6px 0 -4px;
	padding: 0 25px 0 8px;
	max-width: 100%;
	background-color: #66676b;
	line-height: 29px;
	color: #fff;
	cursor: default;
	font-weight: 500;
	border-radius: 3px;
}
.chosen-container-multi .chosen-choices li.search-choice span {
	word-wrap: break-word;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
	position: absolute;
	top: -2px;
	right: 7px;
	display: block;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before {
	content: "\f00d";
	font-family: "FontAwesome";
	font-size: 13px;
	top: 1px;
	position: relative;
	width: 11px;
	height: 5px;
	display: inline-block;
	color: #fff;
	float: right;
	font-weight: normal;
}
.chosen-container-multi .chosen-choices li.search-choice-disabled {
	padding-right: 5px;
	border: 1px solid #ccc;
	background-color: #e4e4e4;
	color: #888;
}
.chosen-container-multi .chosen-choices li.search-choice-focus {
	background: #d4d4d4;
}
.chosen-container-multi .chosen-results {
	margin: 0;
	padding: 0;
}
.chosen-container-multi .chosen-drop .result-selected {
	display: list-item;
	color: #ccc;
	cursor: default;
}
.chosen-container.chosen-container-multi .chosen-drop {
	opacity: 0;
	visibility: hidden;
	padding: 10px 0;
}
.chosen-container.chosen-container-multi.chosen-with-drop.chosen-container-active .chosen-drop {
	opacity: 1;
	visibility: visible;
}
.chosen-container-active .chosen-single, .chosen-container-active .chosen-choices, .chosen-container-active.chosen-with-drop .chosen-single {
	border: 1px solid #e0e0e0;
}
.chosen-container-active.chosen-with-drop .chosen-single div {
	border-left: none;
	background: transparent;
}
.chosen-container-active .chosen-choices li.search-field input[type="text"] {
	color: #888!important;
}
.chosen-drop ::-webkit-scrollbar {
width:10px;
}
.chosen-drop ::-webkit-scrollbar-track {
background-color:rgba(0,0,0,0.05);
border-radius:10px;
}
.chosen-drop ::-webkit-scrollbar-thumb {
border-radius:10px;
background-color:rgba(0,0,0,0.2);
}
.chosen-drop ::-webkit-scrollbar-thumb:hover {
border-radius:10px;
background-color:rgba(0,0,0,0.3);
}
.input-with-icon {
	position: relative;
}
.input-with-icon i {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	padding: 0 0 0 19px;
	color: #999;
	transition: 0.2s;
	pointer-events: none;
}
.input-with-icon input {
	padding-left: 47px!important
}
.input-with-icon.location input {
	padding-left: 20px!important
}
.input-with-icon.location a i {
	position: absolute;
	right: 1px;
	left: auto;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
	padding: 15px 20px 15px 15px;
	background:transparent;
	pointer-events: all
}
.input-with-icon.location a i:hover {
	color: #764c29;
}

/*==========
  Listing Filter Block
============*/
.listing_filter_block{
	padding:15px 0px;
	background:#f9f9f9;
	border-radius:4px;
	margin-bottom:30px;
	display:inline-block;
	width:100%;
	border:1px solid rgba(0, 0, 0, 0.05);
}
.listing_filter_block .sort-by{
    top: 5px;
}
.listing_filter_block .utf_search_map_section ul{
	margin-bottom:0
}
.listing_filter_block .utf_search_map_section li{
	margin-bottom:0
}
.sort-by {
	position: relative;
	top: 9px;
}
.sort-by label, .sort-by .utf_sort_by_select_item {
	display: inline-block;
	float: right;
}
.utf_dashboard_list_box .sort-by .utf_sort_by_select_item{
	margin-top:-10px;
	margin-right:-5px
}
.utf_sort_by_select_item select {
	border-radius: 4px;
	color: #666;
	font-weight:600;
	margin-bottom:10px;
	border: 1px solid rgba(0, 0, 0, 0.05);
	background-color: #f3f3f3;
	height: auto;
	padding: 7px 7px 7px 15px;
	line-height: 22px;
	box-shadow: none;
}
.listing_filter_block .utf_sort_by_select_item select{
	margin-bottom:10px !important;
}
.sort_by_margin{
	margin-left:10px;
}
.sort-by label {
	line-height: 40px;
	margin-top: 1px;
	padding: 0;
}
.sort-by .chosen-container-single .chosen-single {
	height: 40px;
	line-height: 38px;
	border: 1px solid rgba(0, 0, 0, 0.07);
	background-color:#fcfcfc;
	width: 100%;
	display: inline-block;
	padding: 0 15px;
	box-shadow: none;
	color: #666;
	font-weight: 500;
	font-size: 14px;
	border-radius:4px;
	transition: all 0.3s;
	display: inline-block;
}

/*==========
  Search Map Section Block
============*/
.utf_search_map_section .lc-all-filter {
    padding-left: 0;
    margin-bottom: 0px;
}
.utf_search_map_section li {
    list-style: none;
    display: inline-block;
    margin-right: 0px;
    margin-bottom: 25px;
}
.utf_search_map_section .utf_common_button {
	font-size:14.5px;
    height: 40px;
    line-height: 38px;
    padding-top: 0;
	font-weight: 600;
	color: #666;
}
.utf_search_map_section .utf_common_button:hover{
	background:#764c29;
	color:#fff;
}
.utf_search_map_section .utf_common_button i{
	margin-right:7px;
}
.utf_common_button {
    height: 45px;
	color: #666;
    font-weight: 500;
    transition: all 0.2s;
    line-height: 36px;
    border-radius:4px;
    height: 36px;
    line-height: 34px;
    border:1px solid rgba(0, 0, 0, 0.07);
    background-color: #fcfcfc;
	padding: 0 15px;
    display: block;    
}

/*==========
  Sort By Style
============*/
.sort-by .chosen-container-active .chosen-choices, .sort-by .chosen-container-active.chosen-with-drop .chosen-single, .sort-by .chosen-single:hover {
	background: #f3f3f3;
}
.utf_panel_dropdown.float-right a:hover{
	background: #f3f3f3;
}
.utf_panel_dropdown.float-right.active a, .panel-dropdown a:hover{
	background: #f3f3f3;
}
.sort-by .chosen-container .chosen-drop {
	padding-top:0px;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	width: 240px;
	margin-left: 10px;
	background: #fff;
	box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.08);
	z-index: 997;
}
.sort-by .chosen-container.chosen-with-drop .chosen-drop, .sort-by .chosen-container .chosen-drop {
	left: auto;
	right: -1px;
	margin-top:-5px;
}
.sort-by .chosen-container-single .chosen-single div:after {
	color: #66676b;
	right: 0;
}
.sort-by .chosen-container-single .chosen-default {
	color: #66676b;
}
.sort-by .chosen-container-single .chosen-single span {
	margin-right: 0px;
	color: #666;
	font-weight: 600;
	transition: all 0.2s;
}
.sort-by .chosen-container-single .chosen-single div {
	position: relative;
	line-height:38px;
	top: 0;
	margin-left: 0;
	display: inline-block;
}
.sort-by .chosen-container-single .chosen-single span {
	display: inline-block;
	margin-right: 0;
	vertical-align: top;
}

/*==========
  Main Input Search Style
============*/
.main_input_search_part_item .chosen-container-single .chosen-single, .main_input_search_part_item .chosen-single.chosen-container-active {
	position: relative;
	display: block;
	overflow: visible;
	padding: 0 0 0 15px;
	height: 44px;
	line-height: 42px;
	border: 1px solid #fff;
	background-color: #fff;
	color: #888;
	border-radius: 4px;
	z-index: 100;
	box-shadow: none;
	transition: all 0.3s;
}
.main_input_search_part_item .chosen-container-active .chosen-single, .main_input_search_part_item .chosen-container-active .chosen-choices {
	border: 1px solid #fff;
}
.main_input_search_part_item .chosen-container-active.chosen-with-drop .chosen-single {
	border: 1px solid #fff;
	box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.15);
}
.main_input_search_part_item .chosen-container {
	font-size: 16px;
}
.main_input_search_part_item .chosen-container .chosen-drop {
	border: none;
	box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.15);
	font-size: 15px;
	padding-top: 0px;
	padding-bottom: 0px;
	z-index: 99;
}
.slider_chosen_drop .chosen-container .chosen-drop {
	display:inline-grid;
	height:150px;
}	
.main_input_search_part_item .chosen-single:after {
	content: "";
	position: absolute;
	bottom: -5px;
	display: block;
	width: 100%;
	left: 0;
	height: 10px;
	background: #fff;
	z-index: 112;
	opacity: 1;
	tranition: all 0.3s;
}
.main_input_search_part_item .chosen-container-single .chosen-single span {
	display: inline-block;
	vertical-align: top;
}
.main_input_search_part .chosen-container-single .chosen-single div {
	display: inline-block;
	position: relative;
	top: 0px;
}
.main_input_search_part .chosen-container-single .chosen-search {
	padding: 3px 15px;
	margin-bottom: 5px;
}
.main_input_search_part .chosen-container-single .chosen-search input[type="text"] {
	margin: 1px 0;
	padding: 6px 20px 6px 6px;
	width: 100%;
	height: 40px;
	outline: 0;
	border: 1px solid #e6e6e6;
	background-color: #fff;
	display: block;
	position: relative;
	z-index: 9;
	background-color: #fcfcfc;
	border-radius: 4px;
}
.main_input_search_part_item .chosen-container .chosen-results {
	position: relative;
	z-index: 10000
}
.gray-style .main_input_search_part_item .chosen-container-single .chosen-single, .gray-style .main_input_search_part_item .chosen-single.chosen-container-active {
	border: 1px solid #f7f7f7;
	background-color: #f7f7f7;
}
.main_input_search_part_item .chosen-container.chosen-with-drop {
	border-radius: 4px;
}
.gray-style .main_input_search_part_item .chosen-single::after {
	display: none;
}
.gray-style .main_input_search_part_item .chosen-container-active.chosen-with-drop .chosen-single, .gray-style .main_input_search_part_item .chosen-container .chosen-drop {
	box-shadow: none;
	border: 1px solid #e0e0e0;
	background: #f7f7f7;
}
.gray-style .main_input_search_part_item .chosen-container .chosen-drop {
	border-top: none;
}

/*==========
  Select Option Style
============*/
.select-input {
	position: relative;
}
.select-input input {
	position: relative;
	z-index: 101;
}
.select-input .select {
	position: absolute;
	top: -8px;
}
.select-hidden {
	display: none;
	visibility: hidden;
}
.select {
	cursor: pointer;
	display: inline-block;
	position: relative;
	font-size: 15px;
	color: #fff;
	width: 100%;
	height: 51px;
}
.select-styled {
	opacity: 0;
}
.select-options {
	display: none;
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	z-index: 990;
	margin: 0;
	padding: 0;
	list-style: none;
	border: 1px solid #e0e0e0;
	background-color: #fcfcfc;
	padding: 0 4px;
	padding-top: 8px;
	border-top: 0;
	border-radius: 0 0 4px 4px;
	box-sizing: border-box;
}
.select-list-container {
	padding: 0 4px;
	max-height: 300px;
	overflow: auto;
	display: inline-block;
	width: 100%
}
ul.select-options li {
	margin: 0;
	padding: 0;
	transition: all 0.15s ease-in;
	color: #888;
	margin: 0;
	padding: 10px 10px;
	list-style: none;
	line-height: 15px;
	border-radius: 4px;
}
.select-options li:hover {
	color: #fff;
	background-color: #66676b;
}
.select-options li[rel="hide"] {
	display: none;
}
.select-options ::-webkit-scrollbar {
width:10px;
}
.select-options ::-webkit-scrollbar-track {
background-color:rgba(0,0,0,0.05);
border-radius:10px;
}
.select-options ::-webkit-scrollbar-thumb {
border-radius:10px;
background-color:rgba(0,0,0,0.2);
}
.select-options ::-webkit-scrollbar-thumb:hover {
border-radius:10px;
background-color:rgba(0,0,0,0.3);
}

/*==========
  Panel Dropdown Style
============*/
.utf_panel_dropdown {
	position: relative;
	display: inline-block
}
.utf_panel_dropdown a {
	color: #666;
	font-weight: 600;
	font-size: 15px;
	border-radius: 50px;
	padding: 3px 14px;
	transition: all 0.3s;
	display: inline-block;
}
.utf_panel_dropdown.active a, .utf_panel_dropdown a:hover {
	background: #f3f3f3;
}
.utf_panel_dropdown a:after {
	content: "\f0d7";
	font-family: "FontAwesome";
	font-size: 16px;
	color: #66676b;
	font-weight: 500;
	padding-left: 0;
	transition: all 0.3s;
	margin-left: 6px;
	display: inline-block;
}
.utf_panel_dropdown.active a:after {
	transform: rotate(180deg);
}
.utf_panel_dropdown .utf_panel_dropdown-content {
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;
	position: absolute;
	top: 44px;
	left: 0px;
	z-index: 110;
	background: #fff;
	border: 1px solid rgba(0,0,0,0.18);
	border-radius: 4px;
	box-shadow: 0 12px 35px 2px rgba(0,0,0,0.12);
	padding: 20px;
	overflow-y: auto;
	white-space: normal;
	width: 350px;
}
.utf_panel_dropdown .utf_panel_dropdown-content{
	border:4px solid rgba(0, 0, 0, 0.12);
	border-radius: 8px;
}
.utf_panel_dropdown.wide .utf_panel_dropdown-content {
	width: 570px;
}
.utf_panel_dropdown.active .utf_panel_dropdown-content {
	opacity: 1;
	visibility: visible;
}
.listings-container {
	opacity: 1;
	transition: all 0.3s;
}
.fs-inner-container.content.faded-out .listings-container {
	background: #f9f9f9;
	opacity: 0.1;
	pointer-events: none;
}
.fs-inner-container.content.faded-out {
	background-color: #fcfcfc;
}
.utf_panel_dropdown .checkboxes label {
	margin-bottom: 10px;
	display: block;
}
.utf_panel_dropdown-content.checkboxes {
	padding: 28px;
}
.utf_panel_dropdown .row {
	margin-left: -10px;
	margin-right: -10px;
}
button.panel-cancel, button.panel-apply {
	background: none;
	padding: 5px 16px;
	display: block;
	outline: none;
	border: none;
	font-weight: 600;
	float: right;
	margin: 0;
	font-size: 15px;
	border-radius: 50px;
	background-color: #66676b;
	color: #fff;
	transition: all 0.3s;
}
.utf_panel_dropdown button.panel-apply {
	background: none;
	padding: 3px 15px;
	display: block;
	outline: none;
	border: none;
	font-weight: 500;
	float: right;
	margin: 0;
	font-size: 14px;
	border-radius: 4px;
	background-color: #764c29;
	color: #fff;
	transition: all 0.3s;
}
button.panel-apply:hover {
	opacity: 0.9;
}
button.panel-apply:active {
	transform: translateY(-2px);
}
button.panel-cancel {
	float: left;
	background-color: #f0f0f0;
	color: #666;
	opacity: 1;
}
button.panel-cancel:hover {
	background-color: #e9e9e9;
}
button.panel-cancel:active {
	transform: translateY(-2px);
}
.panel-buttons {
	width: 100%;
	margin-top: 13px;
}
.utf_panel_dropdown.float-right {
	float: right;
}
.utf_panel_dropdown.float-right a{
	height: 40px;
    line-height: 38px;
    border: 1px solid rgba(0, 0, 0, 0.07);
	background-color:#fcfcfc;
    width: 100%;
    display: inline-block;
    padding: 0 15px;
    box-shadow: none;
    color: #666;
    font-weight: 600;
    font-size: 15px;
	border-radius:4px;
    transition: all 0.3s;
    display: inline-block;
}	
.utf_panel_dropdown.float-right .utf_panel_dropdown-content {
	left: auto;
	right: 0;
}
.fullwidth_block-filters .utf_panel_dropdown {
	margin-left: 3px
}
.fullwidth_block-filters {
	display: inline-block;
	width: 100%;
	position: relative;
	top: 15px;
}
.fullwidth_block-filters .sort-by {
	top: -1px;
	right: -1px;
}

/*==========
  More Search Style
============*/
.more-search-options.relative {
	position: relative;
	margin-top: 0;
	opacity: 1;
	visibility: visible;
	transform: translate3d(0, 0, 0);
	transition: none;
	display: none;
}
.more-search-options.relative.active {
	transform: translate3d(0, 0, 0);
}
.more-search-options-trigger {
	background: #fff;
    color:#808080;
    font-weight: 400;
    font-size: 16px;
    border-radius: 6px;
    padding: 3px 0;
    transition: all 0.3s;
    display: block;
    border: 1px solid #dbdbdb;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
	box-shadow:0 4px 8px 0 rgba(0,0,0,0.03);
}
.more-search-options-trigger:before {
	content: attr(data-open-title);
}
.more-search-options-trigger.active:before {
	content: attr(data-close-title);
}
.more-search-options-trigger:after {
	content: "\f103";
	font-family: "FontAwesome";
	font-size: 16px;
	color: #66676b;
	font-weight: 500;
	padding-left: 0;
	transition: all 0.3s;
	margin-left: 10px;
	display: inline-block;
}
.more-search-options-trigger.active:after {
	transform: rotate(180deg);
}
.more-search-options-trigger:hover {
	color: #808080;
}

/*==========
  Navigation Layout Style
============*/
.utf_layout_nav a {
	width: 38px;
	height: 38px;
	background-color: #e7e7e7;
	display: inline-block;
	color: #8c8c8c;
	font-size: 14px;
	line-height: 38px;
	top:5px;
	text-align: center;
	transition: all 0.4s;
	border-radius: 50%;
	font-weight: 500;
	overflow: hidden;
	position: relative;
	margin-right: 5px;
}
.utf_layout_nav a.active {
	color: #764c29;
	background-color: #fff;
	border: 1px solid #764c29;
	line-height: 38px;
}
.utf_layout_nav a i {
	position: relative;
	z-index: 11;
}
.utf_layout_nav a:hover {
	color: #fff;
	background-color: #764c29;
}
.fs-container {
	height: 100%;
	width: 100%;
}
.fs-container #utf_map_container {
	position: relative;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 990;
	margin-bottom: 0px;
}
.fs-content {
	max-height: 100%;
	padding: 0;
}
.fs-container div.search {
	padding:40px;
	background-color: #f9f9f9;
}
.fs-inner-container .listing_filter_block{
	margin-bottom:0;
}
.fs-inner-container .row.with-forms .chosen-container{
	margin-bottom:0;
}
.fs-container .fs-listings {
	padding: 0 25px;
	margin: 0;
}
.fs-switcher {
	padding: 0 25px;
	margin: 0;
}
.fs-inner-container {
	width: 50%;
	float: left;
	display: inline-block;
}
.fs-inner-container.content {
	width: 55%;
	background-color: #ffffff;
	z-index: 995;
	position: relative;
	padding-top:0px;
	padding-bottom:0;
}
.fs-inner-container {
	width: 45%;
	background-color: #fff;
	z-index: 10;
}
.fs-container .footer_copyright_part {
	padding: 30px 0;
	font-size: 15px;
}
#header_part.fixed {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1100;
	background-color: #fff;
}
.fs-inner-container.map-fixed {
	height: 100vh;
	position: fixed;
	top: 0;
	padding-top: 90px
}
button.fs-map-btn.button {
	position: relative;
	float: right;
	top: 6px;
	padding: 10px 40px;
	margin: 0;
}
.fs-container .more-search-options-trigger {
	position: relative;
	top: 10px;
}
.showing-results {
	background:#f9f9f9;
	padding:10px;
	border:1px solid rgba(0, 0, 0, 0.05);
	border-radius:4px;
	color: #666;
	text-align:center;
	font-weight: 400;
	margin: 5px 0 25px 0;
}

/*==========
  Listings Container Style
============*/
.listings-container.fs-listings {
	left: 0;
	width: 100%;
	position: relative;
}
.listings-container.fs-listings.grid-layout {
	left: 0;
	width: 100%;
	position: relative;
}
.listings-container.fs-listings.list-layout {
	padding: 0 40px;
}
.listings-container.fs-listings.list-layout .listing-content {
	flex: 4;
}
.listings-container.fs-listings.list-layout .listing-img-container {
	flex: 0 auto;
	max-width: 300px;
}

@media (max-width:1365px) {
.listings-container.fs-listings.grid-layout .utf_listing_item {
	width: 100%;
	margin: 0 0 30px 0;
}
.listings-container.fs-listings.grid-layout {
	padding: 0 40px;
}
.listings-container.fs-listings.list-layout .utf_listing_item {
	display: block;
}
.listings-container.fs-listings.list-layout .details.button.border {
	display: none;
}
.listings-container.fs-listings.list-layout .listing-img-container {
	max-width: 100%;
}
.fs-switcher .utf_layout_nav {
	display: none;
}
}

/*==========
  Bootstrap Column Style
============*/
.col-fs-12, .col-fs-11, .col-fs-10, .col-fs-9, .col-fs-8, .col-fs-7, .col-fs-6, .col-fs-5, .col-fs-4, .col-fs-3, .col-fs-2, .col-fs-1 {
	float: left;
	padding: 0 10px;
}
.col-fs-12 {
	width: 100%
}
.col-fs-11 {
	width: 91.66666667%
}
.col-fs-10 {
	width: 83.33333333%
}
.col-fs-9 {
	width: 75%
}
.col-fs-8 {
	width: 66.66666667%
}
.col-fs-7 {
	width: 58.33333333%
}
.col-fs-6 {
	width: 50%
}
.col-fs-5 {
	width: 41.66666667%
}
.col-fs-4 {
	width: 33.33333333%
}
.col-fs-3 {
	width: 25%
}
.col-fs-2 {
	width: 16.66666667%
}
.col-fs-1 {
	width: 8.33333333%
}

@media (max-width:1599px) {
.col-fs-12, .col-fs-11, .col-fs-10, .col-fs-9, .col-fs-8, .col-fs-7, .col-fs-6 {
	width: 100%
}
.col-fs-5, .col-fs-4, .col-fs-3, .col-fs-2, .col-fs-1 {
	width: 50%
}
}

@media (max-width: 992px) {
.col-fs-12, .col-fs-11, .col-fs-10, .col-fs-9, .col-fs-8, .col-fs-7, .col-fs-6, .col-fs-5, .col-fs-4, .col-fs-3, .col-fs-2, .col-fs-1 {
	width: 100%
}
.overflow-hidden {
	overflow: hidden;
}
}
.row.with-forms input, .row.with-forms input[type="text"], .row.with-forms input[type="password"], .row.with-forms input[type="email"], .row.with-forms input[type="number"], .row.with-forms textarea, .row.with-forms input, .row.with-forms select, .row.with-forms .chosen-container {
	margin-bottom: 18px;
}
.row.with-forms {
	margin-left: -10px;
	margin-right: -10px;
}
.with-forms .col-xs-1, .with-forms .col-sm-1, .with-forms .col-md-1, .with-forms .col-lg-1, .with-forms .col-xs-2, .with-forms .col-sm-2, .with-forms .col-md-2, .with-forms .col-lg-2, .with-forms .col-xs-3, .with-forms .col-sm-3, .with-forms .col-md-3, .with-forms .col-lg-3, .with-forms .col-xs-4, .with-forms .col-sm-4, .with-forms .col-md-4, .with-forms .col-lg-4, .with-forms .col-xs-5, .with-forms .col-sm-5, .with-forms .col-md-5, .with-forms .col-lg-5, .with-forms .col-xs-6, .with-forms .col-sm-6, .with-forms .col-md-6, .with-forms .col-lg-6, .with-forms .col-xs-7, .with-forms .col-sm-7, .with-forms .col-md-7, .with-forms .col-lg-7, .with-forms .col-xs-8, .with-forms .col-sm-8, .with-forms .col-md-8, .with-forms .col-lg-8, .with-forms .col-xs-9, .with-forms .col-sm-9, .with-forms .col-md-9, .with-forms .col-lg-9, .with-forms .col-xs-10, .with-forms .col-sm-10, .with-forms .col-md-10, .with-forms .col-lg-10, .with-forms .col-xs-11, .with-forms .col-sm-11, .with-forms .col-md-11, .with-forms .col-lg-11, .with-forms .col-xs-12, .with-forms .col-sm-12, .with-forms .col-md-12, .with-forms .col-lg-12 {
	padding: 0 10px;
}

/*==========
  Titlebar Style
============*/
#titlebar.utf_listing_titlebar span a, #titlebar.utf_listing_titlebar span {
	font-size: 15px;
	color: #888;
	margin-top: 0;
	font-weight:400;
	display: inline-block;
}
#titlebar.utf_listing_titlebar span.call_now{
	display:block;	
	margin-bottom:8px;
}
#titlebar.utf_listing_titlebar span.call_now i{
	color:#764c29;
	margin-right: 3px;
	vertical-align:sub;
}
#titlebar.utf_listing_titlebar span a i {
	margin-right: 5px;
	color:#764c29;
	font-size:16px;
	vertical-align:sub;
}
#titlebar.utf_listing_titlebar span a:hover {
	color: #666;
}
#titlebar.utf_listing_titlebar h1, #titlebar.utf_listing_titlebar h2 {
	font-size: 26px;
	line-height: 36px;
	font-weight: 600;
	margin-bottom:5px;
	color:#323232;
}
#titlebar.utf_listing_titlebar h2 span.listing-tag {
	margin: 0;
	padding: 3px 7px;
	line-height: 15px;
	letter-spacing: 0.5px;
	border-radius: 4px;
	text-transform: uppercase;
	background-color: #764c29;
	border: 1px solid #764c29;
	color: #fff;
	font-size: 11px;
	font-weight: 600;
	position: relative;
	top: -2px;
	margin-left: 8px;
	vertical-align: middle;
}
.utf_counter_star_rating a {
	color: #fff;
	line-height: 21px;
	vertical-align: top;
}
.utf_star_rating_section .utf_counter_star_rating a:hover {
	color: #66676b;
}
.numerical-rating .utf_counter_star_rating a:hover {
	color: #888;
}
#titlebar.utf_listing_titlebar .numerical-rating {
	display: block;
	margin: 2px 0 0 0;
}
#titlebar.utf_listing_titlebar {
	background: #fff;
	background-color: #fff;
	margin: 2px 0 0 0;
}
.utf_listing_titlebar .utf_listing_titlebar_title{
	background: #f9f9f9;
    padding: 20px 25px;
    border-radius: 4px;
	border:1px solid rgba(0, 0, 0, 0.05);
}
.utf_listing_titlebar .utf_listing_titlebar_title h2{
	text-align:left !important;
}
a.button.utf_add_review-btn {
	min-width: 160px;
	text-align: center;
}
a.button.utf_add_review-btn i {
	font-size: 14px;
	position: relative;
	line-height: 0;
	top: 0;
	margin-right: 0;
	letter-spacing: 0;
}
.back-to-listings {
	height: 60px;
	width: 60px;
	background-color: #eee;
	color: #a6a6a6;
	border-radius: 50%;
	display: inline-block;
	float: left;
	position: relative;
	top: 6px;
	margin: 0 25px 0 0;
	text-align: center;
	transition: all 0.4s;
	position: absolute;
	top: 50%;
	left: 15px;
	margin-top: -5px;
	transform: translate(0, -50%);
}
.back-to-listings:before {
	font-family: "simple-line-icons";
	content: "\e605";
	font-size: 20px;
	line-height: 60px;
	left: -1px;
	position: relative;
}
.back-to-listings:hover {
	color: #a6a6a6;
	transform: translate(-4px, -50%);
}
.titlebar-right-content {
	position: absolute;
	right: 15px;
	display: inline-block;
	top: 50%;
	transform: translateY(-49%);
	text-align: right;
}
.utf_listing_slider .item {
	position: relative;
	background-size: cover;
	background-position: 50%;
	height: 40vh;
	min-height: 400px;
}
.utf_listing_slider, .utf_listing_slider .item {
	height: 40vh;
	min-height: 400px;
}
a.utf_gallery {
	cursor: zoom-in;
}

/*==========
  Listing Slider Style
============*/
.utf_listing_slider {
	background-color: #fff;
}
.utf_listing_slider .slick-next:hover, .utf_listing_slider .slick-prev:hover, .utf_listing_slider .slick-next, .utf_listing_slider .slick-prev {
	background-color: rgba(20,20,20,0.45);
	color: #fff;
	height: 44px;
	width: 44px;
	border-radius:6px;
	text-align: center;
	transition: 0.4s;
}
.utf_listing_slider .slick-next:hover, .utf_listing_slider .slick-next {
	right: 7.5%;
	transform: translate(50px, -50%);
}
.utf_listing_slider .slick-prev:hover, .utf_listing_slider .slick-prev {
	left: 7.5%;
	transform: translate(-50px, -50%);
}
.utf_listing_slider:hover .slick-next {
	transform: translate(60px, -50%);
}
.utf_listing_slider:hover .slick-prev {
	transform: translate(-60px, -50%);
}
.utf_listing_slider .slick-next:hover:before, .utf_listing_slider .slick-prev:hover:before, .utf_listing_slider .slick-prev::before, .utf_listing_slider .slick-next::before {
	font-size: 18px;
	line-height: 44px;
	position: relative;
	color: #fff;
}
.utf_listing_slider .slick-prev::before {
	left: -1px;
}
.utf_listing_slider .slick-next::before {
	left: 1px;
}
.utf_listing_slider .slick-next:hover {
	transform: translate(64px, -50%);
}
.utf_listing_slider .slick-prev:hover {
	transform: translate(-64px, -50%);
}
.utf_listing_slider .item.slick-slide:before {
	position: absolute;
	height: 100%;
	width: 100%;
	display: block;
	content: "";
	background: #222;
	z-index: 100;
	visibility: visible;
	opacity: 0.1;
	transition: 0.3s ease-in-out;
}
.utf_listing_slider .item.slick-slide:hover:before, .utf_listing_slider .slick-next, .utf_listing_slider .slick-prev {
	opacity: 0;
	visibility: hidden;
}
.utf_listing_slider:hover .slick-next, .utf_listing_slider:hover .slick-prev {
	opacity: 1;
	visibility: visible;
}
.utf_listing_slider .slick-next:hover, .utf_listing_slider .slick-prev:hover {
	background-color: #764c29;
	color: #fff;
}
.utf_listing_detail_sidebar {
	padding: 0;
	list-style: none;
	font-size: 15px;
	margin-bottom: -5px;
	position: relative;
	display: block;
}
.utf_listing_detail_sidebar li {
	display: block;	
	position: relative;
	padding:8px 0 8px 28px;
    line-height: 28px;
	color: #66676b;
	font-size:15px;
	font-weight:400;
    border-bottom: 1px solid #e8e8e8;
}
.utf_listing_detail_sidebar li a {
	color: #66676b;
	font-weight:400;
	transition: 0.3s;
}
.utf_listing_detail_sidebar li a:hover{
	color:#764c29;
	padding-left:5px
}
.utf_listing_detail_sidebar li i {
	position: absolute;
	left: 0;
	top: 14px;
	font-size: 16px;
	color: #888;
}
.utf_listing_detail_sidebar.social-profiles {
	margin-top: 10px;
}
.utf_listing_detail_sidebar.social-profiles li {
	line-height: 22px;
	padding-left: 25px;
}
.utf_listing_detail_sidebar.social-profiles li i {
	top: 13px;
}
.utf_listing_detail_sidebar li a.facebook-profile i, .utf_listing_detail_sidebar li a.facebook-profile {
	color: #3b5998;
}
.utf_listing_detail_sidebar li a.twitter-profile i, .utf_listing_detail_sidebar li a.twitter-profile {
	color: #1da1f2;
}
.utf_listing_detail_sidebar li a.gplus-profile i, .utf_listing_detail_sidebar li a.gplus-profile {
	color: #dd4b39;
}
.utf_listing_detail_sidebar li a.gplus-profile {
	padding-left: 4px;
}
.utf_hosted_by_user_title {
    padding: 0 0px 18px 110px;
    margin: 0px 0 5px;
    display: block;
	min-height:100px;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
}
.utf_hosted_by_user_title h4 {
    font-size: 18px;
    transition: .2s;
    line-height: 28px;
    font-weight: 600;
    margin: 15px 0 10px;
    vertical-align: bottom;
}
.utf_hosted_by_user_title h4 span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #888;
    margin-bottom: 2px;
}
.utf_hosted_by_user_title h4 span{
	color:#888888
}
.utf_hosted_by_user_title h4 span i{
	color:#764c29;
	margin-right:3px;
}
.utf_hosted_by_avatar_listing {
    max-width:150px;
    overflow: hidden;
    border-radius:50px;
	border:3px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 0;
    top: -5px;
    image-rendering: -webkit-optimize-contrast;
}
.utf_hosted_by_avatar_listing img{
	width:90px;
	height:90px;
}
.utf_box_widget {
	background-color: #f9f9f9;
	border-radius: 4px;
	padding: 30px;
	transform: translate3d(0, 0, 0);
	border:1px solid rgba(0, 0, 0, 0.05);
	position:relative;
	z-index:1;
}
.utf_box_widget p{
	font-size:15px;
	line-height:26px;
}
.booking_widget_box{
	zoom: 1;
    z-index: 2;
}
.add-review-photos {
    font-weight: 500;
    padding: 6px 10px;
    color: #666;
    background-color: #ededed;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    font-size: 14px;
    width: auto;
    transition: 0.3s;
    cursor: pointer;
    line-height: 20px;
    float: right;
    top: -25px;
}
#utf_add_comment label{
	font-size:16px;
	font-weight:600;
	margin-bottom: 8px;
}
.photoUpload {
    position: relative;
    overflow: hidden;
    padding: 3px 12px;
    cursor: pointer;
}
.add-review-photos i{
	color:#764c29;
	margin-left: 3px;
    transition: 0.3s;
    font-size: 16px;
    position: relative;
    top: 2px;
}
.photoUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}
.add-review-photos:hover, .add-review-photos:hover i{
	background:#764c29;
	color:#fff;
}

/*==========
  Box Widget Style
============*/
.utf_box_widget h3 {
	width:100%;
	font-size: 20px;
	line-height:40px;
	padding: 0 0 20px;
	margin: 5px 0 20px 0;
	display: block;
	font-weight:600;	
	text-align:left;
	border-bottom: 1px solid #e8e8e8;
}
.utf_box_widget h3 i {
	margin-right: 10px;
	color:#fff;	
	width:40px;
	height:40px;
	background:#764c29;
	padding:10px;
	border-radius:50%;
}
.utf_box_widget h3 i.sl-icon-clock {
	position: relative;
	top: 1px;
}
.utf_box_widget h3 i.fa-calendar-check-o {
	position: relative;
	top: -1px;
}
.utf_box_widget h5 {
	font-size:18px;
	font-weight:600;
	color:#525252;
	margin-bottom:12px;
}
a.send-message-to-owner.button {
	font-size: 13px;
	font-weight: 500;
	padding: 4px 20px;
	margin-top: 20px;
	margin-bottom: -5px;
}

/*==========
  Verified Style
============*/
.verified-badge {
	background-color: #54ba1d;
	border-radius: 4px;
	color: #fff;
	text-align: center;
	padding: 12px 20px;
	font-size: 16px;
	font-weight: 400;
	cursor: help;
	position: relative;
	transition: 0.3s;
	display: block;
}
.verified-badge i {
	font-size: 22px;
	padding: 0 4px 0 0;
	position: relative;
	top: 3px;
}
.verified-badge .tip-content {
	transform: translate(-50%, -100%);
	-ms-transform: translate(-50%, -100%);
	padding: 20px;
	border-radius:4px;
	max-width: auto;
	top: -1px;
	bottom: auto;
}
.verified-badge:hover { 
	background:#42a80b
}
.verified-badge:hover .tip-content {
	opacity: 1;
	visibility: visible;
	transform: translate(-50%, -100%);
	-ms-transform: translate(-50%, -100%);
}
.verified-badge .tip-content:after {
	font-family: "FontAwesome";
	content: "\f0d7";
	position: absolute;
	bottom: -22px;
	top: auto;
	left: 50%;
	font-size: 22px;
	color: #333;
	transform: translate(-50%,-50%);
}

/*==========
  Opening Hours Style
============*/
.opening-hours {
	position: relative;
	overflow: hidden;
}
.opening-hours ul {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 16px;
}
.opening-hours ul li {
	width: 100%;
	font-size:15px;
	font-weight: 400;
	color: #66676b;
	border-bottom:1px solid rgba(0, 0, 0, 0.05);
	margin: 0;
	padding:6px 0;
	transition: 0.2s;
	cursor: default;
}
.opening-hours ul li:last-child{
	margin-bottom:0;
}
.opening-hours ul li:hover {
	color: #764c29;
}
.opening-hours ul li span {
	float: right;
	font-weight: 400;
	font-size: 14px;
}

/*==========
  Listing Share Style
============*/
.listing-share {
	text-align: center;
	position: relative;
}
.listing-share .share-buttons li {
	margin: 0 3px 8px 0;
}
.listing-share.with-border {
	border-top: 1px solid #eee;
	padding-top: 35px;
}
.listing-share span {
	display: block;
	font-size: 16px;
	color: #707070;
}
.utf_box_widget .like-button{
	margin-top:10px;
	margin-bottom:0;
}
.like-button {
	outline: none;
	border: none;
	color: #444;
	font-weight: 500;
	font-size: 14px;
	background-color: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 50px;
	padding: 10px 25px;
	line-height: 24px;
	margin-bottom: 9px;
	transition: 0.3s;
	cursor: pointer;
}
.like-button:hover {
	background-color: #fafafa;
	border: 1px solid #ddd;
}
.listing-share .like-icon::before, .listing-share .like-icon::after {
	top: 0;
}
.listing-share .utf_social_icon li {
	text-align: center;
	float: none;
	padding: 0;
	margin: 0;
}
.like-button .like-icon:hover, .like-button .like-icon.liked {
	background-color: transparent;
	color: #764c29;
}
.listing-share span.like-icon{
	top:-5px;
	left:0;
}
.listing-share .like-button .like-icon{
	border:2px solid transparent;
}
.like-button .like-icon {
	position: relative;
	height: 20px;
	display: inline-block;
	line-height: 30px;
	right: auto;
	bottom: 3px;
	background: transparent;
	color: #764c29;
	font-weight: 500;
	float: left;
	width: 20px;
	margin-right: 8px;
	font-size: 18px;
}

/*==========
  Listing Nav Style
============*/
.listing-nav {
	width: 100%;
	padding: 0;
	list-style: none;
	margin: 0;
	border-bottom: 1px solid #e0e0e0;
	font-size: 17px;
	font-weight: 400;
	background-color: #fff;
	margin-bottom: 35px;
}
.listing-nav-container.cloned {
	position: fixed;
	top: 0;
	margin: 0 auto;
	z-index: 1010;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	transition: 0.4s;
	transform: translateY(-100%);
	border-bottom: 1px solid #e0e0e0;
}
.listing-nav-container.cloned.stick {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
	background-color: #fff;
}
.listing-nav-container.cloned .listing-nav {
	border-bottom: none;
	margin: 0 auto;
}
.listing-nav-container.cloned .listing-nav a {
	padding: 15px 0;
}
.listing-nav-container.cloned .listing-nav li:first-child a {
	color: #666;
	border-color: #e0e0e0;
}
.listing-nav li {
	display: inline-block;
	margin-right: 15px;
}
.listing-nav li a {
	display: inline-block;
	border-bottom: 1px solid #e0e0e0;
	position: relative;
	bottom: -1px;
	transition: 0.3s;
	color: #666;
	padding-bottom: 15px;
}
.listing-nav-container.cloned .listing-nav li:first-child a.active, .listing-nav-container.cloned .listing-nav li:first-child a:hover, .listing-nav li:first-child a, .listing-nav li a.active, .listing-nav li a:hover {
	border-color: #66676b;
	color: #66676b;
}
.utf_listing_headline_part {
	font-size: 22px;
	font-weight:600;
	position:relative;
	margin-bottom: 30px;
	margin-top: 45px;
	display: block;
}
.utf_listing_headline_part:after {
    position: absolute;
    content: '';
    left: 0px;
    bottom: -10px;
    height: 2px;
    width: 40px;
    background-color: #764c29;
}
.utf_listing_headline_part span {
	color: #888;
	font-size:18px;
}
.utf_listing_section {
	display: inline-block;
	width: 100%;
}
.utf_listing_section p{
	font-size:15px;
	line-height:27px;
}

/*==========
  Pricing Info Style
============*/
.pricing_info {
    overflow: hidden;
    padding: 10px 0 30px;
}
.pricing_info p {
    display: inline-block;
    line-height: 50px;
    margin: 0 auto;
    vertical-align: middle;
}
.listing_price {
    font-size: 32px;
    color: #323232;
	font-weight:600;
}
.listing_price span{
	color:#764c29;
}
.listing_message {
    float: right;
    margin: 0 auto;
}
.listing_message .btn {
    background-color: #764c29;
    top: 0;
    padding: 10px 20px;
    color: #fff;
    position: relative;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 6px;
    overflow: hidden;
    border: none;
    border-radius: 6px;
}
.listing_message .btn i{
	margin-right:10px;
}
ul.listing_item_social {
    margin-top:15px;
	margin-bottom:0;
    display: inline-block;
    width:100%;
	padding-left:0;
    position: relative;
    z-index: 99;
}
.listing_item_social li {
    float: left;
    list-style: none;
    margin-right: 10px;    
    overflow: hidden;
    -moz-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    margin-bottom: 0px;
}
.listing_item_social li a {
    padding: 2px 15px;
    display: inline-block;
    color: #888;
    font-size: 13px;
    font-weight: 600;
	border-radius: 4px;
    background: #ffffff;
	border:1px solid rgba(0, 0, 0, 0.10);
    text-decoration: none;
    line-height: 26px;
}
.listing_item_social li i {
    margin-right: 5px;
	color:#b5b5b5;
}
.listing_item_social li a:hover i{
	color:#fff;
}
.listing_item_social li a:hover{
	color:#fff;
	background:#764c29;
	border-color:#764c29;
}
.listing_item_social li a.now_open{
	color:#ffffff;
	background:#54ba1d;
	border-color:#54ba1d;
}
.listing_item_social li a.now_open:hover{
	color:#54ba1d;
	background:#ffffff;
	border-color:#54ba1d;
}
.listing_item_social li a.close_now{
	color:#ffffff;
	background:#e91721;
	border-color:#e91721;
}
.listing_item_social li a.close_now:hover{
	color:#e91721;
	background:#ffffff;
	border-color:#e91721;
}
#utf_single_listingmap {
	height: 400px;
	border-radius: 3px;
}
#utf_single_listing_map_block {
	height: 410px;
	position: relative;
	padding-top: 5px;
	display: block;
}
.utf_listing_features {
	width: 100%;
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	columns: 3;
	font-size: 16px;
	-webkit-columns: 3;
	-moz-columns: 3;
}
.utf_listing_features.checkboxes li {
	color: #707070;
	font-weight:400;
	display: block;
	position: relative;
	margin: 0 0 10px 0;
	padding: 0 0 0 30px;
}
.utf_listing_features li:last-child {
	margin: 0;
}
.utf_listing_features li span, .utf_listing_features.checkboxes li {
	color: #707070;
}
.utf_listing_features.checkboxes li:before {
	display: inline-block;
	width: 19px;
	height: 19px;
	position: relative;
	content: "\f00c";
	font-family: "FontAwesome";
	font-size: 12px;
	color: #fff;
	text-align: center;
	line-height: 15px;
	background-color: #764c29;
	border: 2px solid transparent;
	transition: border-color 0s;
	border-radius: 12px;
	position: absolute;
	left: 0;
	top: 3px;
}
.db-tile h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 25px 30px;
    color: #333;
    background-color: #fff;
    display: block;
    border-bottom: 1px solid #eaeaea;
    border-radius: 4px 4px 0 0;
}
.dashboard-list-box {
    box-shadow: 0 0 12px 0 rgba(0,0,0,.06);
    border-radius: 6px;
}
.dashboard-list-box .table thead th {
    vertical-align: bottom;
    border-bottom: .125rem solid #dee2e6;
}
.dashboard-list-box .table thead th.btn_booking_invoice{
	width:145px;
}
.dashboard-list-box .table td, .dashboard-list-box .table th {
    padding: 15px 20px;
	vertical-align:middle;    
}
.dashboard-list-box .button i {
    font-size: 16px;
    padding-right: 0px;
    position: relative;
    top: 2px;
	vertical-align:top;
}
.badge-primary {
    color: #ffffff;
    background-color:#64bc36;
}
.btn-primary.focus, .btn-primary:focus {
    box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08),0 0 0 0 rgba(94,114,228,.5);
}
.badge-danger {
    color: #ffffff;
    background-color:#61b2db;
}
.badge-canceled {
    color: #ffffff;
    background-color:#ee3535;
}
.dashboard-list-box .badge {
    display: inline-block;
    padding:4px 10px;
	font-size:11px;
    font-weight:500;
    text-align: center;
    border-radius:4px;
}
.rounded-circle {
    border-radius:30px;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
@media (max-width:767px){
.utf_dashboard_list_box {
	display:inline-grid;
	width: 100%;
    overflow-x: auto;
	border:0px;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}	
.table-responsive {
    overflow: scroll;
    width: 100%;
	border:0px;
    display: inline-grid;
}
}
.utf_listing_titlebar .utf_room_detail{
    list-style: none;
    padding: 0;
    margin: 0;
}    
.utf_listing_titlebar .utf_room_detail ul{
    list-style: none;
    padding: 0;
    margin: 0;    
	margin-top:5px;	    
}
.utf_listing_titlebar .utf_room_detail ul li {
    display: inline-block;
    font-weight: 600;
    color: #888;
    position: relative;
	font-size:13px;
    line-height: 16px;
	border-radius: 4px;
    padding:8px 15px;
	margin-right:6px;
	background: #ffffff;
	border:1px solid rgba(0, 0, 0, 0.10);    
}
@media (max-width:639px){
.utf_listing_titlebar .utf_room_detail ul li {
	margin-bottom:10px;
}	
}

/*==========
  Pricing List Container Style
============*/
.utf_pricing_list_section h4 {
	color: #ffffff;
	background-color: #764c29;
	border-bottom: 1px solid #764c29;
	width: 100%;
	padding:20px;
	border-radius: 4px 4px 0 0;
	position: relative;
	z-index: 10;
	font-size:20px;
	margin:0;
	display: block;
	font-weight: 600;
}
.utf_pricing_list_section h4:after {
	width: 100%;
	height: 100%;
	border-radius: 4px 4px 0 0;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	background-color: #66676b;
	opacity: 0.05;
	z-index: 9;
}
.utf_pricing_list_section{
	border:1px solid rgba(0, 0, 0, 0.1);
	border-radius:4px;
}
.utf_pricing_list_section ul {
	list-style: none;
	padding: 0;
	background:#f9f9f9;
	margin-bottom: 35px;
}
.utf_pricing_list_section ul:last-child {
	margin-bottom:0;
}
.utf_pricing_list_section ul li:last-child{
	border-bottom:0;
}
.utf_pricing_list_section ul li {
	padding: 15px 20px;
	border-bottom:1px solid rgba(0, 0, 0, 0.1);
	padding-right: 70px;
	position: relative;
}
.utf_pricing_list_section ul li:hover{
	background:#fff;
}
.utf_pricing_list_section ul li:last-child {
	border-radius: 0 0 4px 4px;
}
.utf_pricing_list_section ul li:last-child span{
	background:#54ba1d;
	color:#fff;
	border:0;
	font-size:16px;
	font-weight:600;
}
.utf_pricing_list_section h5 strong{
	font-weight:600;
}
.utf_pricing_list_section ul li:last-child span strong{
	font-weight:600;
}
.utf_pricing_list_section p, .utf_pricing_list_section h5 {
	padding: 0;
	font-size: 15px;
	margin: 0;
	display: inline-block;
	width: 100%;
}
.utf_pricing_list_section h5 {
	font-size: 18px;
	line-height:28px;
	margin-bottom: 1px;
	font-weight:600;
}
.utf_pricing_list_section p {
	color: #777;
	line-height: 24px;
	margin-top: 0px;
}
.utf_pricing_list_section p strong{
	font-weight:600;
}
.utf_pricing_list_section sub.ppl-offer {
    padding: 3px 8px;
    font-size: 13px;
    line-height: 1.6;
    font-weight: 600;
    border-radius: 4px;
    position: relative;
    top: 0;
    margin-left: 0.8rem;
}
.utf_pricing_list_section .label-light-success {
    background-color: #e8fdeb;
    color: #27b737;
}
.utf_pricing_list_section .label-light-danger {
    background-color: #f9e7eb;
    color: #ff3838;
}
.utf_pricing_list_section span {
	position: absolute;
	right: 20px;
	top: 50%;
	font-weight: 600;
	transform: translateY(-50%);
	display: inline-block;
	font-size: 16px;
	color: #626262;
	background: #ffffff;
	border:1px solid rgba(0, 0, 0, 0.1);
	padding: 2px 12px;
	border-radius: 4px;
	vertical-align: middle;
	text-align: center;
}

/*==========
  Show More Style
============*/
.show-more {
	height: 450px;
	overflow: hidden;
	position: relative;
	transition: margin 0.4s;
}
.show-more:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 180px;
	display: block;
	background: linear-gradient(rgba(255,255,255,0), #fff 88%);
	z-index: 9;
	opacity: 1;
	visibility: visible;
	transition: 0.8s;
}
.show-more.visible {
	margin-bottom: 20px;
}
.show-more.visible:after {
	opacity: 0;
	visibility: hidden;
}
.show-more-button {
	position: relative;
	font-weight: 500;
	font-size: 16px;
	left: 0;
	margin-left: 50%;
	transform: translateX(-50%);
	z-index: 10;
	text-align: center;
	display: inline-block;
	opacity: 1;
	visibility: visible;
	transition: all 0.3s;
	padding: 5px 20px;
	color: #fff;
	background-color: #764c29;
	border-radius:4px;
	top: -10px;
	min-width: 130px;
}
.show-more-button:hover, .show-more-button:focus{
	color:#fff;
}
.show-more-button:before {
	content: attr(data-more-title);
}
.show-more-button.active:before {
	content: attr(data-less-title);
}
.show-more-button i {
	margin-left: 6px;
	color: #fff;
	font-weight: 500;
	transition: 0.2s;
}
.show-more-button.active i {
	transform: rotate(180deg);
}

/*==========
  Comments Style
============*/
.dashboard_review_item .utf_comment_content{
	padding-bottom:0;
	border-bottom:0
}
.comments {
	margin: -10px 0 0 0;
}
.comments h4 {
	margin-bottom: 25px;
	font-size: 20px;
	font-weight: 500;
	color: #333;
}
.comments h4 span {
	font-size:18px !important;
	font-weight:500;
	display: inline-block;	
}
.comment {
	font-size: 20px;
}
.comments .button {
	margin: 0 0 10px 0;
	padding: 7px 15px;
}
.comments.reviews .button {
	margin: 0;
}
.comments ul {
	padding-left: 0;
}
.comments ul li {
	display: block;
	width: 100%;
}
.comments ul li, .comments ul li ul li, .comments ul li ul li ul li, .comments ul li ul li ul li {
	margin: 30px 0 0 0px;
}
.comments ul li ul {
	margin: 0 0 0 100px;
}
.utf_comment_content p {
	margin:0;
	line-height: 25px;
}
.utf_listing_section .utf_comment_content, .comments .utf_comment_content {
	color: #666;
	padding: 0 0 0px 100px;	
}
.utf_comment_content a.rate-review{
	position: absolute;	
	right: 20px;
	top:10px;
	font-weight:600;
	margin-top: 10px;
}
.utf_dashboard_list_box .utf_comment_content {
	color: #666;
	padding: 0 0 0px 100px;	
	border-bottom:0
}
.comments ul li {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.10);
    padding: 20px;
    border-radius: 10px;
}
.utf_dashboard_list_box .utf_comment_content {
	color: #666;
	padding: 0 0 0px 100px;	
	border-bottom:0
}
.comments ul li {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding: 20px;
    border-radius: 4px;
}
.utf_comment_content strong {
	padding-right: 5px;
	color: #666;
}
.utf_comment_content span {
	color: #888;
}
body .utf_comment_content p {
	padding: 5px 0;
	font-size: 15px;
	font-weight:400;
}
.comments-amount {
	color: #888;
	font-weight: 500;
}
.avatar {
	display: inline-block;
	float: left;
	padding: 0 20px 0 20px;
	position: absolute;
	left: 0px;
}
.utf_listing_section .utf_listing_reviews .avatar{
	padding:0 20px 0 20px
}
.utf_listing_section .utf_comment_content a.rate-review{
	right:20px;
	top:10px;
}
.dashboard_review_item .avatar, .utf_listing_reviews .avatar{
	padding:0 20px 0 0
}
.dashboard_review_item .utf_by_comment, .utf_listing_reviews .utf_by_comment{
	padding-top:0
}
.dashboard_review_item a.rate-review, .utf_listing_reviews a.rate-review{
	right:0;
	top:0;
}
.avatar img {
	width: 80px;
	height:80px;
	border: 5px solid rgba(0, 0, 0, 0.06);
	border-radius: 50px;
}
.utf_by_comment {
	width: 100%;
	padding-bottom: 0;
	padding-top: 5px;
	font-size:18px;
	font-weight: 600;
	color: #333;
	display:inline-block
}
.utf_by_comment h5 {
	margin: 0 0 7px 0;
}
.utf_by_comment a.reply, .utf_by_comment a.reply i {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.utf_by_comment a.reply {
	display: inline;
	background-color: #f0f0f0;
	padding: 5px 12px;
	line-height: 22px;
	font-size: 13px;
	color: #333;
	font-weight: 600;
	position: absolute;
	right:15px;
	top: 15px;
	border-radius: 4px;
}
.utf_by_comment a.reply i {
	font-size: 12px;
	margin-left: 4px;
}
.utf_by_comment .numerical-rating {
	margin: 4px 0 0 0;
}
.utf_by_comment .numerical-rating, .utf_by_comment .utf_star_rating_section {
	float:left;
	width:100%
}
.utf_by_comment .utf_star_rating_section {
    display: block;
    font-size: 14px;
}
.utf_by_comment a.reply:hover {
	background-color: #764c29;
}
.utf_by_comment a.reply:hover, .utf_by_comment a.reply:hover i {
	color: #fff;
}
.utf_by_comment span.date {
	color: #888;
	font-size:13px;
	font-weight: 600;
	margin: 0px 0 0 0;
	float: none;
	display: block;
	text-align: left;
}
.utf_by_comment span.date i{
	color:#764c29;
	font-size:14px;
	margin-right:2px;
}

/*==========
  Review Style
============*/
.review-images {
	display: inline-block;
	margin-top: 20px;
	margin-bottom: 5px;
}
.review-images a {
	flex: 4;
	width:80px;
	height:80px;
	float:left;
	margin-right: 15px;
	border-radius:50px;
	margin-bottom:10px;
	border:3px solid rgba(0, 0, 0, 0.06)
}
.review-images a img {
	border-radius:50px;
	min-height:74px;
}
.utf_add_review-box {
	border-radius: 4px;
	background-color: #f9f9f9;
	padding: 36px;
	padding-bottom: 40px;
	margin-bottom: 70px;
	margin-top: 70px;
	display: inline-block;
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.05);
	position: relative;
}
.utf_add_review-box h3 {
	margin-top: 5px
}
.utf_add_comment label span {
	color: #e24747;
}
.utf_add_comment input {
	margin: 0 0 25px 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.utf_add_comment textarea, .utf_add_comment input {
	box-shadow: 0 1px 3px 0px rgba(0,0,0,0.05)
}
.utf_add_comment textarea {
	margin: 0 0 30px 0;
	width: 100%;
	min-width: 100%;
	min-height: 200px;
	resize: vertical;
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.utf_add_comment .button {
	min-width: 160px;
}
.utf_add_comment .button, #contactform .submit{
	text-align: center;
    margin: 0 auto;
    display: block;
	border:0;
}
.utf_leave_rating {
	height: 48px;
	float: left;
	background: #fff;
	padding: 12px 15px;
	line-height: 30px;
	border:1px solid rgba(0, 0, 0, 0.08);
	text-align: center;
	vertical-align: middle;
	margin: 0 auto;
	margin-bottom: 0px;
	border-radius: 4px;
}
.utf_leave_rating:hover input[type="radio"]:checked~label {
	color: #dadada;
}
.utf_leave_rating input[type="radio"] {
	display: none;
}
.utf_leave_rating input[type="radio"]:checked~label {
	color: #ffc600;
}
.utf_leave_rating label {
	font-size: 24px;
	float: right;
	letter-spacing: 6px;
	color: #dadada;
	cursor: pointer;
	transition: 0.3s;
}
.utf_leave_rating label:hover, .utf_leave_rating label:hover~label {
	color: #ffc600!important;
}
.utf_leave_rating_title {
	display: block;
	font-weight:400;
	margin:20px 0;
}
.utf_add_review-photos {
	font-weight: 600;
	padding: 6px 7px;
	color: #666;
	background-color: #ededed;
	border-radius: 50px;
	display: inline-block;
	position: relative;
	font-size: 14px;
	width: auto;
	transition: 0.3s;
	cursor: pointer;
	line-height: 20px;
	float: right;
	top: -25px;
}
.photoUpload {
	position: relative;
	overflow: hidden;
	padding: 3px 12px;
	cursor: pointer;
}
.photoUpload input.upload {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
}
.utf_add_review-photos:hover {
	color: #fff;
	background-color: #66676b;
}
.utf_add_review-photos i {
	color: #66676b;
	margin-right: 3px;
	transition: 0.3s;
	font-size: 16px;
	position: relative;
	top: 2px;
}
.utf_add_review-photos:hover i {
	color: #fff;
}
a.rate-review {
	border:1px solid rgba(0, 0, 0, 0.08);
	border-radius:4px;
	font-size: 13px;
	color: #666;
	font-weight: 500;
	padding: 5px 15px;
	margin-top: 20px;
	display: inline-block;
	transition: 0.3s;
}
a.rate-review span {
	border-left: 1px solid #e0e0e0;
	line-height: 20px;
	padding-left: 10px;
	margin-left: 5px;
	transition: 0.3s;
	color: #666;
}
a.rate-review i {
	padding-left: 5px;
	font-size: 15px;
	vertical-align: initial;
}
a.rate-review:hover span {
	border-color: #d0d0d0
}
a.rate-review:hover {
	color:#fff;
	border-color: #764c29;
	background-color: #764c29;
}

/*==========
  Pagination Style
============*/
.pagination {
	margin: 10px 0 0 0;
	text-align: center;
	overflow: hidden;
}
.pagination-next-prev {
	position: relative;
	top: -66px;
}
.pagination ul li {
	display: inline-block;
	margin: 0 2px;
	padding: 0;
}
.pagination ul li a, .pagination-next-prev ul li a {
	padding: 12px 0;
	border-bottom: none;
	display: inline-block;
	color: #333;
	background-color: #e7e7e7;
	font-weight: 600;
	margin: 0;
	line-height: 22px;
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
	font-size: 16px;
}
.pagination ul li a {
	border-radius:50%;
	width: 40px;
	height: 40px;
	padding: 0;
	font-weight:700;
	line-height: 40px;
}
.pagination ul li a i {
	font-size: 12px;
	line-height: 40px;
	font-weight: 700;    
}
.pagination ul li.blank {
	color: #a0a0a0;
	padding: 0 6px;
}
.pagination ul li a.current-page, .pagination .current, .pagination ul li a:hover, .pagination-next-prev ul li a:hover {
	background-color: #764c29;
	color: #fff;
}
.pagination-next-prev a.next {
	float: right;
}
.pagination-next-prev a.prev {
	float: left;
}
.pagination-next-prev ul li a {
	color: #333;
	padding: 12px 16px;
	text-transform: uppercase;
}
.pagination ul, .pagination-next-prev ul {
	padding: 0;
	list-style-type: none;
}
.fs-listings .pagination ul li a, .fs-listings .pagination-next-prev ul li a {
	background-color: transparent;
}
.fs-listings .pagination ul li a.current-page, .fs-listings .pagination .current, .fs-listings .pagination ul li a:hover, .fs-listings .pagination-next-prev ul li a:hover {
	background-color: #e8e8e8;
	color: #333;
}

/*==========
  Contact Style
============*/
#contact textarea {
	min-height: 160px;
	margin: 0px 0 25px 0;
}
#contact input {
	margin-bottom: 25px;
}
.loader {
	margin-left: 15px;
}
.submit.disabled:hover, .submit.disabled {
	background-color: #e8e8e8;
	color: #333;
}
#contact {
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 30px;
    transform: translate3d(0, 0, 0);
    border:1px solid rgba(0, 0, 0, 0.05);
}
#contact h4 {
    width: 100%;
    font-size: 20px;
    line-height: 40px;
    padding: 0 0 20px;
    margin: 5px 0 30px 0;
    display: block;
    border-bottom: 1px solid #e8e8e8;
}
#contact h4 i {
    margin-right: 10px;
    color: #fff;
    width: 40px;
    height: 40px;
    background: #764c29;
    padding: 10px;
    border-radius:50%;
}
#contact input.button.submit {
	line-height: 18px;
	height: 46px;
	display: block;
    text-align: center;
    margin: 0 auto 10px auto;
	transition: all 0.25s!important;
}
#contact input[type="submit"].submit:hover {
	opacity: 0.92;
}
.contact-sent {
	background-color: #EBF6E0;
	color: #5f9025;
	padding: 20px 26px;
	margin-bottom: 30px;
	border-radius: 3px;
}
.address-container {
	background-color: #2b2c30;
	background-size: 100%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	display: table;
	width: 100%;
	position: relative;
	height: 100%
}
.address-container:before {
	content: "";
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	background-color: #333;
	opacity: 0.7;
}
.utf_contact_map {
	display: block;
}
.utf_contact_map #utf_single_listing_map_block {
	flex: 1;
	position: relative;
	padding-top: 0;
}
.utf_contact_map .address-box-container {
	flex: 0 auto;
	width: 440px;
	height: auto;
}
.utf_contact_map #utf_single_listing_map_block #utf_single_listingmap {
	height: 100%;
	width: 100%;
}

@media (min-width: 1680px) {
.utf_contact_map .address-box-container {
	height: 480px;
}
}

@media (max-width: 1440px) {
.utf_contact_map .address-box-container {
	height:auto;
}
}

/*==========
  Sidebar Style
============*/
.utf_sidebar_textbox {
	display: inline-block;
	width: 100%;
	padding-right: 0px;
}
.utf_sidebar_textbox.color {
	background-color: #666;
	color: #fff;
}
.utf_sidebar_textbox.color h4 {
	color: #fff;
}
.utf_sidebar_textbox h4 {
	font-size: 16px;
	margin: 0;
	padding: 0;
	margin-bottom: 16px;
}
.utf_sidebar_textbox span {
	color: #666;
}
.utf_sidebar_textbox.color span {
	color: #fff;
}
.utf_sidebar_textbox ul.utf_contact_detail {
	list-style: none;
	padding: 5px 0 0 0;
	margin-bottom:0;
}
.utf_sidebar_textbox ul.utf_contact_detail strong {
	font-weight: 500;
}
.utf_sidebar_textbox ul.utf_contact_detail li {
	text-align: left;
	position: relative;
	width: 100%;
	display: inline-block;
	padding:10px 0;
	line-height:28px;
	border-bottom:1px solid #e8e8e8;
}
.utf_sidebar_textbox ul.utf_contact_detail li a {
	color: #764c29;
}
.utf_sidebar_textbox ul.utf_contact_detail li strong {
	display: inline-block;
	color: #333;
	font-weight:600;
}
.utf_sidebar_textbox ul.utf_contact_detail li i {
	height: 100%;
	position: absolute;
	left: 0;
	font-size: 22px;
	color: #333;
	top: 0px
}

/*==========
  Blog Post Style
============*/
.utf_blog_post {
	background-color: #fff;
	display: inline-block;
	width: 100%;
	border-radius:4px;
	margin: 15px 0;
	box-shadow:0 3px 8px 0 rgba(0, 0, 0, 0.08);
	border: 1px solid rgba(0, 0, 0, 0.05);
}
section.fullwidth_block .utf_blog_post {
	margin: 5px 0 0 0;
}
.utf_post_img {
	position: relative;
	height: 100%;
	width: 100%;
	display: block;
}
.utf_post_img img {
	width: 100%;
	border-radius: 4px 4px 0 0;
}
.utf_post_img:after {
	position: absolute;
	height: 100%;
	width: 100%;
	display: block;
	top: 0;
	left: 0;
	content: "";
	z-index: 9;
	opacity: 0.1;
	background: #333;
	transition: opacity 0.4s;
	border-radius: 4px 4px 0 0;
}
.utf_post_img:hover:after {
	opacity: 0.5;
}
.utf_post_img:hover:before {
	opacity: 1;
	visibility: visible;
	transform: translateY(-50%);
}
.utf_post_content {
	padding: 38px;
}
.utf_post_content p {
	margin: 15px 0 0;
	color:#848484;
	font-size:15px;
	line-height:27px;
}
.utf_post_content h3 {
	margin: 0;
	line-height: 34px;
	font-size: 22px;
	font-weight:600;
}
.utf_post_content h3 a {
	color: #333;
}
.utf_post_content h3 a:hover {
	color: #764c29;
}
.utf_post_content .meta-tags span {
	margin: 0 0 15px 0;
	display: inline-block;
	color: #888;
}
.utf_post_content a.read-more {
	color: #764c29;
	margin: 22px 0 5px 0;
	font-weight:500;
	display: inline-block;
}
a.read-more i {
	font-size: 20px;
	position: relative;
	padding-left: 4px;
	top: 1px;
	transition: 0.2s;
}
a.read-more:hover i {
	padding-left: 7px;
}
.utf_post_text_meta {
	list-style: none;
	padding: 0;
	margin: 5px 0 0 0;
}
.utf_post_text_meta li {
	display: inline-block;
	color: #999;
	font-size:15px;
}
.utf_post_text_meta li:after {
	content: "";
	width: 1px;
	height: 12px;
	background: #d0d0d0;
	display: inline-block;
	position: relative;
	top: 0px;
	margin: 0 10px;
}
.utf_post_text_meta li:last-child:after {
	display: none;
}
.utf_post_text_meta li a {
	color: #764c29;
}
.utf_post_text_meta li a:hover {
	color: #888;
}
.blog-page .utf_blog_post {
	margin: 0 0 35px 0;
}
.blog-page h4.headline_part {
	font-size: 22px !important;
	font-weight: 500;
	position:relative;
}
.blog-page h4.headline_part:after {
    position: absolute;
    content: '';
    left: 0px;
    bottom: -10px;
    height: 2px;
    width: 40px;
    background-color: #764c29;
}
.utf_search_blog_input {
	position: relative;
}
.utf_box_widget .utf_search_blog_input .input:before {
	content: "\e090";
	font-size: 17px;
	color: #a0a0a0;
	position: absolute;
	display: inline-block;
	font-family: "simple-line-icons";
	right: 18px;
	top: 13px;
	margin-bottom: 0;
	background-color: #fff;
	padding-left: 7px;
}
#contact textarea, #contact input, .widget .utf_search_blog_input input {
	transition: box-shadow 0.2s!important;
}
#contact textarea:focus, #contact input:focus, .widget .utf_search_blog_input input:focus {
	transition: box-shadow 0.2s!important;
	box-shadow:0px 4px 12px 0px rgba(21, 128, 199, 0.3);
}

/*==========
  Widget Style
============*/
.utf_widget_tabs {
	padding: 0;
	list-style: none;
	margin-bottom:0;
}
.utf_widget_tabs li {
	padding: 0 0 27px 0;
	margin: 24px 0 0 0;
	border-bottom: 1px solid #e0e0e0;
}
.widget .tab_content {
	padding-bottom: 0;
}
.utf_widget_thum a img {
	display: block;
	width: 95px;
	height: 95px;
	float: left;
	border-radius: 50px;
	margin: 3px 22px 0 0;
}
.utf_widget_thum {
	width: 85px;
	margin-right: 20px;
}
.utf_widget_text h5 {
	font-size: 16px;
	font-weight:500;
	line-height: 24px;
	margin: 0 0 5px 0;
}
.utf_widget_text h5 a {
	color: #4b4b4b;
	font-weight: 600;
}
.utf_widget_text h5 a:hover {
	color: #764c29
}
.utf_widget_text span {
	color: #888;
	font-size:13px;
	font-weight: 400;	
	line-height: 22px;
	margin: 0;
	display: block;
}
.utf_widget_text span i{
	font-size:14px;
	margin-right:2px;
	color:#764c29;
}
.utf_widget_thum {
	display: inline-block;
}
.utf_widget_thum a img {
	width: 85px;
	height: 85px;
}
.utf_widget_text {
	display: inline-block;
	width: 70%;
	padding: 0;
	position: relative;
	top: -2px;
}
.utf_widget_tabs .utf_widget_content {
	display: table;
}
.utf_widget_tabs .utf_widget_content .utf_widget_text {
	display: table-cell;
	vertical-align: middle;
}
.utf_widget_tabs li {
	padding: 0 0 10px 0;
	margin: 15px 0 0 0;
}
.utf_widget_tabs li:first-child {
	padding: 0 0 10px 0;
	margin: 32px 0 0 0;
}
.utf_widget_tabs li:last-child {
	border: none;
	padding-bottom: 0;
}
.widget h3 {
	background:#f2f2f2;
	padding:15px 20px;
	border:1px solid rgba(0, 0, 0, 0.1);
	margin-top: 0;
	font-weight:500;
	margin-bottom: 25px;
	border-radius:10px;
	font-size: 20px;
}

/*==========
  Blog Single Post Style
============*/
.utf_search_blog_input div.input .search-field{
	margin-bottom:0;
}
.utf_single_post p {
	margin: 20px 0;
}
.utf_single_post p:last-child {
	margin-bottom: 0px;
}
.utf_single_post .utf_post_img {
	border-radius: 4px 4px 0 0;
}
.blog-page .utf_blog_post .utf_post_content, .utf_single_post .utf_post_content {
	padding:30px;
}
.utf_post_content .social-contact {
    margin:10px 0 0px 0;
    padding:15px 0;
    border-top:1px solid #e8e8e8;
	border-bottom:1px solid #e8e8e8;
}
.utf_post_content .social-contact a.facebook-link {
    background-color: #3b5998;
}
.utf_post_content .social-contact a.twitter-link {
    background-color: #1da1f2;
}
.utf_post_content .social-contact a.instagram-link {
    background-color: #e1306c;
}
.utf_post_content .social-contact a.linkedin-link {
    background-color: #2877b5;
}
.utf_post_content .social-contact a.youtube-link {
    background-color: #f00125;
}
.utf_post_content .social-contact a i{
	margin-right:5px;
}
.utf_post_content .social-contact a {
    color: #fff;
    display: inline-block;
    background-color: #e4e4e4;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 15px;
    border-radius:4px;
	margin-right:3px;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}
.utf_post_content .social-contact a:hover {
    -webkit-transform: scale(1.04);
    -moz-transform: scale(1.04);
    -ms-transform: scale(1.04);
    -o-transform: scale(1.04);
    transform: scale(1.04);
}
.utf_about_author {
	margin: 10px 0 45px 0;
	padding: 20px 25px;
	border-radius: 4px;
	background: #fcfcfc;
	box-shadow:0 1px 6px 0 rgba(0, 0, 0, 0.05);
	border: 1px solid rgba(0, 0, 0, 0.08);
}
.utf_about_author p {
	margin: 5px 0 5px 0;
	color: #848484;
	font-size:15px;
	line-height:27px;
}
.utf_about_author a {
	position: relative;
	top: -5px;
	font-size:15px;
	color: #764c29;
}
.utf_about_author a:hover{
	color: #66676b;
}
.utf_about_author h4 {
	margin-top:4px;	
	font-size:22px;
	font-weight:600;
	letter-spacing: 0;
}
.utf_about_author img {
	float: left;
	margin: 7px 5px 5px 5px;
	width: 100px;
	height: 100px;
	border-radius: 10px;
}
.utf_about_description {
	margin: 2px 0 0 130px;
}
.blog_compact_part-container {
	box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2);
	display: block;
	height: 100%;
	width: 100%;
	position:relative;
	overflow:hidden;
	border-radius: 4px;
	background: transparent;
	transform: translate3d(0, 0, 0);
	transition: transform 0.3s;
	margin-bottom: 30px;
}
.blog_compact_part-container:hover {
	transform: translate3d(0, -6px, 0);
}
.blog_compact_part-container:hover img{
    transform: scale(1.10);
	transition: transform 0.35s ease-out;
	transition: all 0.55s;
}
.blog_compact_part {
	background: #ccc;
	border-radius: 4px;
	height: 100%;
	display: block;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50%;
	height: 360px;
	z-index: 100;
	cursor: pointer;
}
.blog-page .blog_compact_part{
	height:320px;
}
.blog_compact_part img {
	object-fit: cover;
	height: 100%;
	width: 100%;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
	border-radius: 4px;
}
.blog_compact_part:before {
	content: "";
	top: 0;
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 9;
	background: linear-gradient(to top, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0.60) 40%, rgba(22,22,23,0) 80%, rgba(0,0,0,0) 100%);
	background-color: rgba(0,0,0,0.2);
	border-radius: 4px;
	opacity: 0.95;
}
.blog_compact_part_content {
	position: absolute;
	bottom: 25px;
	left: 0;
	padding: 0 20px;
	width: 100%;
	z-index: 50;
	box-sizing: border-box;
}
.blog_compact_part_content h3 {
	color: #fff;
	font-size: 20px;
	padding: 5px 0;
	font-weight: 500;
	margin:0px 0 5px 0;
	line-height: 30px;
}
.blog_compact_part_content span {
	font-size: 16px;
	font-weight: 300;
	display: inline-block;
	color: rgba(255,255,255,0.7);
}
.blog_compact_part_content p {
	font-size: 15px;
	line-height:24px;
	font-weight: 400;
	display: inline-block;
	color: rgba(255,255,255,0.9);
	margin: 7px 0 0 0;
}
span.blog-item-tag {
	text-transform: uppercase;
	font-size: 9.7px;
	letter-spacing: 2.5px;
	font-weight: 60;
	background: #66676b;
	border-radius: 4px;
	padding: 5px 16px;
	line-height: 20px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 9px;
	position: absolute;
	top: 30px;
	left: 32px;
	z-index: 110;
}
.blog_post_tag_part {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: 0;	
	opacity: 0.95;
}
.blog_post_tag_part li {
	display: inline-block;
	margin-right: 5px;
	font-size: 13px;
    background:#764c29;
    padding: 0px 12px;
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
    margin-bottom: 5px;
}
.blog_post_tag_part li i {
	margin-right: 3px;
	font-weight: normal;
	display: inline-block;
}

/*==========
  Button Style
============*/
button.button, input[type="button"], input[type="submit"], a.button.border, a.button {
	background-color: #764c29;
	top: 0;
	padding: 10px 20px;
	color: #fff;
	position: relative;
	font-size: 16px;
	font-weight: 500;
	display: inline-block;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	margin-right: 6px;
	overflow: hidden;
	border: none;
	border-radius: 4px;
}
input[type="button"], input[type="submit"] {
	line-height: 28px;
	height: auto;
	padding: 10px 20px;
}
button.button:before, a.button:before {
	width: 100%;
	height: 100%;
	content: "";
	display: block;
	background-color: #fff;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	transition: all 0.2s;
}
button.button:hover:before, a.button:hover:before {
	opacity: 0.1;
}
a.button.white {
	background-color: #fff;
	color: #333;
}
button.button {
	line-height: 26px;
}
a.button.border:before {
	display: none;
}
.centered_content a.button.border {
	background-color: #764c29;
	color:#fff;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.centered_content a.button.border:hover {
	background-color: transparent;
	color: #764c29;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
a.button.border {
	background-color: transparent;
	color: #764c29;
	border: 1px solid #764c29;
	padding: 8px 15px;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
a.button.border:hover {
	background-color: #764c29;
	color: #fff;
	opacity: 1;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
a.button.border.white {
	border-color: #fff;
	color: #fff;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
a.button.border.white:hover {
	background-color: #fff;
	color: #66676b;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
button.button.fullwidth_block, a.button.fullwidth_block {
	width: 100%;
	text-align: center;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
a.button.white.border:hover {
	color: #333;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.button i {
	padding-right: 4px;
}
.centered_content {
	text-align: center;
}
.centered_content .button {
	min-width: 130px;
}
a.button.border {
	font-weight: 500;
}
.utf_progress_button {
	transition: background-color 0.4s ease;
	box-sizing: border-box;
	text-align: center;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	outline: none;
}
.utf_progress_button span {
	position: relative;
	z-index: 11;
}

/*==========
  Box Icon Style
============*/
.box_icon_two {
	text-align: center;
    margin-top: 30px;
    position: relative;
    background: #fff;
    padding: 40px 25px 20px 25px;
    box-shadow: 0 0px 25px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
	border-radius:10px;
	transform: translate3d(0, 0, 0);
	transition: transform 0.3s;	
}
.box_icon_two:hover {
	box-shadow: 0 0px 30px rgba(0, 0, 0, 0.2);
	transform: translate3d(0, 0, 0);
	transition: transform 0.3s;	
	transform: translate3d(0, -12px, 0);	
}
.box_icon_two:hover i{
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-backface-visibility: visible;
	backface-visibility: visible;
	-webkit-animation-name: flip;
	animation-name: flip;
}
@-webkit-keyframes flip {
	0% {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
		-webkit-animation-timing-function: ease-in;
				animation-timing-function: ease-in;
	}
	50% {
		-webkit-transform: rotateY(180deg);
		transform: rotateY(180deg);
		-webkit-animation-timing-function: ease-in;
				animation-timing-function: ease-in;
	}
	100% {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
		-webkit-animation-timing-function: ease-in;
				animation-timing-function: ease-in;
	}
}
@keyframes flip {
	0% {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
		-webkit-animation-timing-function: ease-in;
				animation-timing-function: ease-in;
	}
	50% {
		-webkit-transform: rotateY(180deg);
		transform: rotateY(180deg);
		-webkit-animation-timing-function: ease-in;
				animation-timing-function: ease-in;
	}
	100% {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
		-webkit-animation-timing-function: ease-in;
				animation-timing-function: ease-in;
	}
}
.box_icon_two i {
	width: 100px;
	height: 100px;
	background: #764c29;
	border:5px solid rgba(0, 0, 0, 0.12);
	border-radius:50%;
	line-height: 100px;
	font-size: 44px;
	line-height:90px;
	display: inline-block;
	margin-bottom: 10px;
	text-align: center;
	overflow: hidden;
	color: #fff !important;
	position: relative;
	z-index: 11;
}
.box_icon_two span {
	color: #666;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
}
.box_icon_two h4 {
	margin: 4px 0;
	line-height: 26px;
}
.box_icon_two h3 {
	margin: 4px 0;
	line-height: 26px;
	font-size: 20px;
	font-weight: 600;
}
.box_icon_two p {
	margin-top: 20px;
	font-size:16px;
	line-height:28px;
	color:#808080;
}
.box_icon_two i.im-icon-Mail-withAtSign {
	font-size: 48px;
	top: 2px;
}
.icon-box-3 {
	text-align: left;
	padding-left: 90px;
	position: relative;
	width: 100%;
	display: inline-block;
	margin: 20px 0;
}
.icon-box-3 h4 {
	font-size: 18px;
	font-weight: 600;
}
.icon-box-3 i {
	font-size: 54px;
	color: #333;
	height: 60px;
	margin: 10px 0;
	display: inline-block;
	height: 100%;
	position: absolute;
	left: 0;
}
.icon-box-3 p {
	color: rgba(51,51,51,0.7);
	margin-top: 3px;
	display: inline-block;
}
.icon-box-1.rounded i, .box_icon_two.rounded i, .icon-box-3.rounded i {
	width: 110px;
	height: 110px;
	background-color: #666;
	border-radius: 50%;
	color: #fff;
	line-height: 110px;
	font-size: 50px;
	display: inline-block;
	margin-bottom: 20px;
	text-align: center;
	overflow: hidden;
}
.rounded i:before {
	position: relative;
	z-index: 11;
}
.icon-box-1.rounded i, .box_icon_two.rounded i, .icon-box-3.rounded i {
	transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
	transform: translate3d(0, 0, 0) rotate(0);
}
body .icon-box-1.rounded:hover i, body .box_icon_two.rounded:hover i, body .icon-box-3.rounded:hover i {
	color: #fff;
}
.icon-box-1.rounded i:after, .box_icon_two.rounded i:after, .icon-box-3.rounded i:after {
	content: "";
	width: 120%;
	height: 0px;
	display: inline-block;
	position: absolute;
	top: 0;
	right: 0;
	background-color: #fff;
	z-index: 8;
	transition: height 0.3s ease-in-out, opacity 0.3s ease-out;
	opacity: 0;
	transform: translate3d(50%, -50%, 0) scale(1) rotate(45deg);
}
.icon-box-1.rounded:hover i:after, .box_icon_two.rounded:hover i:after, .icon-box-3.rounded:hover i:after {
	height: 145%;
	opacity: 0.1;
}
.icon-box-1.rounded.dark i, .box_icon_two.rounded.dark i, .icon-box-3.rounded.dark i {
	background-color: #222;
}
.icon-box-1.rounded.light i, .box_icon_two.rounded.light i, .icon-box-3.rounded.light i {
	color: #333;
	background-color: #f2f2f2;
}
.icon-box-3.rounded {
	padding-left: 120px;
}
.icon-box-3.rounded i {
	width: 90px;
	height: 90px;
	font-size: 40px;
	line-height: 90px;
}
body .white-text {
	color: #fff;
}
.white-text .icon-box-1 p, .white-text .box_icon_two p, .white-text .icon-box-3 p, .white-text .icon-box-4 p {
	color: rgba(255,255,255,0.85);
}
.white-text h1, .white-text h2, .white-text h3, .white-text h4, .white-text h5, .white-text .icon-box-1 h4, .white-text .box_icon_two h4, .white-text .icon-box-3 h4, .white-text .icon-box-4 h4, .white-text .icon-box-1 i, .white-text .box_icon_two i, .white-text .icon-box-3 i, .white-text .icon-box-4 i {
	color: #fff;
}

@media (max-width: 1659px) {
.icon-box-3.rounded {
	padding-left: 110px;
}
.icon-box-3.rounded i {
	width: 80px;
	height: 80px;
	font-size: 38px;
	line-height: 80px;
}
}
table.basic-table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	border: none;
	margin-bottom: 15px;
}
table.basic-table th {
	background-color: #66676b;
	text-align: left;
	color: #fff;
	vertical-align: top;
	font-weight: 500;
}
table.basic-table th:first-child {
	border-radius: 4px 0 0 4px;
}
table.basic-table th:last-child {
	border-radius: 0 4px 4px 0;
}
table.basic-table th, table.basic-table td {
	padding: 15px 28px;
}
table.basic-table tr:nth-child(odd) {
	background-color: #f6f6f6
}
table.basic-table {
	margin-bottom: 0;
}
.share-buttons {
	padding: 0;
	list-style: none;
}
.share-buttons li {
	display: inline-block;
	margin: 0 6px 8px 0;
}
.share-buttons li a {
	background: #fff;
	border: 1px solid #666;
	border-radius: 50px;
	font-weight: 500;
	font-size: 13px;
	padding: 7px 20px;
	transition: 0.3s;
	display: inline-block;
	line-height: 17px;
	font-weight: 500;
}
.share-buttons li a:hover {
	color: #fff;
}
.share-buttons li i {
	font-size: 16px;
	position: relative;
	right: 3px;
	top: 1px;
}
.share-buttons li a.fb-share:hover {
	background: #3b5998;
	color: #fff;
}
.share-buttons li a.fb-share {
	border-color: #3b5998;
	color: #3b5998;
}
.share-buttons li a.twitter-share:hover {
	background: #1da1f2;
	color: #fff;
}
.share-buttons li a.twitter-share {
	border-color: #1da1f2;
	color: #1da1f2;
}
.share-buttons li a.gplus-share:hover {
	background: #dd4b39;
	color: #fff;
}
.share-buttons li a.gplus-share {
	border-color: #dd4b39;
	color: #dd4b39;
}
.share-buttons li a.pinterest-share:hover {
	background: #bd081c;
	color: #fff;
}
.share-buttons li a.pinterest-share {
	border-color: #bd081c;
	color: #bd081c;
}

/*==========
  Posts Nav Style
============*/
#posts-nav {
	padding: 0;
	list-style: none;
	width: 100%;
	position: relative;
	margin: 0;
	display: inline-block;
	min-height: 60px;
}
#posts-nav li span {
	display: block;
	color: #888;
	font-size: 15px;
	margin-bottom: 1px;
	font-weight: 400;
}
#posts-nav li {
	position: absolute;
	font-size: 17px;
	font-weight: 500;
	width: 50%;
}
#posts-nav li a {
	transition: transform 0.3s, color 0.3s 0s;
	color: #333;
	display: inline-block;
}
#posts-nav li a:hover {
	color: #66676b;
}
#posts-nav li.next-post {
	right: 0;
	text-align: right;
}
#posts-nav li.prev-post {
	left: 0;
}
#posts-nav li.next-post a {
	padding-right: 50px;
}
#posts-nav li.prev-post a {
	padding-left: 50px;
}
#posts-nav li.prev-post a:before, #posts-nav li.next-post a:before {
	font-family: "simple-line-icons";
	font-size: 20px;
	position: absolute;
	color: inherit;
	top: 50%;
	transform: translate3d(0, -50%, 0);
	transition: transform 0.3s;
}
#posts-nav li.next-post a:before {
	right: 0;
	content: "\e606";
}
#posts-nav li.prev-post a:before {
	left: 0;
	content: "\e605"
}
#posts-nav li.next-post a:hover:before {
	transform: translate3d(5px, -50%, 0);
}
#posts-nav li.prev-post a:hover:before {
	transform: translate3d(-5px, -50%, 0);
}

/*==========
  Plan Featured Style
============*/
.utf_pricing_container_block {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	position: relative;
}
.plan {	
	text-align: center;
	position: relative;
	z-index: 9;
	overflow: hidden;
}
.utf_price_plan {
	width: 100%;
	background: #F2E0F2;
	padding: 45px 30px;
	padding-top: 48px;
}
.plan .utf_price_plan {
	border-radius:10px;
}
.utf_price_plan h3 {
	margin: 0;
	position:relative;
	color: #888;
	font-weight: 500;
	font-size: 24px;
	margin-bottom:30px;
	text-transform:uppercase;
}
.utf_price_plan h3::after{
	position: absolute;
    content: '';
    left: 0px;
    bottom: -15px;
    height: 2px;
    width: 40px;
    background-color: #fff;
    right: 0;
    text-align: center;
    margin: 0 auto;
}
.plan.featured .listing-badge {
	background-color: #fff;
	color: #66676b;
}
.utf_price_plan .value {
	display: block;
	font-size: 36px;
	line-height: 30px;
	font-weight: 600;
	padding: 15px 0 0 0;
	color: #333;
}
.utf_price_plan .period {
	color: #888;
	margin-top:0px;
	padding: 0;
	display: block;
}
.utf_price_plan_features {
	box-shadow:0px 0px 20px 0px rgba(0,0,0,0.15);
	padding: 35px 30px;
	background: #fff;
	border-radius:0 0 4px 4px;
	margin-bottom:10px;
}
.utf_price_plan_features ul {
	list-style: none;
	padding: 0;
}
.utf_price_plan_features ul li {
	padding: 6px 0;
	border-radius: 0;
	font-weight:400;
    border-top: 1px dashed rgba(0, 0, 0, 0.15);
    margin-bottom:0px;
}
.utf_price_plan_features ul li:last-child{
	border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
}
.utf_price_plan_features a.button {
	margin-top: 20px;
}
.plan.featured {
	border-radius:10px;
	z-index: 11;
}
.plan.featured .utf_price_plan {
	box-shadow:0px 0px 20px 0px rgba(0,0,0,0.15);
	background-color: #764c29;
	padding:40px 20px;
	border-radius: 10px 10px 0 0;
}
.plan.featured .utf_price_plan h3 {
	color: #fff;
}
.plan.featured .utf_price_plan .value {
	color: #fff;
	display:inline-block;
}
.plan.featured .utf_price_plan span.value span{
	font-size:16px;
	font-weight:400;
    display: inline-block;
}
.plan.featured .utf_price_plan .period {
	color: #fff;
	line-height: 24px;
	margin-top:15px
}
.plan a.button{
	border-radius:4px;
}
.plan.featured .listing-badges .featured {
	background: #fff;
	color: #66676b;
}
.list-4, .list-3, .list-2, .list-1 {
	padding: 3px 0 0 0;
	font-size: 16px;
}
.list-4 li, .list-3 li, .list-2 li, .list-1 li {
	list-style: none;
	margin: 14px 0;
	line-height: 27px;
	margin-left: 22px;
	position: relative;
}
.list-1.alt li {
	margin: 12px 0;
	margin-left: 22px;
}
.list-4 li:first-child, .list-3 li:first-child, .list-2 li:first-child, .list-1 li:first-child {
	margin-top: 0;
}
.list-4 li:before, .list-3 li:before, .list-2 li:before, .list-1 li:before {
	font-family: "FontAwesome";
	margin: 0;
	position: relative;
	color: #764c29;
	float: left;
	margin-left: -22px;
	display: block;
}
.list-1.gray li:before {
	color: #777;
}
.list-4 li:before {
	content: "\f196";
	font-size: 14px;
	top: 1px;
}
.list-3 li:before {
	content: "\f059";
	font-size: 15px;
	top: 1px;
}
.list-2 li:before {
	content: "\f00b";
	font-size: 14px;
}
.list-1 li:before {
	content: "\f0c3";
	font-size: 14px;
}
.list-4 li {
	margin-left: 27px;
}
.list-4 li:before {
	margin-left: -27px;
}
.numbered ol {
	counter-reset: li;
	list-style: none;
	padding: 0;
	margin-left: 18px;
	display: inline-block;
	font-size: 16px;
}
.numbered ol li {
	display: inline-block;
	padding: 6px 0;
	width: 100%;
}
.numbered ol>li::before {
	content: counter(li);
	counter-increment: li;
	font-size: 16px;
	line-height: 33px;
	width: 36px;
	height: 36px;
	display: inline-block;
	border: 1px solid #764c29;
	border-radius: 50%;
	text-align: center;
	margin: 0;
	margin-left: 0;
	font-weight: 500;
	color: #764c29;
	position: relative;
	float: left;
	left: -18px;
	font-size: 15px;
	transform: translateY(-10%);
}
.numbered.color ol>li::before {
	border: 1px solid #764c29;
	color: #764c29;
}
.numbered.color.filled ol>li::before {
	border: 1px solid #764c29;
	color: #fff;
	background-color: #764c29;
}
mark.color {
	background-color: #66676b;
	border-radius: 4px;
}
mark {
	border-radius: 4px;
}
.demo h1, .demo h2, .demo h3, .demo h4, .demo h5, .demo h6 {
	line-height: 40px;
}
.info-box {
	background-color: #fff;
	display: inline-block;
	width: 100%;
	border-radius: 3px;
	padding: 23px 27px;
	border-top: 2px solid #66676b;
	background: linear-gradient(to bottom, rgba(255,255,255,0.98), rgba(255,255,255,0.95));
	background-color: #66676b;
	color: #66676b;
}
.info-box.no-border {
	border: none;
	background: linear-gradient(to bottom, rgba(255,255,255,0.96), rgba(255,255,255,0.93));
	background-color: #66676b;
}
.info-box h4 {
	margin: 0;
	font-size: 19px;
	color: #66676b;
	margin-bottom: 20px;
	margin-top: 0px;
}
.info-box p {
	margin-bottom: 0;
	font-size: 15px;
	line-height: 26px;
}
.info-box.large {
	padding: 36px;
}
#result .success, .notification {
	line-height: 24px;
	margin-bottom: 15px;
	position: relative;
	padding: 15px 26px;
	padding-right: 50px;
	border-radius: 3px;
}
.notification p {
	margin: 0;
	font-size: 15px;
}
#result .success, .notification.success {
	background-color: #EBF6E0;
}
#result .success, .notification.success, .notification.success a, .notification.success strong {
	color: rgba(84, 186, 29, 1);
}
.notification.error {
	background-color: #ffe9e9;
}
.notification.error, .notification.error a, .notification.error strong {
	color: #de5959;
	box-shadow:0 4px 0px 0 rgba(0, 0, 0, 0.10);
}
.notification.warning {
	background-color: #FBFADD;
	box-shadow:0 4px 0px 0 rgba(0, 0, 0, 0.10);
}
.notification.warning, .notification.warning a, .notification.warning strong {
	color: #8f872e;
}
.notification.notice h4 {
	font-size: 19px;
	margin: 3px 0 15px 0;
}
.notification.notice h4, .notification.notice, .notification.notice a, .notification.notice strong {
	color: #3184ae
}
.notification.notice {
	background-color: #E9F7FE;
	box-shadow:0 4px 0px 0 rgba(0, 0, 0, 0.10);
}
.notification.notice.large {
	padding: 32px 36px;
}
#dashboard .success {
	background-color:rgba(84, 186, 29, 0.1);
	border:1px solid rgba(84, 186, 29, 0.3);
}
#dashboard .notice {
	background-color: #e1f3fc;
}
#dashboard .warning {
	background-color: #f6f4c9;
}
body .notification strong {
	border: none;
	font-weight:600;
}
.notification.success .close, .notification.error .close, .notification.warning .close, .notification.notice .close {
	padding: 0px 9px;
	position: absolute;
	right: 0;
	top: 18px;
	display: block;
	height: 8px;
	width: 8px;
	cursor: pointer;
	box-shadow:none;
}
.notification.notice p span i {
	font-weight: 500;
}
.notification a.button {
	float: right;
	color: #fff;
	margin-top: 3px;
}
.notification.notice a.button {
	background-color: #388fc5;
}
.notification.warning a.button {
	background-color: #dfbe51;
}
.notification.error a.button {
	background-color: #d34c4c;
}
.notification.success a.button {
	background-color: #79ba38;
}
.notification.closeable a.close:before {
	content: "\f00d";
	font-family: "FontAwesome";
	position: absolute;
	right: 25px;
	top: 0;
	cursor: pointer;
}
.img-box {
	height: 300px;
	display: inline-block;
	position: relative;
	width: 100%;
	overflow: hidden;
	z-index: 90;
	margin: 10px 0;
	border-radius:4px;
	box-shadow:0 5px 15px 0 rgba(0,0,0,0.3);
}
.img-box-background {
	background-size: cover;
	background-position: 50%;
	width: 100%;
	height: 100%;
	transition: all 0.55s;
	position: absolute;
	top: 0;
	left: 0;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	transition: transform 0.35s ease-out;
}
.img-box:hover .img-box-background {
	transform: scale(1.10);
}
.img-box:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	content: "";
	background-color: #000;
	opacity: 0.5;
	z-index: 99;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.img-box:hover::after {
    visibility: visible;
    opacity:1;
}
.img-box::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: -webkit-linear-gradient(90deg,rgba(245,84,142,.9) 0,rgba(144,58,249,.9) 100%);
    background: -ms-linear-gradient(90deg,rgba(245,84,142,.9) 0,rgba(144,58,249,.9) 100%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.utf_img_content_box {
	position: absolute;
    z-index: 101;
    top: 82%;
    left: 25px;
    margin: 0 auto;
    text-align: left;
    width: 100%;
    transform: translate(0, -50.5%);
    border-left: 4px solid #764c29;
    border-radius: 2px;
    padding-left: 20px;
}
.utf_img_content_box h4 {
	font-size: 22px;
	line-height: 32px;
	font-weight:600;
	color: #fff;
	margin: 0;
	margin-bottom:5px
}
.utf_img_content_box span {
	font-size: 14px;
	font-weight:600;
	color: #fff;
	opacity: 1;
	background:rgba(255, 34, 34, 0.5);
	padding:2px 7px;
	border-radius: 4px;
}
.img-box:hover .utf_img_content_box span{
	background:rgba(255, 34, 34, 1);
}

/*==========
  Testimonial Style
============*/
.utf_testimonial_box {
	background: #ccc;
	border-radius:10px;
	padding:40px 50px;
	color: #222;
	position: relative;
	margin: 18px 0;
	box-shadow: none;
	transition: 0.4s;
	cursor: grab;
}
.utf_testimonial_carousel .slick-slide {
	margin: 0 20px 30px 20px;
	opacity: 0.5;
	transition: 0.4s;
	pointer-events: all;
}
.utf_testimonial_carousel .slick-slide.slick-active .utf_testimonial_box {
	background: #764c29;
	color: #fff;
}
.utf_testimonial_carousel .slick-slide.slick-active .testimonial:before {
	color: #764c29;
}
.testimonial:before {
	position: absolute;
	margin: 0 auto;
	display: block;
	width: 100%;
	text-align: center;
	bottom: -10px;
	left: 0;
	content: "\f0dd";
	font-family: "FontAwesome";
	font-size: 42px;
	color: transparent;
	background-color: transparent;
	cursor: default;
}
.testimonial, .testimonial p {
	font-size: 16px;
	font-weight: 400;
	line-height: 27px;
	text-align:center;
}
.utf_testimonial_author {
	display: block;
	width: 100%;
	padding: 20px 0 15px 0;
	text-align: center;
	margin: 0 auto;
}
.utf_testimonial_author img {
	width: 120px;
	display: inline-block;
	text-align: center;
	height: auto;
	border-radius:50%;
	border:5px solid rgba(0, 0, 0, 0.1);
	margin: 0 auto;
}
.utf_testimonial_author h4 {
	display: block;
	width: 100%;
	font-size: 18px;
	padding-top: 5px;
	color: #333;
	font-weight: 600;
}
.utf_testimonial_author h4 span {
	display: block;
	color: #888;
	padding-top: 10px;
	font-weight: 400;
	font-size: 14px;
}
.utf_testimonial_box .utf_star_rating_section {
	height: 30px;
	display: inline-block;
	margin-bottom: -10px;
	margin-top: 15px;
}
.info-banner {
	display: block;
	width: 100%;
	padding: 90px 0;
	color: #fff;
	margin: 0;
}
.info-banner-content {
	position: relative;
	z-index: 199;
}
.info-banner.centered {
	text-align: center;
}
.info-banner-content a.button {
	margin: 0 20px -15px 20px;
	position: relative;
}
.flip-banner h1, .flip-banner h2, .flip-banner h3, .flip-banner h4, .flip-banner h5, .flip-banner h6, .flip-banner a, .info-banner-content h1, .info-banner-content h2, .info-banner-content h3, .info-banner-content h4, .info-banner-content h5, .info-banner-content h6, .info-banner-content a, .info-banner-content {
	color: #fff;
	display: inline-block;
	margin: 0;
	position: relative;
}
.utf_text_content {
	background: rgba(0, 0, 0, 0.5);
	padding: 110px 0px;
	font-weight: 500;
	z-index: 999;
	position: relative;
}
.utf_text_content.white-font {
	color: #fff;
	text-align:center;
}
.utf_text_content h1, .utf_text_content h2, .utf_text_content h3, .utf_text_content h4, .utf_text_content h5 {
	color: #fff;
}
.utf_text_content.white-font h1, .utf_text_content.white-font h2, .utf_text_content.white-font h3, .utf_text_content.white-font h4, .utf_text_content.white-font h5 {
	color: #fff;
}
.utf_text_content a.button {
	padding: 10px 25px;
	font-size: 16px;
	font-weight: 600;
}
.utf_text_content.white-font a.button:hover {
	background-color: #fff;
	color: #333;
}
.utf_text_content a.button:before {
	display: none;
}
.utf_text_content p {
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;	
	color:#d6d6d6
}
.utf_text_content h2 {
	margin-bottom: 30px;
	line-height: 46px;
	font-size: 36px;
	font-weight: 600;
}
.top-1 {
	position: relative;
	top: -1px;
}
.top-0 {
	position: relative;
}
.image-edge {
	background-color: #f8f8f8;
	position: relative;
	overflow: hidden;
	margin: 35px 0 0 0;
	height: 50vw;
	max-height: 640px;
	overflow: hidden;
	padding: 0;
}
.image-edge #googlemaps, .image-edge .google-utf_map_container {
	height: 100%;
}
.image-edge-content {
	padding: 0;
	top: 50%;
	position: relative;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	width: 50%;
	padding: 0 9%;
}
.image-edge.left .image-edge-content {
	padding-left: 6%;
}
.image-edge-content h3.headline_part {
	width: 100%;
	display: block;
	margin-top: 0;
}
.edge-bg {
	position: absolute;
	width: 50%;
	top: 0;
	right: 0px;
	height: 100%;
	background-size: 100%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.edge-bg:before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background-color: rgba(34,34,34,0.1);
}
.image-edge.left .image-edge-content {
	left: 50%;
}
.image-edge.left .edge-bg {
	left: 0px;
}
.image-edge.left .container {
	transform: translateX(54%);
	-webkit-transform: translateX(54%);
}
.image-edge.dark {
	background-color: #282828;
	color: #fff;
}
.image-edge.dark h1, .image-edge.dark h2, .image-edge.dark h3, .image-edge.dark h4, .image-edge.dark h5 {
	color: #fff;
}
.presentation-content h2, .image-edge h2 {
	line-height: 56px;
	font-weight: 600;
	font-size: 40px;
	letter-spacing: -0.5px;
}
.presentation-content p, .image-edge p {
	font-size: 20px;
	color: #828282;
	line-height: 34px;
}
.image-edge.dark p {
	color: rgba(255,255,255,0.8);
}
.image-edge-content ul {
	list-style: none;
	padding: 0;
}
.image-edge-content ul li {
	margin-bottom: 30px;
}
.image-edge-content ul li:last-child {
	margin-bottom: 0px;
}
.image-edge-content h4 i {
	position: relative;
	top: -1px;
	margin-right: 2px;
}

@media (max-width: 767px) {
.edge-bg {
	position: relative;
	height: 400px;
	width: 100%;
	display: block;
	left: 0;
	right: 0;
}
#titlebar.utf_listing_titlebar span.listing-tag {
    margin-left: 0;
    margin-bottom: 10px;
}
.utf_listing_section .utf_listing_reviews .avatar {
    display: none;
}
.dashboard_review_item .avatar, .utf_listing_reviews .avatar {
    display: none;
}
.comments .avatar{
	display: none;
}
.utf_listing_section .utf_comment_content, .comments .utf_comment_content{
	padding-left:0;
}
.comments .utf_comment_content{
	padding-left:0;
	padding-right:0;
	padding-bottom:20px;	
	border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}
.utf_dashboard_list_box .utf_comment_content{
	padding-left:0;
	padding-right:0;
	padding-bottom:0px;	
	border-bottom:0;
}
.comments ul li, .comments ul li ul li{
	padding:0;
	margin:0;
	border:0;
}
.utf_listing_section .utf_comment_content a.rate-review {
    right: 0;
    top: -5px;
}
.utf_by_comment a.reply{
	right:0
}
}

/*==========
  Flip Banner Style
============*/
.flip-banner {
	display: block;
	width: 100%;
	height: 180px;
	position: relative;
	text-align: center;
	overflow: hidden;
	z-index: 9999;
}
.flip-banner:after {
	background-color: #000;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: "";
	display: block;
	z-index: 100;
	transition: all 0.6s;
}
.flip-banner:hover:after {
	opacity: 0.6;
}
.flip-banner-content {
	position: relative;
	top: 50%;
	z-index: 200;
}
.flip-banner h2 {
	font-size: 34px;
	font-weight:600;
	line-height: 40px;
	position: relative;
	width: 100%;
	display: block;
}
.flip-banner-content .flip-visible, .flip-banner-content .flip-hidden {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
	transform: translateY(-50%);
	transition: all 0.45s cubic-bezier(.87, -.41, .19, 1.44);
}
.flip-banner:hover .flip-banner-content .flip-hidden, .flip-banner-content .flip-visible {
	opacity: 1;
	visibility: visible;	
}
.flip-banner i {
	padding: 0 10px;
}
.flip-banner .sl {
	position: relative;
	top: 1px;
	font-size: 26px;
}

@media (max-width: 767px) {
.flip-banner .sl {
	font-size: 18px;
}
.flip-banner h2 {
	font-size: 30px;
	line-height: 40px;
}
.search_categorie_block .col-xs-12{
	padding-left:0;
	padding-right:0;
}
}

/*==========
  Tabs Nav Style
============*/
.utf_tabs_nav {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}
.utf_tabs_nav li a i {
	padding-right: 5px;
	font-size: 16px;
	top: 1px;
	position: relative;
}
.utf_tabs_nav li {
	float: left;
	line-height: 45px;
	padding: 0;
	margin:0 2px;
	position: relative;
	z-index: 12;
	width:49%;
	text-align:center;
}
.utf_tabs_nav li a, .tab-reviews {
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}
.utf_tabs_nav li a {
	border: 2px solid transparent;
	color: #333;
	display: inline-block;
	outline: none;
	padding: 7px 15px;
	text-decoration: none;
	text-transform:uppercase;
	font-size: 16px;
	font-weight:500;
	z-index: 11;
	position: relative;
	width:100%
}
.utf_tabs_nav li a:hover {
	border-color: #66676b;
}
.tab-reviews {
	color: #aaa;
}
.utf_tabs_nav li.active .tab-reviews {
	color: #fff;
}
.utf_tabs_nav li a:hover, .utf_tabs_nav li.active a {
	border-color: #66676b;
	color: #66676b;
	z-index: 11;
}
.tab_content {
	padding:25px;
	position: relative;
	z-index: 10;
	display: inline-block;
	width: 100%;
}
.tab_container {
	overflow: hidden;
	width: 100%;
}
.style-1 .tab_container .tab_content{
	padding:25px 0 0 0;
}
.element_tab .utf_tabs_nav li{
	width:auto;
}
.element_tab .utf_tabs_nav li a{
	padding:0px 12px;
	text-transform:none;
	border-radius: 6px;
	margin-bottom:10px;
}
.element_tab .utf_tabs_nav li a:hover, .element_tab .utf_tabs_nav li a.active{
	border-radius: 40px;
}
.element_tab .tab_container{
	border-top:2px solid #d4d4d4
}
.tab_content p:last-child {
	margin-bottom: 0;
}
.style_one .tab_container .tab_content {
	padding: 20px 0 0 0;
}
.style-3 .utf_tabs_nav {
	text-align: center;
}
.style-3 .utf_tabs_nav li {
	float: none;
	line-height: 32px;
	padding: 0;
	margin-right: 4px;
	position: relative;
	z-index: 12;
	display: inline-block;
	text-align: center;
}
.style-3 .utf_tabs_nav li a {
	border: none;
	color: #333;
	display: inline-block;
	outline: none;
	padding: 7px 24px;
	text-decoration: none;
	font-size: 14px;
	z-index: 11;
	position: relative;
	margin-bottom: -1px;
	background-color: #fff;
	border-radius: 3px;
	font-size: 13px;
}
.style-3 .utf_tabs_nav li a:hover, .style-3 .utf_tabs_nav li.active a {
	border-color: #66676b;
	background-color: #66676b;
	color: #fff;
	z-index: 11;
}
.style-3 .tab_container .tab_content {
	border: none;
	padding: 30px 0 0 0;
}
.style-4 .utf_tabs_nav {
	width: 35%;
	display: table-cell;
}
.style-4 .tab_container {
	width: 65%;
	padding-left: 20px;
	display: table-cell;
	vertical-align: middle;
}
.style-4 .tab_container .tab_content {
	border: none;
	padding: 10px 0 0 0;
}
.style-4.tabs {
	display: table;
}
.style-4 .utf_tabs_nav li a {
	float: none;
	width: 90%;
	border: none;
	padding: 0;
	background-color: #fff;
	margin: 0 0 1px 0;
	border: none;
	padding: 9px 24px;
	line-height: 30px;
	border-radius: 3px;
}
.style-4 .utf_tabs_nav li.active a {
	background-color: #66676b;
	color: #fff;
}
.style-4 .utf_tabs_nav li {
	width: 100%;
}
.style-5 .utf_tabs_nav li {
	width: 33.33%;
}
.stytle-5 .tab_container .utf_widget_text {
	position: relative;
	top: 50px;
	transform: transitionY(-50%);
	display: inline-block;
}
.style-5 .utf_tabs_nav {
	bottom: 5px;
	position: relative;
}
.style-5 .utf_tabs_nav li a {
	border: none;
	background-color: #f2f2f2;
	color: #333;
	border-radius: 4px;
	padding: 0;
}
.style-5 .utf_tabs_nav li.active a {
	background-color: #66676b;
	color: #fff;
}
.style-5 .tab_container .tab_content {
	box-shadow: none;
	border: none;
	padding: 20px 0 0 0;
}
.rememberme {
	display: block;
	margin-top: 10px;
	cursor: pointer;
}
.utf_signin_form .tab_container {
	overflow: visible;
}
.utf_signin_form .utf_tabs_nav li {
	line-height: 15px;
}
.utf_tabs_nav li a:hover, .utf_tabs_nav li.active a {
	background:#764c29 !important;
    border-color: #764c29 !important;
    color: #fff !important;
}
.utf_signin_form .utf_tabs_nav li a {
	padding:15px;
	color: #666;
	background:#f4f4f4;
	border-radius:4px;
}
.utf_signin_form .utf_tabs_nav li a:hover, .utf_signin_form .utf_tabs_nav li.active a {
	color: #66676b;
	width:100%;
}
.lis-relative {
	font-size: 16px;
    line-height: 24px;    
	color:#707070
}
.lis-relative a{
	color:#764c29;
	font-weight:500;
}
.lis-relative a:hover{
	color:#707070
}

/*==========
  Accordion Toggles Style
============*/
.ui-accordion .ui-accordion-header, .trigger a {
	display: block;
	cursor: pointer;
	position: relative;
	padding: 0;
	line-height: 32px;
	outline: none;
	color: #333;
	font-size: 18px;
	font-weight:500;
	background-color: #fff;
	border: 1px solid #e0e0e0;
	transition: background-color 0.2s, color 0.2s;
	padding:20px;
	margin: -1px 0 0 0;
}
.ui-accordion .ui-accordion-header i, .trigger a i {
	font-size: 18px;
	top: 1px;
	position: relative;
	padding-right: 3px;
}
.style-2 .ui-accordion .ui-accordion-header i, .style-2 .trigger a i {
	padding-right: 6px;
}
.style-2 .ui-accordion .ui-accordion-header:hover, .style-2 .trigger a:hover { color: #66676b; }
.trigger.active a, .ui-accordion .ui-accordion-header-active:hover, .ui-accordion .ui-accordion-header-active {
	background-color: #764c29;
	border-color: #764c29;
	color: #fff;
}
.ui-accordion .ui-accordion-header i, .trigger a i {
	margin: 0 4px 0 0;
	position: relative;
}
.ui-accordion .ui-accordion-content, .toggle-container {
	padding:20px 22px;
	border: 1px solid #e0e0e0;
	border-top: none;
}
.ui-accordion .ui-accordion-content p, .toggle-container p { margin: 0; }
.style-2 .ui-accordion .ui-accordion-header, .style-2 .trigger a {
	border: none;
	margin: 0;
	padding: 25px 0;
	text-transform: none;
	font-size: 18px;
	font-weight: 500;
	background-color: transparent;
	color: #333;
	padding-right: 60px;
	font-weight: 400;
}
body .style-2 .ui-accordion .ui-accordion-header, body .style-2 .trigger a { text-transform: none; }
.style-2 .ui-accordion .ui-accordion-content, .style-2 .toggle-container {
	padding: 0px 0 30px 0;
	border: none;
}
.style-2 .trigger.active a, .style-2 .ui-accordion .ui-accordion-header-active:hover, .style-2 .ui-accordion .ui-accordion-header-active {
	background-color: transparent;
	border-color: transparent;
	color: #66676b;
	transition: color 0.1s;
}
.style-2 .toggle-wrap:first-child {
	border-top: 1px solid #e0e0e0
}
.style-2 .ui-accordion .ui-accordion-header, .style-2 .ui-accordion .ui-accordion-content, .style-2 .toggle-wrap {
	border-bottom: 1px solid #e0e0e0
}
.style-2 .trigger a:hover{
	color:#764c29;
}
.style-2 .trigger.active a{
	color:#764c29;
}
.style-2 .ui-accordion .ui-accordion-header-active { border: none; }
.accordion h3 .sl-icon-plus, .trigger .sl-icon-plus {
	position: absolute;
	right: 10px;
	top: 50%;
	font-size: 24px;
	transform: translate3d(0,-50%,0) rotate(0);
	transition: 0.2s;
}
.accordion h3.ui-accordion-header-active .sl-icon-plus, .trigger.active .sl-icon-plus {
	margin-top: 2px;
	transform: translate3d(0,-50%,0) rotate(45deg);
}
.style-2 .ui-accordion-header{
    border: none;
    margin: 0;
    padding: 20px 0;
    padding-right: 0px;
    text-transform: none;
    font-size: 18px;
	line-height:26px;
    font-weight: 600;
    background-color: transparent;
    color: #333;
    padding-right: 60px; 
	padding-left:10px;	
}
.style-2 .ui-accordion-header:first-child{
    border-top: 1px solid #e0e0e0;
}
.style-2 .ui-accordion-content {
    border-bottom: 1px solid #e0e0e0;
	padding-left: 10px;
	padding-right: 10px;	
}
.style-2 .ui-accordion-header{
	border-bottom: 1px solid #e0e0e0;	
}
.style-2 .ui-accordion-header-active {
    border: none;
}
.style-2 .ui-accordion-header{
	position:relative
}
.style-2 .accordion h3 .sl-icon-plus{
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 26px;
    transform: translate3d(0,-50%,0) rotate(0);
    transition: 0.2s;
}
.style-2 .ui-accordion-header:hover, .style-2 .ui-accordion-header-active{
	color:#764c29;
	cursor:pointer;
}
.style-2 .accordion h3.ui-accordion-header-active .sl-icon-plus {
	color:#764c29;
    margin-top: 2px;
    transform: translate3d(0,-50%,0) rotate(45deg);
}

/*==========
  Login Block
============*/
.lost_password a {
	margin: 0;
	color: #666;
	font-size: 16px;
	font-weight:400;
}
.lost_password:hover a {
	color: #764c29;
}
.rememberme input {
	margin: 0 2px 0 0;
	display: inline-block;
	min-height: auto;
	width: auto;
}
.myaccount input.button {
	margin-bottom: 0;
}
.form_forgot_part{
	float:left;
	width:100%;
	margin-bottom:20px;
}
.form_forgot_part .checkboxes label{
	margin-right:0;
	color: #666;
    font-size: 16px;
    font-weight: 400;
}
.utf_signin_form label {
	position: relative;
	color: #666;
	font-weight:500;
	font-size: 16px;
}
.utf_signin_form label input {
	margin-top: 10px;
	padding-left: 15px;
	height: 50px;
}
.utf_signin_form label input[type="checkbox"] {
	height: auto;
}
.utf_signin_form input {
	transition: box-shadow 0.2s!important;
}
.utf_signin_form input:focus {
	transition: box-shadow 0.2s!important;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.07);
}
.utf_signin_form label i {
	font-size: 20px;
	color: #a0a0a0;
	position: absolute;
	left: 15px;
	bottom: 11px;
}
.utf_signin_form .button {
	line-height: 30px;
	height: auto;
	width: 100%;
	text-transform:uppercase;
	font-size:16px;
	margin-bottom:0
}

/*==========
  Social Icon Style
============*/
.utf_social_icon {
	width:100%;
	padding-left: 5px;
	display:inline-block;
	margin-bottom:0;
}
.utf_social_icon li {
	display: inline;
	float: left;
	text-align: left;
	margin-right: 16px;
	margin-top: 10px;
	transform: scale(1.19);
	-webkit-transform: scale(1.19);
	-webkit-filter: blur(radius);
}
.utf_social_icon li a {
	width: 36px;
	height: 36px;
	display: inline-block;
	background-color: #ffffff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	position: relative;
	overflow: hidden;
	border: 1px solid #f3f3f3;
}
.utf_social_icon.fixed li a {
	display: block;
}
.utf_social_icon li a i {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	position: absolute;
	z-index: 20;
	transform: scale(0.9);
	-webkit-transform: scale(0.9);
	-webkit-filter: blur(radius);
}
.utf_social_icon li a:hover, .utf_social_icon li a i {
	color: #fff;
}
.utf_social_icon li a i {
	top: -30px;
	opacity: 0;
}
.utf_social_icon li a:hover i {
	top: 0;
	opacity: 1;
}
.utf_social_icon.rounded li a {
	border-radius:50%;
}
.utf_social_icon.border li a {
	background-color: #fff;
	border: 1px solid #e0e0e0;
}
.utf_social_icon.border li a:hover {
	border-color: transparent;
}
.utf_social_icon.color li a {
	border-color: transparent;
}
.utf_social_icon.color li a i, .utf_social_icon.color li a:hover i {
	top: 0;
	opacity: 1;
}
.utf_social_icon.color a:before {
	opacity: 0;
}
.utf_social_icon.fixed {
	position: fixed;
	left: 0;
	padding-left: 2px;
	top: 100px;
	width: 50px;
}
.utf_social_icon.fixed li {
	transform: scale(1.15);
	-webkit-transform: scale(1.15);
	margin-top: 7px;
}
.utf_social_icon.fixed li a {
	background-color: rgba(51,51,51,0.35);
}
.utf_social_icon.fixed li a:before {
	color: #fff;
}
.twitter:before, .wordpress:before, .facebook:before, .linkedin:before, .steam:before, .tumblr:before, .github:before, .delicious:before, .instagram:before, .xing:before, .amazon:before, .dropbox:before, .paypal:before, .lastfm:before, .gplus:before, .yahoo:before, .pinterest:before, .dribbble:before, .flickr:before, .reddit:before, .vimeo:before, .spotify:before, .rss:before, .youtube:before, .blogger:before, .appstore:before, .digg:before, .evernote:before, .fivehundredpx:before, .forrst:before, .stumbleupon:before, .dribbble:before {
	font-family: "fontello";
	font-size: 16px;
	line-height: 16px;
	color: #a0a0a0;
	-webkit-transition: all 0.15s ease-in-out;
	-moz-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	-ms-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	position: absolute;
	z-index: 20;
	transform: scale(0.9);
}
.twitter:before, .utf_social_icon li a.twitter i {
	font-size: 14px;
}
.twitter i, .twitter:before {
	margin: 10px 0 0 8px;
}
.twitter:before {
	content: "\54";
	top: 0;
	opacity: 1;
}
.twitter:hover:before {
	top: 30px;
	opacity: 0;
}
.twitter:hover {
	background-color: #3bc1ed!important;
}
.twitter:hover, .utf_social_icon.color .twitter, .utf_social_icon.fixed .twitter:hover {
	background-color: #3bc1ed;
	border-color: #3bc1ed;
}
.dribbble i, .dribbble:before {
	margin: 13px 0 0 13px;
}
.dribbble:before {
	content: "\44";
	top: 0;
	opacity: 1;
}
.dribbble:hover:before {
	top: 30px;
	opacity: 0;
}
.dribbble:hover {
	background-color: #ef5a92!important;
}
.utf_social_icon.color .dribbble, .utf_social_icon.fixed .dribbble:hover {
	background-color: #ef5a92;
	border-color: #ef5a92;
}
.facebook i, .facebook:before {
	margin:9px 0 0 12px;
}
.facebook:before {
	content: "\66";
	top: 0;
	opacity: 1;
}
.facebook:hover:before {
	top: 30px;
	opacity: 0;
}
.facebook:hover {
	background-color: #4a6d9d!important;
}
.utf_social_icon.color .facebook, .utf_social_icon.fixed .facebook:hover {
	background-color: #4a6d9d;
	border-color: #4a6d9d;
}
.linkedin i, .linkedin:before {
	margin:9px 0 0 8px;
}
.linkedin:before {
	content: "\4c";
	top: 0;
	opacity: 1;
}
.linkedin:hover:before {
	top: 30px;
	opacity: 0;
}
.linkedin:hover {
	background-color: #0b7bb5!important;
}
.utf_social_icon.color .linkedin, .utf_social_icon.fixed .linkedin:hover {
	background-color: #0b7bb5;
	border-color: #0b7bb5;
}
.delicious i, .delicious:before {
	margin:9px 0 0 12px;
}
.delicious:before {
	content: "\23";
	top: 0;
	opacity: 1;
}
.delicious:hover:before {
	top: 30px;
	opacity: 0;
}
.delicious:hover {
	background-color: #3274d1!important;
}
.utf_social_icon.color .delicious, .utf_social_icon.fixed .delicious:hover {
	background-color: #3274d1;
	border-color: #3274d1;
}
.paypal i, .paypal:before {
	margin:9px 0 0 12px;
}
.paypal:before {
	content: "\24";
	top: 0;
	opacity: 1;
}
.paypal:hover:before {
	top: 30px;
	opacity: 0;
}
.utf_social_icon.color .paypal, .utf_social_icon.fixed .paypal:hover {
	background-color: #2e689a;
	border-color: #2e689a;
}
.gplus i, .gplus:before {
	margin:9px 0 0 8px;
}
.gplus:before {
	content: "\f0d5";
	top: 0;
	opacity: 1;
}
.gplus:hover:before {
	top: 30px;
	opacity: 0;
}
.gplus:hover {
	background-color: #d53824!important;
}
.utf_social_icon.color .gplus, .utf_social_icon.fixed .gplus:hover {
	background-color: #d53824;
	border-color: #d53824;
}
.stumbleupon i, .stumbleupon:before {
	margin:9px 0 0 12px;
}
.stumbleupon:before {
	content: "\2f";
	top: 0;
	opacity: 1;
}
.stumbleupon:hover:before {
	top: 30px;
	opacity: 0;
}
.stumbleupon:hover {
	background-color: #eb4924!important;
}
.utf_social_icon.color .stumbleupon, .utf_social_icon.fixed .stumbleupon:hover {
	background-color: #eb4924;
	border-color: #eb4924;
}
.fivehundredpx i, .fivehundredpx:before {
	margin:9px 0 0 12px;
}
.fivehundredpx:before {
	content: "\30";
	top: 0;
	opacity: 1;
}
.fivehundredpx:hover:before {
	top: 30px;
	opacity: 0;
}
.fivehundredpx:hover {
	background-color: #444!important;
}
.utf_social_icon.color .fivehundredpx, .utf_social_icon.fixed .fivehundredpx:hover {
	background-color: #444;
	border-color: #444;
}
.pinterest i, .pinterest:before {
	margin:9px 0 0 12px;
}
.pinterest:before {
	content: "\31";
	top: 0;
	opacity: 1;
}
.pinterest:hover:before {
	top: 30px;
	opacity: 0;
}
.pinterest:hover {
	background-color: #cb1f25!important;
}
.utf_social_icon.color .pinterest, .utf_social_icon.fixed .pinterest:hover {
	background-color: #cb1f25;
	border-color: #cb1f25;
}
.forrst i, .forrst:before {
	margin: 9px 0 0 12px;
}
.forrst:before {
	content: "\3a";
	top: 0;
	opacity: 1;
}
.forrst:hover:before {
	top: 30px;
	opacity: 0;
}
.forrst:hover {
	background-color: #40833e!important;
}
.utf_social_icon.color .forrst, .utf_social_icon.fixed .forrst:hover {
	background-color: #40833e;
	border-color: #40833e;
}
.digg i, .digg:before {
	margin:9px 0 0 12px;
}
.digg:before {
	content: "\3b";
	top: 0;
	opacity: 1;
}
.digg:hover:before {
	top: 30px;
	opacity: 0;
}
.digg:hover {
	background-color: #205685!important;
}
.utf_social_icon.color .digg, .utf_social_icon.fixed .digg:hover {
	background-color: #205685;
	border-color: #205685;
}
.spotify i, .spotify:before {
	margin:9px 0 0 12px;
}
.spotify:before {
	content: "\3d";
	top: 0;
	opacity: 1;
}
.spotify:hover:before {
	top: 30px;
	opacity: 0;
}
.spotify:hover {
	background-color: #6ca405!important;
}
.utf_social_icon.color .spotify, .utf_social_icon.fixed .spotify:hover {
	background-color: #6ca405;
	border-color: #6ca405;
}
.reddit i, .reddit:before {
	margin: 13px 0 0 12px;
}
.reddit:before {
	content: "\3e";
	top: 0;
	opacity: 1;
}
.reddit:hover:before {
	top: 30px;
	opacity: 0;
}
.reddit:hover {
	background-color: #404040!important;
}
.utf_social_icon.color .reddit, .utf_social_icon.fixed .reddit:hover {
	background-color: #404040;
	border-color: #404040;
}
.appstore i, .appstore:before {
	margin: 13px 0 0 14px;
}
.appstore:before {
	content: "\41";
	top: 0;
	opacity: 1;
}
.appstore:hover:before {
	top: 30px;
	opacity: 0;
}
.appstore:hover {
	background-color: #c0c0c0!important;
}
.utf_social_icon.color .appstore, .utf_social_icon.fixed .appstore:hover {
	background-color: #c0c0c0;
	border-color: #c0c0c0;
}
.blogger i, .blogger:before {
	margin: 13px 0 0 13px;
	font-size: 14px;
}
.blogger:before {
	content: "\42";
	top: 0;
	opacity: 1;
}
.blogger:hover:before {
	top: 30px;
	opacity: 0;
}
.blogger:hover {
	background-color: #ff6501!important;
}
.utf_social_icon.color .blogger, .utf_social_icon.fixed .blogger:hover {
	background-color: #ff6501;
	border-color: #ff6501;
}
.dribbble i, .dribbble:before {
	margin: 13px 0 0 13px;
}
.dribbble:before {
	content: "\44";
	top: 0;
	opacity: 1;
}
.dribbble:hover:before {
	top: 30px;
	opacity: 0;
}
.dribbble:hover {
	background-color: #ef5a92!important;
}
.utf_social_icon.color .dribbble, .utf_social_icon.fixed .dribbble:hover {
	background-color: #ef5a92;
	border-color: #ef5a92;
}
.evernote i, .evernote:before {
	margin: 13px 0 0 15px;
}
.evernote:before {
	content: "\45";
	top: 0;
	opacity: 1;
}
.evernote:hover:before {
	top: 30px;
	opacity: 0;
}
.evernote:hover {
	background-color: #6bb130!important;
}
.utf_social_icon.color .evernote, .utf_social_icon.fixed .evernote:hover {
	background-color: #6bb130;
	border-color: #6bb130;
}
.table-hover > tbody > tr:hover {
    background-color: #f9f9f9;
}
.flickr i, .flickr:before {
	margin: 13px 0 0 13px;
}
.flickr:before {
	content: "\46";
	top: 0;
	opacity: 1;
}
.flickr:hover:before {
	top: 30px;
	opacity: 0;
}
.flickr:hover {
	background-color: #136dd5!important;
}
.utf_social_icon.color .flickr, .utf_social_icon.fixed .flickr:hover {
	background-color: #136dd5;
	border-color: #136dd5;
}
.rss i, .rss:before {
	margin: 13px 0 0 14px;
	font-size: 14px;
}
.rss:before {
	content: "\52";
	top: 0;
	opacity: 1;
}
.rss:hover:before {
	top: 30px;
	opacity: 0;
}
.rss:hover {
	background-color: #fe8f00!important;
}
.utf_social_icon.color .rss, .utf_social_icon.fixed .rss:hover {
	background-color: #fe8f00;
	border-color: #fe8f00;
}
.youtube i, .youtube:before {
	margin: 13px 0 0 14px;
	font-size: 18px;
}
.youtube:before {
	content: "\55";
	top: 0;
	opacity: 1;
}
.youtube:hover:before {
	top: 30px;
	opacity: 0;
}
.youtube:hover {
	background-color: #bf2f29!important;
}
.utf_social_icon.color .youtube, .utf_social_icon.fixed .youtube:hover {
	background-color: #bf2f29;
	border-color: #bf2f29;
}
.vimeo i, .vimeo:before {
	margin: 13px 0 0 13px;
	font-size: 14px;
}
.vimeo:before {
	content: "\56";
	top: 0;
	opacity: 1;
}
.vimeo:hover:before {
	top: 30px;
	opacity: 0;
}
.vimeo:hover {
	background-color: #4c8ab0!important;
}
.utf_social_icon.color .vimeo, .utf_social_icon.fixed .vimeo:hover {
	background-color: #4c8ab0;
	border-color: #4c8ab0;
}
.xing i, .xing:before {
	margin: 13px 0 0 14px;
}
.xing:before {
	content: "\58";
	top: 0;
	opacity: 1;
}
.xing:hover:before {
	top: 30px;
	opacity: 0;
}
.xing:hover {
	background-color: #036568!important;
}
.utf_social_icon.color .xing, .utf_social_icon.fixed .xing:hover {
	background-color: #036568;
	border-color: #036568;
}
.yahoo i, .yahoo:before {
	margin: 13px 0 0 11px;
}
.yahoo:before {
	content: "\59";
	top: 0;
	opacity: 1;
}
.yahoo:hover:before {
	top: 30px;
	opacity: 0;
}
.yahoo:hover {
	background-color: #9251a2!important;
}
.utf_social_icon.color .yahoo, .utf_social_icon.fixed .yahoo:hover {
	background-color: #9251a2;
	border-color: #9251a2;
}
.amazon i, .amazon:before {
	margin: 13px 0 0 12px;
}
.amazon:before {
	content: "\61";
	top: 0;
	opacity: 1;
}
.amazon:hover:before {
	top: 30px;
	opacity: 0;
}
.amazon:hover {
	background-color: #2f3842!important;
}
.utf_social_icon.color .amazon, .utf_social_icon.fixed .amazon:hover {
	background-color: #2f3842;
	border-color: #2f3842;
}
.steam i, .steam:before {
	margin: 13px 0 0 11px;
}
.steam:before {
	content: "\62";
	top: 0;
	opacity: 1;
}
.steam:hover:before {
	top: 30px;
	opacity: 0;
}
.steam:hover {
	background-color: #383838!important;
}
.utf_social_icon.color .steam, .utf_social_icon.fixed .steam:hover {
	background-color: #383838;
	border-color: #383838;
}
.dropbox i, .dropbox:before {
	margin: 13px 0 0 13px;
}
.dropbox:before {
	content: "\64";
	top: 0;
	opacity: 1;
}
.dropbox:hover:before {
	top: 30px;
	opacity: 0;
}
.dropbox:hover {
	background-color: #2c92dd!important;
}
.utf_social_icon.color .dropbox, .utf_social_icon.fixed .dropbox:hover {
	background-color: #2c92dd;
	border-color: #2c92dd;
}
.github i, .github:before {
	margin: 13px 0 0 13px;
}
.github:before {
	content: "\67";
	top: 0;
	opacity: 1;
}
.github:hover:before {
	top: 30px;
	opacity: 0;
}
.github:hover {
	background-color: #5c7381!important;
}
.utf_social_icon.color .github, .utf_social_icon.fixed .github:hover {
	background-color: #5c7381;
	border-color: #5c7381;
}
.lastfm i, .lastfm:before {
	margin: 13px 0 0 11px;
}
.lastfm:before {
	content: "\6c";
	top: 0;
	opacity: 1;
}
.lastfm:hover:before {
	top: 30px;
	opacity: 0;
}
.lastfm:hover {
	background-color: #d21309!important;
}
.utf_social_icon.color .lastfm, .utf_social_icon.fixed .lastfm:hover {
	background-color: #d21309;
	border-color: #d21309;
}
.tumblr i, .tumblr:before {
	margin: 13px 0 0 15px;
}
.tumblr:before {
	content: "\74";
	top: 0;
	opacity: 1;
}
.tumblr:hover:before {
	top: 30px;
	opacity: 0;
}
.tumblr:hover {
	background-color: #3d658d!important;
}
.utf_social_icon.color .tumblr, .utf_social_icon.fixed .tumblr:hover {
	background-color: #3d658d;
	border-color: #3d658d;
}
.wordpress i, .wordpress:before {
	margin: 13px 0 0 14px;
}
.wordpress:before {
	content: "\77";
	top: 0;
	opacity: 1;
}
.wordpress:hover:before {
	top: 30px;
	opacity: 0;
}
.wordpress:hover {
	background-color: #383838!important;
}
.utf_social_icon.color .wordpress, .utf_social_icon.fixed .wordpress:hover {
	background-color: #383838;
	border-color: #383838;
}
.instagram i, .instagram:before {
	margin:9px 0 0 9px;
}
.instagram:before {
	content: "\f31f";
	top: 0;
	opacity: 1;
}
.instagram:hover:before {
	top: 30px;
	opacity: 0;
}
.instagram:hover {
	background-color: #3f6f94!important;
}
.utf_social_icon.color .instagram, .utf_social_icon.fixed .instagram:hover {
	background-color: #3f6f94;
	border-color: #3f6f94;
}
@font-face {
	font-family: 'fontello';
	src: url('../fonts/fontello.eot');
	src: url('../fonts/fontello.eot') format('embedded-opentype'), url('../fonts/fontello.woff') format('woff'), url('../fonts/fontello.ttf') format('truetype'), url('../fonts/fontello.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
.utf_social_icon li i {
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 16px;
	font-size: 16px;
	width: auto;
}
.icon-delicious:before {
	content: '\23';
}
.icon-paypal:before {
	content: '\24';
}
.icon-gplus:before {
	content: '\f0d5';
}
.icon-stumbleupon:before {
	content: '\2f';
}
.icon-fivehundredpx:before {
	content: '\30';
}
.icon-pinterest:before {
	content: '\31';
}
.icon-forrst:before {
	content: '\3a';
}
.icon-digg:before {
	content: '\3b';
}
.icon-spotify:before {
	content: '\3d';
}
.icon-reddit:before {
	content: '\3e';
}
.icon-appstore:before {
	content: '\41';
}
.icon-blogger:before {
	content: '\42';
}
.icon-dribbble:before {
	content: '\44';
}
.icon-evernote:before {
	content: '\45';
}
.icon-flickr:before {
	content: '\46';
}
.icon-linkedin:before {
	content: '\4c';
}
.icon-rss:before {
	content: '\52';
}
.icon-twitter:before {
	content: '\54';
}
.icon-youtube:before {
	content: '\55';
}
.icon-vimeo:before {
	content: '\56';
}
.icon-xing:before {
	content: '\58';
}
.icon-yahoo:before {
	content: '\59';
}
.icon-amazon:before {
	content: '\61';
}
.icon-steam:before {
	content: '\62';
}
.icon-dropbox:before {
	content: '\64';
}
.icon-facebook:before {
	content: '\66';
}
.icon-github:before {
	content: '\67';
}
.icon-lastfm:before {
	content: '\6c';
}
.icon-tumblr:before {
	content: '\74';
}
.icon-wordpress:before {
	content: '\77';
}
.icon-linkedin-rect:before {
	content: '\f31b';
}
.icon-instagram:before {
	content: '\f31f';
}
.utf_listing_section .social-contact {
    margin:10px 0 20px 0;
    padding:15px 0;
    border-top:1px solid #e8e8e8;
	border-bottom:1px solid #e8e8e8;
}
.utf_listing_section .social-contact a.facebook-link {
    background-color: #3b5998;
}
.utf_listing_section .social-contact a.twitter-link {
    background-color: #1da1f2;
}
.utf_listing_section .social-contact a.instagram-link {
    background-color: #e1306c;
}
.utf_listing_section .social-contact a.linkedin-link {
    background-color: #2877b5;
}
.utf_listing_section .social-contact a.youtube-link {
    background-color: #f00125;
}
.utf_listing_section .social-contact a i{
	margin-right:5px;
}
.utf_listing_section .social-contact a {
    color: #fff;
    display: inline-block;
    background-color: #e4e4e4;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 15px;
    border-radius:4px;
	margin-right:3px;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}
.utf_listing_section .social-contact a:hover {
    -webkit-transform: scale(1.04);
    -moz-transform: scale(1.04);
    -ms-transform: scale(1.04);
    -o-transform: scale(1.04);
    transform: scale(1.04);
}
.agent ul.utf_social_icon, #footer ul.utf_social_icon, .listing-share ul.utf_social_icon, #top-bar ul.utf_social_icon {
	margin: 0;
	padding: 0;
}
#footer ul.utf_social_icon{
	padding-left:10px;
}
.listing-share .utf_social_icon li a, .agent .utf_social_icon li a, #footer .utf_social_icon li a, #top-bar .utf_social_icon li a {
	width: 45px;
	height: 45px;
	display: inline-block;
	background-color: transparent;
	position: relative;
	overflow: hidden;
	border-radius: 0;
	transform: scale(1);
	margin-right: 0;
}
.listing-share .utf_social_icon li, .agent .utf_social_icon li, #footer .utf_social_icon li, #top-bar .utf_social_icon li {
	border-radius: 0;
	transform: scale(1);
	-webkit-transform: scale(1);
	-webkit-filter: blur(radius);
}
.listing-share .utf_social_icon li a i, .listing-share .utf_social_icon li a:before, .agent .utf_social_icon li a i, .agent .utf_social_icon li a:before, #footer .utf_social_icon li a i, #footer .utf_social_icon li a:before, #top-bar .utf_social_icon li a i, #top-bar .utf_social_icon li a:before {
	transform: scale(1.1) translateY(calc(-50% - 2px));
	-moz-transform: scale(1.1) translateY(calc(-50% - 1px));
	-ms-transform: scale(1.1) translateY(-50%);
	left: 0;
	right: 0;
	margin: 0;
	text-align: center;
	margin-top: 58%;
}
.listing-share .utf_social_icon li a.twitter i, .listing-share .utf_social_icon li a.twitter:before, .agent .utf_social_icon li a.twitter i, .agent .utf_social_icon li a.twitter:before, #footer .utf_social_icon li a.twitter i, #footer .utf_social_icon li a.twitter:before, #top-bar .utf_social_icon li a.twitter i, #top-bar .utf_social_icon li a.twitter:before {
	transform: scale(1.1) translateY(calc(-50% - 1px));
}
#top-bar .utf_social_icon {
	float: right;
}
.agent .utf_social_icon li, #footer .utf_social_icon li, #top-bar .utf_social_icon li {
	margin: 0;
	padding: 0;
	float: left;
	line-height: 0;
}
#top-bar .utf_social_icon li a:before, #top-bar .utf_social_icon li a i {
	font-size: 14px;
	color: #fff;
}
#top-bar .utf_social_icon li .twitter:before, #top-bar .utf_social_icon li .twitter i:before {
	font-size: 12px;
}
#top-bar .utf_social_icon li a:hover {
	background: #fff!important;
	border-color: transparent;
}
#top-bar .utf_social_icon li a:hover i {
	color: #66676b;
}
.listing-share .utf_social_icon li a, .agent .utf_social_icon li a, #footer .utf_social_icon li a {
	width: 44px;
    height: 44px;
    background-color: #764c29;
    margin-right: 8px;
	border:1px solid rgba(0, 0, 0, 0.05);
    border-radius:50%;
}
.agent .utf_social_icon, #footer .utf_social_icon {
	position: relative;
	left: -13px;
}
.agent .utf_social_icon li, #footer .utf_social_icon li {
	margin: 0;
	padding: 0;
	float: left;
	line-height: 0;
}
.listing-share .utf_social_icon li a i, .agent .utf_social_icon li a i{
	font-size: 15px;
	color: #a0a0a0;
}
#footer .utf_social_icon li a:before, .listing-share .utf_social_icon li a:before, .agent .utf_social_icon li a:before{
	font-size: 15px;
    color: #fff !important;
}
#footer .utf_social_icon li a i {
	font-size: 15px;
    color: #fff;
}
.listing-share .utf_social_icon li .twitter:before, .listing-share .utf_social_icon li .twitter i:before, .listing-share .utf_social_icon li .vimeo:before, .listing-share .utf_social_icon li .vimeo i:before, .agent .utf_social_icon li .twitter:before, .agent .utf_social_icon li .twitter i:before, .agent .utf_social_icon li .vimeo:before, .agent .utf_social_icon li .vimeo i:before, #footer .utf_social_icon li .twitter:before, #footer .utf_social_icon li .twitter i:before, #footer .utf_social_icon li .vimeo:before, #footer .utf_social_icon li .vimeo i:before {
	font-size: 13px;
}
.listing-share .utf_social_icon li a:hover, .agent .utf_social_icon li a:hover, #footer .utf_social_icon li a:hover {
	background-color: #ececec !important;
	border:1px solid rgba(0, 0, 0, 0.05);
}
.listing-share .utf_social_icon li a:hover i, .agent .utf_social_icon li a:hover i, #footer .utf_social_icon li a:hover i {
	color: #764c29;
}

/*==========
  Check Box Style
============*/
.checkboxes {
	list-style: none;
	padding: 0;
}
.amenities_checkbox {
	width:100%;
	display:inline-block;	
}
.amenities_checkbox ul{
	list-style-type:none;
	padding-left:0;
}
.amenities_checkbox ul li{
	width:33%;
	float:left
}
.checkboxes.in-row label {
	margin-top: 9px;
}
.checkboxes.one-in-row label {
	margin-top: 9px;
	width: 100%;
}
.checkboxes li {
	padding: 4px 0;
}
.checkboxes li:last-child {
	margin-bottom: -8px;
}
.checkboxes label {
	display: inline-block;
	cursor: pointer;
	position: relative;
	padding-left: 28px;
	margin-right: 20px;
	margin-bottom: 0;
	line-height: 24px;
	font-size: 16px;
}
.checkboxes label span {
	color: #909090;
}
.checkboxes input[type=checkbox] {
	display: none;
}
.checkboxes label:before {
	content: "";
	display: inline-block;
	width: 18px;
	height: 18px;
	margin-right: 10px;
	position: absolute;
	left: 0;
	top: 3px;
	background-color: #fff;
	border: 2px solid #d0d0d0;
	border-radius: 20px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	transition: all 0.25s;
	font-family: "FontAwesome";
	font-size: 12px;
	color: #fff;
	text-align: center;
	line-height: 15px;
}
.checkboxes input[type=checkbox]:checked+label:before {
	content: "\f00c";
	background-color: #764c29;
	border: 2px solid #764c29;
}

/*==========
  Listing Item Container Style
============*/
.utf_listing_item-container.list-layout {
	margin-bottom: 25px;
	padding:5px;
	background: #ffffff;
	box-shadow:0 3px 5px 0 rgba(0, 0, 0, 0.07);	
	border:1px solid rgba(0, 0, 0, 0.08);
}
.utf_listing_item-container.list-layout:hover {
	transform: translate3d(0px, 0px, 0px);
}
.utf_listing_item-container.list-layout .utf_listing_item {
	display: flex;
	background-color: transparent;
	height: 250px;
}
.utf_listing_item-container.list-layout .utf_listing_item:hover > .utf_listing_item_content h3{
	color:#764c29;
}
.utf_listing_item-container.list-layout .utf_listing_item:before {
	display: none;
}
.utf_listing_item-container.list-layout .utf_listing_item img {
	border-radius: 0;
}
.utf_listing_item-container.list-layout .utf_listing_item_content {
	flex: 5;
	position: relative;
	bottom: 0;
	left: 0;
	padding: 0;
	width: 100%;
	z-index: 50;
	box-sizing: border-box;
}
.utf_listing_item-container.list-layout .utf_listing_item-image {
	min-height: 250px;
	flex: 2;
	height:100%;
	overflow: hidden;
	border-radius: 4px 0 0 4px;
	position: relative;
}
.utf_listing_item-container.list-layout .utf_listing_item-image:before {
	position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
	background:rgba(0, 0, 0, 0.1);
    -webkit-transition: all .6s ease-out 0s;
    -moz-transition: all .6s ease-out 0s;
    transition: all .6s ease-out 0s;
}
.listings-container .fs-listings .list-layout .utf_listing_item-image  {
	flex: 4;	
}
.utf_listing_item-container.list-layout .utf_listing_item-image:before, .utf_listing_item-container.list-layout .utf_listing_item-image:after{
	content: "";
}
.utf_listing_item-container.list-layout .utf_listing_item-image:hover:before {
    background-color: rgba(255, 255, 255, .2);
    right: 50%;
    left: 50%
}
.utf_listing_item-container.list-layout .utf_listing_item-image:hover:after {
    background-color: rgba(255, 255, 255, .2);
    top: 50%;
    bottom: 50%
}
.utf_listing_item-container.list-layout .utf_listing_item-image:after{
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all .6s ease-out 0s;
    -moz-transition: all .6s ease-out 0s;
    transition: all .6s ease-out 0s;
}
.utf_listing_item-container.list-layout span.like-icon {
	bottom:15px;
	background-color:#ffffff;
	color: #e52d27;
	right: 15px;
}
.utf_listing_item-container.list-layout span.like-icon.liked, .utf_listing_item-container.list-layout span.like-icon:hover {
	background-color: #e52d27;
	color: #fff;
}
.utf_listing_item-container.list-layout .utf_listing_item-inner {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 25px;
	padding-right: 20px;
}
.utf_listing_item-inner .utf_star_rating_section .utf_counter_star_rating{
	color: #fff;
}
.utf_listing_item-inner p{
	margin-bottom:0;
	font-size:15px;
}
.utf_listing_item-container.list-layout .utf_listing_item-inner h3 {
	color: #333;
	font-weight: 500;
}
.utf_listing_item-container.list-layout .utf_listing_item-inner span {
	color: #808080;
	display: block;
}
.utf_listing_item-container.list-layout .utf_star_rating_section {
	padding: 0px 0 8px 0;
}
.utf_listing_item-container.list-layout .numerical-rating {
	margin: 10px 0 5px 0;
	display: block;
}
.utf_listing_item-container.list-layout .utf_listing_item-details {
	position: absolute;
	bottom: 25px;
	right: 35px;
	left: auto;
	top: auto;
	padding: 0;
	width: auto;
	z-index: 50;
	color: #888;
	box-sizing: border-box;
	font-weight: 500;
	font-size: 14px;
}
.utf_listing_item-container {
	background-color: #f6f6f6;
	display: block;
	height: 100%;
	width: 100%;
	border-radius: 4px;
	margin-bottom:60px;
	box-shadow:0 5px 15px 0 rgba(0,0,0,0.3);
	transform: translate3d(0, 0, 0);
	transition: transform 0.3s;	
}
section.fullwidth_block .utf_listing_item-container, .fs-content .utf_listing_item-container {
	box-shadow:0 3px 5px 0 rgba(0, 0, 0, 0.07);
	background: #ffffff;
}
.fs-content a.utf_listing_item-container{
	box-shadow:0 5px 15px 0 rgba(0,0,0,0.3);
}
.fs-content a.utf_listing_item-container:hover img {
    transform: scale(1.10);
    transition: transform 0.35s ease-out;
    transition: all 0.55s;
}
section.fullwidth_block .utf_listing_item-container.list-layout{
	box-shadow: none;
}
.utf_listing_item-container:hover {
	transform: translate3d(0, -10px, 0);	
}
.listing_grid_item .utf_listing_item:hover img{
    transform: scale(1.10);
	transition: transform 0.35s ease-out;
	transition: all 0.55s;
}
.listing_tow_column .utf_listing_item{
	height:400px;
}
.utf_listing_item {
	background: #ccc;
	border-radius:4px;
	height: 100%;
	display: block;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50%;
	height: 300px;
	z-index: 100;		
	cursor: pointer;
}
.utf_listing_item-container.list-layout .utf_listing_item-image:after{
	content: "";
}
.utf_listing_item-container.list-layout .utf_listing_item-image:hover:before {
    background-color: rgba(255, 255, 255, .2);
    right: 50%;
    left: 50%
}
.utf_listing_item-container.list-layout .utf_listing_item-image:hover:after {
    background-color: rgba(255, 255, 255, .2);
    top: 50%;
    bottom: 50%
}
.utf_listing_item-container.list-layout .utf_listing_item-image:after{
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all .6s ease-out 0s;
    -moz-transition: all .6s ease-out 0s;
    transition: all .6s ease-out 0s;
}
.utf_listing_item img {
	object-fit: cover;
	height: 100%;
	width: 100%;	
	border-radius: 4px 4px 0 0;
	transition: transform 0.35s ease-out;
	transition: all 0.55s;
}
.slick-list .utf_listing_item:hover img{
    transform: scale(1.10);
	transition: transform 0.35s ease-out;
	transition: all 0.55s;
}
.utf_listing_item:before {
	content: "";
	top: 0;
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 9;
	background: linear-gradient(to top, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0.60) 40%, rgba(22,22,23,0) 80%, rgba(0,0,0,0) 100%);
	background-color: rgba(0,0,0,0.2);
	border-radius: 4px 4px 0 0;
	opacity: 1;
}
.utf_listing_item_content {
	position: absolute;
    bottom: 45px;
    left: 0;
    padding: 0 20px;
    width: 100%;
    z-index: 50;
    box-sizing: border-box;
}
.utf_listing_item_content p{
	color:rgba(255,255,255,0.9);
	font-size:16px;
	font-weight:400;
	line-height:26px;
	margin-bottom:0;
}
.list-layout .utf_listing_item_content p{
	color:#848484;
	font-size:15px;
	line-height:25px;
	margin-bottom:0;
}
.utf_listing_item_content h3 {
	color: #fff;
    font-size: 20px;
    bottom: 2px;
    position: relative;
    font-weight: 400;
    margin: 0;
    line-height: 30px;
}
.utf_listing_prige_block .utp_approve_item{
	display:inline-block;
}
.utf_listing_prige_block .utp_approve_item i.utf_approve_listing {
	display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: #10aa08;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    position: relative;
    top: -2px;
    margin-left: 4px;
}
.utp_approve_item i.utf_approve_listing::before {
    content: "\f058";
    font-family: fontawesome;
    position: relative;
    z-index: 11;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}
.utp_approve_item i.utf_approve_listing::after {
	position: relative;
	z-index: 9;
	width: 20px;
	height: 20px;
	border-radius: 20px;
	display: block;
	content: "";
	position: absolute;
	top: 2px;
	left: -1px;
	background-color: #fff;
}
.utf_listing_prige_block{
	margin-bottom:10px;
	display:block;
}
.utf_listing_prige_block .utf_meta_listing_price{
	background:rgba(255, 255, 255, 0.95);
	color: #525252;
	font-size: 13px;
	font-weight: 600;
	padding: 0px 8px;
	margin-right:5px;
	border-radius:4px;
}
.utf_listing_prige_block .utf_meta_listing_price i {
    font-size: 14px;
    color: #764c29;
    padding-right: 0px;
	width: 16px;
	vertical-align:initial;
}
.utf_half_list{
	position: absolute;
	left: 15px;
	bottom:8px;
}
.utf_half_list .utp_approve_item i.utf_approve_listing{
	top:3px;
}
.utf_half_list .utf_meta_listing_price{
	padding:7px 10px;
}
.utf_listing_item span.utf_open_now{
	background-color: #54ba1d;
	float: left;
	position: absolute;
	transform: rotate(-45deg);
	left: -45px;
	top: 15px;
	text-align: center;
	width: 150px;
	font-size: 12px;
	margin: 0;
	z-index: 999;
	color: #fff;
	font-weight: 600;
	line-height: 28px;
	border-radius: 0 20px 20px 0;
}
.utf_listing_item span.utf_closed{
	background: #e91721;
	float: left;
	position: absolute;
	transform: rotate(-45deg);
	left: -45px;
	top: 15px;
	text-align: center;
	width: 150px;
	font-size: 12px;
	margin: 0;
	z-index: 999;
	color: #fff;
	font-weight: 600;
	line-height: 28px;
	border-radius: 0 20px 20px 0;
}
.utf_listing_prige_block span.utf_meta_listing_price{
	display:inline-block;
	padding: 0px 8px;
}
.utf_listing_item_content span {
	font-size: 14px;
	margin:2px 0;
    font-weight: 400;
    display:block;
    color: rgba(255,255,255,0.85);
}
.utf_listing_item_content span.star{
	font-size: 14px;	
}
.utf_listing_item_content span i{
	font-size:15px;
	color:#764c29;
	padding-right:3px;
	width: 18px;
	font-weight:600;
	display: inline-block;
	text-align: center;
	vertical-align:sub;
}
.utf_listing_item span.tag i{
	font-size:14px;
	margin-right:2px;
}
.utf_listing_item span.tag {
	text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 600;
    background:#764c29;
    border-radius: 4px;
    padding:1px 6px;
    line-height: 20px;
    color: #fff;
	border:2px solid #764c29;
    margin-bottom: 9px;
    position: absolute;
    z-index: 9999;
    top: 20px;
    right: 20px;
}
.utf_listing_item span.tag:hover{
	background:#fff;
	color:#764c29;
	border:2px solid #fff;
	transition: all 0.3s;
}
.utf_listing_item span.featured_tag {
	text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 500;
    background:#2cafe3;
    border-radius: 4px;
    padding: 1px 10px;
    line-height: 20px;
    color: #fff;
	border:2px solid #2cafe3;
    margin-bottom: 9px;
    position: absolute;
    z-index: 9999;
    top: 58px;
    right: 20px;
}
#titlebar.utf_listing_titlebar span.featured_tag {	
	margin: 0;
    margin-left: 0px;
	padding: 1px 15px;
	line-height: 20px;
	letter-spacing: 1px;
	border-radius: 50px;
	text-transform: uppercase;
	background-color:#2cafe3;
	color: #fff;
	border:1px solid #2cafe3;
	font-size: 11px;
	font-weight: 600;
	position: relative;
	top: 0;
	margin-left: 8px;
}
.utf_listing_item-container.list-layout span.tag{
	text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 600;
    background:#764c29;
    border-radius: 6px;
    padding: 4px 16px;
    line-height: 20px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 9px;
}
.utf_listing_item-container.list-layout span.tag {
	text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 500;
    background:#764c29 !important;
    border-radius: 4px;
    padding: 0px 5px;
    line-height: 20px;
    color: #fff;
	font-weight:600;
	border:2px solid #764c29;    
    margin-bottom: 9px;
    position: absolute;
    z-index: 9999;
    top: 20px;
    right: 20px;
}
.utf_listing_item-container.list-layout span.tag:hover{
	background:#fff !important;
	border-color:#fff !important;
	color:#764c29;
}
.utf_listing_item-details {
	position: absolute;
	top: 24px;
	left: 0;
	padding: 0 32px;
	width: 100%;
	z-index: 50;
	color: #fff;
	box-sizing: border-box;
	font-weight: 500;
	font-size: 15px;
}
.utf_listing_item-details ul {
	margin: 0;
	list-style: none;
	padding: 0;
}
.utf_listing_item-details ul li {
	display: inline-block;
	margin-right: 6px;
	line-height: 20px;
}
.utf_listing_item-details ul li i {
	display: inline-block;
	margin: 0 3px 0 0;
	position: relative;
	top: 0px;
	font-size: 14px;
}
.utf_layout_nav a:hover {
    color: #ffffff !important;
    border-color: #764c29;
}
.utf_listing_item-container.compact .utf_listing_item {
	border-radius: 4px;
}
.utf_listing_item-container.compact .utf_star_rating_section {
	padding: 15px;
	z-index: 99999;
	position: absolute;
	background: #fff;
	width: auto;
	bottom: -25px;
	left: 20px;
	right: 20px;
	border-radius: 4px;
	box-shadow:0 1px 8px 0 rgba(0, 0, 0, 0.1);
}
.utf_listing_item-container.compact .utf_counter_star_rating {
	color: rgba(255,255,255,1);
}
.utf_listing_item-container.compact .utf_star_rating_section .star.empty:before, .utf_listing_item-container.compact .utf_star_rating_section .star.half:before {
	color: rgba(0, 0, 0, 0.3);
}
.utf_listing_item-container.compact .utf_listing_item_content span.tag {
	background-color: #66676b;
}
.numerical-rating {
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 8px;
	text-align: center;
	line-height: 26px;
	height: 26px;
	width: 44px;
	display: inline-block;
	font-family: "Open Sans";
	position: relative;
	border-radius: 50px;
	letter-spacing: -0.5px;
	background-color: rgba(255,255,255,0.2);
	margin: 15px 32px;
}
.compact .numerical-rating {
	margin: 0 0 8px 0;
}
.numerical-rating:before {
	content: attr(data-rating);
}
.numerical-rating.high {
	background-color: #64bc36;
}
.numerical-rating.mid {
	background-color: #c0c52a;
}
.numerical-rating.low {
	background-color: #ee3535;
}
.infoBox .numerical-rating .utf_counter_star_rating, .numerical-rating .utf_counter_star_rating {
	position: absolute;
	top: 0;
	left: 14px;
	width: auto;
	display: inline-block;
	font-weight: 400;
	font-size: 15px;
	width: 150px;
	color: #909090;
}
.infoBox .numerical-rating .utf_counter_star_rating {
	left: 12px;
}
.infoBox .numerical-rating {
	margin: 0;
	font-family: "Open Sans";
}
.utf_star_rating_section {
	display: block;
	font-size: 15px;
}
.list-layout .utf_listing_item-inner .utf_star_rating_section {
    padding:0; 
	z-index: 99999;
    position: relative;
    background:transparent;
    width: auto;
    bottom:0;
    left:0;
    right:0;
    border-radius:0;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1);	
	margin-bottom:7px;
}
.utf_listing_item-container .utf_star_rating_section {
    padding: 15px;
    z-index: 99999;
    position: absolute;
    background: #fff;
    width: auto;
    bottom: -25px;
    left: 20px;
    right: 20px;
    border-radius: 4px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
}
.utf_star_rating_section .star {
	display: inline-block;
	margin: 0;
	padding: 0;
	float: left;
	margin-right: 4px;
	position: relative;
}
.utf_list_box_listing_item_content .utf_counter_star_rating {
	color:#fff;
	font-weight:600;
}
.utf_counter_star_rating {
	font-family: "Open Sans";
	color: #fff;
	padding:1px 5px;
	display: inline-block;
	font-size: 11px;
	margin-left:6px;
	background:#54ba1d;
	font-weight: 600;
	line-height: 18px;
	border-radius: 4px;
	vertical-align:middle;
}
.utf_listing_titlebar_title .utf_star_rating_section .utf_counter_star_rating {
	top:-2px;	
}
.utf_dashboard_list_box .utf_counter_star_rating{
	line-height:18px;
}
.utf_star_rating_section .utf_counter_star_rating {
	top: 0px;
	position: relative;
}
.infoBox .utf_star_rating_section .utf_counter_star_rating {
	font-size: 11px;
	top: -4px;
	color: #fff;
	font-weight: 700;
}
.utf_star_rating_section .star.half:after, .utf_star_rating_section .star:before {
	font-family: "FontAwesome";
	content: "\f005";
	display: block;
	color: #ffc600;
}
.utf_star_rating_section .star.empty:before {
	color: #999;
}
.rating:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
.utf_star_rating_section .star.half:before {
	color: #999;
}
.utf_star_rating_section .star.half:after {
	color: #ffc600;
	position: absolute;
	top: 0;
	width: 50%;
	display: block;
	height: 100%;
	overflow: hidden;
}
.utf_view_count i{
	font-size:15px;
	color:rgba(0, 0, 0, 0.55);
	margin-right:3px;
	text-align:center;	
}
.utf_view_count{
	position: absolute;
	right: 65px;
	font-weight: 600;
	color:#626262;
	font-size: 14px;
	bottom:15px;
}
span.like-icon {
	color: #764c29;
	font-size: 18px;
	position: absolute;
	z-index: 101;
	right: 15px;
	bottom: 11px;
	cursor: normal;
	background-color:#f0f0f0;
	display: block;
	height: 36px;
	width: 36px;
	line-height:37px;
	border-radius:30px;
	transition: all 0.4s;
}
.like-icon:before, .like-icon:after {
	transition: opacity 0.3s, transform 0.4s cubic-bezier(.0, -.41, .19, 2.5);
	font-family: "FontAwesome";
	position: absolute;
	right: 0;
	left: 0;
	text-align: center;
	top: 0px;
}
.like-icon:before {
	content: "\f08a";
	transform: scale(0.95);
}
.like-icon:after {
	content: "\f004";
	opacity: 0;
	transform: scale(0.3);
}
.like-icon.liked:after {
	opacity: 1;
	transform: scale(0.95);
}
.like-icon:hover, .like-icon.liked {
	background-color:#e52d27;
	color:#fff;
}
.like-icon.liked:before {
	opacity: 0;
}
.utf_listing_item {
	overflow: hidden;
}
.listing-badge {
	background-color: #333;
	float: left;
	position: absolute;
	transform: rotate(45deg);
	right: -64px;
	top: 22px;
	text-align: center;
	width: 200px;
	font-size: 12.5px;
	margin: 0;
	z-index: 999;
	color: #fff;
	font-weight: 500;
	line-height: 28px;
}
.listing-badge.now-open {
	background-color: #54ba1d;
}
.listing-badge.now-closed {
	background-color: #e91721;
}

/*==========
  Subscribe Newsletter Style
============*/
.utf_cta_area2_block {
    background-color: transparent;
    padding-bottom: 0;
    margin-bottom: -80px;
    z-index: 2;
}
.utf_cta_area_item {
    padding-top:20px;
    padding-bottom: 80px;
    position: relative;
}
.utf_cta_area2_block {
    background-color: transparent;
    padding-bottom: 0;
    margin-bottom: -95px;
    z-index: 2;
}
.utf_cta_area2_block .utf_subscribe_block {
    background-color: #282b42;
    border-radius: 8px;
    padding: 35px 35px 40px 35px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    -webkit-box-shadow: 0 10px 40px rgba(82,85,90,0.4);
    -moz-box-shadow: 0 10px 40px rgba(82,85,90,0.4);
    box-shadow: 0 10px 40px rgba(82,85,90,0.4);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.utf_cta_area2_block .utf_subscribe_block .section-heading .utf_sec_title_item2 {
    margin-bottom: 12px;
}
.section-heading .utf_sec_title_item {
	font-family:'Nunito', sans-serif;
    font-size: 36px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
    padding-top:5px;
}
.utf_cta_area_item .section-heading .utf_sec_meta {
    width: 100%;
}
.section-heading .utf_sec_meta {
    font-size: 20px;
    color: #808996;
    font-weight: 500;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    line-height: 30px;
}
.section-heading .utf_sec_title_item::before {
    position: absolute;
    content: "\f006";
    font-family:"FontAwesome";
    top:-30px;
    left: 0;
    color: #764c29;
    font-size: 20px;
}
.utf_cta_area2_block .utf_subscribe_block:before {
    content: "\f003";
}
.utf_cta_area2_block .utf_subscribe_block:before{
    position: absolute;
    top: 45px;
    left: 0;
    color: rgba(128,137,150,0.2);
    font-family: "FontAwesome";
    font-size: 140px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    z-index: -1;
}
.utf_cta_area2_block .utf_subscribe_block:after {
    content: "\f040";
    left: auto;
    top: auto;
    right: 0;
    bottom:15px;
	position: absolute;
    color: rgba(128,137,150,0.2);
    font-family: "FontAwesome";
    font-size: 160px;
	z-index:-1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}
.utf_cta_area2_block .utf_subscribe_block .contact-form-action {
    margin-top: 38px;
}
.utf_cta_area2_block .utf_subscribe_block .contact-form-action form {
    position: relative;
}
.utf_cta_area2_block .utf_subscribe_block .contact-form-action form span {
    position: absolute;
    top: 15px;
    left: 12px;
    color: #808996;
    font-size: 18px;
}
.utf_cta_area2_block .utf_subscribe_block .contact-form-action form .form-control {
    height: auto;
    padding: 11px 12px 11px 40px;
    color: #707070;
    font-weight: 400;
	border-radius: 4px;
	border: 0;
    line-height: inherit;
}
.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.utf_cta_area2_block .utf_subscribe_block .contact-form-action form .utf_theme_btn {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    border-radius: 0 4px 4px 0;
}
.utf_theme_btn, .author-area .top-author-slider .top-author-item .topauthor__btn .author__btn, .my-button-css {
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    padding: 11px 25px;
    text-transform: capitalize;
    background-color: #764c29;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    -webkit-box-shadow: 0 0 40px rgba(82,85,90,0.1);
    -moz-box-shadow: 0 0 40px rgba(82,85,90,0.1);
    box-shadow: 0 0 40px rgba(82,85,90,0.1);
    position: relative;
    display: inline-block;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.utf_cta_area2_block .utf_subscribe_block .contact-form-action form span {
	position: absolute;
    top: 10px;
    left: 12px;
    color: #808996;
    font-size: 16px;
}
.la-envelope-o:before {
	font-family: "FontAwesome";
    content: "\f003";
}
@media only screen and (min-width: 768px) and (max-width: 991px){
.utf_cta_area2_block .utf_subscribe_block .contact-form-action form .utf_theme_btn {
    position: relative;
    right: 0;
    top: 0;
    border: 0;
    border-radius: 4px;
    display: block;
    margin: 0 auto;
}
}
@media (max-width: 549px){
.utf_cta_area_item {
    text-align: center;
}
.utf_cta_area2_block .utf_subscribe_block {
    padding: 35px 20px 40px 20px;
}	
.utf_cta_area2_block .utf_subscribe_block .contact-form-action form .form-control{
	margin-bottom:5px;
	font-size:15px;
}
.utf_cta_area2_block .utf_subscribe_block .contact-form-action form .utf_theme_btn {
    position: inherit;
    border-radius:4px;
    margin-top: 10px;
}
.section-heading .utf_sec_title_item{
	font-size:34px;
	line-height:42px;
	font-weight:600;
}
.section-heading .utf_sec_meta{
	font-size:16px;
	line-height:28px
}
}

/*==========
  Categories Box
============*/
.container_categories_box {
	width: calc(100% + 20px);
	left: -20px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
}
.utf_category_small_box_part {
	float: left;
	background-color: #f2f2f2;
	border-radius: 4px;
	margin: 20px 0 0 20px;
	padding:35px 20px;
	text-align: center;
	color: #fff;
	transition: 0.4s;
	transform: scale(1);
	vertical-align: top;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.utf_category_small_box_part:hover {
	background-color: #66676b;
	color: #fff;
	-webkit-transform: scale(1.06);
	-moz-transform: scale(1.1);
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.utf_category_small_box_part:nth-child(1){
	background:#1966b6;
	border:0px solid rgba(255, 255, 255, 0.5);
}
.utf_category_small_box_part:nth-child(2){
	background:#cc4300;
	border:0px solid rgba(255, 255, 255, 0.5);
}
.utf_category_small_box_part:nth-child(3){
	background:#04724d;
	border:0px solid rgba(255, 255, 255, 0.5);
}
.utf_category_small_box_part:nth-child(4){
	background:#d80a48;
	border:0px solid rgba(255, 255, 255, 0.5);
}
.utf_category_small_box_part:nth-child(5){
	background:#762acb;
	border:0px solid rgba(255, 255, 255, 0.5);
}
.utf_category_small_box_part:nth-child(6){
	background:#097784;
	border:0px solid rgba(255, 255, 255, 0.5);
}
.utf_category_small_box_part:nth-child(7){
	background:#2531d5;
	border:0px solid rgba(255, 255, 255, 0.5);
}
.utf_category_small_box_part:nth-child(8){
	background:#931674;
	border:0px solid rgba(255, 255, 255, 0.5);
}
.utf_category_small_box_part:nth-child(9){
	background:#d52f31;
	border:0px solid rgba(255, 255, 255, 0.5);
}
.utf_category_small_box_part:nth-child(10){
	background:#134b7e;
	border:0px solid rgba(255, 255, 255, 0.5);
}
.utf_category_small_box_part:hover h4 {
	font-size: 17px;
}
.utf_category_small_box_part h4 {
	font-size: 16px;
	color: #fff;
	transition: 0.4s;
	line-height: 22px;
	margin-top: 15px;
}
.utf_category_small_box_part span {
    position: absolute;
    right: 15px;
    top: 15px;
    background: rgba(255, 255, 255, 0.15);
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
}
.utf_category_small_box_part i {
	font-size: 36px;
	line-height: 70px;
	display: block;
	background: rgba(255, 255, 255, 0.12);
	border:3px solid rgba(255, 255, 255, 0.05);
	width: 74px;
	height: 74px;
	border-radius:50%;
	margin: 10px auto 0 auto;
	text-align: center;
}
.utf_category_small_box_part:hover i{
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-backface-visibility: visible;
	backface-visibility: visible;
	-webkit-animation-name: flip;
	animation-name: flip;
}
@-webkit-keyframes flip {
	0% {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
		-webkit-animation-timing-function: ease-in;
				animation-timing-function: ease-in;
	}
	50% {
		-webkit-transform: rotateY(180deg);
		transform: rotateY(180deg);
		-webkit-animation-timing-function: ease-in;
				animation-timing-function: ease-in;
	}
	100% {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
		-webkit-animation-timing-function: ease-in;
				animation-timing-function: ease-in;
	}
}
@keyframes flip {
	0% {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
		-webkit-animation-timing-function: ease-in;
				animation-timing-function: ease-in;
	}
	50% {
		-webkit-transform: rotateY(180deg);
		transform: rotateY(180deg);
		-webkit-animation-timing-function: ease-in;
				animation-timing-function: ease-in;
	}
	100% {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
		-webkit-animation-timing-function: ease-in;
				animation-timing-function: ease-in;
	}
}
.utf_category_small_box_part:hover h4 {
	color: #fff;
}
@media (max-width: 768px) {
.utf_category_small_box_part {
	width:calc(100% * (1/2) - 20px);
}
}
@media (min-width: 768px) {
.utf_category_small_box_part {
	width:calc(100% * (1/3) - 20px);
}
}
@media (max-width: 991px) {
.utf_dashboard_content .col-lg-2 {
    width: calc(50% * (1/1) - 0px);
    float: left;
}
}
@media only screen and (min-width: 480px) and (max-width: 991px){
.utf_dashboard_content .col-lg-3 {
    width: calc(50% * (1/1) - 0px);
    float: left;
}
}
@media (min-width: 992px) {
.utf_category_small_box_part {
	width:calc(100% * (1/3) - 20px);
}
}
@media (min-width: 1240px) {
.utf_category_small_box_part {
	width:calc(100% * (1/5) - 20px);
}
}

/*==========
  Titlebar Style
============*/
#titlebar {
	background-color: #f8f8f8;
	position: relative;
	padding: 75px 0 20px;
	margin-bottom: 80px;
}
#titlebar.gradient {
	position: relative;
    padding-top: 70px;
    padding-bottom: 70px;
    background-image: url(../images/page-title.jpg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}
#titlebar.gradient::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(48, 48, 48, 1);
    opacity: .88;
}
.gradient nav#breadcrumbs ul li{
	color:#d9d9d9;
}
.gradient nav#breadcrumbs ul li a{
	color:#d9d9d9;
}
.gradient nav#breadcrumbs ul li a:hover{
	color:#764c29;
}
#titlebar span a, #titlebar span {
	font-size: 20px;
	color: #ccc;
	margin-bottom: 3px;
	margin-top: 6px;
	display: inline-block;
}
#titlebar #breadcrumbs span {
	font-size: 13px
}
#titlebar h1, #titlebar h2 {
	color:#fff;
	font-size: 32px;
	line-height: 40px;
	font-weight:600;
	margin: 4px 0 10px 0;
}
#titlebar h2 {
	text-align:center;
}

/*==========
  Breadcrumbs Style
============*/
#breadcrumbs {
	position: relative;
	left:0;
	display:block;
	font-size: 15px;
	font-weight: 400;
	color: #888;
	right:0;
	text-align:center;
}
#breadcrumbs ul {
	margin: 0;
	padding-left:0;
}
#breadcrumbs ul li a {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	color: #707070;
}
#breadcrumbs ul li a:hover {
	color: #764c29
}
#breadcrumbs ul li {
	display: inline-block;
	list-style: none;
	color: #707070;
	margin: 0 0 0 10px;
}
#breadcrumbs ul li:first-child{
	margin-left:0;
}
#breadcrumbs ul li:first-child:before {
	display: none;
}
#breadcrumbs ul li:before {
	content: "\f101";
	font-family: "FontAwesome";
	color: #ccc;
	font-size: 14px;
	margin-right: 10px;
}
#titlebar.photo-bg #breadcrumbs ul li, #titlebar.photo-bg #breadcrumbs ul li a, #titlebar.photo-bg #breadcrumbs ul li:before {
	color: #fff;
}
.parallax #titlebar {
	background-color: transparent;
	position: relative;
	z-index: 999;
	padding: 90px 0;
}
.parallax #titlebar h1, .parallax #titlebar h2, .parallax #titlebar #breadcrumbs ul li, .parallax #titlebar #breadcrumbs ul li a, .parallax #titlebar #breadcrumbs ul li::before {
	color: #fff
}
.parallax #titlebar span a, .parallax #titlebar span {
	color: rgba(255,255,255,0.7);
}

/*==========
  Page Not Found Style
============*/
.notfound_mr_bottom{
	margin-bottom:0 !important;
}
.not_found_block {
	position: relative;
    padding-top: 20px;
    padding-bottom: 10px;
    background-image: url(../images/error-page-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
}
#not-found {
	margin: 80px 0 80px 0;
}
#not-found.center {
	text-align: center;
}
#not-found h2 {
	text-align: center;
	font-size: 150px;
	line-height:110px;
	font-weight: 800;
	margin-bottom:0;
	letter-spacing: -8px;
}
#not-found p {
	text-align: center;
	font-size: 30px;
	line-height: 48px;
	display: inline-block;
	margin-top: 10px;
	color: #999;
}
#not-found i {
	color: #e0e0e0;
	font-size: 190px;
	top: 16px;
	position: relative;
}
.utf_error_description_part {
    margin: 5px 0 0;
}
.utf_ferror_description span {
    font-size: 16px;
	color:#8e8e8e;
    line-height: 1.2;
	display: block;
    text-align: center;
}
.utf_ferror_description strong {
    font-size: 22px;
	color: #6d7a83;
    font-weight: 500;
    line-height: 1.2;
	display:block;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
#not-found h2 {
	font-size: 110px;
	line-height: 110px;
}
#not-found p {
	font-size: 20px;
	line-height: 38px;
}
#not-found i {
	font-size: 120px;
	top: 16px;
}
.amenities_checkbox ul li {
    width: 100%;
    float: left;
}
.listing_tow_column .utf_listing_item{
	height:300px;
}
}

/*==========
  Dashboard Content Style
============*/
#dashboard {
	background-color: #fbfbfb;
	min-height: 100vh;
	display: flex;
	flex-wrap: wrap;
	padding-top: 80px;
}
.utf_dashboard_content, .utf_dashboard_navigation {
	flex: 1;
}
.utf_dashboard_content {
	padding:25px;
	padding-bottom: 0;
	position: relative;
	z-index: 10;
	height: 100%;
	margin-left: 260px;
}
.utf_dashboard_navigation, .dashboard #logo {
	min-width: 260px;
	max-width: 260px;
}
.utf_dashboard_navigation {
	position: fixed;
	min-height: 100vh;
	overflow: hidden;
}
.utf_dashboard_navigation_inner_block {
	height: 100%;
	width: 277px;
	overflow-y: scroll;
	position: relative;
	display: block;
	padding: 10px 0 25px 0;
}
.utf_dashboard_navigation_inner_block {
	height:calc(100vh - 71px);
	max-height: 900px;
	width: calc(100% + 17px);
	overflow-y: scroll;
	position: relative;
	display: block;
	padding:0;
}

@media (max-width: 991px) {
.utf_dashboard_navigation_inner_block {
	max-height: 100%;
	width: calc(100% + 0px);	
	overflow-y:hidden;
	height:auto;
}
}
header.fullwidth_block.dashboard .container {
	padding-left: 270px;
}
.dashboard #logo {
	display: block;
	background-color:#2a2a2a;
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	margin: 0;
	box-shadow:none;
}
.dashboard #logo a {
	margin-left: 30px;
	display: inline-block;
	height: 100%;
}
.dashboard #logo a img {
	top: 50%;
	position: relative;
	transform: translate3d(0, -50%, 0);
}
#dashboard .footer_copyright_part {
	padding: 40px 0;
	margin:20px 0 0 0;
	display: block;
	text-align: center;	
}
.dashboard #logo a {
	display: none;
}
.dashboard #logo a.dashboard-logo {
	display: inline-block;
}
.utf_dashboard_navigation {
	background-color: #2a2a2a;
	display: block;
	box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.12);
	z-index: 11;
}
a.utf_dashboard_nav_responsive {
	display: none;
}
.utf_dashboard_navigation ul {
	list-style: none;
	padding: 0;
	margin:0;
}
.utf_dashboard_navigation ul:first-child{
	margin-top:20px;
	border-top:1px solid rgba(255, 255, 255, 0.1);
}
.utf_dashboard_navigation ul li {
	color: #eee;
	display: block;
	border-left: 5px solid transparent;
	transition: 0.3s;
	border-bottom:1px solid rgba(255, 255, 255, 0.1);
	line-height: 24px;
	font-size: 15px;
}
.utf_dashboard_navigation ul li a {
	color: #eee;
	display: block;
	padding: 12px 15px;
	transition: 0.3s;
	cursor: pointer;
	font-weight:400;
}
.utf_dashboard_navigation ul li a i {
    margin-right: 12px;
    width: 20px;
    background: #fff;
    height: 30px;
    padding: 8px;
    line-height: 31px;
    border-radius:50%;
    color: #764c29;
}
.utf_dashboard_navigation ul li a:hover {
	color: #fff;
}
.utf_dashboard_navigation ul li span.nav-tag {
	background-color: rgba(255,255,255,0.2);
	font-weight: 500;
	display: inline-block;
	font-size: 11px;
	line-height: 20px;
	color: #fff;
	padding: 0 8px;
	box-sizing: border-box;
	text-align: center;
	min-width: 20px;
	height: 20px;
	text-align: center;
	border-radius: 4px;
	margin-left: 5px;
}
.utf_dashboard_navigation ul li span.nav-tag.messages {
	top: -2px;
	position: relative;
}
.utf_dashboard_navigation ul li span.nav-tag.messages, .utf_dashboard_navigation ul li span.nav-tag.green {
	background-color: #1ec38b;
}
.utf_dashboard_navigation ul li span.nav-tag.yellow {
	background-color: #ffbb28;
}
.utf_dashboard_navigation ul li span.nav-tag.red {
	background-color: #764c29;
}
.utf_dashboard_navigation ul li ul {
	padding: 0;
	margin: 0;
	visibility: hidden;
	max-height: 0;
	opacity: 0;
	line-height: 0;
	transition: 0.2s;
}
.utf_dashboard_navigation ul li.active ul {
	visibility: visible;
	padding:10px 0 10px 0px;
	line-height: 24px;
	opacity: 1;
	max-height: 300px;
	background:#2a2a2a;
}
.utf_dashboard_navigation ul li ul li {
	color: #eee;
	background-color: transparent;
	border: none;
	padding-left: 63px;
	position: relative;
	margin: 0;
}
.utf_dashboard_navigation ul li ul li a {
	padding: 10px 0;
}
.utf_dashboard_navigation ul li ul li:hover {
	color: #fff;
	border: none;
	background-color: transparent;
}
.utf_dashboard_navigation ul li a:after {
	font-family: "FontAwesome";
	content: "\f0d7";
	right: 27px;
	font-size: 16px;
	position: absolute;
	transition: 0.2s;
	color: rgba(255,255,255,0.8);
	padding-top:5px;
}
.utf_dashboard_navigation ul li.active a:after {
	transform: rotate(180deg);
	margin-top:3px;
}
.utf_dashboard_navigation ul li ul:before, .utf_dashboard_navigation ul li a:only-child:after {
	content: '';
	display: none;
}
.utf_dashboard_navigation ul li ul li:hover a {
	padding-left: 0px;
}
.utf_dashboard_navigation ul li.active, .utf_dashboard_navigation ul li:hover {
	color: #fff;
	border-color:#fff;
	border-bottom:1px solid rgba(255, 255, 255, 0.1);
	background-color: #764c29;
}
.utf_dashboard_navigation ul li ul li.active, .utf_dashboard_navigation ul li ul li:hover {
	border-bottom:0;
}
.dashboard_gradient h2, .dashboard_gradient span, .dashboard_gradient nav ul li, .dashboard_gradient nav ul li a{
	color:#323232
}
.dashboard_gradient nav ul li a:hover{
	color:#764c29 !important;
}
#dashboard #titlebar {
	padding: 15px 20px;
	border-radius:4px;
	margin: 0 0 30px 0;
	display: block;
	background-color:#f9f9f9;
	box-shadow:0 2px 8px 0 rgba(0, 0, 0, 0.08);	
	border:1px solid rgba(0, 0, 0, 0.08);	
}
#dashboard #titlebar h2 {
	font-size: 26px;
	font-weight:600;
	line-height: 32px;
	color:#323232;
}
.utf_dashboard_stat {
	display: inline-block;
	padding: 0;
	height: 180px;
	background-color: #444;
	color: #fff;
	border-radius:4px;
	width: 100%;
	position: relative;
	margin-bottom: 10px;
	overflow: hidden;
	transition: 0.3s;
	cursor: default;
}
.utf_dashboard_stat:hover {
	transform: translateY(-4px);
}
.utf_dashboard_stat_content {
	position: absolute;
	left: 0;
	right:0;
	text-align:center;
	bottom:0;
	width: 100%;
	transform: translateY(-40%);
}
.utf_dashboard_stat_content h4 {
	font-size: 30px;
	font-weight: 600;
	padding: 0;
	margin: 0;
	color: #fff;
	letter-spacing:0px;
}
.utf_dashboard_stat_content span {
	font-size: 14px;
	margin-top: 5px;
	line-height: 22px;
	font-weight: 400;
	opacity: 0.95;
	display: inline-block;
}
.utf_dashboard_stat_icon {
	position: absolute;
	right:0;
	left:0;
	text-align:center;
	top: 25%;
	transform: translateY(-40%);
	font-size: 80px;
	opacity: 7;
	margin-bottom:10px;
}
.utf_dashboard_stat_icon i, .utf_dashboard_ic_stat i{
    font-size: 30px;
    padding: 10px;
    border: 2px solid #fff;
    display: inline-block;
    width: 60px;
    height: 60px;
    vertical-align: middle;
    margin: 0 auto;
    line-height: 35px;
    border-radius:50%;
}
.utf_dashboard_stat.color-1 {
	background-color: #764c29;
	box-shadow:0 7px 15px 0 rgba(0,0,0,0.15);
}
.utf_dashboard_stat.color-2 {
	background-color: #18a4e1;
	box-shadow:0 7px 15px 0 rgba(0,0,0,0.15);
}
.utf_dashboard_stat.color-3 {
	background-color: #f1c40f;
	box-shadow:0 7px 15px 0 rgba(0,0,0,0.15);
}
.utf_dashboard_stat.color-4 {
	background-color: #f98925;
	box-shadow:0 7px 15px 0 rgba(0,0,0,0.15);
}
.utf_dashboard_stat.color-5 {
	background-color: #4f17ed;
	box-shadow:0 7px 15px 0 rgba(0,0,0,0.15);
}
.utf_dashboard_stat.color-6 {
	background-color: #ea3986;
	box-shadow:0 7px 15px 0 rgba(0,0,0,0.15);
}
#dashboard .pagination-next-prev ul li a {
	background-color: transparent;
}
#dashboard .pagination .current, #dashboard .pagination-next-prev ul li a:hover {
	background-color: #fff;
}
.utf_dashboard_list_box {
	margin: 30px 0 0 0;
	background:#fff;
	border:1px solid rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 12px 0 rgba(0,0,0,0.06);
	border-radius: 4px;
}
.utf_dashboard_list_box table.table-hover thead tr{
	background:#efefef;
}
.utf_dashboard_list_box table.table-hover tr th{
	font-weight:600;
}
.utf_dashboard_list_box ul li strong a{
	background:#e1e1e1;
	padding:3px 10px;
	border-radius:4px;
	display:inline;
	font-size:13px;
	font-weight:600;
	margin:0 2px;
	color:#595858
}
.utf_dashboard_list_box .button {
    padding: 5px 10px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}
.utf_dashboard_list_box .button.gray:before {
	display: none;
}
.utf_dashboard_list_box .button.gray {
    background-color: #f4f4f4;
    color: #666;
    font-weight:500;
    padding:4px 12px 4px 6px;
    border-radius: 4px;
    width: auto;
    height: auto;
    text-align: center;
    vertical-align: middle;
    line-height: 24px;
}
.utf_dashboard_list_box a.button.approve:hover {
    background-color: #54ba1d;
}
.utf_dashboard_list_box a.button.approve:hover i {
    color: #54ba1d;
}
.utf_dashboard_list_box .button.gray:hover {
	background-color: #764c29;
	color: #fff;
}
.utf_dashboard_list_box .button.gray:hover i{
	color: #764c29;
}
.dashboard-list-block {
	background-color: #424242;
	border-bottom: 1px solid #eaeaea;
	border-radius: 4px 4px 0 0;
}
.utf_dashboard_list_box h4 {
	font-size: 20px;
	line-height:40px;
	font-weight: 400;
	margin: 0;
	padding:15px 25px;
	color: #fff;
	background-color: #424242;
	display: block;
	border-bottom: 1px solid #eaeaea;
	border-radius: 4px 4px 0 0;
}
.utf_dashboard_list_box h4 i {
    margin-right: 10px;
    color: #fff;
    width: 40px;
    height: 40px;
    font-size: 18px;
    background: #764c29;
    padding: 10px;
    border-radius:50%;
	text-align: center;
	vertical-align: middle;
}
.utf_dashboard_list_box h4.gray {
	background-color: #424242;	
}
.utf_dashboard_list_box ul {
	list-style: none;
	padding: 0;
	margin: 0;
	background-color: #fff;	
	border-radius: 0 0 4px 4px;
}
.utf_dashboard_list_box ul li {
	padding: 20px 25px;
	border-bottom: 1px solid #eaeaea;
	transition: 0.3s;
	position: relative;
}
.utf_dashboard_list_box.with-icons ul li {
	padding-left: 75px;
}
.utf_dashboard_list_box.with-icons ul li strong{
	font-weight:700;
}
.utf_dashboard_list_box ul li:last-child {
	border: none;
}
.utf_dashboard_list_box ul li:hover {
	background-color: #fbfbfb;
}
.utf_dashboard_list_box .numerical-rating {
	margin: 0 3px;
}
.utf_dashboard_list_box ul li i.utf_list_box_icon {
	height: 38px;
	width: 38px;
	color: #777;
	text-align: center;
	line-height: 37px;
	border-radius:50%;
	transition: 0.3s;
	display: inline-block;
	background-color:#f0f0f0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 20px;
}
.utf_dashboard_list_box ul li:hover i.utf_list_box_icon {
	background-color: #eaeaea;
}
.utf_dashboard_list_box-static {
	padding: 30px;
	background-color: #fff;
}
.utf_dashboard_list_box-static .button {
	padding: 8px 20px;
	font-size: 15px;
	line-height:26px;
	margin-bottom: 3px;
	font-weight:500;
}
a.close-list-item {
	background-color: #f4f4f4;
	width:40px;
	height:40px;
	line-height:20px;
	padding:10px;
	border-radius:30px;
	text-align: center;
	vertical-align: middle;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right:20px;
	opacity: 1;
	color:#764c29;
	transition: 0.3s;
}
.utf_dashboard_list_box ul li:hover a.close-list-item {
	opacity: 1;
}
.utf_dashboard_list_box ul ul {
	background-color: transparent;
}
.utf_dashboard_list_box ul ul li {
	padding: 0;
	border: none;
	font-weight: 400;
	font-size: 14px;
    color: #8d8d8d;
	transition: 0.3s;
	background-color: transparent;
	display: inline-block;
}
.utf_dashboard_list_box ul ul li:hover {
	background-color: transparent;
}
.utf_dashboard_list_box .sort-by {
	top: 16px;
	right: 26px;
}
.utf_dashboard_list_box .my_sort_by{
	top:25px;
}
.utf_dashboard_list_box .my_sort_by .chosen-single {
    background-color: #f3f3f3 !important;
    padding: 0 20px !important;
}
.utf_dashboard_list_box .sort-by .chosen-container-single .chosen-single {
	background-color: transparent;
	padding: 0 4px;
}
.utf_dashboard_list_box.invoices li {
	padding: 20px;
}
.utf_dashboard_list_box.invoices ul li strong {
	color: #333;
}
.utf_dashboard_list_box.invoices ul li strong span.unpaid{
	background:rgba(0, 0, 0, 0.08);
	padding:2px 7px;
	border-radius:4px;
	display:inline;
	font-size:12px;
	font-weight:700;
	color:#ee3535;
	margin-left:2px;
	top: -1px;
    position: relative;
}
.utf_dashboard_list_box.invoices ul li strong span.paid{
	background:rgba(0, 0, 0, 0.08);
	padding:2px 7px;
	border-radius:4px;
	display:inline;
	font-size:12px;
	font-weight:700;
	color:#64bc36;
	margin-left:2px;
	top: -1px;
    position: relative;
}
.utf_dashboard_list_box.invoices ul li strong span.list_hotel{
	background:#e1e1e1;
	padding:2px 7px;
	border-radius:4px;
	display:inline;
	font-size:13px;
	font-weight:600;
	margin-left:3px;
	color:#595858
}
.utf_dashboard_list_box.invoices ul ul {
	line-height: 22px;
	padding-bottom: 2px;
	color: #909090;
}
.utf_dashboard_list_box.invoices ul ul li {
	background:transparent;
	padding: 0;
	font-size:13px;
	font-weight:600;
}
.utf_dashboard_list_box.invoices ul ul li span{
	color:#666;
	font-weight:700;
}
.utf_dashboard_list_box.invoices ul ul li:after {
	content: "";
	display: inline-block;
	width: 2px;
	height: 10px;
	background-color: #d5d5d5;
	position: relative;
	margin: 0 8px;
}
.utf_dashboard_list_box.invoices ul ul li:last-child:after {
	display: none;
}
.utf_post_quote blockquote {
	background:rgba(0, 0, 0, 0.02);
    padding:20px;
    margin: 0 0 20px;
    font-size: 15px;
    line-height: 27px;
    color: #848484;
    border-left: 5px solid #eeeeee;
}
blockquote {
	background:rgba(0, 0, 0, 0.02);
    padding:20px;
    margin: 0 0 20px;
    font-size: 16px;
    line-height: 28px;
    color: #707070;
    border-left: 5px solid #eeeeee;
}
.paid {
	color: #64bc36;
	font-weight:500;
}
.unpaid {
	color: #ee3535;
	font-weight:500;
}
.buttons-to-right, .utf_dashboard_list_box .button.to-right {
	position: absolute;
	right: 20px;
	top: 30%;
	transform: translate3d(0, -49%, 0);
	-moz-transform: translate3d(0, -50%, 0);
	opacity: 0;
	transition: 0.4s;
	box-shadow: 0px 0px 10px 15px #fbfbfb;
}
.utf_dashboard_list_box .utf_approved_booking_listing .buttons-to-right, .utf_dashboard_list_box .utf_canceled_booking_listing .buttons-to-right, .utf_dashboard_list_box .utf_pending_booking_listing .buttons-to-right {
	position: relative;
	right: 20px;
	left: 0;
	transform: translate3d(0, -49%, 0);
	-moz-transform: translate3d(0, -50%, 0);
	transition: 0.4s;
	box-shadow: 0px 0px 0px 0px transparent;
	text-align: right;
}
.invoices .buttons-to-right{
	top: 50% !important;
}
.utf_dashboard_list_box li .buttons-to-right, .utf_dashboard_list_box li .button.to-right {
	opacity: 1;
}
.buttons-to-right {
	box-shadow: none;
}
.buttons-to-right .button {
	margin-left: 2px;
}
.utf_inner_booking_listing_list ul.utf_booking_listing_list li.highlighted::after {
	display:none;
}
.utf_dashboard_list_box .button i {
	font-size: 14px;
	position: relative;
	top: 0px;
	background: #fff;
	width: 24px;
	height: 24px;
	line-height: 24px;
	border-radius: 30px;
	margin-right: 6px;
	padding: 0;
	float:left;
}
.utf_dashboard_list_box .button i.fa-remove {
	padding-right: 1px;
}

/*==========
  Bookings Wallet Style
============*/
.utf_dashboard_block_part {
    display: inline-block;
    padding: 0;
    min-height: 170px;
    background-color: #444;
    color: #fff;
    border-radius:4px;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    transition: 0.3s;
    cursor: default;
}
.utf_dashboard_content_part {
	width: 100%;
    text-align: center;
    vertical-align: middle;
    left: 0;
    right: 0;
    margin-top: 15px;
}
.utf_dashboard_content_part h4 {
	background:transparent;
    font-size: 32px;
    font-weight: 600;
    padding: 0;
    margin: 0;
	border-bottom:0;
    color: #fff;
    letter-spacing: 0px;
}
.utf_dashboard_content_part span {
    font-size: 14px;
    margin-top: 5px;
    line-height: 28px;
    font-weight: 500;
    opacity: 0.95;
    display: inline-block;
}
.utf_dashboard_block_part.color-1 {
    background-color: #764c29;
	box-shadow:0 7px 15px 0 rgba(0,0,0,0.15);
	padding:20px 10px;
}
.utf_dashboard_block_part.color-2 {
    background-color: #18a4e1;
	box-shadow:0 7px 15px 0 rgba(0,0,0,0.15);
	padding:20px 10px;
}
.utf_dashboard_block_part.color-3 {
    background-color: #ea3986;
	box-shadow:0 7px 15px 0 rgba(0,0,0,0.15);
	padding:20px 10px;
}
.utf_dashboard_block_part.color-4 {
    background-color: #f98925;
	box-shadow:0 7px 15px 0 rgba(0,0,0,0.15);
	padding:20px 10px;
}
.utf_dashboard_ic_stat {
    font-size: 50px;
}
.utf_dashboard_ic_stat {
	font-size: 50px;
    color: #fff;
    text-align: center;
    display: block;
    margin: 0 auto;
}

/*==========
  Bookings Payment Style
============*/
.utf_payment_tab_block.utf_payment_tab_block_active {
    max-height:100%;
    background: #fff;
	border-radius: 4px;
}
.payment {    
    border-radius: 4px;
    overflow: hidden;
}
.utf_listing_payment_section .opening-hours.summary li:first-child{
	padding-top:0
}
.utf_listing_payment_section .opening-hours.summary li {
    color: #424242;
	border-bottom:0;
	font-weight: 600;
}
.utf_listing_payment_section .opening-hours ul li span {
    float: right;
    font-weight: 400;
	color:#797979;
}
.utf_listing_payment_section .opening-hours.summary li.total-costs {
    font-size: 20px;
    border-top: 1px solid #e8e8e8;
    padding-top: 15px;
    margin-top: 10px;
	padding-bottom:0;
}
li.total-costs .col-md-8{
	padding-left:0;	
	padding-right:4px;
}
li.total-costs .col-md-4{
	padding-right:0;	
	padding-left:4px;
}
li.total-costs .col-md-4 input{
	margin-right:0;
	width:100%;
}
li.total-costs #couponCode, li.total-costs .coupon_code{
	margin-bottom:0;
}
.utf_listing_payment_section .opening-hours.summary li.total-costs span {
    color: #764c29;
	font-size:20px;
	font-weight:600;
}
.utf_payment_trigger_tab {
    padding: 0px 20px;
}
.utf_payment_trigger_tab > input {
    position: absolute;
    visibility: hidden;
}
input[type="radio"] {
    width: 15px;
    height: 15px;
    cursor: pointer;
    box-shadow: none;
}
.utf_payment_trigger_tab > label {
    cursor: pointer;
    display: block;
    position: relative;
    top: 0;
    padding: 15px 0;	
    top: 1px;
	border-bottom:1px solid #e8e8e8;
    margin-bottom: 10px;
    font-weight: 500;
}
.utf_payment_trigger_tab > input:checked ~ label::before {
    border-color: #764c29;
}
.utf_payment_trigger_tab > label::before {
    transition: 0.2s;
    box-sizing: border-box;
    background-color: #fff;
    border: 2px solid #d0d0d0;        
    border-radius:5px;
    content: "";
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 4px;
    width: 20px;
    height: 20px;
}
.utf_payment_trigger_tab > input:checked ~ label::after {
    background-color: #764c29;
    transform: scale(1);
}
.utf_payment_trigger_tab > label::after {
    background: #fff;
    background-color: rgb(255, 255, 255);
    border-radius:2px;
    content: "";
    display: inline-block;
    position: absolute;
    left: 6px;
    top: 25px;
    width: 8px;
    height: 8px;
    transition: 0.2s;
    transform: scale(0);
}
.utf_payment_logo.paypal {
    height: 22px;
    top: 20px;
}
.utf_payment_logo.stripe {
	height: auto;
	top: 18px;
	max-width: 80px;
}
.utf_payment_logo {
    height: 24px;
    position: absolute;
    right: 19px;
    top: 18px;
    image-rendering: -webkit-optimize-contrast;
}
.utf_payment_tab_block.utf_payment_tab_block_active .utf_payment_tab_block_content {
    visibility: visible;
    opacity: 1;
}
.card-label > label {
	background-color: #fff;
	display: inline-block;
	margin: 10px 0;
	font-size: 16px;
	padding: 0px 2px;
	color: #909090;
}
.utf_payment_tab_block {
	background: #ffffff;
	box-sizing: border-box;
	max-height: 60px;
	overflow: hidden;
	position: relative;
	transition: all 0.3s 0s ease-in-out;
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin-bottom: 10px;
}
.utf_payment_tab_block:last-child{
	margin-bottom:0
}
.utf_booking_payment_option_form .utf_booking_confirmation_button{
	display:block;
	text-align:center;
}
.utf_payment_tab_block.utf_payment_tab_block_active .utf_payment_tab_block_content {
    visibility: visible;
}
.card-label > input {
    box-sizing: border-box;
    display: block;
    height: 51px;
    margin: 0px auto 10px;
    padding: 0px 15px 0 15px;
    transition: border-color 0.3s;
    width: 100%;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    text-decoration: none;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.06);
}
.utf_payment_tab_block_content {
    padding:10px 20px 20px 20px;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease-in-out;
}
.utf_listing_payment_section .utf_booking_listing_item_container{
	position:relative;
}
.utf_listing_payment_section label{
	font-weight:600;
	color:#707070;
	font-size:16px;
	margin-bottom: 10px;
}
.input-with-icon.medium-icons i {
    font-size: 21px;
    color: #a0a0a0;
    position: absolute;
    left: -3px;
    bottom: 0px;
    top: auto;
}
.utf_listing_payment_section .listing-item::before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    background:linear-gradient(to top, rgba(35,35,37,0.9) 0%, rgba(35,35,37,0.3) 100%, rgba(22,22,23,0) 60%, rgba(0,0,0,0) 100%);
    background-color: rgba(0, 0, 0, 0);
    background-color: rgba(35,35,37,0.2);
    border-radius: 4px 4px 0 0;
    opacity: 1;
}
.utf_listing_payment_section .listing-item img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius:4px 4px 0 0;
}
.utf_listing_payment_section .utf_listing_item_content {
    position: absolute;
    bottom: 25px;
    left: 0;
    padding: 0 25px;
    width: 100%;
    z-index: 50;
    box-sizing: border-box;
}
.utf_listing_payment_section .numerical-rating.high {
    background-color: #64bc36;
}
.utf_listing_payment_section .utf_listing_item_content h3 {
    color: #fff;
    font-size: 20px;
    bottom: 2px;
    position: relative;
    font-weight: 500;
    margin: 0;
    line-height: 28px;
}
.utf_listing_payment_section .utf_listing_item_content span {
    font-size: 14px;
    font-weight: 400;
	margin:1px 2px;
	display: inline-block;
    color: rgba(255,255,255,0.85);
}
.utf_listing_item_content .utf_star_rating_section .utf_counter_star_rating {
    top:-1px;
    position: relative;
    margin-left: 6px;
}
.utf_listing_payment_section .boxed-widget {
    background-color: #f9f9f9;
    padding: 25px;
    transform: translate3d(0,0,0);
    z-index: 90;
	border:1px solid rgba(0, 0, 0, 0.08);
    position: relative;
}
.utf_listing_payment_section .boxed-widget.summary {
    border-radius:0 0 4px 4px;
}
.utf_listing_payment_section .boxed-widget h3 {
    font-size: 20px;
    padding: 0 0 20px;
    margin: 0 0 20px 0;
    display: block;
	font-weight:600;
    border-bottom: 1px solid #e8e8e8;
}
.utf_listing_payment_section .boxed-widget h3 i.fa-calendar-check-o {
    position: relative;
    top: -1px;
	margin-right: 10px;
	color: #fff;
	width: 40px;
	height: 40px;
	background: #764c29;
	padding: 10px;
	border-radius:50%;
}

/*==========
  Bookings Style
============*/
.dashboard-list-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
}
.dashboard-list-box ul li {
    padding: 23px 30px;
    border-bottom: 1px solid #eaeaea;
    transition: 0.3s;
    position: relative;
}
.bookings {
    margin: 10px 0 20px 0;
}
.utf_list_box_listing_item {
    display: flex;
    padding: 5px 0;
    position: relative;
}
.utf_inner_booking_listing_list{
	line-height:34px;
}
.bookings .utf_list_box_listing_item-img {
	max-width: 80px;
	max-height: 80px;
	overflow: hidden;
	margin-right: 5px;
	border: 3px solid rgba(0, 0, 0, 0.06);
	border-radius: 50px;
}
.utf_list_box_listing_item-img {
    flex: 1;
    max-width: 150px;
    position: relative;
}
.utf_list_box_listing_item_content {
    flex: 1;
    padding-left: 25px;
}
.bookings .utf_list_box_listing_item_content .inner {
    top: 0;
    transform: none;
}
.utf_list_box_listing_item_content .inner {
    position: relative;
    top: 14px;    
    vertical-align: top;
}
.bookings .utf_list_box_listing_item_content .inner h3 {
    margin-bottom: 10px;
}
.utf_list_box_listing_item_content .inner h3 {
    font-size: 18px;
    bottom: -2px;
    position: relative;
    font-weight: 500;
    margin: 0;
    margin-bottom: 0px;
    line-height: 27px;
}
.utf_pending_booking_listing .bookings .inner h3 span.utf_booking_listing_status {
    background-color: #61b2db;
}
.bookings .inner h3 span.utf_booking_listing_status {
    border-radius: 4px;
    line-height: 20px;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    font-style: normal;
    padding: 2px 7px;
    margin-left: 3px;
    position: relative;
    top: 0px;
	display: inline-block;
	vertical-align: top;
}
.utf_list_box_listing_item_content .inner span {
    font-size: 15px;
    font-weight: 300;
    display: inline-block;
}
.bookings .inner h3 span.utf_booking_listing_status.unpaid {
    background-color: #ee3535;
}
.utf_inner_booking_listing_list h5 {
    display: inline-block;
    font-weight: 600;
    margin: 0;
    color: #666;
}
.dashboard-list-box ul ul {
    background-color: transparent;
}
.utf_inner_booking_listing_list ul {
    display: inline-block;
    padding: 0;
    margin-bottom: 0;
}
.utf_inner_booking_listing_list ul li.highlighted {
    padding:0px;
    line-height: 24px;
    font-weight: 400;
    font-size: 14px;
    color: #8d8d8d;
    display: inline-block;
}
.dashboard-list-box ul li:last-child {
    border: none;
}
.utf_approved_booking_listing .bookings .inner h3 span.utf_booking_listing_status {
    background-color: #64bc36;
}
.dashboard-list-box ul ul {
    background-color: transparent;
}
.utf_inner_booking_listing_list ul {
    display: inline-block;
    padding: 0;
    margin-bottom: 0;
}
.utf_inner_booking_listing_list ul li::after {
    content: "";
    display: inline-block;
    position: relative;
    margin: 0 8px;
    height: 12px;
    top: 2px;
    background-color: #ddd;
    width: 2px;
}
.utf_canceled_booking_listing .bookings .inner h3 span.utf_booking_listing_status {
    background-color: #ee3535;
}
.utf_list_box_listing_item_content .utf_inner_booking_listing_list ul.utf_booking_listing_list li:last-child::after {
    display:none;
}
.dashboard-list-box ul li:last-child {
    border: none;
}

/*==========
  Bookings Confimation Style
============*/
.utf_booking_listing_section_form, .utf_booking_payment_option_form{
	background-color: #f9f9f9;
	border-radius: 4px;
	padding: 30px;
	transform: translate3d(0, 0, 0);
	border:1px solid rgba(0, 0, 0, 0.08);
}
.utf_booking_listing_section_form h3, .utf_booking_payment_option_form h3{
    width: 100%;
    font-size: 20px;
	font-weight: 600;
    line-height: 40px;
    padding: 0 0 20px;
    margin: 5px 0 30px 0;
    display: block;
    border-bottom: 1px solid #e8e8e8;
}
.utf_booking_listing_section_form h3 i, .utf_booking_payment_option_form h3 i {
    margin-right: 10px;
    color: #fff;
    width: 40px;
    height: 40px;
    background: #764c29;
    padding: 10px;
    border-radius:50%;
}
.utf_booking_listing_confirmation_section {
	background-color: #f9f9f9;
	border-radius: 4px;
	padding:50px 30px;
	transform: translate3d(0, 0, 0);
	border: 1px solid rgba(0, 0, 0, 0.08);
    text-align: center;    
}
.utf_booking_listing_confirmation_section i {
    color: #54ba1d;
	font-size: 140px;
	margin-bottom: 20px;
	display: inline-block;
	font-weight: 800;
}
.utf_booking_listing_confirmation_section h2 {
    font-size: 40px;
	line-height:54px;
	font-weight: 800;
	letter-spacing: 1px;
	margin-bottom: 15px;
	display: block;
	text-transform: uppercase;
}
.utf_booking_listing_confirmation_section p {
    font-size: 20px;
    display: block;
}

/*==========
  Edit Profile Style
============*/
.edit-profile-photo img {
	border-radius:50%;
	max-width: 200px;
	width: 100%;
	border:10px solid rgba(0, 0, 0, 0.08);
}
.edit-profile-photo {
	position: relative;
	display: block;
	margin-bottom: 35px;
	text-align:center;
}
.change-photo-btn {
	font-weight: 600;
	padding: 5px;
	color: #333;
	background-color: #fff;
	border-radius: 50px;
	display: inline-block;
	position: relative;
	font-size: 13px;
	width: auto;
	transition: 0.3s;
	cursor: pointer;
	line-height: 20px;
	position: absolute;
	bottom:35px;
	left:0;
	right:0;
	width:140px;
	margin:0 auto;	
}
.photoUpload {
	position: relative;
	overflow: hidden;
	padding: 3px 5px;
	cursor: pointer;
}
.photoUpload input.upload {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
}
.change-photo-btn:hover {
	color: #fff;
	background-color: #764c29;
}
.change-photo-btn i {
	margin-right: 2px;
}
.my-profile label {
	margin-top: 10px;
	font-size: 16px;
	font-weight: 600;
	color: #707070;
	margin-bottom: 9px;
}
.my-profile {
	display: block;
}
.my-profile label i {
	margin-right: 3px;
}
.my-profile textarea {
	height: 200px;
}
.utf_list_box_listing_item {
	display: flex;
	padding: 5px 0;
	position: relative;
}
.utf_list_box_listing_item-img {
	flex: 1;
	max-width: 250px;
	position: relative;
}
.utf_list_box_listing_item-img a {
	width: 100%;
	height: 100%;
	display: inline-block;
	overflow: hidden;
	position: relative;
	z-index: 10;
}
.utf_list_box_listing_item-img a:before {
	content: "";
	height: 100%;
	width: 100%;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	background-color: rgba(22,22,22,0.2);
	border-radius: 4px;
	z-index: 11;
}
.utf_list_box_listing_item-img a img {
	object-fit: cover;
	height: 100%;
	width: 100%;
	border-radius: 4px;
}
.utf_list_box_listing_item_content {
	flex: 1;
	padding-left: 20px;
}
.utf_list_box_listing_item_content .inner {
	position: relative;
	top: 14px;
	vertical-align: top;
}
.utf_list_box_listing_item_content .inner h3 {
	font-size: 20px;
	bottom: 2px;
	position: relative;
	font-weight: 600;
	margin: 0;
	line-height: 28px;
}
.utf_list_box_listing_item_content .inner span {
	font-size: 14px;
	font-weight: 400;
	display: inline-block;
}
.utf_dashboard_list_box .utf_list_box_listing_item_content .inner span {
	display:block;
	margin:2px;
	vertical-align: sub;
	color:#808080;
}
.utf_dashboard_list_box .utf_list_box_listing_item_content .inner span i {
    font-size: 16px;
    color: #764c29;
    padding-right: 3px;
	width: 18px;
	display: inline-block;
	text-align: center;
	vertical-align: sub;
}
.utf_list_box_listing_item_content .inner .numerical-rating, .utf_list_box_listing_item_content .inner .utf_star_rating_section {
	margin: 5px 0 0 0;
	display: block;
}
.utf_list_box_listing_item_content p{
	margin-top:5px;
	font-size: 15px;
    line-height: 25px;
	color:#848484;
}

/*==========
  Coming Soon Style
============*/
.utf_coming_soon_section {
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
	position: relative;
}
.utf_coming_soon_section .container {
	z-index: 15;
	position: relative;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
}
@media (max-width: 992px) {
.utf_coming_soon_section {
	min-height: 100vh;
	height: auto;
}
.utf_coming_soon_section .container {
	padding-top: 50px;
	padding-bottom: 50px;
	top: auto;
	transform: translateY(0%);
}
}
.utf_coming_soon_section:before {
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 10;
	content: "";
	background-color: rgba(26,26,26,0.80)
}
.utf_coming_soon_section h2, .utf_coming_soon_section h3 {
	margin: 0;
	padding: 0;
}
.utf_coming_soon_section h2 {
	font-size: 46px;
	color: #fff;
}
.utf_coming_soon_section h3 {
	font-size:38px;
	font-weight: 600;
	margin: 30px 0 25px 0;
	color:#f9f9f9;
}
.utf_coming_soon_section p {
	font-size:16px;
	font-weight: 400;
	margin: 10px 0 30px 0;
	color:#cbcbcb;
}
.utf_coming_soon_section .utf_coming_soon_search_input_section input {
	font-size: 16px;
	font-weight: 600;
}
.utf_coming_soon_section .gray-style.utf_coming_soon_search_input {
	max-width: 600px;
	margin: 0 auto;
}
.utf_coming_soon_section .utf_coming_soon_search_input button.button { font-size: 16px; }
#utf_countdown {
	font-weight: 600;
	float: none;
	display: inline-block;
}
#utf_countdown div {
	display: inline-block;
	margin: 0 8px;
	float: left;
}
#utf_countdown div:first-child i { border: none;}
#utf_countdown span {
	background-color: #764c29;
	font-size: 38px;
	color: #fff;
	width: 135px;
	height:135px;
	display: inline-block;
	padding:56px 0;
	font-weight: 600;
	letter-spacing: -1px;
	border-radius:50%;
}
#utf_countdown i {
	display: block;
	font-style: normal;
	margin: 0;
	margin-top:10px;
	text-transform: none;
	font-size: 16px;
	padding: 5px 0;
	font-weight:400;
	letter-spacing: 0.5px;
	background-color:rgba(255, 255, 255, 0.2);
	color: #ffffff;
	border-radius:30px;
}
.utf_countdown-text {
	font-size: 24px;
	margin-top: 15px;
	line-height: 36px;
	font-weight: 300;
}
@media (max-width: 550px) {
#utf_countdown span {
	color: #fff;
	width: 128px;	
	height:128px;
	padding:50px 0;
}	
#utf_countdown{
	float:none;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0 auto;        
}
#utf_countdown div {
	padding: 10px 0;
	text-align:center;
	display:inline-block;
}
}
@media (max-width: 633px) {
#utf_countdown div {
	padding: 10px;
	text-align:center;
	display:inline-block;
	margin:0 auto;
	left:0;
	right:0;
	width:50%
}
}
.utf_coming_soon_search_input {
    margin-top: 40px;
    border-radius: 3px;
    width: 100%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 10px;
    max-height: 70px;
}
.utf_coming_soon_search_input .utf_coming_soon_search_input_section:nth-last-child(2) {
    border-right: none;
    padding-left: 0px;
    padding-right: 10px;
}
.gray-style.utf_coming_soon_search_input input{
	background: #fff;
}
.utf_coming_soon_section .utf_coming_soon_search_input_section input {
    font-size: 16px;
    font-weight: 400;
}
.utf_coming_soon_search_input input, .utf_coming_soon_search_input input:focus {
    font-size: 16px;
    border: none;
    background: #fff;
    margin: 0;
    padding:10px 15px 10px 20px;
	color:#808080;
    height: 50px;
    line-height: 50px;
	border-radius:30px;
    box-shadow: none;
}
.utf_coming_soon_search_input button.button {
    font-size: 18px;
    font-weight: 500;
    padding: 0 40px;
    margin-right: 1px;
    height: 50px;
	border-radius:30px;
    outline: none;
}
.utf_coming_soon_search_input_section {
    flex: 1;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
}
@media (max-width: 767px) {
.utf_coming_soon_search_input_section, .utf_coming_soon_search_input .utf_coming_soon_search_input_section:nth-last-child(2) {
    padding: 0;
}
.utf_coming_soon_search_input_section {
    margin-bottom: 10px;
}
.gray-style.utf_coming_soon_search_input input, .gray-style.utf_coming_soon_search_input input:focus, .utf_coming_soon_search_input input, .utf_coming_soon_search_input input:focus {
    padding: 0 20px;
    height: 55px;
    line-height: 55px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.08);
}
.gray-style.utf_coming_soon_search_input input, .gray-style.utf_coming_soon_search_input input:focus, .utf_coming_soon_search_input input, .utf_coming_soon_search_input input:focus {
    padding: 0 20px;
}
.utf_coming_soon_search_input button.button {
    margin-top: 10px;
}
.utf_coming_soon_section .utf_coming_soon_search_input button.button {
    font-size: 16px;
}
.gray-style.utf_coming_soon_search_input {
    background-color: transparent;
}
.utf_coming_soon_search_input {
    border-radius: 0;
    display: block;
    padding: 0;
    max-height: 100%;
    background-color: transparent;
    box-shadow: none;
}
}
.utf_listing_categorie {
    clear: both;
    margin: -15px 0;
    overflow: hidden;
}
.utf_listing_categorybox {
    width: 100%;
    float: left;
    margin: 15px 0;
    border-radius: 6px;
	border:1px solid rgba(0, 0, 0, 0.02);
    background: #f9f9f9;
    padding:20px 10px;
}
.utf_listing_category_title {
    width: 100%;
    float: left;
}
.utf_listing_category_title h3{
	color: #323232;
	margin: 0;
	float: left;
	font-size: 18px;
	font-weight:500;
	line-height: 40px;
	padding-left:10px;
}
.utf_listing_cateicon{
	background: #764c29;
	width: 36px;
	height:36px;
	color: #fff;
	float: left;
	margin-right:5px;
	line-height: 36px;
	border-radius:50%;
	text-align: center;
}
.utf_listing_cateicon i{
    color: #fff;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
}
.utf_listing_categorybox ul {
    width: 100%;
    float: left;
    list-style: none;
    font-size: 14px;
    line-height: 18px;
    padding: 15px 0 0;
}
.utf_listing_categorybox ul li:first-child{
    border-top:1px solid rgba(0, 0, 0, 0.05);
}
.utf_listing_categorybox ul li {
    width: 100%;
    float: left;
    padding: 12px 10px;
    line-height: inherit;
    list-style-type: none;
	border-bottom:1px solid rgba(0, 0, 0, 0.05);
}
.utf_listing_categorybox ul li a{
	font-size:15px;
	font-weight:400;
	color:#626262;
    float: left;
    line-height: inherit;
    list-style-type: none;	
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.utf_listing_categorybox ul li a:hover{
	color:#764c29;
	padding-left:5px;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.utf_listing_categorybox ul li span {
	font-size:15px;
	font-weight:400;
    float: right;
}
.listing_tags_section a {
    border: 1px solid #e0e0e0;
    color: #707070;
    padding: 3px 15px;
    font-size: 15px;
	font-weight:600;
    margin-right: 10px;
    border-radius: 4px;
    display: inline-block;
    margin-top: 8px;
    transition: all 250ms ease;
    -webkit-transition: all 250ms ease;
}
.listing_tags_section a i {
    padding-right: 5px;
    opacity:1;
	color: #b0b0b0;
	vertical-align:middle;
}
.listing_tags_section a:hover{
	background:#764c29;
	color: #ffffff;
    border-color: #764c29 !important;
}
.listing_tags_section a:hover i{
	color: #ffffff;
}

/*==========
  Dashboard List Style
============*/
.utf_dashboard_list_box .comments.utf_listing_reviews li {
	margin: 19px 0 10px 0;
}
.utf_dashboard_list_box a.rate-review {
	border: none;
	color: #666;
	font-weight:600;
	background-color: #f4f4f4;
	padding:5px 15px;
}
.utf_dashboard_list_box a.rate-review:hover {
	background-color: #764c29;
	color: #fff;
}
.utf_dashboard_list_box .utf_by_comment-listing {
	display: inline-block;
	color: #888;
    font-size: 14px;
    font-weight: 400;
}
.utf_dashboard_list_box .utf_by_comment-listing.own-comment, .utf_dashboard_list_box .utf_by_comment-listing.own-comment a {
	color: #888;
	font-weight: 600;
}
.utf_dashboard_list_box .utf_by_comment-listing.own-comment a{
	color:#764c29
}
.utf_dashboard_list_box .utf_by_comment-listing a {
	color: #764c29;
	font-size:16px;
	font-weight: 600;
}
.utf_dashboard_list_box .utf_by_comment-listing a:hover {
	color: #888;
}
.add_utf_listing_section label.switch {
	position: absolute;
	right: 30px;
	top: 30px;
	z-index: 100;
}
.add_utf_listing_section {
	position: relative;
}
.add_utf_listing_section .switcher-content {
	opacity:1;
	transition: 0.4s;
}
.add_utf_listing_section.switcher-on .switcher-content {
	opacity: 1;
	pointer-events: all;
}
label.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 30px;
	margin-top: 0;
	margin-bottom: 0px;
}
.switch input {
	display: none;
}
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	border-radius: 50px;
	transition: 0.4s;
}
.slider:before {
	position: absolute;
	content: "";
	height: 22px;
	width: 22px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	transition: 0.4s;
	border-radius: 50px;
}
input:checked+.slider {
	background-color: #66676b;
}
input:checked+.slider:before {
	transform: translateX(30px);
}
.utf_submit_section h4{
	font-size:18px;
	font-weight:500;
	margin-bottom:20px;
	text-align:center;
	color:#707070
}
.add_utf_listing_section {
	border-radius: 4px;
	background-color: #fff;
	border:1px solid rgba(0, 0, 0, 0.08);
	box-shadow:0 3px 8px 0 rgba(0,0,0,0.08);
	padding: 0 30px 30px 30px;
	width:100%;
	float:left;
}
.utf_add_listing_part_headline_part {
	width: calc(100% + 60px);
	left: -30px;
	position: relative;
	padding:20px 25px;
	margin: 0 0 30px 0;
	border-radius: 4px 4px 0 0;
	background-color: #424242;
	border-bottom: 1px solid #eaeaea;
}
.utf_add_listing_part_headline_part h3 {
	padding: 0;
	margin: 0;
	color:#fff;
	font-weight:400;
	line-height: 34px;
	font-size: 20px;
	position: relative;
	top: 1px;
}
#utf_add_listing_part h5 {
	font-size: 16px;
	font-weight: 600;
	color: #707070;
	margin-bottom: 15px;
}
#utf_add_listing_part h5 span {
	color: #888;
	font-weight: 400;
}
#utf_add_listing_part h5 i {
	padding-right: 4px;
}
.utf_add_listing_part_headline_part i {
	margin-right: 10px;
    color: #fff;
    width: 40px;
    height: 40px;
	font-size:18px;
    background: #764c29;
    padding: 10px;
    border-radius:50%;
}
.button.preview {
	margin-top: 40px;
	font-size: 16px;
	font-weight: 500;
	padding: 8px 20px;
	display: inline-block;
}
.button.preview i {
	padding: 0;
	margin: 0 0 0 4px;
}
.add_utf_listing_section .row.with-forms input, .add_utf_listing_section .row.with-forms input[type="text"], .add_utf_listing_section .row.with-forms input[type="password"], .add_utf_listing_section .row.with-forms input[type="email"], .add_utf_listing_section .row.with-forms input[type="number"], .add_utf_listing_section .row.with-forms textarea, .add_utf_listing_section .row.with-forms input, .add_utf_listing_section .row.with-forms select, .add_utf_listing_section .row.with-forms .chosen-container {
	margin-bottom: 22px;
}
.add_utf_listing_section textarea {
	min-height: 200px;
	margin-bottom: 35px;
}
#utf_add_listing_part h5.fb-input {
	color: #3b5998;
}
#utf_add_listing_part h5.twitter-input {
	color: #1da1f2;
}
#utf_add_listing_part h5.gplus-input {
	color: #dd4b39;
}
.tip {
	font-style: normal;
	position: relative;
	top: -1px;
	left: 2px;
	display: inline-block;
	cursor: help;
	color: #fff;
	font-size: 11px;
	line-height: 0;
	width: 18px;
	height: 18px;
	background: #66676b;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	padding: 0!important;
	font-weight: 500;
}
.tip-content {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, calc(-100% - 20px));
	-ms-transform: translate(-50%, -100%);
	background: #333;
	color: #fff;
	padding: 13px 15px;
	min-width: 200px;
	max-width: 200px;
	font-size: 14px;
	border-radius: 4px;
	line-height: 20px;
	pointer-events: none;
	z-index: 9;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;
}
.tip:hover .tip-content {
	opacity: 1;
	visibility: visible;
	transform: translate(-50%, calc(-100% - 13px));
	-ms-transform: translate(-50%, -115%);
}
.tip:after {
	font-family: "FontAwesome";
	content: "\f128";
	line-height: 19px;
	display: inline-block;
	position: relative;
	text-align: center;
	-moz-transform: translateY(-1px);
}
.tip-content:after {
	font-family: "FontAwesome";
	content: "\f0dd";
	position: absolute;
	bottom: 3px;
	left: 50%;
	font-size: 22px;
	color: #333;
	transform: translate(-50%, 50%);
}
.utf_opening_day {
	margin-bottom: 22px;
}
.utf_opening_day h5 {
	margin-top: 17px;
	transition: 0.15s;
}
.utf_opening_day:hover h5 {
	color: #66676b!important;
}
.utf_opening_day .chosen-container .chosen-results {
	max-height: 200px;
}
#utf_pricing_list_section {
	width: 100%;
	margin-top: 10px;
}
.pricing-list-item td {
	display: flex;
	width: 100%;
	margin-bottom: 5px;
}
.pricing-list-item td .fm-input, .pricing-list-item td .fm-move, .pricing-list-item td .fm-close {
	flex: 1;
	position: relative;
}
.pricing-list-item td .fm-move, .pricing-list-item td .fm-close {
	max-width: 40px;
}
.pricing-list-item td .fm-move {
	cursor: move;
	line-height: 51px;
	color: #666;
	font-size: 17px;
}
.pricing-list-item td .fm-close {
	text-align: right;
}
.pricing-list-item td .fm-close a {
	background: #764c29;
	padding: 0;
	width: 32px;
	height: 32px;
	line-height: 30px;
	border-radius:50%;
	color: #fff;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	margin-left: 10px;
	margin-top: 10px;
}
.pricing-list-item td .fm-input {
	margin-right: 20px;
}
.pricing-list-item td .fm-input.pricing-name {
	max-width: 350px;
}
.pricing-list-item td .fm-input.pricing-price {
	max-width: 150px;
	margin-right: 0;
}
.pricing-submenu {
	display: block;
	padding-top: 20px;
}
.pricing-submenu input {
	background-color: #ffffff;
}
.pricing-submenu.pricing-list-item td .fm-input {
	margin-right: 0;
}
#utf_pricing_list_section tbody tr:first-child.pricing-list-item.pricing-submenu {
	padding-top: 0;
}
.data-unit {
	position: absolute;
	top: 12px;
	right: 19px;
	font-style: normal;
	color: #aaa;
	z-index: 110;
	background-color: transparent;
	outline: 0;
	border: 0;
	padding: 0 0 0 10px;
	margin: 0;
	font-size: 13px;
	font-weight: 500;
	pointer-events: none;
	background-color: #fff;
}
.add-pricing-submenu.button:before, .add-pricing-list-item.button:before {
	display: none;
}
.add-pricing-submenu.button, .add-pricing-list-item.button {
	margin-top: 15px;
	margin-bottom: 5px;
	background-color: #764c29;
	color: #fff;
	padding: 8px 22px;
	font-weight: 500;
}
.add-pricing-submenu.button:hover, .add-pricing-list-item.button:hover {
	color: #fff;
	background-color: #999;
}
.infoBox {
	border-radius: 4px;
}

/*==========
  Styles Switcher
============*/

#color_switcher_preview h3 {
	color: #fff;
	font-size: 13px;
	margin: 10px 0;
	font-weight: 500;
	text-transform: none;
	letter-spacing: 0;
}
#color_switcher_preview {
	background: #f9f9f9;
	width: 210px;
	position: fixed;
	top: 200px;
	z-index: 999;
	right: -210px;
	box-shadow:0px 2px 6px 0px rgba(0, 0, 0, 0.5);
	border-radius:0 0 0px 15px;
}
#color_switcher_preview ul {
	list-style: none;
	padding: 0;
	margin-bottom: 15px;
}
#color_switcher_preview div {
	padding: 6px 18px;
}
#color_switcher_preview h2 {
	background: #323232;
	color: #fff;
	font-size: 15px;
	line-height: 50px;
	padding:0;
	text-align:center;
	margin-top: 0;
	font-weight: 500;
	text-transform: capitalize;
}
#color_switcher_preview h2 a {
	background-color: #323232;
	display: block;
	height: 50px;
	position: absolute;
	left: -50px;
	top: 0;
	width: 50px;
	border-radius:25px 0 0 25px;
	font-size: 22px;
	color: #fff;
	text-align: center;
}
#color_switcher_preview h2 a i {
	position: relative;
	top: 0px;
}
.colors {
	list-style: none;
	margin: 0 0px 10px 0;
	overflow: hidden;
}
.colors li {
	float: left;
	margin:6px 3px 0 3px;
}
.colors li a {
	display: block;
	width: 50px;
	height: 50px;
	cursor: pointer;
}
div.layout-style {
	padding: 0 !important;
}
.layout-style select {
	width: 164px;
	padding: 7px;
	color: #aaa;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	margin: 2px 0 25px 0px;
	background-color: #505050;
	border: 0;
	min-height: auto;
}
.stylesheet {
	background: #764c29;
}
.stylesheet_1 {
	background: #ff7600;
}
.stylesheet_2 {
	background: #ff214f;
}
.stylesheet_3 {
	background: #f52084;
}
.stylesheet_4 {
	background: #5c37ec;
}
.stylesheet_5 {
	background: #1dc4ce;
}
.colors li a.active {
	box-shadow:0 0 0 5px rgba(0, 0, 0, 0.3) inset;
}
.colors li a {
	border-radius:50px;
}
#reset {
	margin: -5px 0 15px 0px;
}
#reset a.button {
	padding: 7px 14px;
	font-weight: 700;
	font-size: 13px;
}
#reset a.button:hover {
	color: #333;
	background-color: #fff;
}
 @media only screen and (max-width: 767px) {
#color_switcher_preview {
	display: none;
}
.utf_booking_listing_section_form, .utf_booking_payment_option_form{
	padding:30px 20px;
}
}

/*==========
  Map Box Style
============*/
.map-box {
	background-color: #fff;
	margin-bottom: 20px;
	border-radius:4px;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.12);
	position: relative;
}
.map-box .listing-img-container img {
	width: 100%;
	border-radius:4px 4px 0 0;
}
.map-box h4 {
	margin: 0;
	padding: 0;
}
.map-box h4 a {
	padding: 0 0 2px 0;
	font-size: 17px;
	line-height: 25px;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	display: inline-block;
}
.listing-img-container {
	position: relative;
	height: 100%;
	display: block;
}
.map-box h4 a:hover {
	color: #66676b;
}
.map-box p {
	padding: 0;
	line-height: 25px;
	margin: 2px 0 0 0;
	font-size: 14px;
}
.map-box ul.listing-details {
	padding: 0;
	font-size: 14px;
	display: none;
}
.map-box .listing-title {
	padding:5px 15px 2px;
	text-align:center;
}
.map-box .listing-img-content {
	padding: 18px 22px;
}
.map-box .listing-img-content span {
	font-size: 21px;
}
.map-box .listing-img-content .utf_listing_price i {
	font-size: 15px;
	margin: -7px 0 0 0;
}
.infoBox>img {
	position: absolute!important;
	right: 0px;
	top: 0px;
}
.map-box .utf_listing_item_content {
	position: relative;
	left: 0;
	margin: 20px 0 0 0;
	width: 100%;
	z-index: 50;
	padding: 0;
	text-align: left;
	box-sizing: border-box;
	display: inline-block;
	width: 100%;
	bottom: 12px;
}
.map-box .utf_listing_item_content h3 {
	color: #444;
	font-size: 17px;
	padding: 0;
	font-weight: 600;
	margin: 0;
	line-height: 28px;
}
.map-box .utf_listing_item_content span {
	font-size: 14px;
	font-weight: 500;
	display: inline-block;
	color: rgba(0,0,0,0.5);
}
.gm-style .gm-style-iw-c, .gm-style-iw-t::before, .gm-style .gm-style-iw-t::after{
	display:none;
}
.infoBox-close {
	position: absolute;
	top: 0px;
	right: 0px;
	display: inline-block;
	z-index: 99999;
	text-align: center;
	line-height: 30px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	height: 30px;
	width: 30px;
	background-color: #fff;
	color: #000000;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
	font-family: "FontAwesome";
	border-radius:0 15px 0 10px;
}
.map-box .infoBox-close {
	border-radius:4px;
}
.infoBox-close:hover {
	color: #fff;
	background-color: #764c29;
	-webkit-text-stroke: 1px #764c29;
}
.map-box-icon {
	height: 38px;
	width: 38px;
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: #3685cf;
	display: block;
	color: #fff;
	font-size: 12px;
	z-index: 101;
	text-align: center;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-transform: translateX(50%);
	-ms-transform: translateX(50%);
	transform: translateX(50%);
	opacity: 0;
	visibility: hidden;
}
.map-box-icon:before {
	content: "\f064";
	font-family: "FontAwesome";
	position: relative;
	top: 7px;
	display: block;
}
.map-box-image:hover .map-box-icon {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateX(0);
}
.map-box-image {
	position: relative;
	overflow: hidden;
	display: block;
}
.infoBox {
	-webkit-animation: fadeIn 0.9s;
	animation: fadeIn 0.9s;
}
@-webkit-keyframes fadeIn {
from {
opacity:0;
}
to {
opacity:1;
}
}
@keyframes fadeIn {
from {
opacity:0;
}
to {
opacity:1;
}
}
.infoBox .utf_star_rating_section {	
	font-size: 16px;
    text-align: left; 
	margin-top:-10px;	
}
.infoBox .utf_star_rating_section span {
	margin-right: 3px;
}
.infoBox .utf_counter_star_rating {
	position: relative;
	top: -1px;
}
.infoBox:after {
	content: "";
	position: absolute;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 20px solid #fff;
}
#map {
	height: 100%;
	width: 100%;
	margin: 0;
	z-index: 990;
}
#utf_map_container {
	position: relative;
	top: 0;
	left: 0;
	height: 520px;
	width: 100%;
	z-index: 990;
	margin-bottom: 60px;
}
.gmnoprint {
	margin: 10px;
}
#utf_map_container.fullwidth_block-home-map {
	height: 620px;
	margin-bottom: 0;
}
#utf_map_container.fullwidth_block-home-map .main_inner_search_block {
	position: absolute;
	display: block;
	bottom: 60px;
	top: auto;
	transform: none;
	padding-bottom: 0;
	z-index: 9999;
	margin-top: 0;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}
#utf_map_container.fullwidth_block-home-map .main_input_search_part {
	margin-top: 0;
}
.cluster img {
	display: none
}
.cluster-visible {
	text-align: center;
	font-size: 15px!important;
	color: #fff!important;
	font-weight: 500;
	border-radius: 50%;
	width: 36px!important;
	height: 36px!important;
	line-height: 36px!important;
	background-color: #764c29;
}
.cluster-visible:before {
	box-shadow: inset 0 0 0 4px rgba(21, 128, 199, 0.3);
	content: '';
	position: absolute;
	border-radius: 50%;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation: cluster-animation 2.5s infinite;
}
@keyframes cluster-animation {
0%, 100% {
transform:scale(1.3) rotate(0deg)
}
50% {
transform:scale(1.5) rotate(90deg)
}
}
.map-marker-container {
	position: absolute;
	margin-top: 10px;
	transform: translate3d(-50%, -100%, 0);
}
.marker-container {
	position: relative;
	margin: 10px auto;
	width: 40px;
	height: 40px;
	z-index: 1;
	border-radius: 50%;
	cursor: pointer;
	top: -5px;
}
#utf_single_listingmap .marker-container {
	cursor: default;
}
.marker-container {
	perspective: 1000;
}
.marker-card {
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: all 0.4s ease-in-out;
	position: absolute;
	z-index: 1;
}
.marker-container:hover .marker-card, .map-marker-container.clicked .marker-card {
	transform: rotateY(180deg) translateY(-4px);
}
.marker-arrow {
	width: 0;
	content: "";
	height: 0;
	border-style: solid;
	border-width: 12px 6px 0;
	border-color: #764c29 transparent transparent;
	top: 35px;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	border-radius: 50%;
	z-index: 1;
}
.face {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	text-align: center;
	border-radius:30px;
	color: #fff;
	z-index: 100;
}
.face i {
	line-height: 34px;
	font-size: 20px;
}
.face.front {
	background-color: #fff;
	border: 2px solid #764c29;
	color: #764c29;
	z-index: 100;
}
.face.back {
	transform: rotateY(180deg);
	box-sizing: border-box;
	background: #764c29;
	border-color: #764c29;
	color: #fff;
}
.face.back i {
	trasform: scaleX(-1);
	line-height: 38px;
	font-size: 18px;
}
.face.back:after {
	content: "";
	display: block;
	width: 36px;
	height: 36px;
	top: 2px;
	left: 2px;
	border-radius: 50%;
	position: absolute;
}
.btn_center_item{
	display:block !important;
	text-align:center;
	margin:0 auto !important;
}
.custom-zoom-in, .custom-zoom-out {
	background-color: #fff;
	color: #333;
	cursor: pointer;
	border-radius: 50%;
	margin: 5px 15px;
	transition: color 0.3s, background-color 0.3s;
	box-shadow: 0px 1px 4px -1px rgba(0,0,0,0.2);
	text-align: center;
	font-size: 15px;
	height: 34px;
	width: 34px;
}
.custom-zoom-in:hover, .custom-zoom-out:hover {
	background-color: #66676b;
	color: #fff;
}
.custom-zoom-in:before, .custom-zoom-out:before {
	font-family: "FontAwesome";
	width: 100%;
	line-height: 35px;
	transition: -webkit-text-stroke 0.3s;
	-webkit-text-stroke: 1px #fff;
}
.zoomControlWrapper {
	position: absolute;
	right: 0;
	right: auto;
	width: 70px;
}
.custom-zoom-in:before {
	content: "\f067";
}
.custom-zoom-out:before {
	content: "\f068";
}
.custom-zoom-in:hover:before, .custom-zoom-out:hover:before {
	-webkit-text-stroke: 1px #66676b;
}
#mapnav-buttons {
	position: absolute;
	transform: translate(0, 0);
	z-index: 999;
	font-size: 14px;
	display: inline-block;
	bottom: 20px;
	right: 20px;
	list-style: none;
	padding: 0;
}
#mapnav-buttons.top {
	top: 20px;
	right: 20px;
	bottom: auto;
}
#mapnav-buttons li {
	float: left;
	margin-left: 4px;
	line-height: 20px;
}
#utf_street_view_btn, #geoLocation, #scrollEnabling, #mapnav-buttons a {
	color: #333;
	background-color: #fff;
	padding: 7px 18px;
	padding-top: 9px;
	font-weight: 500;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-transition: all 0.2s ease-in-out;
	box-sizing: border-box;
	display: inline-block;
	border-radius: 4px;
	box-shadow: 0px 1px 4px -1px rgba(0,0,0,0.2);
}
button.gm-control-active{
	border-radius:30px !important;
}
#geoLocation:hover, #utf_street_view_btn:hover, #scrollEnabling:hover, #scrollEnabling.enabled, #mapnav-buttons a:hover {
	background-color: #764c29;
	color: #fff;
}
#prevpoint:before, #nextpoint:after {
	font-family: "FontAwesome";
	position: relative;
	font-weight: 500;
	margin: 0 0 0 6px;
	font-size: 17px;
	top: 0px;
	line-height: 1px;
}
#prevpoint:before {
	content: "\f104";
	margin: 0 6px 0 0;
}
#nextpoint:after {
	content: "\f105";
	margin: 0 0 0 6px;
}
#utf_street_view_btn, #geoLocation, #scrollEnabling {
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 999;
	font-size: 13px;
	line-height: 21px;
}
#utf_street_view_btn:before, #geoLocation:before, #scrollEnabling:before {
	content: "\e015";
	font-family: "simple-line-icons";
	position: relative;
	top: 2px;
	margin: 0 6px 0 0;
	font-size: 15px;
	line-height: 1px;
}
#scrollEnabling:before {
	margin-left: -3px;
}
#utf_street_view_btn:before {
	content: "\f21d";
	font-family: "FontAwesome";
	font-size: 16px;
	top: 1px;
	margin-right: 8px;
}
#geoLocation {
	right: auto;
	left: 20px;
	padding: 8px 11px;
}
#geoLocation:before {
	content: "\f192";
	font-family: "FontAwesome";
	font-size: 16px;
	margin: 0;
	top: 2px;
}
@-webkit-keyframes passing-through {
0% {
opacity:0;
-webkit-transform:translateY(40px);
-moz-transform:translateY(40px);
-ms-transform:translateY(40px);
-o-transform:translateY(40px);
transform:translateY(40px);
}
30%, 70% {
opacity:1;
-webkit-transform:translateY(0px);
-moz-transform:translateY(0px);
-ms-transform:translateY(0px);
-o-transform:translateY(0px);
transform:translateY(0px);
}
100% {
opacity:0;
-webkit-transform:translateY(-40px);
-moz-transform:translateY(-40px);
-ms-transform:translateY(-40px);
-o-transform:translateY(-40px);
transform:translateY(-40px);
}
}
@-moz-keyframes passing-through {
0% {
opacity:0;
-webkit-transform:translateY(40px);
-moz-transform:translateY(40px);
-ms-transform:translateY(40px);
-o-transform:translateY(40px);
transform:translateY(40px);
}
30%, 70% {
opacity:1;
-webkit-transform:translateY(0px);
-moz-transform:translateY(0px);
-ms-transform:translateY(0px);
-o-transform:translateY(0px);
transform:translateY(0px);
}
100% {
opacity:0;
-webkit-transform:translateY(-40px);
-moz-transform:translateY(-40px);
-ms-transform:translateY(-40px);
-o-transform:translateY(-40px);
transform:translateY(-40px);
}
}
@keyframes passing-through {
0% {
opacity:0;
-webkit-transform:translateY(40px);
-moz-transform:translateY(40px);
-ms-transform:translateY(40px);
-o-transform:translateY(40px);
transform:translateY(40px);
}
30%, 70% {
opacity:1;
-webkit-transform:translateY(0px);
-moz-transform:translateY(0px);
-ms-transform:translateY(0px);
-o-transform:translateY(0px);
transform:translateY(0px);
}
100% {
opacity:0;
-webkit-transform:translateY(-40px);
-moz-transform:translateY(-40px);
-ms-transform:translateY(-40px);
-o-transform:translateY(-40px);
transform:translateY(-40px);
}
}
@-webkit-keyframes slide-in {
0% {
opacity:0;
-webkit-transform:translateY(40px);
-moz-transform:translateY(40px);
-ms-transform:translateY(40px);
-o-transform:translateY(40px);
transform:translateY(40px);
}
30% {
opacity:1;
-webkit-transform:translateY(0px);
-moz-transform:translateY(0px);
-ms-transform:translateY(0px);
-o-transform:translateY(0px);
transform:translateY(0px);
}
}
@-moz-keyframes slide-in {
0% {
opacity:0;
-webkit-transform:translateY(40px);
-moz-transform:translateY(40px);
-ms-transform:translateY(40px);
-o-transform:translateY(40px);
transform:translateY(40px);
}
30% {
opacity:1;
-webkit-transform:translateY(0px);
-moz-transform:translateY(0px);
-ms-transform:translateY(0px);
-o-transform:translateY(0px);
transform:translateY(0px);
}
}
@keyframes slide-in {
0% {
opacity:0;
-webkit-transform:translateY(40px);
-moz-transform:translateY(40px);
-ms-transform:translateY(40px);
-o-transform:translateY(40px);
transform:translateY(40px);
}
30% {
opacity:1;
-webkit-transform:translateY(0px);
-moz-transform:translateY(0px);
-ms-transform:translateY(0px);
-o-transform:translateY(0px);
transform:translateY(0px);
}
}
@-webkit-keyframes pulse {
0% {
-webkit-transform:scale(1);
-moz-transform:scale(1);
-ms-transform:scale(1);
-o-transform:scale(1);
transform:scale(1);
}
10% {
-webkit-transform:scale(1.1);
-moz-transform:scale(1.1);
-ms-transform:scale(1.1);
-o-transform:scale(1.1);
transform:scale(1.1);
}
20% {
-webkit-transform:scale(1);
-moz-transform:scale(1);
-ms-transform:scale(1);
-o-transform:scale(1);
transform:scale(1);
}
}
@-moz-keyframes pulse {
0% {
-webkit-transform:scale(1);
-moz-transform:scale(1);
-ms-transform:scale(1);
-o-transform:scale(1);
transform:scale(1);
}
10% {
-webkit-transform:scale(1.1);
-moz-transform:scale(1.1);
-ms-transform:scale(1.1);
-o-transform:scale(1.1);
transform:scale(1.1);
}
20% {
-webkit-transform:scale(1);
-moz-transform:scale(1);
-ms-transform:scale(1);
-o-transform:scale(1);
transform:scale(1);
}
}
@keyframes pulse {
0% {
-webkit-transform:scale(1);
-moz-transform:scale(1);
-ms-transform:scale(1);
-o-transform:scale(1);
transform:scale(1);
}
10% {
-webkit-transform:scale(1.1);
-moz-transform:scale(1.1);
-ms-transform:scale(1.1);
-o-transform:scale(1.1);
transform:scale(1.1);
}
20% {
-webkit-transform:scale(1);
-moz-transform:scale(1);
-ms-transform:scale(1);
-o-transform:scale(1);
transform:scale(1);
}
}

/*==========
  Dropzone Style
============*/
.dropzone, .dropzone * {
	box-sizing: border-box;
}
.dropzone {
	min-height: 150px;
	border: 2px solid rgba(0,0,0,0.3);
	background: white;
	padding: 20px 20px;
}
.dropzone.dz-clickable {
	cursor: pointer;
}
.dropzone.dz-clickable * {
	cursor: default;
}
.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
	cursor: pointer;
}
.dropzone.dz-started .dz-message {
	display: none;
}
.dropzone.dz-drag-hover {
	border-style: solid;
}
.dropzone.dz-drag-hover .dz-message {
	opacity: 0.5;
}
.dropzone .dz-preview {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin: 10px;
	min-height: 100px;
}
.dropzone .dz-preview:hover {
	z-index: 1000;
}
.dropzone .dz-preview:hover .dz-details {
	opacity: 1;
}
.dropzone .dz-preview.dz-file-preview .dz-image {
	border-radius: 3px;
	background: #999;
	background: linear-gradient(to bottom, #eee, #ddd);
}
.dropzone .dz-preview.dz-file-preview .dz-details {
	opacity: 1;
}
.dropzone .dz-preview.dz-image-preview {
	background: white;
}
.dropzone .dz-preview.dz-image-preview .dz-details {
	-webkit-transition: opacity 0.2s linear;
	-moz-transition: opacity 0.2s linear;
	-ms-transition: opacity 0.2s linear;
	-o-transition: opacity 0.2s linear;
	transition: opacity 0.2s linear;
}
.dropzone .dz-preview .dz-remove {
	font-size: 14px;
	text-align: center;
	display: block;
	cursor: pointer;
	border: none;
}
.dropzone .dz-preview .dz-remove:hover {
	text-decoration: underline;
}
.dropzone .dz-preview:hover .dz-details {
	opacity: 1;
}
.dropzone .dz-preview .dz-details {
	z-index: 20;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	font-size: 13px;
	min-width: 100%;
	max-width: 100%;
	padding: 2em 1em;
	text-align: center;
	color: rgba(0,0,0,0.9);
	line-height: 150%;
}
.dropzone .dz-preview .dz-details .dz-size {
	margin-bottom: 1em;
	font-size: 16px;
}
.dropzone .dz-preview .dz-details .dz-filename {
	white-space: nowrap;
}
.dropzone .dz-preview .dz-details .dz-filename:hover span {
	border: 1px solid rgba(200,200,200,0.8);
	background-color: rgba(255,255,255,0.8);
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
	overflow: hidden;
	text-overflow: ellipsis;
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
	border: 1px solid transparent;
}
.dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
	background-color: rgba(255,255,255,0.4);
	padding: 0 0.4em;
	border-radius: 3px;
}
.dropzone .dz-preview:hover .dz-image img {
	-webkit-transform: scale(1.05, 1.05);
	-moz-transform: scale(1.05, 1.05);
	-ms-transform: scale(1.05, 1.05);
	-o-transform: scale(1.05, 1.05);
	transform: scale(1.05, 1.05);
	-webkit-filter: blur(8px);
	filter: blur(8px);
}
.dropzone .dz-preview .dz-image {
	border-radius: 3px;
	overflow: hidden;
	width: 107px;
	height: 107px;
	position: relative;
	transition: all 0.3s;
	display: block;
	z-index: 10;
}
.dropzone .dz-preview .dz-image img {
	display: block;
	filter: blur(0px);
	transition: all 0.4s;
}
.dropzone .dz-preview.dz-success .dz-success-mark {
	-webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
	-moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
	-ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
	-o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
	animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview.dz-error .dz-error-mark {
	opacity: 1;
	-webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
	-moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
	-ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
	-o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
	animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
	pointer-events: none;
	opacity: 0;
	z-index: 500;
	position: absolute;
	display: block;
	top: 58%;
	left: 56%;
	margin-left: -27px;
	margin-top: -27px;
}
.dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
	display: block;
	width: 40px;
	height: 40px;
}
.dropzone .dz-preview.dz-processing .dz-progress {
	opacity: 1;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}
.dropzone .dz-preview.dz-complete .dz-progress {
	opacity: 0;
	-webkit-transition: opacity 0.4s ease-in;
	-moz-transition: opacity 0.4s ease-in;
	-ms-transition: opacity 0.4s ease-in;
	-o-transition: opacity 0.4s ease-in;
	transition: opacity 0.4s ease-in;
}
.dropzone .dz-preview:not(.dz-processing) .dz-progress {
	-webkit-animation: pulse 6s ease infinite;
	-moz-animation: pulse 6s ease infinite;
	-ms-animation: pulse 6s ease infinite;
	-o-animation: pulse 6s ease infinite;
	animation: pulse 6s ease infinite;
}
.dropzone .dz-preview .dz-progress {
	opacity: 1;
	z-index: 1000;
	pointer-events: none;
	position: absolute;
	height: 16px;
	left: 50%;
	top: 50%;
	margin-top: -8px;
	width: 80px;
	margin-left: -40px;
	background: rgba(255,255,255,0.9);
	-webkit-transform: scale(1);
	border-radius: 8px;
	overflow: hidden;
}
.dropzone .dz-preview .dz-progress .dz-upload {
	background: #333;
	background: linear-gradient(to bottom, #666, #444);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 0;
	-webkit-transition: width 300ms ease-in-out;
	-moz-transition: width 300ms ease-in-out;
	-ms-transition: width 300ms ease-in-out;
	-o-transition: width 300ms ease-in-out;
	transition: width 300ms ease-in-out;
}
.dropzone .dz-preview.dz-error .dz-error-message {
	display: block;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
	opacity: 1;
	pointer-events: auto;
}
.dropzone .dz-preview .dz-error-message {
	pointer-events: none;
	z-index: 1000;
	position: absolute;
	display: block;
	display: none;
	opacity: 0;
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	-ms-transition: opacity 0.3s ease;
	-o-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
	font-size: 13px;
	top: 130px;
	left: -10px;
	width: 140px;
	background: #764c29;
	background: linear-gradient(to bottom, #764c29, #764c29);
	border-radius: 4px;
	padding: 10px 15px;
	line-height: 18px;
	color: white;
}
.dropzone .dz-preview .dz-error-message:after {
	content: '';
	position: absolute;
	top: -6px;
	left: 64px;
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #764c29;
}
.dropzone {
	position: relative;
	border: 2px dashed #d0d0d0;
	border-radius: 16px;
	background: #fcfcfc;
	transition: all 0.3s linear;
	margin-bottom: 10px;
	margin-top: 5px;
	display: inline-block;
	width: 100%;
}
.dropzone:hover {
	border: 2px dashed #764c29;
	background: #fff;
	transition: all 0.35s linear;
}
.dropzone:before {
	position: absolute;
	height: 100%;
	width: 100%;
	content: "";
	top: 0;
	left: 0;
	background: linear-gradient(to bottom, rgba(255,255,255,0.95), rgba(255,255,255,0.9));
	background-color: #59b02c;
	opacity: 0;
	z-index: 10;
	transition: all 0.3s linear;
	border-radius: 3px;
}
.dropzone:hover:before {
	opacity: 0.4;
}
.dropzone .dz-message {
	text-align: center;
	margin: 50px 0;
	font-size: 17px;
	color: #888;
	transition: all 0.3s linear;
	position: relative;
	z-index: 11;
}
.dropzone:hover .dz-message {
	color: #764c29;
}
.dropzone:hover .dz-message i {
	border: 2px solid #764c29;
	width: 80px;
    height: 80px;
    line-height: 36px;
	border-radius: 50px;
}
.dz-message i {
	border: 2px solid #888;
    padding: 20px;
    display: block;
    font-size: 36px;
    margin-bottom: 15px;
    width: 80px;
    height: 80px;
    line-height: 36px;
    display: block;
	margin-left:auto;
	margin-right:auto;
    border-radius: 50px;
}
.dropzone.dz-clickable .dz-message span{
	margin-left:auto;
	margin-right:auto;
	display:inline-block;
	text-align:center;
}
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2000;
	overflow: hidden;
	position: fixed;
	background: #000;
	opacity: 0.96;
}
.mfp-wrap {
	top: -10px;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2001;
	position: fixed;
	outline: none!important;
	-webkit-backface-visibility: hidden;
}
.mfp-container {
	height: 100%;
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 18px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.mfp-container:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
	display: none;
}
.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 505;
}
.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto;
}
.mfp-ajax-cur {
	cursor: progress;
}
.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}
.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
	cursor: auto;
	overflow-x: hidden;
}
.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.mfp-loading.mfp-figure {
	display: none;
}
.mfp-hide {
	display: none!important;
}
.mfp-preloader {
	color: #aaa;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8em;
	left: 8px;
	right: 8px;
	z-index: 504;
}
.mfp-preloader a {
	color: #cccccc;
}
.mfp-preloader a:hover {
	color: #fff;
}
.mfp-s-ready .mfp-preloader {
	display: none;
}
.mfp-s-error .mfp-content {
	display: none;
}
.mfp-s-loading .mfp-arrow {
	display: none;
}
button.mfp-close, button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	border: 0;
	-webkit-appearance: none;
	display: block;
	padding: 0;
	z-index: 506;
}
.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
	right: 0px;
	top: -40px;
}
.og-close, button.mfp-close {
	position: absolute;
	width: 40px;
	height: 40px;
	top: -20px;
	display: block;
	right: -12px;
	cursor: pointer!important;
	z-index: 9999;
	color: #fff;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	border-radius:6px;
	margin: 0px;
	background-color: rgba(255, 255, 255, 0.2);
	outline: none;
	transform: translate3d(0, 0, 0);
}
.mfp-iframe-holder .mfp-close {
	top: -50px;
	right: -12px;
	transform: translate3d(0, 0, 0);
}
.utf_gallery .mfp-close {
	right: 10px;
	left: 0;
	top: 17px;
	margin: 0 auto;
}
.utf_gallery .mfp-content .mfp-close {
	display: none;
}
.utf_gallery .mfp-close:after, .utf_gallery .mfp-close:before {
	font-family: "FontAwesome";
	font-size: 29px;
	top: 2px;
	left: 50%;
	position: absolute;
	height: 10px;
	transition: 0.3s;
}
.mfp-close::after, .mfp-close::before {
	top: 0px;
}
.og-close {
	margin: 17px;
	right: 10px;
	background: transparent;
	color: #222;
}
.mfp-close:hover, .og-close:hover {
	transform: rotate(90deg);
}
.og-close:after, .og-close:before, .mfp-close:after, .mfp-close:before {
	font-family: "FontAwesome";
	font-size: 29px;
	top: 21px;
	left: 50%;
	position: absolute;
	height: 10px;
	transition: 0.3s;
}
.og-close:after, .og-close:before {
	top: 7px;
	font-size: 29px;
}
.og-close:after, .mfp-close:after {
	content: "\f106";
	-webkit-transform: translate(0, 0);
	transform: translate(-50%, 60%);
}
.og-close:after {
	-webkit-transform: translate(0, 0);
	transform: translate(-50%, 60%);
}
.og-close:before, .mfp-close:before {
	content: "\f107";
	-webkit-transform: translate(0, 0);
	transform: translate(-50%, 0%);
}
#small-dialog .mfp-close, #dialog_signin_part, .mfp-close, .mfp-close:hover {
	color: #fff;
}
#dialog_signin_part .mfp-close, #small-dialog .mfp-close {
	color: #764c29;
	background-color:#fff;
	border-radius: 30px;
	top: 20px;
	right: 20px;
	width: 34px;
	height: 34px;
}
#dialog_signin_part .mfp-close:hover, #small-dialog .mfp-close:hover {
	color: #666;
	transform: rotate(0);
	background-color: #e4e4e4 !important;
}
#dialog_signin_part .mfp-close::after, #dialog_signin_part .mfp-close::before, #small-dialog .mfp-close::after, #small-dialog .mfp-close::before {
	top: 0px;
	height: 9px;
	font-size: 21px;
	transition: 0s;
}
.small-dialog-content input[type=submit] {
	width: 100%;
	margin-top: 26px;
	margin-bottom: 10px;
}
.small-dialog-content .divider {
	display: inline-block;
	width: 100%;
	margin-top: 15px;
	padding-bottom: 0;
}
.small_dialog_header {
	font-size: 22px;
	width: calc(100% + 40px);
	position: relative;
	left: -20px;
	top: 0;
	border-radius: 4px 4px 0 0;
	display: inline-block;
	background-color: #764c29;
	padding: 25px;
	margin-bottom: 20px;
}
.small_dialog_header h3 {
	font-weight:500;
	padding: 0;
	margin: 0;
	color:#fff;
	font-size: 20px;
}
.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #aaa;
	font-size: 13px;
	line-height: 18px;
}
.mfp-arrow {
	opacity: 1;
	margin: 0 20px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 9999;
	color: #fff;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	width: 44px;
	height: 44px;
	position: absolute;
	display: block;
	z-index: 100;
	overflow: hidden;
	background: rgba(255,255,255,0.15);
	border-radius:6px;
	outline: none;
}
.mfp-arrow:hover {
	background: #764c29;
	color: #fff;
}
.mfp-arrow.mfp-arrow-left, .mfp-arrow.mfp-arrow-right {
	font-family: "simple-line-icons";
	font-size: 20px;
}
.mfp-arrow.mfp-arrow-right {
	right: 15px;
}
.mfp-arrow.mfp-arrow-left:before {
	content: "\e605";
	left: -1px;
	top: 0px;
	position: relative;
}
.mfp-arrow.mfp-arrow-right:before {
	content: "\e606";
	left: 1px;
	top: 0px;
	position: relative;
}
.mfp-content:hover .mfp-arrow {
	opacity: 1;
}
.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 1180px;
}
.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 54%;
}
.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0,0,0,0.6);
	background: black;
}
#small-dialog .utf_message_reply_block, #small-dialog .utf_message_reply_block button{
	text-align:center;
	margin-right:0
}
#small-dialog .utf_message_reply_block button{
	line-height:26px;
}
#dialog_signin_part, #small-dialog {
	background: #ffffff;
	padding:30px 20px;
	padding-top: 0;
	text-align: left;
	max-width: 400px;
	margin: 20px auto;
	position: relative;
	box-sizing: border-box;
	border-radius: 4px;
}
#dialog_signin_part {
	max-width: 450px;
}
#dialog_signin_part span.line, #small-dialog span.line {
	padding-top: 0;
}
.my-mfp-zoom-in .zoom-anim-dialog {
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
}
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0;
}
.my-mfp-zoom-in.mfp-bg {
	opacity: 0.001;
	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}
.my-mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.8;
}
.my-mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0;
}
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 40px auto;
	border-radius: 30px;
	border:6px solid rgba(255, 255, 255, 0.2);
}
.mfp-figure:after {
	content: '';
	position: absolute;
	left: 0;
	top: 40px;
	bottom: 40px;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	box-shadow: 0 0 18px rgba(11,11,11,0.6);
}
.mfp-figure {
	line-height: 0;
}
.mfp-bottom-bar {
	margin-top: -30px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
}
.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #f3f3f3;
	word-break: break-word;
	padding-right: 36px;
}
.mfp-figure small {
	color: #bdbdbd;
	display: block;
	font-size: 12px;
	line-height: 14px;
}
.mfp-image-holder .mfp-content {
	max-width: 100%;
}
.utf_gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
.mfp-img-mobile .mfp-image-holder {
	padding-left: 0;
	padding-right: 0;
}
.mfp-img-mobile img.mfp-img {
	padding: 0
}
.mfp-img-mobile .mfp-figure:after {
	top: 0;
	bottom: 0;
}
.mfp-img-mobile .mfp-bottom-bar {
	background: rgba(0,0,0,0.6);
	bottom: 0;
	margin: 0;
	top: auto;
	padding: 3px 5px;
	position: fixed;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.mfp-img-mobile .mfp-bottom-bar:empty {
	padding: 0;
}
.mfp-img-mobile .mfp-counter {
	right: 5px;
	top: 3px;
}
.mfp-img-mobile .mfp-close {
	top: 0;
	right: 0;
	width: 35px;
	height: 35px;
	line-height: 35px;
	background: rgba(0,0,0,0.6);
	position: fixed;
	text-align: center;
	padding: 0;
}
.mfp-img-mobile .mfp-figure small {
	display: inline;
	margin-left: 5px;
}
}
.mfp-ie7 .mfp-img {
	padding: 0;
}
.mfp-ie7 .mfp-bottom-bar {
	width: 600px;
	left: 50%;
	margin-left: -300px;
	margin-top: 5px;
	padding-bottom: 5px;
}
.mfp-ie7 .mfp-container {
	padding: 0;
}
.mfp-ie7 .mfp-content {
	padding-top: 44px;
}
.mfp-ie7 .mfp-close {
	top: 0;
	right: 0;
	padding-top: 0;
}
.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.92;
	background-color: #111;
}
.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}
.parallax {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	position: relative;
	z-index: 99;
}
.fullscreen, .content-a {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.fullscreen.overflow, .fullscreen.overflow .content-a {
	height: auto;
	min-height: 100%;
}
.centered_content {
	position: relative;
	vertical-align: middle;
	text-align: center;
}
.centered_content a.button.border{
	font-weight:600;
	margin-right:0;
}
.parallax-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 101;
	background-color: #333;
	opacity: 0.4;
}
.parallax-content {
	position: relative;
	z-index: 999;
	padding: 105px 0;
}

/*==========
  Messages Style
============*/

.messages-container {
	margin: 0;
	box-shadow: 0 0 12px 0 rgba(0,0,0,.06);
	border-radius: 4px;
	background-color: #fff
}
.messages-headline {
	font-size: 16px;
	margin: 0;
	padding: 22px 30px;
	color: #333;
	background-color: #fff;
	display: block;
	border-bottom: 1px solid #eaeaea;
	border-radius: 4px 4px 0 0;
	position: relative
}
.messages-headline h4 {
	font-size: 16px;
	color: #333;
	padding: 0;
	margin: 0;
	font-weight: 600;
	display: inline-block
}
.utf_dashboard_list_box a.message-action {
	border-radius: 50px;
	font-size: 14px;
	font-weight: 500;
	display: block;
	transition: .3s;
	color: #fff;
	padding: 0;
	float: right;
	position: absolute;
	top: 28px;
	right: 40px;
}
.utf_dashboard_list_box a.message-action i{
	margin-right:3px;
	background:#764c29;
	width:28px;
	height:28px;
	line-height:28px;
	color:#fff;
	border-radius:30px;
	text-align:center;
}
.utf_dashboard_list_box .message-action:hover {
	color: #764c29
}
@media (max-width: 767px){
.utf_dashboard_list_box a.message-action{
	display:none;
}
}
.utf_user_messages_block ul {
	list-style: none;
	padding: 0;
	margin: 0
}
.utf_message_user img {
	display: inline-block;
	width: 60px;
	height: 60px;
	border-radius: 50px
}
.utf_user_messages_block .online::after {
    background: #63c76a;
}
.utf_message_content_item .online::after {
    background:transparent;
}
.utf_message_content_item .utf_message_user::after {
	display: none;
}
.utf_message_user::after {
	background: #63c76a;
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    background: #ccc;
    border: 2px solid #fff;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
}
.utf_user_messages_block ul li a {
	position: relative;
	display: block;
	padding:0px;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}
.utf_user_messages_block ul li a:hover h5{
	color:#764c29;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}
.utf_user_messages_block ul li {
	border-bottom: 1px solid #eaeaea;
	transition: .2s
}
.utf_user_messages_block ul li:last-child {
	border-bottom: none
}
.utf_user_messages_block .utf_message_user {
	position: absolute;
	left:0px;
	top: 45%;
	transform: translateY(-50%);
	border: 5px solid rgba(0, 0, 0, 0.06);
	border-radius: 50px;
}
.utf_user_messages_block .utf_message_headline_item {
	margin-left: 90px;
	font-size: 16px;
	color: #666
}
.utf_user_messages_block .utf_message_headline_item p {
	margin: 0;
	padding: 0;
	line-height: 25px;
	font-size: 15px;
	color:#666;
}
.utf_message_headline_text h5, .utf_message_headline_text span {
	display:block
}
.utf_message_headline_text span {
	float: left;
	color: #888;
	font-size: 13px;
	font-weight:600;
	margin-bottom: 10px;
	line-height: 24px;
}
.utf_message_headline_text span i{
	color:#764c29;
    font-size: 14px;
    margin-right: 2px;
}
.utf_message_headline_text h5 {
	font-size:16px;
	font-weight: 600;
	color: #333;
	margin:0 0 5px 0;
	padding:0;
}
.utf_message_headline_text:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0
}
.utf_message_headline_item h5 i {
	background-color: #64bc36;
	border-radius: 4px;
	line-height: 20px;
	font-size: 11px;
	font-weight:500;
	color: #fff;
	font-style: normal;
	padding: 3px 8px;
	margin-left: 3px
}
.utf_messages_block_inner {
	display: flex
}
.utf_messages_block_inner .utf_user_messages_block, .utf_messages_block_inner .utf_message_content_part {
	flex: 1
}
.utf_messages_block_inner .utf_user_messages_block {
	overflow: hidden
}
.utf_messages_block_inner .utf_user_messages_block ul {
	max-height:800;
	overflow-y: scroll;
	width: 357px
}
.utf_messages_block_inner .utf_user_messages_block ul::-webkit-scrollbar {
	width:17px
}
.utf_messages_block_inner .utf_message_content_part {
	padding: 30px
}
.utf_messages_block_inner .utf_user_messages_block {
	max-width: 340px;
	border-right: 1px solid #eaeaea
}
.utf_messages_block_inner .utf_user_messages_block .utf_message_headline_item p {
	height: 24px;
	max-width: 230px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	font-size: 15px;
	color:#666
}
.utf_messages_block_inner .utf_user_messages_block .utf_message_headline_item {
	margin-left: 90px
}
.utf_messages_block_inner .utf_message_headline_text h5 {
	font-size: 16px;
	margin-bottom: 3px;
	max-width: 240px;
	line-height: 22px;
	display: block;
}
.utf_messages_block_inner .utf_message_headline_text span {
	font-size: 13px;
	position: relative;
	margin-bottom: 10px;
	line-height: 24px;
}
.utf_message_content_item {
	display: block;
	position: relative;
	margin-bottom: 25px
}
.utf_message_content_item .utf_message_user {
	position: absolute;
	left: 0;
	top: 0;
	border: 5px solid rgba(0, 0, 0, 0.06);
	border-radius:50px;
}
.utf_message_content_item .utf_message_user img {
	width: 50px;
	height: 50px
}
.utf_message_content_item .utf_message_text {
	margin-left: 85px;
	background-color:transparent;
	border:1px solid rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	padding: 20px;
	position: relative
}
.utf_message_content_item .utf_message_text p {
	font-size: 15px;
	color:#666;
	padding: 0;
	margin: 0;
	line-height: 26px
}
.utf_message_content_item .utf_message_text:before {
	position: absolute;
	top: 15px;
	left: -11px;
	content: "\f0d9";
	font-family: fontawesome;
	font-size: 30px;
	color: #eaeaea
}
.utf_message_content_item.send_user .utf_message_user {
	left: auto;
	right: 0
}
.utf_message_content_item.send_user .utf_message_text p{
	color: #666;
}
.utf_message_content_item.send_user .utf_message_text {
	margin-left: 0;
	margin-right: 85px;
	color: #764c29;
    background-color:transparent;
	border:1px solid #eaeaea;
}
.utf_message_content_item.send_user .utf_message_text:before {
	left: auto;
	right: -11px;
	content: "\f0da"
}
.utf_message_reply_block {
	display: block;
	margin-top: 15px
}
@media (max-width:479px){
.utf_messages_block_inner .utf_message_content_part {
    padding: 20px 15px;
}
}
.utf_message_reply_block .button{
	background-color: #764c29;
	top: 0;
	padding: 6px 15px;
	color: #fff;
	position: relative;
	font-size: 14px;
	font-weight: 500;
	display:block;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	line-height:26px;
	overflow: hidden;
	border: none;
	border-radius: 4px;
	text-align: center;
	margin: 0 auto;
}
.utf_message_reply_block textarea {
	max-width: 100%;
	width: 100%;
	font-size: 15px;
	font-weight: 400;
	line-height: 26px;
	padding: 15px 20px
}
.utf_message_reply_block button {
	border-radius: 4px;
	margin-top: 5px
}

/*==========
  Slick Slider Style
============*/
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}
.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
}
.slick-list:focus {
	outline: none;
}
.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}
.slick-slider .slick-track, .slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
}
.slick-track:before, .slick-track:after {
	display: table;
	content: '';
}
.slick-track:after {
	clear: both;
}
.slick-loading .slick-track {
	visibility: hidden;
}
.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}
.slick-slide img {
	display: block;
}
.slick-slide.slick-loading img {
	display: none;
}
.slick-slide.dragging img {
	pointer-events: none;
}
.slick-initialized .slick-slide {
	display: block;
}
.slick-loading .slick-slide {
	visibility: hidden;
}
.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}
.utf_testimonial_part{
    position: relative;
    content: '';
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: inline-block;
    background:#f9f9f9 url(../images/testimonial.png) no-repeat;
	background-size: contain;
}
.slick-arrow.slick-hidden {
	display: none;
}
.companie-logo-slick-carousel, .utf_testimonial_carousel, .simple_slick_carousel_block, .simple-slick-carousel {
	display: none;
}
.slick-initialized.companie-logo-slick-carousel, .slick-initialized.utf_testimonial_carousel, .slick-initialized.simple_slick_carousel_block, .slick-initialized.simple-slick-carousel {
	display: block;
}
.slick-initialized.simple_slick_carousel_block{
	margin-bottom:30px;
}
.simple_slick_carousel_block .utf_carousel_item {
	padding: 10px 15px 0 15px;
}
.simple_slick_carousel_block {
	margin: 0;
	max-width: 100%;
	box-sizing: border-box;
}
.slick-prev, .slick-next {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	z-index: 100;
	display: block;
	width: auto;
	height: auto;
	padding: 0;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;
}
.companie-logo-slick-carousel .slick-prev, .companie-logo-slick-carousel .slick-next, .simple-slick-carousel .slick-prev, .simple-slick-carousel .slick-next {
	display: none!important;
}
.simple-slick-carousel.arrows-nav .slick-prev, .simple-slick-carousel.arrows-nav .slick-next {
	display: block!important;
}
.slick-prev:hover, .slick-next:hover {
	color: transparent;
	outline: none;
	background: transparent;
}
.slick-prev:hover:before, .slick-next:hover:before {
	color: #999;
}
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
	opacity: .25;
}
.slick-prev:before, .slick-next:before {
	font-family: 'simple-line-icons';
	font-size: 32px;
	line-height: 1;
	opacity: 1;
	color: #aaa;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition: all 0.3s;
}
.white-nav .slick-prev:before, .white-nav .slick-next:before {
	color: #fff;
}
.slick-prev {
	left: 3px;
	transform: translate3d(-90px, -50%, 0);
}
.slick-next {
	right: 3px;
	transform: translate3d(90px, -50%, 0);
}
.slick-prev:before {
	content: '\e605';
}
.slick-next:before {
	content: '\e606';
}
.slick-dotted .slick-slider {
	margin-bottom: 30px;
}
.slick-dots {
	position: absolute;
	bottom: -30px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
}
.utf_testimonial_carousel .slick-dots{
	bottom:-5px;
}
.simple-slick-carousel .slick-dotted .slick-slider {
	margin-bottom: 0;
}
.simple-slick-carousel .slick-dotted .slick-slider .utf_dots_nav {
	margin-bottom: 30px;
}
.simple-slick-carousel .slick-dots {
	display: none!important;
}
.simple-slick-carousel.utf_dots_nav .slick-dots {
	display: block!important;
}
.slick-dots li {
	position: relative;
	display: inline-block;
	padding: 0;
	margin: 0 3px;
	cursor: pointer;
}
.slick-dots li {
	font-size: 0;
	line-height: 0;
	cursor: pointer;
	color: transparent;
	border: 0;
	position: relative;
	outline: none;
	display: inline-block;
	width: 16px;
	height: 16px;
	margin: 0 4px;
	opacity: 1;
	border-radius:30px;
	line-height: 0;
	box-shadow: inset 0 0 0 2px #c0c0c0;
	transition: all 0.3s;
}
.slick-dots li.slick-active {
	box-shadow: inset 0 0 0 6px #764c29;
	transition: all 0.3s;
}
.slick-dots li:after {
	display: block;
	height:auto;
	width: auto;
	content: "";
	position: absolute;
	top: 1px;
	left: 1px;
	background-color: transparent;
	border-radius: 50%;
	transition: all 0.3s;
	opacity: 0;
	background-color: #764c29;
}
.slick-dots li.slick-active:after {
	opacity: 1;
}
.white-nav .slick-dots li {
	box-shadow: inset 0 0 0 2px #fff;
}
.white-nav .slick-dots li.slick-active {
	box-shadow: inset 0 0 0 8px #fff;
}
.slick-dots li button {
	display: none;
}
.slick-dots li button:hover, .slick-dots li button:focus {
	outline: none;
}
.companie-logo-slick-carousel div.item {
	margin-bottom: 30px;
	text-align: center;
	display: inline-block;
}
.companie-logo-slick-carousel div.item img {
	display: inline-block;
	border:1px solid rgba(0, 0, 0, 0.05);
	border-radius:6px;
	box-shadow:0 2px 12px rgba(0, 0, 0, 0.15);
}
.fullwidth_block-slick-carousel .slick-slide {
	margin: 0 15px 30px 15px;
	opacity: 0.2;
	transition: 0.4s;
	pointer-events: none;
}
.slick-slide {
	outline: none;
}
.utf_testimonial_carousel .slick-slide.slick-active, .fullwidth_block-slick-carousel .slick-slide.slick-active {
	opacity: 1;
	filter: none;
	pointer-events: all;
}
.fullwidth_carousel_container_block {
	overflow: hidden;
	width: 100%;
}
input#date-picker {
	border: none;
	cursor: pointer;
	border-radius: 5px;
	box-shadow: 0 1px 6px 0 rgba(0,0,0,.1);
	font-size: 16px;
	font-weight: 400;
	height: auto;
	padding: 10px 16px;
	line-height: 30px;
	margin: 0 0 15px;
	position: relative;
	background-color: #fff;
	text-align: left;
	color: #888;
	transition: color .3s!important
}
input#date-picker:hover {
	color: #888
}
.daterangepicker {
	position: absolute;
	color: inherit;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 1px 6px 0 rgba(0,0,0,.12);
	width: 278px;
	max-width: none;
	padding: 0;
	margin-top: 14px;
	top: 100px;
	left: 20px;
	z-index: 990;
	display: none;
	font-size: 15px;
	line-height: 1em;
	transition: opacity .4s, transform .4s, visibility .4s;
	opacity: 0;
	transform: translateY(-15px)
}
.daterangepicker.bordered-style {
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	box-shadow: 0 0 8px 0 rgba(0,0,0,.08);
	margin-top: 4px;
	transform: none!important
}
.daterangepicker.bordered-style.opensleft:after {
	display: none
}
.daterangepicker.calendar-animated {
	opacity: 0;
	transform: translateY(-15px)
}
.daterangepicker.calendar-hidden {
	display: block!important;
	opacity: 0;
	pointer-events: none;
	visibility: hidden;
	transform: translateY(-15px)
}
.daterangepicker.calendar-visible {
	opacity: 1;
	pointer-events: all;
	visibility: visible;
	transform: translateY(0)
}
.daterangepicker:before, .daterangepicker:after {
	position: absolute;
	display: inline-block;
	border-bottom-color: rgba(0,0,0,.2);
	content: ''
}
.daterangepicker:after {
	top: -7px;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent
}
.daterangepicker.opensleft:before {
	right: 9px
}
.daterangepicker.opensleft:after {
	right: 50px
}
.daterangepicker.openscenter:before {
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto
}
.daterangepicker.openscenter:after {
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto
}
.daterangepicker.opensright:before {
	left: 9px
}
.daterangepicker.opensright:after {
	left: 50px
}

@media(min-width:1366px) {
.daterangepicker.opensleft:after {
	right: 154px
}
.daterangepicker.single:before {
	content: "";
	height:auto;
	position: absolute;
	width: 100%;
	top:0;
	background-color: #fff;
	left: 0;
	transition: all .3s;	
}
.daterangepicker.single {
	width: 309px;
	border-radius:6px;
	margin-top: 3px;
	transform: translateY(0)!important
}
.daterangepicker.single .drp-calendar {
	width: 100%;
	zoom: 1.08;
	margin: 3px 0 3px 5px
}
.daterangepicker.single.opensleft:after {
	right: 50%;
	margin-right: -4px;
	display: none
}
}
.daterangepicker.drop-up {
	margin-top: -7px
}
.daterangepicker.drop-up:before {
	top: initial;
	bottom: -7px;
	border-bottom: initial;
	border-top: 7px solid #ccc
}
.daterangepicker.drop-up:after {
	top: initial;
	bottom: -6px;
	border-bottom: initial;
	border-top: 6px solid #fff
}
.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
	float: none
}
.daterangepicker.single .drp-selected {
	display: none
}
.daterangepicker.show-calendar .drp-calendar {
	display: block
}
.daterangepicker.show-calendar .drp-buttons {
	display: block
}
.daterangepicker.auto-apply .drp-buttons {
	display: none
}
.daterangepicker .drp-calendar {
	display: none;
	max-width: 270px
}
.daterangepicker .drp-calendar.left {
	padding: 12px 0 12px 12px
}
.daterangepicker .drp-calendar.right {
	padding: 12px
}
.daterangepicker .drp-calendar.single .calendar-table {
	border: none
}
.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
	color: #fff;
	border: solid #626262;
	border-width: 0 2px 2px 0;
	border-radius: 0;
	display: inline-block;
	padding: 3px;
	margin-left: 3px
}
.daterangepicker th.available.next:hover, .daterangepicker th.available.prev:hover {
	background: #fff
}
.daterangepicker .calendar-table .next span {
	margin-right: 6px
}
.daterangepicker .calendar-table .next span {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg)
}
.daterangepicker .calendar-table .prev span {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg)
}
.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	min-width: 32px;
	width: 32px;
	height: 24px;
	line-height: 24px;
	font-size: 14px;
	border-radius: 4px;
	border: 1px solid transparent;
	white-space: nowrap;
	cursor: pointer;
	font-weight: 500;
}
.daterangepicker .calendar-table td {
	transition: .3s
}
.daterangepicker .calendar-table td.available.in-range:hover {
	border-radius: 0 3px 3px 0
}
.daterangepicker .calendar-table td.available.start-date:hover {
	border-radius: 3px 0 0 3px
}
.daterangepicker .calendar-table {
	border: 1px solid #fff;
	border-radius: 4px;
	background-color: #fff
}
.daterangepicker .calendar-table table {
	width: 100%;
	margin: 0;
	border-spacing: 0;
	border-collapse: collapse
}
.daterangepicker td.available:hover, .daterangepicker th.available:hover {
	background-color: #764c29;
	border-color: transparent;
	color: #fff
}
.daterangepicker td.week, .daterangepicker th.week {
	font-size: 80%;
	color: #ccc
}
.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
	background-color: #fff;
	border-color: transparent;
	color: #999
}
.daterangepicker td.in-range {
	background-color: rgba(102,103,107,.08);
	border-color: transparent;
	color: #764c29;
	border-radius: 0
}
.daterangepicker td.start-date {
	border-radius: 6px 0 0 6px
}
.daterangepicker td.end-date {
	border-radius: 0 6px 6px 0
}
.daterangepicker td.start-date.end-date {
	border-radius: 4px
}
.daterangepicker td.active, .daterangepicker td.active:hover {
	background-color: #764c29;
	border-color: transparent;
	color: #fff
}
.daterangepicker th.month {
	width: auto;
	background: #764c29;
    color: #fff;
	font-weight: 500;
}
.daterangepicker td.disabled, .daterangepicker option.disabled {
	color: #999;
	cursor: not-allowed;
	text-decoration: line-through;
	background: #f4f4f4;
	border: 1px solid #fff
}
.daterangepicker select.monthselect, .daterangepicker select.yearselect {
	font-size: 12px;
	padding: 1px;
	height: auto;
	margin: 0;
	cursor: default
}
.daterangepicker td.end-date.in-range.available {
	color: #fff
}
.daterangepicker select.monthselect {
	margin-right: 2%;
	width: 56%
}
.daterangepicker select.yearselect {
	width: 40%
}
.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
	width: 50px;
	margin: 0 auto;
	background: #eee;
	border: 1px solid #eee;
	padding: 2px;
	outline: 0;
	font-size: 12px
}
.daterangepicker .calendar-time {
	text-align: center;
	margin: 4px auto 0;
	line-height: 30px;
	position: relative
}
.daterangepicker .calendar-time select.disabled {
	color: #ccc;
	cursor: not-allowed
}
.daterangepicker .drp-buttons {
	clear: both;
	text-align: right;
	padding: 14px;
	min-height: 63px;
	display: block;
	border-top: 1px solid #eaeaea;
	display: none;
	line-height: 12px;
	vertical-align: middle
}
.daterangepicker .drp-selected {
	display: inline-block;
	font-size: 14px;
	padding-right: 8px;
	font-weight:500;
}
@media(max-width:570px) {
.daterangepicker .drp-selected{
	width: 100%;
	margin-bottom: 10px;
}
}
.daterangepicker .drp-buttons button.applyBtn, .daterangepicker .drp-buttons button.cancelBtn {
	background: 0 0;
	padding: 6px 14px;
	display: inline-block;
	outline: none;
	line-height: 20px;
	border: none;
	font-weight: 500;
	margin: 0;
	font-size: 14px;
	border-radius: 50px;
	background-color: #764c29;
	color: #fff;
	transition: all .3s;
	margin: 0 3px
}
.daterangepicker .drp-buttons button.cancelBtn {
	background-color: #f4f4f4;
	color: #888
}
.daterangepicker .drp-buttons button.cancelBtn:hover {
	background-color:#eaeaea;
	color: #2d2d2d
}
.daterangepicker .drp-buttons button.applyBtn {
	background-color: #764c29
}
.daterangepicker .drp-buttons button.applyBtn:hover {
	opacity: .95
}
.daterangepicker.show-ranges .drp-calendar.left {
	border-left: 1px solid #ddd
}
.daterangepicker .ranges {
	float: none;
	text-align: left;
	margin: 0
}
.daterangepicker.show-calendar .ranges {
	margin-top: 8px
}
.daterangepicker .ranges ul {
	list-style: none;
	margin: 0 auto;
	padding: 0;
	width: 100%
}
.booking-requests-filter {
	position: relative;
	float: right;
	top: 14px;
	right: 25px;
	background: #fff;
	border-radius: 4px
}
.dashboard-list-box .booking-requests-filter .sort-by {
	right: 0;
	top: 0;
	float: left
}
#booking-date-range {
	float: right;
	background: #fff;
	border-radius: 3px;
	cursor: pointer;
	padding: 6px;
	display: inline-block;
	position: relative;
	margin: 0;
	box-sizing: border-box;
	color: #666;
	font-weight: 600;
	transition: .3s;
	background-color: #fff;
	margin-left: 15px;
	top: -2px
}

@media(max-width:768px) {
.booking-requests-filter {
	display: none
}
}
#booking-date-range:hover {
	color: #66676b
}
#booking-date-range span {
	padding-right: 7px
}
#booking-date-range span:after {
	content: "\f107";
	font-family: fontawesome;
	font-size: 18px;
	margin: 0;
	right: 0;
	position: relative;
	width: auto;
	height: auto;
	display: inline-block;
	color: #66676b;
	float: right;
	font-weight: 400;
	transition: transform .3s;
	transform: translate3d(0, 0, 0) rotate(0deg)
}
#booking-date-range i {
	color: #999;
background-color:
}
.daterangepicker .ranges li {
	font-size: 13px;
	padding: 8px 12px;
	cursor: pointer;
	transition: .3s
}
.daterangepicker .ranges li:hover {
	background-color: #f4f4f4
}
.daterangepicker .ranges li.active {
	background-color: #66676b;
	color: #fff
}

@media(min-width:564px) {
.daterangepicker {
	width: auto
}
.daterangepicker .ranges ul {
	width: 140px
}
.daterangepicker.single .ranges ul {
	width: 100%
}
.daterangepicker.single .drp-calendar.left {
	clear: none
}
.daterangepicker.single.ltr .ranges, .daterangepicker.single.ltr .drp-calendar {
	float: left
}
.daterangepicker.single.rtl .ranges, .daterangepicker.single.rtl .drp-calendar {
	float: right
}
.daterangepicker.ltr {
	direction: ltr;
	text-align: left
}
.daterangepicker.ltr .drp-calendar.left {
	clear: left;
	margin-right: 0
}
.daterangepicker.ltr .drp-calendar.left .calendar-table {
	border-right: none;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}
.daterangepicker.ltr .drp-calendar.right {
	margin-left: 0
}
.daterangepicker.ltr .drp-calendar.right .calendar-table {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}
.daterangepicker.ltr .drp-calendar.left .calendar-table {
	padding-right: 8px
}
.daterangepicker.ltr .ranges, .daterangepicker.ltr .drp-calendar {
	float: left
}
.daterangepicker.rtl {
	direction: rtl;
	text-align: right
}
.daterangepicker.rtl .drp-calendar.left {
	clear: right;
	margin-left: 0
}
.daterangepicker.rtl .drp-calendar.left .calendar-table {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}
.daterangepicker.rtl .drp-calendar.right {
	margin-right: 0
}
.daterangepicker.rtl .drp-calendar.right .calendar-table {
	border-right: none;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}
.daterangepicker.rtl .drp-calendar.left .calendar-table {
	padding-left: 12px
}
.daterangepicker.rtl .ranges, .daterangepicker.rtl .drp-calendar {
	text-align: right;
	float: right
}
}

@media(min-width:730px) {
.daterangepicker .ranges {
	width: auto
}
.daterangepicker.ltr .ranges {
	float: left
}
.daterangepicker.rtl .ranges {
	float: right
}
.daterangepicker .drp-calendar.left {
	clear: none!important
}
}
.booking_widget_box .panel-dropdown .panel-dropdown-content.padding-reset {
	padding: 0
}
.panel-dropdown-scrollable {
	max-height: 273px;
	overflow: auto;
	padding: 0 10px;
	margin: 22px 6px 16px
}
.panel-dropdown-scrollable::-webkit-scrollbar {
width:6px
}
.panel-dropdown-scrollable::-webkit-scrollbar-track {
background-color:rgba(0,0,0,.05);
border-radius:6px
}
.panel-dropdown-scrollable::-webkit-scrollbar-thumb {
border-radius:6px;
background-color:rgba(0,0,0,.2)
}
.panel-dropdown-scrollable::-webkit-scrollbar-thumb:hover {
border-radius:6px;
background-color:rgba(0,0,0,.3)
}
.panel-dropdown .panel-dropdown-content {
	pointer-events: none
}
.panel-dropdown.active .panel-dropdown-content {
	pointer-events: all
}
.time-slot {
	display: block
}
.time-slot label {
	border-radius: 3px;
	border: none;
	background-color: #f6f6f6;
	width: 100%
}
.time-slot input:empty {
	display: none
}
.time-slot label i {
	position: relative;
	font-size: 18px;
	top: 2px;
	margin-right: 2px
}
.time-slot input:empty~label {
	position: relative;
	float: left;
	padding: 10px 15px;
	text-align: left;
	cursor: pointer;
	margin-bottom:10px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-radius: 4px;
	color: #777;
	transition: .3s;
	overflow: hidden;
	font-size: 15px
}
.time-slot:last-child label {
	margin-bottom: 0
}
.time-slot input~label:hover {
	color: #ffffff;
	background-color:#764c29
}
.time-slot input:checked~label:hover {
	color: #fff
}
.time-slot input:checked~label {
	color: #fff;
	background-color: #764c29
}
.time-slot input:checked~label span {
	color: #fff;
	opacity: .8
}
.time-slotinput:empty~label:after, .time-slot input:empty~label:before {
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	left: 0;
	content: '';
	width: 100%;
	height: 100%;
	text-align: center;
	border-radius: 4px;
	background: 0 0;
	z-index: 100;
	opacity: 0
}
.time-slot label span, .time-slot label strong {
	font-weight: 400;
	display: block;
	padding: 0;
	margin: 0;
	line-height: 24px
}
.time-slot label strong span {
    float: left;
    display: inline-block;
    background: #d6d1d0;
    width: 24px;
    height: 24px;
    font-weight: 500;	
    border-radius: 20px;
    text-align: center;
    margin-right: 5px;
    color: #777;
}
.time-slot input:checked~label strong span{
	background:#ffffff;
	color:#764c29;
	opacity: 1;
}
.time-slot input~label:hover strong span{
	background:#ffffff;
	color:#764c29;
	opacity: 1;
}
.time-slot label span {
	font-weight: 400;
	opacity: .6;
	transition: .3s
}
.time-slot label:hover span {
	color: #ffffff;
	opacity: .8
}
.boxed-widget {
	background-color: #f9f9f9;
	border-radius: 3px;
	padding: 32px;
	transform: translate3d(0, 0, 0);
	z-index: 90;
	position: relative
}
.boxed-widget h3 {
	font-size: 20px;
	padding: 0 0 25px;
	margin: 0 0 25px;
	display: block;
	border-bottom: 1px solid #e8e8e8
}
.boxed-widget h3 i {
	margin-right: 4px
}
.boxed-widget h3 i.sl-icon-clock {
	position: relative;
	top: 1px
}
.boxed-widget h3 i.fa-calendar-check-o {
	position: relative;
	top: -1px
}
.booking_widget_box {
	z-index: 100
}
.booking_widget_box .panel-dropdown .panel-dropdown-content {
	border: none;
	overflow: visible;
	padding: 20px;
	box-shadow: 0 5px 6px 0 rgba(0,0,0,.1)
}
.booking_widget_box .panel-dropdown {
	width: 100%
}
.booking_widget_box .panel-dropdown .panel-dropdown-content:before {
	content: "";
	height: 8px;
	position: absolute;
	width: 100%;
	top:0px;
	background-color: #fff;
	left: 0;
	opacity: 0;
	visibility: hidden;
	transition: all .3s;
	border-bottom: 1px solid #eee
}
.booking_widget_box .panel-dropdown.active .panel-dropdown-content:before {
	opacity: 1;
	visibility: visible
}
.booking_widget_box .panel-dropdown a {
	border: none;
	cursor: pointer;
	border-radius: 5px;
	box-shadow: 0 1px 6px 0 rgba(0,0,0,.1);
	font-size: 16px;
	font-weight: 400;
	height: auto;
	padding: 10px 16px;
	line-height: 30px;
	margin: 0 0 15px;
	position: relative;
	background-color: #fff;
	text-align: left;
	color: #888;
	display: block;
	width: 100%;
	transition: color .3s
}
.booking_widget_box .panel-dropdown a:hover {
	color: #444
}
.booking_widget_box .panel-dropdown a:after {
	font-size: 20px;
	color: silver;
	margin-left: 0;
	position: absolute;
	right: 20px
}
.qtyButtons {
	display: flex;
	margin: 0 0 13px
}
.panel-dropdown-content .qtyButtons:first-child {
	margin-top: 8px
}
.panel-dropdown-content .qtyButtons:last-child {
	margin-bottom: 3px
}
.qtyButtons input {
	outline: 0;
    font-size: 18px;
    text-align: center;
    width: 80px;
    height: 40px;
    color: #333;
	font-weight:500;
    line-height: 40px;
    margin: 0 5px !important;
    padding: 10px 5px;
    border: none;
    box-shadow: none;
    pointer-events: none;
    display: inline-block;
    background: #f2f2f2;
    border-radius: 4px;
}
.qtyTitle {
	font-size: 15px;
	font-weight: 500;
	line-height: 36px;
	width:110px;
	padding-right: 15px;
	display: block;
}
.qtyInc, .qtyDec {
	width: 50px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	background-color: #f2f2f2;
	-webkit-text-stroke: 1px #f2f2f2;
	color: #333;
	display: inline-block;
	text-align: center;
	border-radius:4px;
	cursor: pointer;
	font-family: fontawesome
}
.qtyInc:hover, .qtyDec:hover{
	background:#764c29;
	color:#fff;
	font-weight:500;
}
.qtyInc:before {
	content: "\f067"
}
.qtyDec:before {
	content: "\f068"
}
.qtyTotal {
	background-color: #764c29;
	border-radius:4px;
	color: #fff;
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	text-align: center;
	position: relative;
	top: -1px;
	left: 2px;
	height: 18px;
	width: 18px
}
.rotate-x {
	animation-duration: .5s;
	animation-name: rotate-x
}
@keyframes rotate-x {
from {
	transform: rotateY(0deg);
}
to {
	transform: rotateY(360deg);
}
}
.booking-estimated-cost {
	display: block;
	margin-top: 15px;
	margin-bottom: -5px;
	padding-top: 15px;
	border-top: 1px solid #e8e8e8;
	font-weight: 16px
}
.booking-estimated-cost strong, .booking-estimated-cost span {
	display: inline;
	font-weight: 600
}
.booking-estimated-cost span {
	text-align: right;
	float: right;
	color: #66676b;
	font-size: 16px;
	position: relative
}
.booking_widget_box h3 {
	position: relative
}
.panel-dropdown .checkboxes label {
	margin-bottom: 10px;
	display: block
}
.panel-dropdown-content.checkboxes {
	padding: 28px
}
.panel-dropdown .row {
	margin-left: -10px;
	margin-right: -10px
}
button.panel-cancel, button.panel-apply {
	background: 0 0;
	padding: 5px 16px;
	display: block;
	outline: none;
	border: none;
	font-weight: 600;
	float: right;
	margin: 0;
	font-size: 15px;
	border-radius: 50px;
	background-color: #66676b;
	color: #fff;
	transition: all .3s
}
button.panel-apply:hover {
	opacity: .9
}
button.panel-apply:active {
	transform: translateY(-2px)
}
button.panel-cancel {
	float: left;
	background-color: #f0f0f0;
	color: #666;
	opacity: 1
}
button.panel-cancel:hover {
	background-color: #e9e9e9
}
button.panel-cancel:active {
	transform: translateY(-2px)
}
.panel-buttons {
	width: 100%;
	margin-top: 13px
}
.panel-dropdown.float-right {
	float: right
}
.panel-dropdown.float-right .panel-dropdown-content {
	left: auto;
	right: 0
}
.fullwidth-filters .panel-dropdown {
	margin-left: 3px
}
.fullwidth-filters {
	display: inline-block;
	width: 100%;
	position: relative;
	top: 15px
}
.fullwidth-filters .sort-by {
	top: -1px;
	right: -1px
}
@media only screen and (max-width:359px) {
.qtyButtons input {
	width:50px;
}
.daterangepicker .drp-selected {
    display: inline-block;
    font-size: 14px;
    padding-right: 0;
    width: 100%;
    margin-bottom: 10px;
}
}
@media only screen and (min-width: 992px) and (max-width: 1239px) {
.companie-logo-slick-carousel div.item {
    padding: 0 10px;
}
}
@media only screen and (min-width: 992px) and (max-width: 1239px) {
.qtyButtons input {
	width:40px;
}	
}
.panel-dropdown {
	position: relative;
	display: inline-block
}
.panel-dropdown a {
	color: #666;
	font-weight: 600;
	font-size: 15px;
	border-radius: 50px;
	padding: 3px 14px;
	transition: all .3s;
	display: inline-block
}
.panel-dropdown.active a, .panel-dropdown a:hover {
	background: #ffffff
}
.panel-dropdown a:after {
	content: "\f103";
	font-family: fontawesome;
	font-size: 16px;
	color: #66676b;
	font-weight: 500;
	padding-left: 0;
	transition: all .3s;
	margin-left: 6px;
	display: inline-block
}
.panel-dropdown.active a:after {
	transform: rotate(180deg)
}
.panel-dropdown .panel-dropdown-content {
	opacity: 0;
	visibility: hidden;
	transition: all .3s;
	position: absolute;
	top: 44px;
	left: 0;
	z-index: 110;
	background: #fff;
	border: 1px solid rgba(0,0,0,.18);
	border-radius: 4px;
	box-shadow: 0 12px 35px 2px rgba(0,0,0,.12);
	padding: 20px 24px;
	overflow-y: auto;
	white-space: normal;
	width:100%
}
.panel-dropdown.wide .panel-dropdown-content {
	width:100%
}
.panel-dropdown.active .panel-dropdown-content {
	opacity: 1;
	visibility: visible
}
.text-theme {
    color: #764c29;
}
.review-avg-wrapper .rating-avg-wrapper .rating-avg {
    font-size: 40px;
    font-weight: 600;
    line-height: .8;
    float: left;
    padding-right: 5px;
}
.review-avg-wrapper .rating-avg-wrapper .rating-after {
	margin-top: 12px;
    float: left;
}
.review-avg-wrapper .rating-avg-wrapper .rating-mode {
    font-size: 16px;
    font-weight: 500;
}
.review-avg-wrapper .rating-avg-wrapper .rating-text {
    font-size: 14px;
    font-weight: 400;
}
.review-avg-wrapper .ratings-avg-wrapper {
    margin-top: 15px;
}
.review-avg-wrapper .ratings-avg-wrapper .ratings-avg-item {
    margin-bottom: 5px;
    display: inline-block;
    margin-right: 12px;
	font-weight: 500;
    font-size: 12px;
}
.review-avg-wrapper .ratings-avg-wrapper .ratings-avg-item .rating-label{
    font-size: 13px;
	font-weight: 600;
}
.review-avg-wrapper .ratings-avg-wrapper .ratings-avg-item .rating-value {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    background: #ece2e2;
    padding: 3px 5px;
    border-radius: 4px;
    display: inline-block;
}
.review-avg-wrapper .ratings-avg-wrapper .ratings-avg-item:last-child {
    margin-right: 0;
}
@media (max-width: 360px){
.review-avg-wrapper .ratings-avg-wrapper .ratings-avg-item {
    margin-right: 6px;    
}
}
/*==========
  Categorie Item Style
============*/
.search_categorie_block{
	display: block;
    position: relative;
}
.category_container_item_part {
    position: relative;
    height: 100%;
	margin-bottom:30px;	
}
.category_container_item_part a:hover{
    transform: translate3d(0, -8px, 0);
	overflow:hidden;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.category_container_item_part a:hover::after {
    visibility: visible;
    opacity:1;
}
.category_container_item_part a::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: -webkit-linear-gradient(45deg,rgba(245,84,142,.9) 0,rgba(144,58,249,.9) 100%);
    background: -ms-linear-gradient(45deg,rgba(245,84,142,.9) 0,rgba(144,58,249,.9) 100%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.category_item_box:hover img{
    transform: scale(1.10);
	transition: transform 0.35s ease-out;
	transition: all 0.55s;
}	
.category_item_box {
    background: #ccc;
    border-radius:4px;
    height: 100%;
    display: block;    
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    overflow: hidden;
    cursor: pointer;
	box-shadow:0 5px 12px 0 rgba(0,0,0,0.2);
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.category_item_box:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    background:#000;
	opacity:0.5
}
.category_item_box:hover .category_content_box_part span{
	background: #764c29;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.category_item_box_btn {
    position: absolute;    
	right:20px;
	top:20px;
    z-index: 111;
	font-size:14px;
	font-weight:500;
    background-color: #fff;
    color: #764c29;
    padding: 2px 15px;
    text-align: center;
    width:auto;
    border-radius:4px;
    -webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.category_item_box_btn:hover {
	background-color: #764c29;
	color:#fff;
	border-radius:4px;
	-webkit-transition: all .5s ease 0;
	transition: all .5s ease 0;
	transition: all 0.5s ease 0s;
}
.category_content_box_part {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 0;
    text-align: left;
    width: calc(90% - 0px);
    z-index: 50;
    box-sizing: border-box;
}
.category_content_box_part h3 {
    color: #fff;
    font-size: 22px;
    padding: 3px 0;
    margin: 0;
	font-weight:500;
	line-height:32px;
}
.category_content_box_part span {
    font-size: 12px;
	line-height:24px;
	font-weight: 400;
	display: inline-block;
	color: #fff;	
	letter-spacing: 0.5px;
	background:rgba(255, 34, 34, 0.5);
	padding: 0px 10px;
	text-transform:capitalize;
	border-radius: 4px;
}
.category-box-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    transition: transform 0.35s ease-out;
    transform: translate3d(0, 0, 0) scale(1);
    image-rendering: -webkit-optimize-contrast;
}

.category-box-container.half {
	height: calc(50% - 15px);
	margin-bottom: 30px;
}
.category-box {
	background: #ccc;
	border-radius:50%;
	height: 100%;
	display: block;
	margin-bottom: 20px;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50%;
	overflow: hidden;
	cursor: pointer;
}
.category-box:before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 9;
	background: -webkit-linear-gradient(bottom, rgba(35,35,37,0.85) 0%, rgba(35,35,37,0.8) 35%, rgba(22,22,23,0) 100%, rgba(0,0,0,0) 100%);
	background: -o-linear-gradient(bottom, rgba(35,35,37,0.85) 0%, rgba(35,35,37,0.8) 35%, rgba(22,22,23,0) 100%, rgba(0,0,0,0) 100%);
	background: linear-gradient(to top, rgba(35,35,37,0.85) 0%, rgba(35,35,37,0.8) 35%, rgba(22,22,23,0) 100%, rgba(0,0,0,0) 100%);
}
.fullwidth_block-slick-carousel.category-carousel .slick-slide {
	height: 450px;
}
.fullwidth_block-slick-carousel.category-carousel .slick-slide.utf_carousel_review_part {
	height: auto;
}
.category-box-content {
	position: absolute;
	bottom: 70px;
	left: 0;
	right:0;
	text-align:center;
	width: calc(100% - 0px);
	z-index: 50;
	box-sizing: border-box;
}
.category-box-content h3 {
	color: #fff;
	font-size: 24px;
	padding: 5px 0;
	margin: 0;
}
.category-box-content span {
	font-size: 18px;
	font-weight: 300;
	display: inline-block;
	color: rgba(255,255,255,0.7);
}
.category-box-btn {
	position: absolute;
	left: 30px;
	top: 120px;
	z-index: 111;
	background-color: transparent;
	border: 2px solid #fff;
	color: #fff;
	padding: 5px 0px;
	text-align: center;
	min-width: 110px;
	border-radius: 30px;
	transition: all 0.3s;
}
.category-box-btn:hover {
	background-color: #66676b;
	border-color: #66676b;
}
.utf_carousel_item {
	position: relative;
	height: 100%;
}
.category-box-background {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	transition: transform 0.35s ease-out;
	transform: translate3d(0, 0, 0) scale(1);
	image-rendering: -webkit-optimize-contrast;
}
.category-box:hover .category-box-background {
	transform: translate3d(0, 0, 0) scale(1.25);
}
.simple-slick-carousel {
	height: auto;
	width: calc(100% + 30px);
	left: -10px;
}
.simple-slick-carousel .category-box-container {
	height: 100%;
	display: block;
}
.simple-slick-carousel .slick-slide {
	padding: 10px 15px;	
}
.headline_part span {
	font-size: 17px;
	line-height: 30px;
	margin-top: 10px;
	color: #888;
	font-weight: 400;
	display: block;
}
.headline_part span i {
	color: #66676b;
	font-style: normal;
}
h4.headline_part {
	font-size: 24px;
	line-height: 32px;
	margin: -5px 0 30px 0;
}
.margin-bottom-30 h4.headline_part {
	font-size: 22px;
    line-height: 32px;
    margin: -5px 0 30px 0;
    font-weight: 500;
}
.row h4.headline_part {
	font-size: 22px;
    line-height: 32px;
    font-weight: 600;
}
.margin-bottom-30 p{
	font-size:15px;
}
.headline_part.centered {
	text-align: center;	
}
.headline_part.centered b{
	font-weight:500;
}
.border-top {
	border-top: 1px solid #e8e8e8;
}
.border-bottom {
	border-bottom: 1px solid #e8e8e8;
}
.container_icon {
	overflow: hidden;
}
mark {
	font-weight: 500;
}
.utf_glooph {
	font-size: 16px;
	width: 23%;
	margin:10px 9px;
	display: inline-block;
	transition: 0.3s;
}
h4 span.headline_part-detail {
	font-size: 15px;
	margin-top: 5px;
}
.mls {
	font-size: 15px;
	top:-6px;
	position: relative;
}
.utf_fs1 {
	font-size: 28px;
}
.pbs .im {
	display: inline-block;
	margin-right:5px;
	background:#fff;
	width:50px;
	height:50px;
	line-height:50px;
	font-size:22px;
	text-align:center;
	border:1px solid rgba(0, 0, 0, 0.1);
	border-radius:50%;
}
.unit, .unitRight, .fs0 {
	display: none;
}
.pbs {
	background:#f2f2f2;
	border:1px solid rgba(0, 0, 0, 0.1);
	padding: 10px 0 7px 15px;
	border-radius:10px;
	width: 100%;
	display: inline-block;
	transition: 0.2s;
}
.pbs:hover {
	background-color: #f4f4f4;
}
.sl-icons-demo .col-md-3{
    background: #f2f2f2;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
    width: 24%;
    margin: 5px;
}

/*=========
	Typography List
=========*/
.fa-icons-demo .col-md-4{
    background: #f2f2f2;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
    width: 31%;
    margin: 12px;
}
.fa-icons-demo .col-md-4 i{
	margin-right:5px;
	background:#fff;
	width:36px;
	height:36px;
	line-height:36px;
	border:1px solid rgba(0, 0, 0, 0.1);
	border-radius:50%;
}
.fa-icons-demo .col-md-4 .text-muted{
	line-height:36px;
}
.fa-list-icon .col-md-4{
	background:#f2f2f2;
	border:1px solid rgba(0, 0, 0, 0.1);
	padding:10px;	
	border-radius:10px;
	margin-bottom:15px;
}
.sl-icons-demo i {
	display: inline-block;
	top: 1px;
	position: relative;
	margin-right:5px;
	background:#fff;
	width:36px;
	height:36px;
	line-height:36px;
	text-align:center;
	border:1px solid rgba(0, 0, 0, 0.1);
	border-radius:50%;
}
.icons .fa.fa-fw {
	margin-right: 3px;
	font-size: 14px;
}
.icons .text-muted {
	display: none;
}
.margin-top-0 {
	margin-top: 0!important;
}
.margin-top-1 {
	margin-top: 1px!important;
}
.margin-top-2 {
	margin-top: 2px!important;
}
.margin-top-3 {
	margin-top: 3px!important;
}
.margin-top-4 {
	margin-top: 4px!important;
}
.margin-top-5 {
	margin-top: 5px!important;
}
.margin-top-6 {
	margin-top: 6px!important;
}
.margin-top-7 {
	margin-top: 7px!important;
}
.margin-top-8 {
	margin-top: 8px!important;
}
.margin-top-9 {
	margin-top: 9px!important;
}
.margin-top-10 {
	margin-top: 10px!important;
}
.margin-top-15 {
	margin-top: 15px!important;
}
.margin-top-20 {
	margin-top: 20px!important;
}
.margin-top-25 {
	margin-top: 25px!important;
}
.margin-top-30 {
	margin-top: 30px!important;
}
.margin-top-35 {
	margin-top: 35px!important;
}
.margin-top-40 {
	margin-top: 40px!important;
}
.margin-top-45 {
	margin-top: 45px!important;
}
.margin-top-50 {
	margin-top: 50px!important;
}
.margin-top-55 {
	margin-top: 55px!important;
}
.margin-top-60 {
	margin-top: 60px!important;
}
.margin-top-65 {
	margin-top: 65px!important;
}
.margin-top-70 {
	margin-top: 70px!important;
}
.margin-top-75 {
	margin-top: 75px!important;
}
.margin-top-80 {
	margin-top: 80px!important;
}
.margin-top-85 {
	margin-top: 85px!important;
}
.margin-top-90 {
	margin-top: 90px!important;
}
.margin-top-95 {
	margin-top: 95px!important;
}
.margin-top-100 {
	margin-top: 100px!important;
}
.margin-top-105 {
	margin-top: 105px!important;
}
.margin-top-110 {
	margin-top: 110px!important;
}
.margin-top-115 {
	margin-top: 115px!important;
}
.margin-top-120 {
	margin-top: 120px!important;
}
.margin-bottom-0 {
	margin-bottom: 0!important;
}
.margin-bottom-5 {
	margin-bottom: 5px!important;
}
.margin-bottom-10 {
	margin-bottom: 10px!important;
}
.margin-bottom-15 {
	margin-bottom: 15px!important;
}
.margin-bottom-20 {
	margin-bottom: 20px!important;
}
.margin-bottom-25 {
	margin-bottom: 25px!important;
}
.margin-bottom-30 {
	margin-bottom: 30px!important;
}
.margin-bottom-35 {
	margin-bottom: 35px!important;
}
.margin-bottom-40 {
	margin-bottom: 40px!important;
}
.margin-bottom-45 {
	margin-bottom: 45px!important;
}
.margin-bottom-50 {
	margin-bottom: 50px!important;
}
.margin-bottom-55 {
	margin-bottom: 55px!important;
}
.margin-bottom-55 {
	margin-bottom: 55px!important;
}
.margin-bottom-55 {
	margin-bottom: 55px!important;
}
.margin-bottom-55 {
	margin-bottom: 55px!important;
}
.margin-bottom-60 {
	margin-bottom: 60px!important;
}
.margin-bottom-65 {
	margin-bottom: 65px!important;
}
.margin-bottom-70 {
	margin-bottom: 70px!important;
}
.margin-bottom-75 {
	margin-bottom: 75px!important;
}
.margin-bottom-80 {
	margin-bottom: 80px!important;
}
.margin-bottom-85 {
	margin-bottom: 85px!important;
}
.margin-bottom-90 {
	margin-bottom: 90px!important;
}
.margin-bottom-95 {
	margin-bottom: 95px!important;
}
.margin-bottom-100 {
	margin-bottom: 100px!important;
}
.margin-left-0 {
	margin-left: 0!important;
}
.margin-left-1 {
	margin-left: 1px!important;
}
.margin-left-2 {
	margin-left: 2px!important;
}
.margin-left-3 {
	margin-left: 3px!important;
}
.margin-left-4 {
	margin-left: 4px!important;
}
.margin-left-5 {
	margin-left: 5px!important;
}
.margin-left-6 {
	margin-left: 6px!important;
}
.margin-left-7 {
	margin-left: 7px!important;
}
.margin-left-8 {
	margin-left: 8px!important;
}
.margin-left-9 {
	margin-left: 9px!important;
}
.margin-left-10 {
	margin-left: 10px!important;
}
.margin-left-15 {
	margin-left: 15px!important;
}
.margin-left-20 {
	margin-left: 20px!important;
}
.margin-left-25 {
	margin-left: 25px!important;
}
.margin-left-30 {
	margin-left: 30px!important;
}
.margin-left-35 {
	margin-left: 35px!important;
}
.margin-left-40 {
	margin-left: 40px!important;
}
.margin-left-45 {
	margin-left: 45px!important;
}
.margin-left-50 {
	margin-left: 50px!important;
}
.margin-left-55 {
	margin-left: 55px!important;
}
.margin-left-60 {
	margin-left: 60px!important;
}
.margin-left-65 {
	margin-left: 65px!important;
}
.margin-left-70 {
	margin-left: 70px!important;
}
.margin-left-75 {
	margin-left: 75px!important;
}
.margin-left-80 {
	margin-left: 80px!important;
}
.margin-left-85 {
	margin-left: 85px!important;
}
.margin-left-90 {
	margin-left: 90px!important;
}
.margin-left-95 {
	margin-left: 95px!important;
}
.margin-left-100 {
	margin-left: 100px!important;
}
.margin-right-0 {
	margin-right: 0!important;
}
.margin-right-1 {
	margin-right: 1px!important;
}
.margin-right-2 {
	margin-right: 2px!important;
}
.margin-right-3 {
	margin-right: 3px!important;
}
.margin-right-4 {
	margin-right: 4px!important;
}
.margin-right-5 {
	margin-right: 5px!important;
}
.margin-right-6 {
	margin-right: 6px!important;
}
.margin-right-7 {
	margin-right: 7px!important;
}
.margin-right-8 {
	margin-right: 8px!important;
}
.margin-right-9 {
	margin-right: 9px!important;
}
.margin-right-10 {
	margin-right: 10px!important;
}
.margin-right-15 {
	margin-right: 15px!important;
}
.margin-right-20 {
	margin-right: 20px!important;
}
.margin-right-25 {
	margin-right: 25px!important;
}
.margin-right-30 {
	margin-right: 30px!important;
}
.margin-right-35 {
	margin-right: 35px!important;
}
.margin-right-40 {
	margin-right: 40px!important;
}
.margin-right-45 {
	margin-right: 45px!important;
}
.margin-right-50 {
	margin-right: 50px!important;
}
.margin-right-55 {
	margin-right: 55px!important;
}
.margin-right-60 {
	margin-right: 60px!important;
}
.margin-right-65 {
	margin-right: 65px!important;
}
.margin-right-70 {
	margin-right: 70px!important;
}
.margin-right-75 {
	margin-right: 75px!important;
}
.margin-right-80 {
	margin-right: 80px!important;
}
.margin-right-85 {
	margin-right: 85px!important;
}
.margin-right-90 {
	margin-right: 90px!important;
}
.margin-right-95 {
	margin-right: 95px!important;
}
.margin-right-100 {
	margin-right: 100px!important;
}
.padding-top-0 {
	padding-top: 0!important;
}
.padding-top-1 {
	padding-top: 1px!important;
}
.padding-top-2 {
	padding-top: 2px!important;
}
.padding-top-3 {
	padding-top: 3px!important;
}
.padding-top-4 {
	padding-top: 4px!important;
}
.padding-top-5 {
	padding-top: 5px!important;
}
.padding-top-6 {
	padding-top: 6px!important;
}
.padding-top-7 {
	padding-top: 7px!important;
}
.padding-top-8 {
	padding-top: 8px!important;
}
.padding-top-9 {
	padding-top: 9px!important;
}
.padding-top-10 {
	padding-top: 10px!important;
}
.padding-top-15 {
	padding-top: 15px!important;
}
.padding-top-20 {
	padding-top: 20px!important;
}
.padding-top-25 {
	padding-top: 25px!important;
}
.padding-top-30 {
	padding-top: 30px!important;
}
.padding-top-35 {
	padding-top: 35px!important;
}
.padding-top-40 {
	padding-top: 40px!important;
}
.padding-top-45 {
	padding-top: 45px!important;
}
.padding-top-50 {
	padding-top: 50px!important;
}
.padding-top-55 {
	padding-top: 55px!important;
}
.padding-top-60 {
	padding-top: 60px!important;
}
.padding-top-65 {
	padding-top: 65px!important;
}
.padding-top-70 {
	padding-top: 70px!important;
}
.padding-top-75 {
	padding-top: 75px!important;
}
.padding-top-80 {
	padding-top: 80px!important;
}
.padding-top-85 {
	padding-top: 85px!important;
}
.padding-top-90 {
	padding-top: 90px!important;
}
.padding-top-95 {
	padding-top: 95px!important;
}
.padding-top-100 {
	padding-top: 100px!important;
}
.padding-top-105 {
	padding-top: 105px!important;
}
.padding-top-110 {
	padding-top: 110px!important;
}
.padding-top-115 {
	padding-top: 115px!important;
}
.padding-top-120 {
	padding-top: 120px!important;
}
.padding-bottom-0 {
	padding-bottom: 0!important;
}
.padding-bottom-5 {
	padding-bottom: 5px!important;
}
.padding-bottom-10 {
	padding-bottom: 10px!important;
}
.padding-bottom-15 {
	padding-bottom: 15px!important;
}
.padding-bottom-20 {
	padding-bottom: 20px!important;
}
.padding-bottom-25 {
	padding-bottom: 25px!important;
}
.padding-bottom-30 {
	padding-bottom: 30px!important;
}
.padding-bottom-35 {
	padding-bottom: 35px!important;
}
.padding-bottom-40 {
	padding-bottom: 40px!important;
}
.padding-bottom-45 {
	padding-bottom: 45px!important;
}
.padding-bottom-50 {
	padding-bottom: 50px!important;
}
.padding-bottom-55 {
	padding-bottom: 55px!important;
}
.padding-bottom-55 {
	padding-bottom: 55px!important;
}
.padding-bottom-55 {
	padding-bottom: 55px!important;
}
.padding-bottom-55 {
	padding-bottom: 55px!important;
}
.padding-bottom-60 {
	padding-bottom: 60px!important;
}
.padding-bottom-65 {
	padding-bottom: 65px!important;
}
.padding-bottom-70 {
	padding-bottom: 70px!important;
}
.padding-bottom-75 {
	padding-bottom: 75px!important;
}
.padding-bottom-80 {
	padding-bottom: 80px!important;
}
.padding-bottom-85 {
	padding-bottom: 85px!important;
}
.padding-bottom-90 {
	padding-bottom: 90px!important;
}
.padding-bottom-95 {
	padding-bottom: 95px!important;
}
.padding-bottom-100 {
	padding-bottom: 100px!important;
}
.padding-right-0 {
	padding-right: 0!important;
}
.padding-right-5 {
	padding-right: 5px!important;
}
.padding-right-10 {
	padding-right: 10px!important;
}
.padding-right-15 {
	padding-right: 15px!important;
}
.padding-right-20 {
	padding-right: 20px!important;
}
.padding-right-25 {
	padding-right: 25px!important;
}
.padding-right-30 {
	padding-right: 30px!important;
}
.padding-right-35 {
	padding-right: 35px!important;
}
.padding-right-40 {
	padding-right: 40px!important;
}
.padding-right-45 {
	padding-right: 45px!important;
}
.padding-right-50 {
	padding-right: 50px!important;
}
.padding-right-55 {
	padding-right: 55px!important;
}
.padding-right-55 {
	padding-right: 55px!important;
}
.padding-right-55 {
	padding-right: 55px!important;
}
.padding-right-55 {
	padding-right: 55px!important;
}
.padding-right-60 {
	padding-right: 60px!important;
}
.padding-right-65 {
	padding-right: 65px!important;
}
.padding-right-70 {
	padding-right: 70px!important;
}
.padding-right-75 {
	padding-right: 75px!important;
}
.padding-right-80 {
	padding-right: 80px!important;
}
.padding-right-85 {
	padding-right: 85px!important;
}
.padding-right-90 {
	padding-right: 90px!important;
}
.padding-right-95 {
	padding-right: 95px!important;
}
.padding-right-100 {
	padding-right: 100px!important;
}
.padding-left-0 {
	padding-left: 0!important;
}
.padding-left-5 {
	padding-left: 5px!important;
}
.padding-left-10 {
	padding-left: 10px!important;
}
.padding-left-15 {
	padding-left: 15px!important;
}
.padding-left-20 {
	padding-left: 20px!important;
}
.padding-left-25 {
	padding-left: 25px!important;
}
.padding-left-30 {
	padding-left: 30px!important;
}
.padding-left-35 {
	padding-left: 35px!important;
}
.padding-left-40 {
	padding-left: 40px!important;
}
.padding-left-45 {
	padding-left: 45px!important;
}
.padding-left-50 {
	padding-left: 50px!important;
}
.padding-left-55 {
	padding-left: 55px!important;
}
.padding-left-55 {
	padding-left: 55px!important;
}
.padding-left-55 {
	padding-left: 55px!important;
}
.padding-left-55 {
	padding-left: 55px!important;
}
.padding-left-60 {
	padding-left: 60px!important;
}
.padding-left-65 {
	padding-left: 65px!important;
}
.padding-left-70 {
	padding-left: 70px!important;
}
.padding-left-75 {
	padding-left: 75px!important;
}
.padding-left-80 {
	padding-left: 80px!important;
}
.padding-left-85 {
	padding-left: 85px!important;
}
.padding-left-90 {
	padding-left: 90px!important;
}
.padding-left-95 {
	padding-left: 95px!important;
}
.padding-left-100 {
	padding-left: 100px!important;
}

@media (max-width: 1600px) {
.category-box-btn {
	left: 32%;
	right: 32%;
}
.category-box-container.half {
	height: 100%;
}
.utf_carousel_item .category-box-container.half:first-child {
	display: none;
}
.fullwidth_block-slick-carousel.category-carousel .slick-slide {
	height: 350px;
}
.utf_dashboard_stat_icon {
	font-size: 66px;
}
}
@media only screen and (max-width: 1366px) and (min-width: 1240px) {
.input-with-icon.location a i {
	padding: 15px 18px 15px 10px;
}
.data-radius-title {
	margin-top: 7px;
	line-height: 24px;
}
#utf_map_container.fullwidth_block-home-map {
	height: 605px;
}
.fs-content .search .row.with-forms .col-fs-6 {
	width: 50%;
}
}

/*=========
	Responsive Style
=========*/
@media (max-width: 1366px) {
.fullwidth_block-slick-carousel.category-carousel .slick-slide {
	height: 320px;
}
.utf_dashboard_stat_icon {
	font-size: 40px;
}
.search_container_block {
	height: 575px;
}
}
@media only screen and (max-width: 1365px) {
header.fullwidth_block.dashboard .container {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
	padding: 0 40px;
	width: 100%;
}
.dashboard #logo {
	background:transparent;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    margin-top: 28px;
    width: 40%;
	top:0;
    text-align: center;
    left: 0;
    right: 0;
}
.utf_dashboard_navigation, .dashboard #logo {
    min-width: auto;
    max-width: auto;
}
.dashboard #logo a {
	margin-left: 0;
	display: inline-block;
	height: 100%;
}
.dashboard #logo a.dashboard-logo {
	display: none;
}
.dashboard #logo a img {
	top: 0;
	position: relative;
	transform: none
}
.utf_user_menu {
	margin: 0 25px 0 25px;
}
.utf_user_menu ul {
	left: -25px;
	right: auto;
}
}

@media (max-width: 1239px) {
#navigation.style_one {
	margin: 25px 0 5px -5px;
	width: 100%;
	padding-top: 15px;
	padding-bottom: 0;
	position: relative;
}
#navigation.style_one:before {
	position: absolute;
	width: 100%;
	height: 1px;
	display: block;
	top: 0;
	left: 5px;
	content: "";
	background: #e8e8e8;
	width: 940px;
}
.utf_inner_booking_listing_list {
    line-height: 30px;
}
.fullwidth_block #navigation.style_one:before {
	background: linear-gradient(to right, #e8e8e8 90%, #fff);
}
.utf_listing_item-container.list-layout .utf_listing_item-image {
	flex: 3;
}
.utf_listing_slider, .utf_listing_slider .item {
	height: 30vh;
	min-height: 400px;
	max-height: 400px;
}
#header.cloned {
	display: none;
}
}

@media (max-width: 1024px) {
#navigation.style_one {
	margin: 0;
	padding-top: 0;
}
#navigation.style_one:before {
	display: none;
}
.header_widget {
	margin-bottom: 5px;
	height: 64px;
}
.utf_testimonial_box{
	border-radius:6px;
}
}
@media only screen and (min-width: 1025px) and (max-width: 1365px) {
.utf_user_menu ul {
	right: -25px;
	left: auto;
}
}
@media only screen and (min-width: 992px) and (max-width: 1239px) {
.fs-inner-container.map-fixed {    
    padding-top: 165px;
}
.header_item_half {
    position: fixed;
	z-index: 999;
	float: left;
	width: 100%;
	background: #fff;
}
.utf_listing_item-container.compact .utf_star_rating_section, .utf_listing_item-container .utf_star_rating_section {
    padding: 15px 10px;
    left: 10px;
    right: 10px;    
}
}
@media only screen and (min-width: 1260px) and (max-width: 1360px) {
.utf_listing_item-container .utf_star_rating_section{	
	padding: 15px 10px;
    left: 10px;
    right: 10px;    
}
}
@media only screen and (min-width: 992px) and (max-width: 1239px){
.listing_item_social li {
    margin-bottom: 8px;
}	
}
@media (max-width: 991px) {
.search_container_block {
	height: 680px;
}
.main_input_search_part{
	margin-top:30px;
}
.main_inner_search_block {
	top:53%
}
.main_popular_categories_list{
	padding-left:0;
}
.main_popular_categories{
	margin-top:10px !important;
}
.main_input_search_part select, .main_input_search_part select:focus {
	font-size: 16px;
	border: 1px solid #e0e0e0;
	box-shadow: 0 1px 3px 0px rgba(0,0,0,0.08);
	background: #fff;
	height: 55px;
	padding: 12px 18px;
	border-radius: 4px;
}
.slick_carousel_slider{
	overflow:hidden;
}
li.total-costs .col-md-8 {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
}
li.total-costs .col-md-4 {
    padding-right: 0;
    padding-left: 0;
}
#dashboard {
	padding-top: 0!important;
}
.utf_dashboard_navigation {
	min-width: 90%;
	max-width: 90%;
	margin: 0 5%;
	border-radius:6px;
	position: relative;
	min-height: auto;
	opacity: 1;
	visibility: hidden;
	max-height: 0;
	padding: 0;
	transition: all 0.3s;
}
.utf_dashboard_navigation.active {
	margin-top:10px;
}
.utf_dashboard_navigation.active ul{
	margin-top:0;
}
.utf_dashboard_navigation.active ul, .utf_dashboard_navigation.active {
	opacity: 1;
	visibility: visible;
	max-height: 900px;	
}
.utf_dashboard_navigation ul, .utf_dashboard_navigation.active ul li ul, .utf_dashboard_navigation ul li ul {
	visibility: hidden;
	opacity: 0;
	max-height: 0;
	transition: all 0.3s;
}
.utf_dashboard_navigation.active ul li.active ul {
	visibility: visible;
	opacity: 1;
	max-height: 300px;
}
#header_part.fixed {
	position: relative;
	top: 0;
	width: 100%;
}
.utf_dashboard_content {
	padding: 30px 5%;
	padding-bottom: 0;
	height: auto;
	margin-left: 0px;
}
header.fullwidth_block.dashboard .container {
	padding: 0 5%;
}
.main_inner_search_block {
	transform: translate(0, -51%);
}
a.utf_dashboard_nav_responsive {
	width: 90%;
	margin: 0 5%;
	margin-top: 40px;
	background-color: #333;
	border-radius: 30px;
	color: #fff;
	text-align:center;
	padding: 16px 30px;
	font-weight: 400;
	transition: 0.2s;
	display: block;
}
a.utf_dashboard_nav_responsive i {
	margin-right: 5px;
}
.utf_messages_block_inner {
	display: block;
}
.utf_messages_block_inner .utf_user_messages_block {
	max-width: 100%;
	width: 100%;
	border-right: none;
	display: none;
}
.main_input_search_part .button{
	text-align:center;
	width:100%;
}
.main_input_search_part {
	border-radius:10px;
	display: block;
	padding:20px;
	max-height: 100%;
	background-color: rgba(255, 255, 255, 0.25);
	box-shadow: none;
}
.gray-style.main_input_search_part {
	background-color:#f7f7f7;
}
.gray-style .main_input_search_part_item, .main_input_search_part_item {
	border-color: transparent;
}
.gray-style .main_input_search_part_item .chosen-container-active.chosen-with-drop .chosen-single, .gray-style .main_input_search_part_item .chosen-container .chosen-drop{
	background:#fff;
}
.main_input_search_part button.button {
	margin-top: 0px;
	border-radius: 4px;
}
.main_input_search_part_item {
	margin-bottom: 10px;
}
.main_input_search_part_item, .main_input_search_part .main_input_search_part_item:nth-last-child(2) {
	padding: 0;
}
.main_input_search_part_item .chosen-container-single .chosen-single, .main_input_search_part_item .chosen-single.chosen-container-active, .gray-style .main_input_search_part_item .chosen-container-single .chosen-single, .gray-style .main_input_search_part_item .chosen-single.chosen-container-active, .gray-style.main_input_search_part input, .gray-style.main_input_search_part input:focus, .main_input_search_part input, .main_input_search_part input:focus {
	padding: 0 20px;
	height: 55px;
	line-height: 55px;
	border-radius: 4px;
	background: #fff;
	border:0;
	box-shadow: 0 1px 3px 0px rgba(0,0,0,0.08);
}
.gray-style.main_input_search_part input, .gray-style.main_input_search_part input:focus, .main_input_search_part input, .main_input_search_part input:focus {
	padding: 0 20px;
}
.gray-style .main_input_search_part_item.location a {
	background: #fff;
}
.gray-style .main_input_search_part_item .chosen-single {
	border-bottom: 1px solid #e0e0e0;
	transition: border 0s;
}
.main_input_search_part_item .chosen-container-single .chosen-single, .main_input_search_part_item .chosen-container-active.chosen-with-drop .chosen-single {
	border:0;
	box-shadow: none;
	transition: 0s;
}
.main_input_search_part_item .chosen-container-active.chosen-with-drop .chosen-single {
	border-bottom: 0;
}
.main_input_search_part_item .chosen-container .chosen-drop {
	border: 1px solid #e0e0e0;
	border-top: 0;
	border-radius: 0 0 4px 4px;
	box-shadow: none;
}
.gray-style .main_input_search_part_item .chosen-container-active.chosen-with-drop .chosen-single {
	border: none;
}
.main_input_search_part_item .chosen-single::after {
	display: none;
}
.simple-slick-carousel .slick-dotted .slick-slider {
	margin-bottom: 0;
	width: 100%;
	left: 0;
}
.box_icon_two.box_icon_with_line::before {
	display: none;
}
.container .row div.padding-right-30 {
	padding-right: 15px!important;
}
.utf_listing_item-container.list-layout:hover {
	transform: none;
}
.utf_listing_item-container.list-layout .utf_listing_item-inner {
	position: relative;
	top: auto;
	transform: none;
	padding: 30px;
	left: 0;
}
.utf_listing_item-container.list-layout .utf_listing_item {
	display: block;
	height: auto;
	padding: 0;
}
.utf_listing_item-container.list-layout .utf_listing_item .listing-badge {
	display: none;
}
.utf_listing_item-container.list-layout .utf_listing_item-image {
	min-height: auto;
}
.utf_listing_item-container.list-layout .utf_listing_item-details {
	position: relative;
	bottom: auto;
	right: auto;
	left: 0;
	top: -15px;
	margin: 0px 30px 15px 30px;
	padding: 5px 20px;
	background-color: #eee;
	border-radius: 50px;
	font-size: 14px;
	width: auto;
	display: inline-block;
}
#titlebar {
	padding: 40px 0 20px;
	margin-bottom: 60px;
}
.utf_panel_dropdown {
	margin-right: 4px;
	display: block;
	width: 100%;
}
.fullwidth_block-filters .sort-by {
	display: block;
	width: 100%;
}
.fullwidth_block-filters .sort-by label, .fullwidth_block-filters .sort-by .utf_sort_by_select_item{
	float: left;
	margin-top: 10px;
}
.utf_panel_dropdown {
	margin-top:0;
	float:left;
	width:auto;
}
.utf_panel_dropdown a, .fullwidth_block-filters .sort-by .chosen-container-single .chosen-single {
	background: #f3f3f3;
}
.utf_panel_dropdown .utf_panel_dropdown-content, .fullwidth_block-filters .utf_panel_dropdown.float-right .utf_panel_dropdown-content {
	left: 0;
	right: auto;
	width: 400px;
	max-width: 70vw;
}
.fullwidth_block-filters .sort-by .chosen-container.chosen-with-drop .chosen-drop, .fullwidth_block-filters .sort-by .chosen-container .chosen-drop {
	left: 0;
	right: auto;
	margin-left: 0;
}
.fs-inner-container.map-fixed {
	height: 500px;
	position: relative;
	top: 0;
	padding-top: 0!important;
	width: 100%;
}
.fs-inner-container.content {
	width: 100%;
	padding-top: 0!important;
	overflow:hidden;
}
.buttons-to-right, .utf_dashboard_list_box .button.to-right {
	position: relative;
	transform: none;
	top: 0;
	margin: 10px 0 0 0;
	opacity: 1;
	right: auto;
}
.utf_list_box_listing_item-img {
	max-width: 168px;
}
.utf_add_review-photos {
	float: left;
	top: 0;
}
.utf_add_review-box {
	border-radius: 4px;
	background-color: #f9f9f9;
	padding: 36px;
	padding-bottom: 40px;
	margin-bottom: 30px;
	margin-top: 70px;
	display: inline-block;
	width: 100%;
	position: relative;
}
.utf_listing_slider .slick-next:hover, .utf_listing_slider .slick-next {
	right: 100px
}
.utf_listing_slider .slick-prev:hover, .utf_listing_slider .slick-prev {
	left: 100px
}
.utf_listing_slider, .utf_listing_slider .item {
	height: 30vh;
	min-height: 400px;
	max-height: 400px;
}
.listing-nav-container.cloned .listing-nav {
	display: none;
}
.utf_opening_day .chosen-container {
	vertical-align: initial;
}
.utf_dashboard_content .utf_dashboard_list_box-static {
	margin-bottom: 20px;
}
#googlemaps {
	height: 30vh!important;
	padding-bottom: 0!important;
}
.utf_contact_map {
	display: block;
}
.utf_contact_map #utf_single_listing_map_block {
	flex: 1;
	position: relative;
	padding-top: 0;
	display: block;
	height: 300px;
}
.utf_contact_map .address-box-container {
	width: 100%;
}
}
@media (max-width: 527px) {
.utf_panel_dropdown {
	margin-bottom:10px;
}
}
@media (max-width: 991px) {
.utf_listing_item-container.list-layout .utf_listing_item-inner{
	padding:20px;
}
}
@media (max-width: 767px) {
.utf_listing_features {
	columns: 1;
	-webkit-columns: 1;
	-moz-columns: 1;
}
#dialog_signin_part .mfp-close::after, #dialog_signin_part .mfp-close::before, #small-dialog .mfp-close::after, #small-dialog .mfp-close::before {
	top: 3px;	
}
.slick-dots{
	bottom:-30px;
}
.utf_payment_logo{
	display:none;
}
.fs-container .fs-listings, .fs-switcher{
	padding:0;
}
.fs-container div.search{
	padding:40px 20px;
}
.listing_item_social li{
	margin-bottom:8px;
}
.simple_slick_carousel_block{
	margin-left:15px;
	margin-right:15px;
}
.slick_carousel_slider{
	overflow:hidden;
}
.messages-headline_part h4 {
	display: block;
}
.message-action {
	display: inline-block;
	float: none;
	margin-top: 15px;
	background-color: #f2f2f2;
	border-radius: 50px;
	padding: 4px 20px;
	text-align: center;
}
.utf_glooph{
	width:100%;
	margin:5px 0;	
}
.utf_add_listing_part_headline_part h3{
	font-size:18px;
}
.utf_add_listing_part_headline_part{
	padding:20px;
	width: calc(100% + 40px);
    left: -20px;
}
.utf_dashboard_list_box-static {
    padding:20px;
}	
.utf_opening_day {
    margin-bottom: 10px;
}
.add_utf_listing_section{
	padding:0 20px 20px 20px;
}
#bottom_backto_top {
	display: block;
	margin-bottom:40px;
	margin-right:15px;
}
.small_dialog_header {
	width: calc(100% + 60px);
	left: -30px;
	padding:20px;
}
#dialog_signin_part, #small-dialog {
	background: #fff;
	padding: 30px;
	padding-top: 0;
}
#dialog_signin_part .mfp-close, #small-dialog .mfp-close {
	top: 15px;
	right: 20px;
	width: 40px;
	height: 40px;
}
.utf_list_box_listing_item {
	display: block;
}
.utf_list_box_listing_item_content .inner {
	position: relative;
	top: 0;
	transform: translateY(0);
	display: inline-block;
}
.header_widget {
	height: auto;
}
.utf_list_box_listing_item-img {
	max-width: 100%;
}
.utf_list_box_listing_item_content {
	padding-left: 0;
	padding-top: 10px;
}
.pricing_info {
    overflow: hidden;
    padding: 10px 0 20px;
}
#titlebar {
	padding: 40px 0 20px;
	margin-bottom: 40px;
}
#utf_map_container.fullwidth_block-home-map .zoomControlWrapper {
	top: 15px!important;
}
.utf_pricing_container_block {
	display: block;
}
.plan.featured {
	transform: none;
}
#breadcrumbs {
	position: relative;
	display: block;
	margin-top: 15px;
}
#breadcrumbs ul li {
	margin: 0 10px 0 0;
}
.utf_by_comment .numerical-rating, .utf_by_comment .utf_star_rating_section {
	position: relative;
	right: 0;
	top: 7px;
	display: block;
	width: 100%;
	float: none;
	height: 30px;
}
.avatar {
	display: block;
}
.utf_comment_content {
	padding: 0;
}
.small_dialog_header h3 {
	padding: 0;
	margin: 0;
	font-size: 22px;
	padding-right: 80px;
	line-height: 30Px;
}
#dialog_signin_part, #small-dialog {
	max-width: 90vw;
}
.listing-nav li a {
	border-bottom: 1px solid #e0e0e0;
	padding: 15px;
	width: 100%;
	display: block;
	padding-left: 0;
	border-color: #e8e8e8;
}
.listing-nav li {
	margin-right: 0;
	display: block;
}
.listing-nav-container .listing-nav li:first-child a {
	padding-top: 0;
}
.listing-nav-container.cloned .listing-nav li:first-child a.active, .listing-nav-container.cloned .listing-nav li:first-child a:hover, .listing-nav li:first-child a, .listing-nav li a.active, .listing-nav li a:hover {
	border-color: #e8e8e8;
}
.utf_dashboard_content .checkboxes label {
	width: 100%;
}
.pricing-list-item td {
	display: block;
	width: 100%;
	position: relative;
}
.pricing-list-item td .fm-input, .pricing-list-item td .fm-input.pricing-price, .pricing-list-item td .fm-input.pricing-name {
	max-width: 100%;
	margin-right: 0;
}
.pricing-list-item td .fm-close {
	text-align: left;
	position: absolute;
	top: 4px;
	right: 0;
}
.utf_about_author img {
	width: 70px;
	height: 70px;
}
.utf_about_description {
	margin: 2px 0 0 100px;
}
#posts-nav li {
	position: relative;
	width: 100%;
}
#posts-nav li a {
	padding: 0!important;
}
#posts-nav li a::before {
	display: none;
}
#posts-nav li.next-post {
	text-align: left;
	margin-bottom: 30px;
}
.comments ul li ul {
	margin: 0;
}
.avatar img {
	max-width: 80px;
}
.utf_comment_content {
	padding: 0 0 0 85px;
}
.comments ul li ul li::before {
	top: 25px;
}
.comments ul li, .comments ul li ul li, .comments ul li ul li ul li, .comments ul li ul li ul li{
	margin-top:20px;
}
.utf_comment_content {
	padding: 0 0 20px 0;
}
.testimonial::after {
	display: none;
}
.utf_testimonial_box {
	padding:20px;
}
}
@media only screen and (min-width: 1025px) and (max-width: 1239px) {
#navigation.style_one ul a {
    line-height: 32px;
    padding: 5px 10px 15px 10px;
}
}
@media only screen and (max-width: 1024px) and (min-width: 979px) {
.utf_user_menu{
	display:block;
}
.utf_glooph{
	width:47%;
}
}
@media (max-width: 979px){
.main_input_search_part_item.location a{
	padding:10px 0;
}
.utf_price_plan_features{
	margin-bottom:30px;
}
.utf_booking_payment_option_form{
	margin-bottom:40px;
}
.sidebar-search{
	margin-bottom:70px;
	margin-top:30px !important;
}
.chosen-container-single .chosen-single div{
	right:0px;
}
.utf_box_widget .with-forms .chosen-container-single .chosen-single div{
	right:22px;
}
#utf_add_listing_part .chosen-container-single .chosen-single div{
	right:20px;
}
.main_input_search_part_item .chosen-container-single .chosen-single div{
	right:0px;
}
.utf_user_menu{
	display:block;
	margin:0px 0 30px 25px;
}
#contact{
	margin-bottom:30px !important;
}
}
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
.dashboard #logo {
    background: transparent;
    position: relative;
    width: 100%;
    margin: 0;
    margin-top: 0;
    width: 100%;
    top: 0;
    text-align: left;
    left: 0;
    right: 0;
}
}
@media only screen and (min-width: 1240px) and (max-width: 1365px) {
.dashboard #logo {
    min-width: 240px;
    max-width: 240px;
}
}
@media only screen and (min-width: 1240px) and (max-width: 1366px) {
header.fullwidth_block.dashboard .container {
    padding-left: 270px;
}
.dashboard #logo {
    display: block;
    background-color: #2a2a2a;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    box-shadow: none;
}
.dashboard #logo a.dashboard-logo {
    display:block;
}
.dashboard #logo a {
    display: none;
	margin-left:30px;
}
.utf_left_side {
    float: left;
    width: 70%;
    display: inline-block;
}
.utf_right_side {
    float: right;
    width: 28%;
    display: inline-block;
}
.dashboard #logo a img {
    top: 50%;
    position: relative;
    transform: translate3d(0, -50%, 0);
}
}
@media only screen and (min-width: 1367px) and (max-width: 1700px) {
.utf_left_side {
    float: left;
    width: 70%;
    display: inline-block;
}
.utf_right_side {
    float: right;
    width: 28%;
    display: inline-block;
}
}
@media (max-width: 767px){
.header_widget {
    margin-bottom: 0px;
}
.search_categorie_block{
	display:block
}
.add-review-photos {
    float: left;
    top: -5px;
}
.utf_dashboard_list_box .sort-by .utf_sort_by_select_item {
	display: none;
}
a.close-list-item {
	opacity:0;	
}
.utf_dashboard_list_box ul li:hover a.close-list-item {
	opacity:0;
}
.category_container_item_part a img{
	width:100%;
	object-fit: cover;
	height:300px;
}
.utf_box_widget{
	padding:20px;
}
#footer ul.social_footer_link{
	min-height:180px;
}
.search_container_block h2 {
	font-size: 30px;
	line-height: 40px;
}
.search_container_block h4 {
	font-size:18px;
	line-height:30px;
}
.headline_part span{
	font-size: 16px;
	line-height:28px
}
h2.headline_part, h3.headline_part{
	font-size:26px;
	line-height:36px;
}
.sl-icons-demo .col-md-3{
	width:100%;
	margin:5px 0;
}
.box_icon_two {
	margin-top:10px;
}
.utf_text_content h2 {
    margin-bottom: 30px;
    line-height: 38px;
    font-size: 26px;
}
.utf_text_content p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}
.recent_booking{
	display:none;
}
}
@media (max-width: 609px){
.tp-caption .utf_rev_description_text{
	line-height:26px !important;
	margin-top:-5px !important;
	font-size:17px !important;
}
.rev_slider .tp-mask-wrap :last-child{
	margin-top:5px !important;
	font-size:14px !important;
}
}
@media (max-width: 510px){
.utf_signin_form ul.utf_tabs_nav li{
	width:100%;
	margin-bottom:10px;
}
}
@media (max-width: 479px){
.header_widget {
    margin-bottom: 0px;
}
.utf_box_widget h3{
	font-size:18px;
}
.utf_box_widget h3 i {
    width: 34px;
    height: 34px;
    padding: 8px;
	font-size:16px;    
	margin-right:5px;
}
.utf_text_content{
	padding:80px 0;
}
.utf_dashboard_list_box a.rate-review{
	padding:3px 10px;
	margin-top:60px;
}
.add-pricing-submenu.button, .add-pricing-list-item.button{
	padding:7px 13px;
}
.utf_listing_item-container.compact .utf_star_rating_section {
    padding: 15px 10px;
    bottom: -25px;
    left: 10px;
    right: 10px;
}
.utf_listing_item-container .utf_star_rating_section{
	padding:15px 10px;
	left: 10px;
    right: 10px;
}
.lost_password, .form_forgot_part .checkboxes{
	width:100%;
	margin-bottom:10px;
	text-align:center;
}
.tp-caption .utf_rev_description_text{
	line-height:25px !important;
	margin-top:-5px !important;
	font-size:16px !important;
}
.rev_slider .tp-mask-wrap :last-child{
	margin-top:20px !important;
	font-size:14px !important;
}
.listing_message {
    float: left;
    margin: 0 auto;
    margin-top: 20px;
}
.utf_pricing_list_section span {
    right: 10px;
    font-weight: 600;
    font-size: 13px;
    padding: 2px 15px;
    border-radius: 30px;
}
}
@media (max-width: 359px){
.utf_right_side .header_widget a.button.border{
	padding:8px 15px;
}
}
@media (max-width: 480px) {
.utf_tabs_nav.blog li, .utf_tabs_nav.blog li a, .utf_tabs_nav li, .utf_tabs_nav li a {
	width: 100%;
	padding: 0;
	border-radius: 3px;
	text-align: center;
	border-bottom: none;
}
.utf_about_author img {
	display: none;
}
.utf_about_description {
	margin: 0;
}
.utf_user_menu {
	margin: 10px 0 20px 25px;
	top: 0;
	display: block;
}
.header_widget .sign-in {
	position: relative;
	display: block;
	top: 0px;
	margin-bottom: 10px;
	margin-right: 25px;
}
}
.main_popular_categories {
    display: inline-block;
    margin: 15px auto 0 auto;
    width: 100%;
}
.main_popular_categories h3{
	font-size:18px;
	font-weight:400;
	color:#fff;
	text-align:center;
	margin:10px 0 20px 0;
}
ul.main_popular_categories_list {
    display: block;
    margin: 0 auto;
    text-align: center;
	padding-left:0;
}
ul.main_popular_categories_list li {
    margin: 5px;
    list-style: none;
    display: inline-block;
    text-align: center;
}
ul.main_popular_categories_list li a {
    background: rgba(255, 255, 255, 0.07);
    position: relative;
    width: 150px;
    height: 105px;
    display: block;
    overflow: hidden;
    color: #ffffff;
    border-radius:6px;
    padding: 7px;
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}
ul.main_popular_categories_list li a:hover{
	background:#764c29;
	color:#fff
}
.utf_box {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 6px;
    padding-top: 16px;
	text-align:center;
	font-weight:600;
    box-shadow: 0px 0px 16px 0px rgba(2, 2, 2, 0.1);
    -webkit-box-shadow: 0px 0px 16px 0px rgba(2, 2, 2, 0.1);
}
.utf_box i {	
    font-size: 26px;
    margin-bottom: 5px;
}
@media only screen and (max-width: 2600px) and (min-width: 1400px) {
.utf_testimonial_part{
	background-size: cover;
}
.parallax{
	background-size:100%
}
.utf_left_side {
    float: left;
    width: 70%;
    display: inline-block;
}
.utf_right_side {
    float: right;
    width: 28%;
    display: inline-block;
}
}
@media only screen and (max-width: 1199px) and (min-width: 980px) {
.utf_search_map_section li{
	margin-right:10px;
}
.utf_common_button{
	padding:0 10px !important
}
.listing_filter_block .utf_search_map_section ul {
    padding-left: 10px;
}
}
@media only screen and (max-width: 1239px) and (min-width: 980px) {
ul.main_popular_categories_list{
	padding-left:0;
}	
ul.main_popular_categories_list li a{
	width:140px;
}	
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
.utf_dashboard_list_box{
	margin-bottom:20px;
}
}
@media only screen and (max-width: 979px) and (min-width: 768px) {
ul.main_popular_categories_list li a{
	width:105px;
}
ul.main_popular_categories_list li a p{
	font-size:14px;
}
ul.main_popular_categories_list{
	padding-left:0;
}
.utf_glooph{
	width:46%;
}
.sl-icons-demo .col-md-3{
	width:48%;	
}
.fa-icons-demo .col-md-4{
	width:47%;
}
.utf_category_small_box_part {
	padding:55px 20px;
}
.chosen-container-single .chosen-single div:after {
	right:0;
}
}
body.home-2 .banner p {
    color: #fff;
    opacity: .92;
    margin: 0 auto 15px;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 1.9;
}
h4.headline {
    font-size: 22px;
    line-height: 32px;
    margin: -5px 0 30px 0;
    margin-top: -5px;
}
@media (max-width: 767px){
.utf_category_small_box_part {
	padding:30px 10px;
	border-radius:4px;
}
.utf_category_small_box_part span{
	top:10px;
	right:10px;
}
.utf_listing_categorybox{
	margin:10px 0;
}
.utf_listing_categorie .col-xs-12{
	padding-left:0;
	padding-right:0;
}
.simple_slick_carousel_block .utf_carousel_item {
    padding: 10px 0px 0 0px;
}
.utf_listing_section .social-contact a, .utf_post_content .social-contact a{
	margin-bottom:10px;
}
.utf_dashboard_list_box ul li{
	padding:20px 10px 20px 10px;	
}
.utf_search_map_section li {
    margin-right: 0;
    margin-bottom: 15px !important;
}
.chosen-container-single .chosen-single div:after {
	right:0;
}
.utf_add_review-box{
	padding:30px 20px;
}
.fa-icons-demo .col-md-4{
	width:100%;
	margin:10px 0;
}
}
@media (max-width: 639px){
.search_container_block {
    height: 1000px;
}
.main_search_block {
    height: 680px;
}
ul.main_popular_categories_list li a{
	width:130px;
	height:100px
}
.utf_box p{
	font-size:14px;
}
.search_container_block:before{
	height:98%
}
.main_search_block:before{
	height:100%
}
.main_popular_categories{
	margin-top:20px;
}
.main_inner_search_block {
    top: 51%;
}
ul.main_popular_categories_list{
	padding-left:0;
}
}
.utf_coupon_widget {
    border-radius:4px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    color: #fff;
    transition: .3s;
    display: block;
}
.utf_coupon_overlay {
    background-color: #000;
    position: absolute;
	border-radius:4px;
	opacity: .8;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}
.utf_coupon_widget a.utf_coupon_top {
    padding: 30px;
    position: relative;
    z-index: 10;
    text-align: center;
    cursor: default;
    display: block;
    cursor: pointer;
    background: rgba(255,255,255,.07);
}
.utf_coupon_widget a.utf_coupon_top h3 {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin: 0;
    font-weight: 500;
    color: #fff;
	padding-bottom:15px;
	border-bottom:1px solid rgba(255, 255, 255, 0.2);
}
.utf_coupon_widget .utf_coupon_bottom {
    margin: 0;
    position: relative;
    z-index: 10;
    text-align: center;
	padding:15px;
    border-radius: 0 0 4px 4px;
}
.utf_coupon_expires_date{
	background-color: #764c29;
    display: inline-block;
    border-radius: 4px;
    padding: 5px 15px;
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
    margin-top: 15px;
    color: #fff;
}
.utf_coupon_used {
    padding: 0 20px;
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;
    color: rgba(255,255,255,.85);
}
.utf_coupon_used strong{
	font-weight:600;
}
.utf_coupon_bottom p {
    display: inline-block;
	margin-bottom:0;
	margin-right:10px;
}
.utf_coupon_code {
	background:rgba(255, 255, 255, 0.15);
    color: #fff;
    font-size: 16px;
	font-weight:500;
    letter-spacing: 2px;
    display: inline-block;
    padding: 2px 15px;
    border-radius:4px;
    position: relative;
    z-index: 11;
    text-transform: uppercase;
    transition: .4s;
    border: 2px dashed rgba(255, 255, 255, 0.4);
}
.header-button .dashboard_header_button_item:first-child {
    padding-left: 15px;
    margin-right: 25px;
}
.header-button .dashboard_header_button_item {
    cursor: pointer;
}
.dashboard_header_button_item i{
	position:relative;
	font-size:20px;
	line-height:30px;
}
.dashboard_header_button_item:hover i{
	color:#323232
}
.dashboard_header_button_item {
	position: relative;
	display: inline-block;
	cursor: pointer;
	padding: 9px 10px 11px 10px;
	margin-top:3px;
	align-items: center;
	background: #f9f9f9;
	border-radius: 30px;
	margin-right: 30px;
	width: 46px;
	height: 46px;
	vertical-align: middle;
	text-align: center;
}
.header-button .dashboard_header_button_item > i {
    vertical-align: middle;
    font-size: 28px;
    margin-top: 10px;
}
.has-noti > i::after {
    content: '';
    height: 8px;
    width: 8px;
    background:#764c29;
    position: absolute;
    border-radius: 100%;
    top:-5px;
    right: -5px;
}
.header-button .dashboard_header_button_item > i {
    font-size: 28px;
}
.header-button .dashboard_header_button_item .dashboard_notifi_dropdown {
    top: 60px;
    left: auto;
    right: -68px;
    transform-origin: right top;
}
.dashboard_notifi_item {
    display: flex;
    padding: 15px 20px;
    border-bottom: 1px solid #f2f2f2;
    transition: all .5s ease;
}
.dashboard_notifi_dropdown, .setting-dropdown {
    position: absolute;
    z-index: 9999;
    min-width: 250px;
    background: #fff;
    box-shadow:0px 0px 10px 0px rgba(0,0,0,0.12);
    transform: scale(0);
    transition: all .4s ease;
    transform-origin: right top;
	border-radius:4px;
    top: 100%;
    left: 0;
}
.dashboard_notifi_title {
    padding: 22px;
    border-bottom: 1px solid #f2f2f2;
    cursor: default;
	text-align:left;
}
.dashboard_notifi_title p {
    margin-bottom:0;
    font-size: 16px;
	font-weight:600;
    color: #808080;
}
.dashboard_notifi_item .content {
    width: calc(100% - 55px);
    text-align: left;
    font-size: 14px;
}
.pad-tb-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
.bg-c1.red i {
    background: #764c29;
}
.dashboard_notify_bottom a:hover{
    color: #764c29;    
}
.dashboard_notify_bottom a {
    color: #666;
    font-weight:600;
}
.dashboard_notifi_item .content p {
    color: #555;
    line-height: 1.5;
    padding-top: 5px;
    margin-bottom:0px;
}
.dashboard_notifi_item .content p b{
	font-weight:600;
}
.dashboard_notifi_item .content .date {
    font-size: 13px;
	font-weight:500;
    color: #999;
}
.dashboard_notifi_item:hover {
    background: #f7f7f7;
}
.bg-c1 {
    display: flex;
    margin-top: 10px;
}
.bg-c1.green i {
    background:rgba(84, 186, 29, 1);
}
.bg-c1 i {
    font-size: 18px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    margin-right: 15px;
    color: #fff !important;
}
.header-button .dashboard_header_button_item .dashboard_notifi_dropdown {
    top: 60px;
    left: auto;
    right: -68px;
    transform-origin: right top;
}
.show-dropdown .js-dropdown {
    transform: scale(1);
}
.dashboard_notifi_dropdown {
    left: -300px;
    top: 61px;
}
.dashboard_notifi_dropdown::before {
    content: '';
    position: absolute;
    right: 12px;
    bottom: 100%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fff;
}
@media only screen and (max-width: 1024px) {
.utf_user_menu {
    display: block;
    margin: 0px 0 30px 100px;
}
.dashboard_header_button_item {
    position: relative;
    display: inline-block;
    cursor: pointer;
    align-items: center;
    float: left;
}
.dashboard_notifi_dropdown {
    right: -300px;
	left:0;
    top: 62px;
}
.dashboard_notifi_dropdown::before {
    content: '';
    position: absolute;
	right:0;
	width: 26px;
    left: 10px;
    bottom: 100%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fff;
}
.dashboard_notifi_dropdown, .setting-dropdown {
    transform-origin: left top;    
}
}
@media only screen and (max-width: 767px) {
.utf_user_menu {
    top:9px;
}	
.dashboard_notifi_dropdown {
    right: -300px;	
}
.home_main_search_part {
    height: 850px;
}
}
@media only screen and (max-width:479px) {
.home_main_search_part {
    height: 1000px;
}
}
@media only screen and (max-width: 365px) {
.dashboard_notifi_dropdown {
    right: -240px;	
}
}
a.social_bt.facebook_btn{
    background-color: #3b5998;
}
a.social_bt.google_btn {
    background-color: #dc4e41;
}
a.social_bt.facebook_btn i, a.social_bt.google_btn i{
    text-align: left;
    padding-left: 10px;
    float: left;
}
a.social_bt:hover {
    -webkit-filter: brightness(115%);
    filter: brightness(115%);
}
a.social_bt {    
    text-align: center;
    color: #fff;
    margin-bottom: 15px;
    display: block;
    padding: 15px;
    line-height: 1;
    border-radius: 4px;
    position: relative;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.booking_widget_box h3 .price{
    float:right;
}
.booking_widget_box h3 .price>span {
    font-size: 24px;
    font-weight: 600;
}
.booking_widget_box h3 .price>span>small {
    font-size: 13px;
    font-weight: 500;
    padding-left:4px;
    color:#707070;
}
.add_to_wishlist{
    width:220px;
    font-size:16px;
    font-weight: 400;
    margin: 0 auto;
    display: block;
}
.reviews-container{
    margin-top:30px;	
	margin-bottom:30px;	
}
#review_summary {
    text-align: center;
    color: #fff;
    padding: 20px 10px;
    -webkit-border-radius:4px;
    -moz-border-radius:4px;
    -ms-border-radius:4px;
    border-radius:4px;
}
#review_summary, .reviews-container .progress-bar {
    background-color: #0054a6;
}
#review_summary strong {
    font-size: 42px;
    display: block;
    line-height: 1;
    font-weight: 700;
}
#review_summary em {
    font-style: normal;
    font-weight: 600;
    display: block;
}
#review_summary small {
    font-size: 86%;
    font-weight:400;
}
.review_progres_title small{
    margin-top: -5px;
    display: block;
    font-size: 90%;
}
.review_progres_title small strong{
    font-weight:600
}
.reviews-container .progress {
    margin-bottom: 14px;
}
.progress {
    display: -ms-flexbox;
    display: flex;
    height:16px;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius:30px;
}
#review_summary, .reviews-container .progress-bar {
    background-color: #764c29;
}
.booking_widget_box .select_date_box{
    position:relative
}
.booking_widget_box .select_date_box i{
    position: absolute;
    top: 37%;
    transform: translateY(-50%);
    right: 28px;
    z-index: 10;
    color: #999;
}
.unique_search_block:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.4);
}
.search-input-icon{
    width:330px;
    padding:0 0px 0 20px;
    position:relative;
    margin-top:3px;
}
.search-input-icon input{
    padding-right:30px;
    background: #fff;
}
.search-input-icon i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 21px;
    z-index: 10;
    color: #999;
}
.option-set {
    text-align: center;
    display: inline-block;
    padding: 15px 0px;
    background: #f9f9f9;
    border-radius: 10px;
    margin-bottom: 30px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.05);
}
.option-set li {
    display: inline-block;
    margin-right: 2px;
}
.option-set li a {
    padding: 5px 16px;
    display: block;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-weight: 500;
    margin: 0;
    font-size: 15px;
    border-radius: 6px;
    background-color: #ffffff;
    color: #666;
    line-height: 26px;
    transition: all .3s;
}
.option-set li a.selected {
    color: #fff;
    background-color: #764c29;
}
.option-set li a:hover {
    color:#fff;
    background-color: #764c29;
}
.category_container_item_part a .featured {
	color: #fff;
    background-color: #F12C15;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    padding: 50px 0 15px;
    position: absolute;
    left: -130px;
    text-align: center;	
    text-transform: uppercase;
    top: -75px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: right top 0;
    transform-origin: right top 0;
    width: 180px;
    z-index:99;
}
@media (max-width: 991px) {
.search-input-icon{
    width:100%;
    padding:0;	
}
.isotope-wrapper .col-md-12{
    width:100%;
}
}
@media (max-width: 767px) {
.option-set li a{
    margin-bottom:10px;
}
.option-set li:last-child a{
    margin-bottom:0;
}
}
@media (max-width: 1259px) {
.reviews-container{
    margin-top:30px;	
	margin-bottom:30px;
	padding-bottom:10px;
	border-bottom:1px solid #e0e0e0;
}
.review_progres_title{
	margin-top:10px;
	float:left;
}
.reviews-container .col-lg-9{
	margin-top:10px;
}
.reviews-container .col-lg-9 .col-lg-2 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    width: 100px;
}
.reviews-container .col-lg-9 .col-lg-9 {
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    width: 72%;
	margin-top: 10px;
	float:left;
}
.reviews-container .col-lg-9 .col-lg-1 {
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    width: 80px;
	margin-top:10px;
	float:left;
}
}
@media (max-width: 320px){
.header_widget .button, .header_widget .button.border{
    min-width: 110px;	
}
}
@media (max-width: 639px) {
.reviews-container .col-lg-9 .col-lg-2 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    width: 90px;
}
.reviews-container .col-lg-9 .col-lg-9 {
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    width: 48%;
    margin-top: 10px;
    float: left;
}
.reviews-container .col-lg-9 .col-lg-1 {
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    width: 70px;
    margin-top: 10px;
    float: left;
}
}
@media only screen and (min-width: 992px) and (max-width: 1239px){
.utf_dashboard_content {
    margin-top: 95px;
}	
.utf_dashboard_navigation {
	margin-top:75px;
}
.utf_dashboard_list_box h4 {
    font-size: 15px;
    line-height: 40px;
}
.utf_dashboard_list_box ul li {
    padding: 15px;
	line-height: 24px;
}	
.utf_dashboard_list_box .utf_sort_by_select_item select {
	width:140px;
}
.booking_widget_box h3 .price>span {
    font-size: 18px;    
}
.utf_box_widget h3 {
    font-size: 18px;
}	
.utf_box_widget h3 i {
    margin-right: 5px;    
}
.utf_social_icon li {
    margin-right: 8px;
}	
.utf_box_widget {
    padding: 20px 15px;
}	
}
@media only screen and (max-width: 1365px) and (min-width: 1240px){
.dashboard #logo {
    min-width: 247px;
    max-width: 247px;
}
}
/* ---------------------------------- */
/* Bootstrap-select
------------------------------------- */
select.bs-select-hidden, select.selectpicker {
	display: none !important;
}
.bootstrap-select .btn {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.bootstrap-select > .dropdown-toggle {
	width: 100%;
	padding-right: 25px;
	z-index: 1;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
	color: #808080;
}
.bootstrap-select > select {
	position: absolute !important;
	bottom: 0;
	left: 50%;
	display: block !important;
	width: 0.5px !important;
	height: 100% !important;
	padding: 0 !important;
	opacity: 0 !important;
	border: none;
}
.bootstrap-select > select.mobile-device {
	top: 0;
	left: 0;
	display: block !important;
	width: 100% !important;
	z-index: 2;
}
.has-error .bootstrap-select .dropdown-toggle, .error .bootstrap-select .dropdown-toggle {
	border-color: #b94a48;
}
.bootstrap-select.fit-width {
	width: auto !important;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
	width: 100%;
}
.bootstrap-select.form-control {
	margin-bottom: 0;
	padding: 0;
	border: none;
}
.bootstrap-select.form-control:not([class*="col-"]) {
	width: 100%;
}
.bootstrap-select.form-control.input-group-btn {
	z-index: auto;
}
.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
	border-radius: 0;
}
.bootstrap-select.btn-group:not(.input-group-btn), .bootstrap-select.btn-group[class*="col-"] {
	float: none;
	display: flex;
	margin-left: 0;
}
.bootstrap-select.btn-group.dropdown-menu-right, .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right, .row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
	float: right;
}
.form-inline .bootstrap-select.btn-group, .form-horizontal .bootstrap-select.btn-group, .checkbox .bootstrap-select.btn-group {
	margin-bottom: 0;
}
.checkbox-lg .bootstrap-select.btn-group.form-control, .checkbox-sm .bootstrap-select.btn-group.form-control {
	padding: 0;
}
.checkbox-lg .bootstrap-select.btn-group.form-control .dropdown-toggle, .checkbox-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
	height: 100%;
	font-size: inherit;
	line-height: inherit;
	border-radius: inherit;
}
.form-inline .bootstrap-select.btn-group .form-control {
	width: 100%;
}
.bootstrap-select.btn-group.disabled, .bootstrap-select.btn-group > .disabled {
	cursor: not-allowed;
}
.bootstrap-select.btn-group.disabled:focus, .bootstrap-select.btn-group > .disabled:focus {
	outline: none !important;
}
.bootstrap-select.btn-group.bs-container {
	position: absolute;
	height: 0 !important;
	padding: 0 !important;
}
.bootstrap-select.btn-group.bs-container .dropdown-menu {
	z-index: 1060;
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
	overflow: hidden;
	width: 100%;
	line-height: 48px;
	text-align: left;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 90%;
	float: left;
	height: 48px;
	display: block;
}
.bootstrap-select.btn-group .dropdown-menu li.hidden {
	display: none;
}
.language-switcher.bootstrap-select.btn-group .dropdown-toggle .filter-option, .sort-by .bootstrap-select.btn-group .dropdown-toggle .filter-option {
	max-width: 100%;
	float: left;
}
.sort-by .bootstrap-select.btn-group .dropdown-toggle .filter-option {
	line-height: 20px;
	height: auto;
}
.bootstrap-select.btn-group .dropdown-toggle .caret {
	position: absolute;
	top: 50%;
	right: 15px;
	margin-top: -2px;
	vertical-align: middle;
	transition: 0.35s;
}
.bootstrap-select.open .dropdown-toggle .caret {
	transform: rotate(180deg)
}
.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
	width: 100%;
}
.bootstrap-select.btn-group .dropdown-menu {
	min-width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 16px;
}
.bootstrap-select.btn-group .dropdown-menu.inner {
	position: static;
	float: none;
	border: 0;
	padding: 0;
	border-radius: 0;
	box-shadow: none;
}
.bootstrap-select.btn-group .dropdown-menu li {
	position: relative;
	border-bottom:1px solid rgba(0, 0, 0, 0.05);
}
.bootstrap-select.btn-group .dropdown-menu li:last-child{
	border-bottom:0px solid rgba(0, 0, 0, 0.05);
	margin-bottom:0px;
}
.bootstrap-select.btn-group .dropdown-menu li.disabled {
	opacity: 0.75;
}
.bootstrap-select.btn-group .dropdown-menu li.active small {
	color: #fff;
}
.bootstrap-select.btn-group .dropdown-menu li.disabled a {
	cursor: not-allowed;
}
.bootstrap-select.btn-group .dropdown-menu li a {
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.bootstrap-select.btn-group .dropdown-menu li a span.text {
	display: inline-block;
}
.bootstrap-select.btn-group .dropdown-menu li small {
	padding-left: 7px;
	opacity: 0.8;
}
.bootstrap-select.btn-group .dropdown-menu .notify {
	position: absolute;
	bottom: 5px;
	width: calc(100% - 20px);
	margin: 0;
	min-height: 26px;
	padding: 3px 5px;
	pointer-events: none;
	padding: 5px 10px;
	background: #333;
	color: #fff;
	margin: 5px;
	margin-bottom: 5px;
	border-radius: 4px;
	white-space: nowrap;
	font-size: 14px;
	border: none;
}
.bootstrap-select.btn-group .no-results {
	padding: 5px 10px;
	background: #f6f6f6;
	margin: 5px 0;
	margin-bottom: 0;
	border-radius: 4px;
	white-space: nowrap;
	font-size: 14px;
}
.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
	position: static;
}
.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
	position: static;
	top: auto;
	margin-top: -1px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
	white-space: normal;
}
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
	z-index: 1061;
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
	content: '';
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid rgba(204, 204, 204, 0.2);
	position: absolute;
	bottom: -4px;
	left: 9px;
	display: none;
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
	content: '';
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid white;
	position: absolute;
	bottom: -4px;
	left: 10px;
	display: none;
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
	bottom: auto;
	top: -3px;
	border-top: 7px solid rgba(204, 204, 204, 0.2);
	border-bottom: 0;
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
	bottom: auto;
	top: -3px;
	border-top: 6px solid white;
	border-bottom: 0;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
	right: 12px;
	left: auto;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
	right: 13px;
	left: auto;
}
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before, .bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
	display: block;
}
.bs-searchbox, .bs-actionsbox, .bs-donebutton {
	padding: 0 5px;
}
.bs-actionsbox {
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0 5px;
	margin-bottom: 10px;
	margin-top: -5px;
}
.bs-actionsbox div {
	display: flex;
}
.bootstrap-select.btn-group .bs-actionsbox div button {
	flex: 1;
	background: #f6f6f6;
	box-shadow: none;
	padding: 5px;
	height: 36px;
	font-size: 14px;
	color: #777;
	transition: 0.3s;
}
.bootstrap-select.btn-group .bs-actionsbox div button:first-child {
	border-radius: 4px 0 0 4px;
	background-color: #f0f0f0;
}
.bootstrap-select.btn-group .bs-actionsbox div button:last-child {
	border-radius: 0 4px 4px 0;
}
.bootstrap-select.btn-group .bs-actionsbox div button:hover {
	background: #66676b;
	color: #fff;
}
.bs-actionsbox .btn-group button {
	width: 50%;
}
.bs-donebutton {
	float: left;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.bs-donebutton .btn-group button {
	width: 100%;
}
.bs-searchbox + .bs-actionsbox {
	padding: 0;
}
.bs-searchbox .form-control:focus, .bs-searchbox .form-control {
	margin-bottom: 0;
	width: 100%;
	float: none;
	box-shadow: none;
	background: #f4f4f4;
	margin: 0px 0 8px 0;
	height: 38px;
	line-height: 38px;
	padding: 10px;
}
.bs-searchbox {
	position: relative;
}
.bs-searchbox:after {
	font-family: "Material-Icons";
	content: "\e982";
	font-size: 20px;
	width: 20px;
	height: 20px;
	line-height: 0;
	display: inline-block;
	position: absolute;
	margin: 0;
	color: #a0a0a0;
	right: 15px;
	top: 18px;
}
.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 5px dashed;
	border-top: 5px solid #aaa;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
}
.dropup, .dropdown {
	position: relative;
}
.dropdown-toggle:focus {
	outline: 0;
}
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	padding:0px;
	margin:0;
	font-size: 15px;
	text-align: left;
	list-style: none;
	background-color:#ffffff;
	border: none;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
}
.dropdown-menu.pull-right {
	right: 0;
	left: auto;
}
.dropdown-menu .divider {
	display: none;
}
.dropdown-menu > li > a {
	display: block;
	padding: 8px 15px;
	padding-right: 35px;
	clear: both;
	font-weight: normal;
	line-height: 22px;
	color: #808080;
	border-radius: 0px;
}
.bootstrap-select:before {
	content: "";
	position: absolute;
	z-index: 2100;
	bottom: 0px;
	left: 0;
	height: 8px;
	width: 100%;
	background-color: #ffffff;
	display: block;
	opacity: 0;
	pointer-events: none;
	transition: 0.4s;
}
.bootstrap-select.open:before {
	opacity: 1;
}
.dropdown-menu > li > a:hover, .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover {
	color: #66676b;
	text-decoration: none;
	background-color: rgba(102,103,107,0.07);
}
.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover {
	color: #777;
}
.dropdown-menu > .disabled > a:hover {
	text-decoration: none;
	cursor: not-allowed;
	background-color: transparent;
	background-image: none;
}
.dropdown-menu.inner::-webkit-scrollbar {
width: 5px;
}
 .dropdown-menu.inner::-webkit-scrollbar-track {
 background-color: rgba(0,0,0,0.0);
 border-radius: 12px;
}
 .dropdown-menu.inner::-webkit-scrollbar-thumb {
 border-radius: 12px;
 background-color: #d8d8d8;
}
 .dropdown-menu.inner::-webkit-scrollbar-thumb:hover {
 border-radius: 12px;
 background-color: #c8c8c8;
}
.bootstrap-select .dropdown-menu {
	opacity: 0;
	transition: 0.4s;
	display: block;
	pointer-events: none;
	visibility: hidden;
	cursor: default;
}
.bootstrap-select.open .dropdown-menu {
	opacity: 1;
	pointer-events: all;
	visibility: visible;
	padding-bottom:10px;
}
.open > a {
	outline: 0;
}
.dropdown-menu-right {
	right: 0;
	left: auto;
}
.dropdown-menu-left {
	right: auto;
	left: 0;
}
.dropdown-header {
	display: block;
	padding: 8px 15px;
	line-height: 20px;
	white-space: nowrap;
	background: #f6f6f6;
	color: #777;
	border-radius: 0px;	
}
.dropdown-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 990;
}
.pull-right > .dropdown-menu {
	right: 0;
	left: auto;
}
.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
	content: "";
	border-top: 0;
	border-bottom: 4px dashed;
	border-bottom: 4px solid \9;
}
.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
	top: 100%;
	bottom: auto;
	margin-bottom: 2px;
}
 @media (min-width: 767px) {
.navbar-right .dropdown-menu {
	right: 0;
	left: auto;
}
.navbar-right .dropdown-menu-left {
	right: auto;
	left: 0;
}
#login-register-page button{
	margin-top:10px;
}
}
.bootstrap-select.btn-group {
	position: relative;
}
.bootstrap-select.btn-group button {
	position: relative;
	display: inline-block;
	height: 48px;
	line-height: 0;
	padding: 0 15px;
	margin: 0;
	outline: none;
	font-size: 16px;
	color: #808080;
	max-width: 100%;
	width: 100%;
	box-sizing: border-box;
	display: block;
	background-color: #ffffff;
	font-weight: 400;
	opacity: 1;
	border-radius: 4px;
	border: none;	
}
.hide-tick .check-mark {
	display: none !important;
}
.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
	position: absolute;
	display: inline-block;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
	margin: 0;
	line-height: 0;
	width: 20px;
	height: 20px;
	text-align: center;
}
.bootstrap-select.btn-group .dropdown-menu li span.check-mark:before {
	font-family: "Feather-Icons";
	content: "\e92b";
	font-size: 18px;
	transition: all 0.3s;
	display: inline-block;
	top: 50%;
	right: 0;
	position: absolute;
	margin: 0;
	opacity: 0;
	transform: scale(0.5);
}
.bootstrap-select.btn-group .dropdown-menu li.selected span.check-mark:before {
	opacity: 1;
	transform: scale(1);
	transition: all 0.35s cubic-bezier(0.3, -.41, .19, 2), opacity 0.3s;
}
.bootstrap-select .dropdown-menu li.selected a span.check-mark:before, .bootstrap-select .dropdown-menu li.selected:hover a span.check-mark:before {
	color: #66676b;
}
.bootstrap-select .glyphicon:not(.check-mark) {
	font-size: 17px;
	line-height: 0;
	position: relative;
	top: 1px;
	margin-right: 3px;
}
.bootstrap-select .filter-option .glyphicon {
	position: relative;
	top: 1px;
}
.with-border .dropdown-menu, .with-border.bootstrap-select.btn-group button {
	background:#fbfdff;
	border: 1px solid #dde6ef;
	box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.05);
}
.with-border.bootstrap-select:before {
	width: calc(100% - 2px);
	left: 1px;
}
.dropdown-menu > li > a:hover, .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover {
    background-color: rgba(255, 138, 0,0.07);
}
.dropdown-menu > li > a:hover, .dropdown-menu > .active > a, .bootstrap-select .dropdown-menu li.selected a span.check-mark:before{
	color: #764c29;
}
@media (max-width: 991px) {
.main_input_search_part .bootstrap-select.btn-group button {
    height: 55px;
}	
}
.utf-chosen-cat-single{
	border-radius: 4px;
	border:1px solid #dbdbdb;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.03);
    background-color: #fff;
	margin-bottom:20px;
}
.utf-chosen-cat-single .bootstrap-select .dropdown-menu {
    width: 100%;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    border-radius: 0px;
	margin-top: 4px;
}
#utf_rev_slider_block .bootstrap-select.btn-group .dropdown-menu.inner{
    height: 150px;
}
/*# sourceMappingURL=stylesheet.css.map */
